import React, { useState, useEffect } from 'react'
import TurnPhoneH from '../other/TurnPhoneH'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import isAuthenticated from '../auth/auth'
import { Grid, Button, Typography, MenuItem, Select, Modal, Box, CircularProgress, TextField } from "@mui/material"
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { fullRegister, getOrganizations, googleSignup, login, userLog } from '../_api/index'
import { GoogleLogin, useGoogleLogin, googleLogout, } from '@react-oauth/google'
import { setLoading } from '../../redux-features/user/userSlice'
import ApiSauce from '../../services/networkRequest'
import { USER } from '../../config/WebServices';
import { handleUser } from '../../redux-features/userDetails'
import { useDispatch, useSelector } from 'react-redux'
import { FormControl } from 'react-bootstrap'
import { getAdmindzByOrg } from '../_api/journal'
export default function GetStarted() {

    let isNorthwell = process.env.REACT_APP_API_LINK.includes("northwell")
    window.onresize = handleOrientation;


    const navigate = useNavigate()
    const dispatch = useDispatch()
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const link = queryParams.get('returnURL');
    const anchor = window.location.hash.substring(1);
    const { t } = useTranslation();
    const { mutate: log } = useMutation(userLog);
    const [isMobile, setIsMobile] = useState(false);
    const [message, setMessage] = useState("")
    const [modalOpen, setModalOpen] = useState(false)
    const [organization, setOrganization] = useState("")
    const [organizations, setOrganizations] = useState([])
    const [teacher, setTeacher] = useState("")
    const [other, setOther] = useState("")
    const [payload, setPayload] = useState()


      const _g_login = useGoogleLogin({
        onSuccess: async tokenResponse => {
          loginData({tokenResponse:tokenResponse.access_token, loginType: "google" })
        },
        flow: 'implicit', // implicit is the default
      });
    const queryClient = useQueryClient();
    const register = useSelector(state => state.user.register)

    function handleOrientation() {
        setIsMobile(window.innerHeight < window.innerWidth && window.innerWidth < 1000)
    }
    const { isLoading: isLoading, isSuccess: isSuccess, mutate: loginData } = useMutation(login, {
        onSuccess: (response) => {
            console.log("🚀 ~ GetStarted ~ response:", response)
            loginResponseHandling(response)
        },
        onError: (error) => {
            if(error?.response?.data?.payload){
                let _payload = error?.response?.data?.payload
                setPayload({
                    firstName:_payload.given_name,
                    lastName:_payload.family_name,
                    email:_payload.email,
                    picture: _payload.picture
                })
            }
            loginResponseHandling(null, error)
        }
    });
    const { isLoading: isLoading2, data: orgs } = useQuery('get-organizations', getOrganizations)
    useEffect(() => {
        if (!isLoading) {
            setOrganizations(orgs?.data || [])
            setOrganization(orgs?.data[0]?._id || "")
        }
    }, [isLoading, orgs])

    const { isLoading: adminsLoading, data: adminsList } = useQuery(['admins-by-org', organization], () => getAdmindzByOrg(organization), {
        enabled: Boolean(organization)
    })

    const errorMessage = (error) => {
        console.log("🚀 ~ errorMessage ~ error:", error)
    }

    const loginResponseHandling = (response, error) => {
        console.log("🚀 ~ loginResponseHandling ~ error:", error)
        if (error) {
            const { message } = error
            if (message === "Request failed with status code 404") {
                // setPayload({
                //     firstName:response.payload
                // })
                setModalOpen(true)
                return setMessage("Sorry, User does not exist")
            }
            return setMessage(`Internal Error, Sorry for inconvenience, Please try again!`)
        }
        const { token, message } = response
        if (token) {
            setMessage(`User logged in successfully`)
            localStorage.setItem("token", token);
            getUserDetails(token)
            dispatch(setLoading(true))
            queryClient.invalidateQueries('fetching-all-data');

            if (register) return navigate("/video")
            if (link) return navigate(link + "#" + anchor)
            navigate('/module')
            return

        }
        if (message === "No such User !") {
            return setMessage(`Sorry, this email is not registered for login, Please Register and try again`)
        }
        if (response.message === "Invalid Credentials") return setMessage(`Given password is not valid, Please try again or reset your password`)
        if (response.message === "Password not found") return setMessage(`Password not found. Please login using Google.`)

    }

    const getUserDetails = async (token) => {
        try {
            const { user } = await ApiSauce.getWithToken({ url: USER, token })
            user.token = token
            dispatch(handleUser(user))
        } catch (error) {
            console.log("🚀 ~ file: Login.js:98 ~ getUserDetails ~ error:", error)
        }
    }
    const { isLoading: reqLoading, isSuccess: isSuccess2, mutate: GoogleSignup } = useMutation(googleSignup, {
        onSuccess: (response) => {
            console.log("🚀 ~ GetStarted ~ response:", response)
            queryClient.invalidateQueries('request-code');
            loginResponseHandling(response,null)
            setMessage("Registered Successfully !")
            // setServerError(false)
            // setTimeout(() => {
            //     navigate(navigateLink)
            // }, 1000);
        },
        onError: (error) => {
            if (error.message === "Request failed with status code 409") {
                setMessage("User already registered, try with different Email")
                // setServerError(true)
            } else {
                setMessage("Internal error, sorry for inconvenience, please try again!")
                // setServerError(true)
            }
        }
    });

    const handleGoogleSignup = () => {
        const data = {
            ...payload,
            OrganizationId: organization,
            teacher: teacher,
        }
        GoogleSignup(data)

    }
    useEffect(() => {
        setIsMobile(window.innerHeight < window.innerWidth && window.innerWidth < 1000)
    }, [window.innerWidth, window.innerHeight, window.orientation]);

    if (isMobile) {
        return (<TurnPhoneH content="Turn your phone to side to get start" />)
    } else
        return (
            <>{isAuthenticated(localStorage.getItem("token")) && navigate("/module")}

                <Grid className="getstarted" container justifyContent="space-between" sx={{ height: "100vh" }}>
                    <Grid item xs={12} sm={12} md={6}>
                        <img className="getstarted__image" src="/assets/svg/logo.gif" style={{ width: '180px' }} alt="" />
                        <Grid item container justifyContent="center" alignItems="center" xs={12} sm={12} sx={{ display: { xs: 'flex', sm: 'flex', md: 'none' } }}>
                            <img className="getstarted__illustration" src="../assets/img/meetone.png" alt="" />
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <Link to="/full-register"><Button variant="contained" className="getstarted__button getstarted__buttonone">{t("codeRegister.btnSignUp")}</Button></Link>
                        </Grid>
                        <Grid item xs={12} sm={8} mt={3}>
                            <Link to="/login"><Button variant="outlined" className="getstarted__button getstarted__buttontwo">Continue with email</Button></Link>
                        </Grid>
                        <Grid item xs={12} sm={8} mt={3}>
                          <Button onClick={() => _g_login()} variant="outlined" className="getstarted__button google_button" 
                            style={styles.googleBtn}>
                                <img src='../assets/img/google-icon.png' style={{ width: 35 }} />Continue With Google</Button>
                        </Grid>
                        {/* <Grid item xs={12} sm={8} mt={3}>
                            <div className="loginscreen__btnwidth" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <GoogleLogin
                                    width={400}
                                    shape="pill"
                                    
                                    auto_select={false}
                                    // ux_mode="popup"
                                    // type="standard"
                                    // theme='filled_blue'
                                    onSuccess={(response) =>{
                                        console.log("response",response)
                                         loginData({ ...response, loginType: "google" })
                                        }}
                                    onError={errorMessage}
                                    useOneTap
                                />
                            </div>
                        </Grid> */}
                    </Grid>
                    <Grid item container justifyContent="center" alignItems="center" xs={12} sm={12} md={5} sx={{ display: { xs: 'none', sm: 'none', md: 'flex' } }}>
                        <img className="getstarted__illustration" src="../assets/img/meetone.svg" alt="" />
                    </Grid>

                </Grid>
                <Modal
                    open={modalOpen}
                    onClose={() => setModalOpen(prev => !prev)}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style} >
                        <Typography id="modal-modal-title" variant="h6" component="h3">
                            Please provide following information to proceed
                        </Typography>

                        <Grid container>
                            <Grid item xs={12} my={1}>
                                {/* <FormControl sx={{ width: "70%" }}> */}
                                <Typography pl={1} sx={{ fontWeight: "600", fontSize: "12px", color: "#242424", textTransform: "uppercase" }}>
                                    Organization <span style={{ color: 'red' }}>*</span>
                                </Typography>

                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={organization}
                                    label={"Text"}
                                    className="reqcode__role"
                                    sx={{ '& legend': { display: 'none' }, width: '100%', '& fieldset': { top: 0 } }}
                                    size="small"
                                    onChange={(e) => setOrganization(e.target.value)}
                                    defaultValue="Student"

                                >
                                    {
                                        isLoading ?
                                            <MenuItem >Loading...</MenuItem>
                                            :
                                            organizations?.sort((a, b) => a?.name?.localeCompare(b?.name))?.map((org) => <MenuItem key={org?._id} value={org?._id}>{org?.name}</MenuItem>)
                                    }
                                </Select>
                                {/* </FormControl> */}
                            </Grid>
                            <Grid item xs={12} my={1}>
                                <Typography pl={1} sx={{ fontWeight: "600", fontSize: "12px", color: "#242424", textTransform: "uppercase" }}>
                                    Teacher <span style={{ color: 'red' }}>*</span>
                                </Typography>

                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={!!other ? other : teacher}
                                    label={"Teacher"}
                                    className="reqcode__role"
                                    sx={{ '& legend': { display: 'none' }, width: '100%', '& fieldset': { top: 0 } }}
                                    size="small"
                                    onChange={(e) => {
                                        if (e.target.value !== 'Other') {
                                            setTeacher(e.target.value)
                                            setOther("")
                                        } else {
                                            setOther(e.target.value)
                                            setTeacher("")
                                        }
                                    }}
                                    defaultValue="Select Teacher"
                                    disabled={Boolean(!organization)}
                                >
                                    <MenuItem disabled value="xyz" >Select Teacher</MenuItem>
                                    {
                                        adminsLoading ?
                                            <MenuItem disabled value="xyz" >Loading...</MenuItem>
                                            :
                                            adminsList?.map((admin) => <MenuItem key={admin?.username} value={admin?.username}>{admin?.username}</MenuItem>)

                                    }
 
                                    <MenuItem value="Other">Other</MenuItem>
                                </Select>
                            </Grid>
                            {
                                other === "Other" &&
                                <Grid item xs={12} my={1}>
                                    <TextField size="small" placeholder={`Enter ${isNorthwell ? "Teacher / Mentor Name" : "Teacher Name"}`} type="text" style={{ width: "100%" }} id="outlined-basic"
                                        onChange={(e) => setTeacher(e.target.value)} value={teacher} variant="outlined"
                                        sx={{ fontFamily: "Poppins", '& legend': { display: 'none' }, '& fieldset': { top: 0 } }}
                                    />
                                </Grid>
                            }
                            <Grid item xs={12} my={1}>
                                <Button variant="contained" disabled={!organization || !teacher} sx={{ width: "100%", background: "#616DED" }} 
                                    onClick={handleGoogleSignup}>{
                                    reqLoading ?
                                        <CircularProgress style={{ color: 'white' }} />
                                        : "Register"
                                }</Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Modal>
                {/* <div className="body-bg-gradient">
                    <div className="container splash-screen-container">
                        <div className="container-inner">
                            <img width="130px" className="logo" src="/assets/svg/logo-white.gif" alt="" />
                            <div className="content">
                                <div className="main" style={{ marginTop: '70px' }}>
                                    <img src="../assets/img/meet.svg" alt="" />
                                </div>
                                <div className="buttons" style={{ marginTop: '85px' }}>
                                    <Link to="/got-a-code">
                                        <button className="get-started"
                                            onClick={() => log(
                                                {
                                                    pageName: 'Get Started',
                                                    buttonName: 'Get Started'
                                                })}
                                        >{t('getStarted.btnGetStarted')}</button>
                                    </Link>
                                    <Link to="/login">
                                        <button className="login"
                                            onClick={() => log(
                                                {
                                                    pageName: 'Get Started',
                                                    buttonName: 'Login'
                                                })}
                                        >{t('getStarted.btnLogin')}</button>
                                    </Link>
                                    <div className="redirect">
                                        <Link to="/reset-password">
                                            <h1 className="forgot-password"
                                                onClick={() => log(
                                                    {
                                                        pageName: 'Get Started',
                                                        buttonName: 'Forgot Password'
                                                    })}
                                            >{t('getStarted.btnForgotPassword')}</h1>
                                        </Link>

                                        <Link to="/customer-support">
                                            <h1 className="customer-support"
                                                onClick={() => log(
                                                    {
                                                        pageName: 'Get Started',
                                                        buttonName: 'Customer Support',
                                                    })}
                                            >{t('getStarted.btnCustomerSupport')}</h1>
                                        </Link>
                                        <Link to="/help-desk">
                                            <h1 className="customer-support"
                                                onClick={() => log(
                                                    {
                                                        pageName: 'Get Started',
                                                        buttonName: 'Customer Support',
                                                    })}
                                            >Help Desk</h1>
                                        </Link>

                                    </div>
                                </div>
                                <div className="copy-right mt-3">
                                    <span>{t('getStarted.copyRight')}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
            </>
        )
}
const style = {
    position: "absolute",
    top: "40%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    bgcolor: "background.paper",
    // boxShadow: 24,
    p: 4,
    borderRadius: 3,
};
const styles = {
    googleBtn:{
        textTransform:"none",
        borderRadius: "14px", 
        color: "rgb(113 114 115)", 
        fontWeight: "normal", 
        border: "1px solid lightgrey",
        letterSpacing:"normal",
        gap:10,
        display:"flex"
        }
}