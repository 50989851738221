import React, { useState } from 'react';
import {
    Grid,
    Button,
    Slide,
    Card,
    CardHeader,
    CardContent,
    Divider,
    IconButton,
    TextField,
    Typography,
    Box
} from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import TaskIcon from '@mui/icons-material/Task';
import GridOnIcon from '@mui/icons-material/GridOn';
import DeleteIcon from '@mui/icons-material/Delete';
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { postTimeTrackers } from '../../../_api/time-management'
import { toast } from 'react-hot-toast';

let payload = {
    "projectName": 'String',
    "jobName": 'String',
    "trackingDate": 'Timestamp',
    "punchIn": 'Timestamp',
    "punchOut": 'Timestamp',
    "breaks": [{ "start": 'Timestamp', "end": 'Timestamp' }],
    "note": 'String',
    "label": 'String'
}


const fieldGroup = {
    display: 'flex',
    flexDirection: 'column',
    gap: 10
}
const labelStyle = {
    fontSize: '16px',
    fontWeight: 500,
    color: '#011652',
    marginLeft: 2,
    marginBottom: 14,
    height: 24,
}
const inputStyle = {
    padding: 10,
    border: '1px solid',
    borderRadius: '10px',
    minWidth: '250px'
}
const CustomInput = (props) => {
    const { name, error, label, type, helperText, onChange, value, onBlur, placeholder, fullWidth, multiline, rows, subLabel, others } = props
    if (multiline) {
        return (<>
            <div style={fieldGroup}>
                <label
                    style={labelStyle}>{label}</label>
                {
                    subLabel &&
                    <p
                        style={{ color: 'rgba(0, 0, 0, 0.6)', fontSize: '12px', margin: '0px', }}
                    >{subLabel}</p>
                }
                <textarea
                    {...others}
                    name={name}
                    placeholder={placeholder}
                    style={{ ...inputStyle, borderColor: error ? 'red' : 'rgba(0, 0, 0, 0.23)', width: fullWidth ? '100%' : 'fit-content' }}
                    onBlur={onBlur}
                    value={value}
                    onChange={onChange}
                    rows={rows}
                    type={type} />
                <p
                    style={{ color: error ? 'red' : 'rgba(0, 0, 0, 0.6)', fontSize: '12px', margin: '0px', }}
                >{helperText}</p>
            </div>
        </>)
    }
    return (<>
        <div style={fieldGroup}>
            <label
                style={labelStyle}>{label}</label>
            {
                subLabel &&
                <p
                    style={{ color: 'rgba(0, 0, 0, 0.6)', fontSize: '12px', margin: '0px', }}
                >{subLabel}</p>
            }
            <input
                {...others}
                name={name}
                placeholder={placeholder}
                style={{ ...inputStyle, borderColor: error ? 'red' : 'rgba(0, 0, 0, 0.23)', width: fullWidth ? '100%' : 'fit-content' }}
                onBlur={onBlur}
                value={value}
                onChange={onChange}
                type={type} />
            <p
                style={{ color: error ? 'red' : 'rgba(0, 0, 0, 0.6)', fontSize: '12px', margin: '0px', }}
            >{helperText}</p>
        </div>
    </>)

}

export default function TrackTime({ setPage, setPageTitle, page }) {

    const unixCalculator = (date, time) => {
        var currentDate = new Date(date);
        var [hours, minutes] = time.split(":");
        return (
            currentDate.getTime() +
            parseInt(hours) * 3600000 +
            parseInt(minutes) * 60000
        ).toString();
    };

    const formik = useFormik({
        initialValues: {
            projectName: '',
            jobName: '',
            trackingDate: '',
            punchIn: '',
            punchOut: '',
            breaks: [{ "start": '', "end": '' }, { "start": '', "end": '' }],
            note: '',
            label: ''
        },
        validationSchema: Yup.object({
            projectName: Yup.string("").trim()
                .required("Project Name is required")
                .matches(/^[A-Z]{0,3}[A-Za-z0-9\s]*$/, 'Please enter valid Project Name')
                .min(3, "Project Name must be at least Three Characters").max(120, "Project Name must be at most 120 Characters"),
            jobName: Yup.string("").trim()
                .required("Job Name is required")
                .matches(/^[A-Z]{0,3}[A-Za-z0-9\s]*$/, 'Please enter valid Job Name')
                .min(3, "Job Name must be at least Three Characters").max(120, "Job Name must be at most 120 Characters"),
            trackingDate: Yup.string().required(['Tracking Date is required']),
            punchIn: Yup.string().required(['This field is required']),
            punchOut: Yup.string().required(['This field is required']),
            note: Yup.string().trim().required(['This field is required']).min(5, 'Note must be at least 5 characters long'),
            label: Yup.string().trim().required(['This field is required']).min(3, "Name must be at least 3 characters long"),
            breaks: Yup.array()
                .of(
                    Yup.object().shape({
                        start: Yup.string(),
                        end: Yup.string(),
                    })
                ).required("There must be at least 2 breaks").min(2, "There must be at least 2 breaks")
        }),
        onSubmit: async (values) => {
            let tempBody = {
                ...values,
                trackingDate: unixCalculator(values.trackingDate, '0:0'),
                punchIn: unixCalculator(values.trackingDate, values.punchIn),
                punchOut: unixCalculator(values.trackingDate, values.punchOut),
                breaks: values?.breaks?.map(item => {
                    if (item?.start && item.end) {
                        return ({ start: unixCalculator(values.trackingDate, item.start), end: unixCalculator(values.trackingDate, item.end) })
                    }
                }),
            }
            try {
                toast.loading("Saving please wait...")
                await postTimeTrackers(tempBody)
                toast.dismiss()
                toast.success("Saved!")
                setPage('main')
            } catch (err) {
                console.log(err.message)
                toast.dismiss()
                toast.error(err.message)
            }

        }
    })
    return (
        <Slide direction="left" in={page === "trackTime"} mountOnEnter unmountOnExit>
            <Grid sx={mainPageStyle}>
                <Card sx={cardStyle}>
                    <CardHeader sx={{ textAlign: 'center' }} title="Time Tracker Form" />
                    <Divider />
                    <CardContent>
                        <form onSubmit={formik.handleSubmit}>
                            <Grid container spacing={6} sx={{ p: 3 }}>
                                <Grid item xs={6}>
                                    <CustomInput
                                        name="projectName"
                                        helperText={formik.touched.projectName && formik.errors.projectName}
                                        error={Boolean(formik.touched.projectName && formik.errors.projectName)}
                                        onChange={formik.handleChange}
                                        value={formik.values.projectName}
                                        onBlur={formik.handleBlur}
                                        fullWidth label="Project Name" />
                                </Grid>
                                <Grid item xs={6}>
                                    <CustomInput
                                        name="jobName"
                                        helperText={formik.touched.jobName && formik.errors.jobName}
                                        error={Boolean(formik.touched.jobName && formik.errors.jobName)}
                                        onChange={formik.handleChange}
                                        value={formik.values.jobName}
                                        onBlur={formik.handleBlur}
                                        fullWidth label="Job Code/Name" />
                                </Grid>
                                <Grid item xs={6}>
                                    <CustomInput
                                        name="trackingDate"
                                        helperText={formik.touched.trackingDate && formik.errors.trackingDate}
                                        error={Boolean(formik.touched.trackingDate && formik.errors.trackingDate)}
                                        onChange={formik.handleChange}
                                        value={formik.values.trackingDate}
                                        onBlur={formik.handleBlur}
                                        type="date" fullWidth label="Date"
                                    />
                                </Grid>
                                <Grid item xs={6} />
                                <Grid item xs={4}>
                                    <CustomInput
                                        name="punchIn"
                                        helperText={formik.touched.punchIn && formik.errors.punchIn}
                                        error={Boolean(formik.touched.punchIn && formik.errors.punchIn)}
                                        onChange={formik.handleChange}
                                        value={formik.values.punchIn}
                                        onBlur={formik.handleBlur}
                                        type="time" label="Punch In" />
                                </Grid>
                                <Grid item xs={4}>
                                    <CustomInput
                                        name="punchOut"
                                        helperText={formik.touched.punchOut && formik.errors.punchOut}
                                        error={Boolean(formik.touched.punchOut && formik.errors.punchOut)}
                                        onChange={formik.handleChange}
                                        value={formik.values.punchOut}
                                        onBlur={formik.handleBlur}
                                        type="time" label="Punch Out" />
                                </Grid>
                                <Grid item xs={4} />


                                <Grid item xs={4}>
                                    <CustomInput
                                        value={formik.values.breaks[0]?.start}
                                        onChange={(e) => {
                                            let breaks = formik.values.breaks
                                            breaks[0].start = e.target.value
                                            formik.setFieldValue('breaks', breaks)
                                        }}
                                        type="time"
                                        disabled={false}
                                        label="Breaks"
                                        subLabel="Start Time"
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <CustomInput
                                        value={formik.values.breaks[0]?.end}
                                        onChange={(e) => {
                                            let breaks = formik.values.breaks
                                            breaks[0].end = e.target.value
                                            formik.setFieldValue('breaks', breaks)
                                        }}
                                        type="time" label="" subLabel="End Time" />
                                </Grid>
                                <Grid item xs={4} sx={{ pt: 0, mt: 0 }}></Grid>

                                <Grid item xs={4} sx={{ pt: 0, mt: -4 }}>
                                    <CustomInput
                                        value={formik.values.breaks[1]?.start}
                                        onChange={(e) => {
                                            let breaks = formik.values.breaks
                                            breaks[1].start = e.target.value
                                            formik.setFieldValue('breaks', breaks)
                                        }} type="time" label={""} subLabel={""} />
                                </Grid>
                                <Grid item xs={4} sx={{ pt: 0, mt: -4 }}>
                                    <CustomInput
                                        value={formik.values.breaks[1]?.end}
                                        onChange={(e) => {
                                            let breaks = formik.values.breaks
                                            breaks[1].end = e.target.value
                                            formik.setFieldValue('breaks', breaks)
                                        }}
                                        type="time" label="" subLabel={""} />
                                </Grid>
                                <Grid item xs={4} sx={{ mt: 0, pt: -4 }}></Grid>

                                {
                                    formik.values.breaks.map((_, index) => {
                                        return (
                                            index > 1 ?
                                                <Grid item xs={12} key={index}>
                                                    <Grid container spacing={6}>
                                                        <Grid item xs={4} sx={{ mt: 0, pt: 0 }}>
                                                            <CustomInput
                                                                value={formik.values.breaks[index]?.start}
                                                                onChange={(e) => {
                                                                    let breaks = formik.values.breaks
                                                                    breaks[index].start = e.target.value
                                                                    formik.setFieldValue('breaks', breaks)
                                                                }} type="time" label={index === 0 && "Breaks"} subLabel={index === 0 && "Start Time"} />
                                                        </Grid>
                                                        <Grid item xs={4} sx={{ mt: 0, pt: 0 }}>
                                                            <CustomInput
                                                                value={formik.values.breaks[index]?.end}
                                                                onChange={(e) => {
                                                                    let breaks = formik.values.breaks
                                                                    breaks[index].end = e.target.value
                                                                    formik.setFieldValue('breaks', breaks)
                                                                }}
                                                                type="time" label="" subLabel={index === 0 && "End Time"} />
                                                        </Grid>
                                                        <Grid item xs={4} sx={{ display: 'flex', alignItems: 'center' }}>
                                                            <IconButton
                                                                sx={{ mt: 5 }}
                                                                onClick={() => {
                                                                    let breaks = formik.values.breaks
                                                                    let newBreaks = breaks?.filter((_, ind) => index !== ind)
                                                                    formik.setFieldValue('breaks', newBreaks)
                                                                }}
                                                            ><DeleteIcon /></IconButton>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                : <></>
                                        )
                                    })
                                }
                                <Grid item xs={12} sx={{ mt: 0 }}>
                                    {
                                        formik.errors.breaks &&
                                        <p style={{ color: 'red', fontSize: '12px', margin: '0px', }}
                                        >{formik.errors.breaks}</p>
                                    }
                                </Grid>
                                <Grid item xs={12} sx={{ mt: 2 }}>
                                    <Button onClick={() => {
                                        let breaks = formik.values.breaks
                                        breaks.push({ start: '', end: '' })
                                        formik.setFieldValue('breaks', breaks)
                                    }} variant="contained" sx={{ backgroundColor: '#FB785C' }} >Add</Button>
                                </Grid>
                                <Grid item xs={12} sx={{ mt: 3 }}>
                                    <CustomInput
                                        name="note"
                                        helperText={formik.touched.note && formik.errors.note}
                                        error={Boolean(formik.touched.note && formik.errors.note)}
                                        onChange={formik.handleChange}
                                        value={formik.values.note}
                                        onBlur={formik.handleBlur}
                                        multiline rows={7} fullWidth label="Tasks & Notes" />
                                </Grid>
                                <Grid item xs={12} sx={{ mt: 3 }}>
                                    <CustomInput
                                        name="label"
                                        helperText={(formik.touched.label && formik.errors.label) ? formik.errors.label : ''}
                                        error={Boolean(formik.touched.label && formik.errors.label)}
                                        onChange={formik.handleChange}
                                        value={formik.values.label}
                                        onBlur={formik.handleBlur}
                                        fullWidth
                                        label="Assignee Name"
                                        type="text" />
                                </Grid>

                                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                                    <Button type="submit" className="btn btn-lg" variant="contained" sx={{ background: '#616ded', padding: '12px 55px' }}>Save</Button>
                                </Grid>
                            </Grid>
                        </form>
                    </CardContent>
                </Card>
            </Grid>
        </Slide>
    )
}



const mainPageStyle = {
    background: '#ecedf3',
}
const cardStyle = {
    m: '72px 170px'
}