import Badge from "@mui/material/Badge";
import NotificationsIcon from '@mui/icons-material/Notifications';
import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import { makeStyles } from "@mui/styles";
import { useSelector, useDispatch } from "react-redux";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import NotificationTable from "./notification-table";
import { setAsReadNotification, setAsUnReadNotification, setAllAsReadNotification } from "../../redux-features/user/userSlice";
import { Typography } from "@mui/material";

const useStyles = makeStyles({
  modalPaper: {
    position: "absolute",
    right: 0,
    top: 30,
    width: "420px", // Adjust the width as needed
  },
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} timeout={1000} />;
});
export default function Notifications() {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [selectedNotification, setSelectedNotification] = useState()
  const [showDetails, setShowDetails] = useState(false)
  const [openModal, setOpenModal] = useState(false)

  const notifications = useSelector((state) => state?.user?.notifications);
  const unReadNotifications = notifications?.filter(item => item.status === 'unread')
  const dialogActions = showDetails ? (
    <>
      {selectedNotification.status === "unread" ? (
        <Button
          style={{ color: "#616ded" }}
          onClick={() => {
            dispatch(setAsReadNotification(selectedNotification._id));
            setSelectedNotification({ ...selectedNotification, status: 'read' })
          }}
        >
          Mark As Read
        </Button>
      ) : (
        <Button
          style={{ color: "#616ded" }}
          onClick={() => {
            dispatch(setAsUnReadNotification(selectedNotification._id));
            setSelectedNotification({ ...selectedNotification, status: 'unread' })
          }}
        >
          Mark as unread
        </Button>
      )}
    </>
  ) : (
    notifications.length > 0 && (
      <Button style={{ color: "#616ded" }} onClick={() => dispatch(setAllAsReadNotification())}>
        Mark all as read
      </Button>
    )
  );

  return (
    <>
      <div
        style={{
          background: "white",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "10px",
          height: "50px",
          width: "50px",
          cursor: "pointer",
          margin: '5px 0px 0px 20px'
        }}
        onClick={(e) => {
          setOpenModal(true);
        }}
      >
        <Badge badgeContent={unReadNotifications?.length} color="error">
          <NotificationsIcon style={{ color: "#616ded" }} className="icon-color" />
        </Badge>
      </div>


      <Dialog
        open={openModal}
        onClose={() => setOpenModal(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="sm"
        fullWidth
        TransitionComponent={Transition}
        keepMounted
        classes={{
          paper: classes.modalPaper,
        }}
      >
        <DialogTitle id="alert-dialog-title">
          {
            !showDetails ? "All Notifications"
              :
              <Button
                onClick={() => setShowDetails(false)}
                variant="contained"
                sx={{ backgroundColor: '#616ded', textTransform: 'capitalize' }}
                startIcon={<ArrowBackIcon />}>
                Back
              </Button>
          }
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => {
            setOpenModal(false);
          }}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          {
            showDetails ?
              <Typography>
                {
                  selectedNotification.message
                }
              </Typography>
              :
              <NotificationTable
                notifications={notifications}
                selectedNotification={selectedNotification}
                setSelectedNotification={setSelectedNotification}
                setShowDetails={setShowDetails}
              />
          }
        </DialogContent>

        <DialogActions>{dialogActions}</DialogActions>
      </Dialog>
    </>
  );
}
