import {
    Box, InputAdornment, Modal, TextField,
    //  MenuItem, Button, Menu, IconButton
} from "@mui/material"
import SendIcon from '@mui/icons-material/Send';
import "./index.css"
import { sendMessageToGpt } from "../_api/chat-bot";
import { useEffect, useRef, useState } from "react";
import Swal from "sweetalert2";
import Typewriter from "./typewriterAnimation";
import { userLog } from "../_api";
import { useMutation } from "react-query";
import { useSelector } from "react-redux";
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import { isEverit } from "./check-org";
// import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
// import DoneRoundedIcon from '@mui/icons-material/DoneRounded';
// import toast from "react-hot-toast";

const ChatBot = ({ data, pageName }) => {
    const [chatBotModal, setChatBotModal] = useState(false)
    const user = useSelector((state) => state?.user?.userData?.user);
    const { mutate: log } = useMutation(userLog);
    return (
        <div style={{ position: "relative", }}>
            <ChatBotBody user={user} setChatBotModal={setChatBotModal} data={data} pageName={pageName} chatBotModal={chatBotModal} />
            <div className="chat-btn-2"
                onClick={() => {
                    log({
                        pageName: 'Skills',
                        buttonName: 'Chat'
                    });
                    setChatBotModal(true)
                }}>
                {
                    isEverit(user?.OrganizationId) ?
                        <img width="40px" src="assets/img/bot/bot-icon-white.svg" alt="Bot Icon" />
                        :
                        <AutoAwesomeIcon sx={{ color: "white" }} />
                }
            </div>
        </div>
    )
}
export const ChatBotBody = ({ setChatBotModal, chatBotModal, data, pageName, user }) => {

    const messagesEndRef = useRef()

    const [messages, setMessages] = useState([]);
    const [inputText, setInputText] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [gradeNumber, setGradeNumber] = useState(1)
    // const [anchorEl, setAnchorEl] = useState(null);
    const handlesendMessage = async () => {
        if (!inputText.trim()) return;

        const newMessages = [...messages, { sender: 'user', message: inputText }];
        setMessages(newMessages);
        setInputText('');

        try {
            setIsLoading(true)
            const botReply = await sendMessageToGpt(newMessages, data, pageName, gradeNumber); // Pass newMessages as an argument
            setIsLoading(false)
            setMessages([...newMessages, { sender: 'assistant', message: botReply }]);
            scrollToBottom()

        } catch (error) {
            setIsLoading(false)
            console.error('Error sending message:', error);
            setChatBotModal(false)
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Something went wrong!",
            });
        }
    };


    useEffect(() => {
        scrollToBottom();
    }, [messages]);

    const scrollToBottom = () => {
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
        }
    };

    // const open = Boolean(anchorEl);


    // useEffect(() => {
    //     setGradeNumber(localStorage.getItem("gradeNumber"))
    // }, [])

    return (
        <Modal
            open={chatBotModal}
            onClose={() => setChatBotModal(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <div className='chatbot-container'>
                {/* header */}
                <div className='chatbot-header-container'>
                    <div style={{ display: "flex", gap: 6, justifyContent: 'center', alignItems: "center" }}>
                        <div className="chat-bot-profile-pic">
                            {
                                isEverit(user?.OrganizationId) ?
                                    <img width="50px" src="assets/img/bot/bot-icon-white.svg" alt="" />
                                    :
                                    <img src="../../assets/svg/main-home/chat-icon.svg" alt="" />
                            }
                        </div>
                        <div>
                            <b>Ask Anything</b>
                            <p style={{ margin: 0 }}>online</p>
                        </div>
                        {/* <Box sx={boxStyle}>
                            <Button
                                endIcon={<KeyboardArrowDownIcon />}
                                onClick={(event) => setAnchorEl(event.currentTarget)}
                                variant="outlined"
                                color="inherit"
                                sx={readnessButtonStyle}
                            >
                                Read-ness Level
                            </Button>
                            <IconButton
                                onClick={() => {
                                    toast.success(`Read-ness level set to Grade ${gradeNumber}`)
                                    localStorage.setItem("gradeNumber", gradeNumber)
                                }}
                                sx={{ borderRadius: '12px', px: 1.5 }}
                            >
                                <DoneRoundedIcon color="primary" />
                            </IconButton>
                            <Menu

                                id="basic-menu"
                                anchorEl={anchorEl}
                                open={open}
                                onClose={() => setAnchorEl(null)}
                                MenuListProps={{
                                    'aria-labelledby': 'basic-button',
                                }}
                            >
                                {
                                    [1, 2, 3, 4, 5].map(item => {
                                        return (
                                            <MenuItem key={item} sx={itemStyle}
                                                onClick={() => {
                                                    setAnchorEl(null)
                                                    setGradeNumber(item)
                                                }}>
                                                {item == gradeNumber && <DoneRoundedIcon color="primary" sx={{ mr: 1 }} />} Grade {item}
                                            </MenuItem>
                                        )
                                    })
                                }
                            </Menu>
                        </Box> */}

                    </div>
                    <p style={{ cursor: "pointer" }} onClick={() => {
                        setChatBotModal(false)
                    }}>❌</p>
                </div>
                {/* body */}
                <Box sx={{
                    WebkitOverflowScrolling: "touch", // Enable momentum scrolling for iOS
                    "&::-webkit-scrollbar": {
                        width: "5px", // Adjust the width of the scrollbar
                    },
                    "&::-webkit-scrollbar-thumb": {
                        backgroundColor: "rgba(0, 0, 0, 0.3)", // Adjust the color of the scrollbar thumb
                        borderRadius: "5px", // Adjust the border radius of the scrollbar thumb
                    },
                    "&::-webkit-scrollbar-thumb:hover": {
                        backgroundColor: "rgba(0, 0, 0, 0.5)", // Adjust the color of the scrollbar thumb on hover
                    },
                    "&::-webkit-scrollbar-track": {
                        backgroundColor: "transparent", // Set the background color of the scrollbar track to transparent
                    },
                }} className='chatbot-body-container'>
                    {
                        !messages.length && <div className="col-12 d-flex justify-content-center align-items-center">
                            <img width="41%" src="../assets/svg/no-data-2.png" alt="" />
                        </div>
                    }
                    {messages?.map((val) => (
                        <>
                            <EachMessage user={user} isLoading={isLoading} val={val} />
                        </>
                    ))}
                    {isLoading && <EachMessage user={user} val={{ sender: 'assistant', message: "typing...", }} />}
                    <div ref={messagesEndRef} />
                </Box>
                <div className='chatbot-header-container'>
                    <TextField

                        size="small"
                        placeholder="Enter Question"
                        value={inputText}
                        onChange={(e) => setInputText(e.target.value)}
                        onKeyPress={(e) => {
                            if (e.key === 'Enter') {
                                handlesendMessage();
                            }
                        }}
                        fullWidth
                        id="outlined-basic"
                        sx={{
                            "& legend": { display: "none" },
                            "& fieldset": { top: 0 },
                        }}
                        variant="outlined"
                        InputProps={{
                            sx: { borderRadius: 50 },
                            endAdornment: (
                                <InputAdornment position="end">
                                    <SendIcon sx={{ cursor: "pointer", color: "#616DED" }} onClick={handlesendMessage} />
                                </InputAdornment>
                            ),
                        }}
                    />
                </div>
            </div>
        </Modal>
    )
}
const EachMessage = ({ val, messageRef, user }) => {
    const botBorderRadius = "0px 14px 14px 14px"
    const userBorderRadius = "14px 0px 14px 14px"
    const isBot = val.sender == "assistant";
    return (
        <>
            <div className={isBot ? "each-message" : "each-message-user"}>
                <div className="chat-bot-profile-pic" style={{ padding: isBot ? 8 : 0, height: 50, width: 50, background: !isBot && "lightgray" }}>
                    {
                        isEverit(user?.OrganizationId) ?
                            <>
                                {isBot && <img src={"assets/img/bot/bot-icon-white.svg"} alt="" />}
                                {!isBot && user?.photo && <img src={user?.photo} alt="" />}
                                {!isBot && !user?.photo && <img src={"assets/img/bot/chatbot-user.png"} alt="" />}
                            </>
                            :
                            <>
                                {isBot && <img src={"../../assets/svg/main-home/chat-icon.svg"} alt="" />}
                                {!isBot && !user?.photo && <img src={"../../assets/img/dp_default.png"} alt="" />}
                                {!isBot && user?.photo && <img src={user?.photo} alt="" />}
                            </>
                    }

                </div>
                <div

                    style={{
                        display: "flex",
                        padding: "10px 25px",
                        borderRadius: isBot ? botBorderRadius : userBorderRadius,
                        backgroundColor: isBot ? "#616DED" : "lightgray",
                        maxWidth: '50%',
                        width: "fit-content",
                        position: "relative",
                        top: "28px",
                        color: isBot ? "white" : "black",
                        alignItems: 'center'
                    }}>
                    <p className="chat-bot-message-text">{val?.message}</p>
                </div>
            </div>
        </>
    )
}


export default ChatBot

// const readnessButtonStyle = {
//     color: 'black',
//     textTransform: 'none',
//     borderRadius: "12px",
//     padding: '10px 20px',
//     background: 'white',
//     border: '1px solid #D8D7D7'
// }

// const boxStyle = {
//     display: 'flex',
//     width: 'fit-content',
//     backgroundColor: '#616DED1A',
//     borderRadius: "12px",
//     gap: '5px',
//     ml: 5
// }
// const itemStyle = {
//     width: '170px',
//     mx: '5px',
//     borderRadius: '12px',
//     '&:hover': {
//         backgroundColor: '#616DED',
//         color: 'white'
//     }
// }