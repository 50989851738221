import React, { useState, useEffect } from 'react'
import TurnPhoneH from '../other/TurnPhoneH'
import { useNavigate } from 'react-router-dom'

export default function Splash2() {
    const navigate = useNavigate()
    useEffect(() => {
        setTimeout(() => {
            navigate("/welcome")
        }, 3000);
    }, [])
    const [isMobile, setIsMobile] = useState(false);
    function handleOrientation() {
        setIsMobile(window.innerHeight < window.innerWidth && window.innerWidth < 1000)
    }
    window.onresize = handleOrientation;
    useEffect(() => {
        setIsMobile(window.innerHeight < window.innerWidth && window.innerWidth < 1000)
    }, [window.innerWidth, window.innerHeight, window.orientation]);
    if (isMobile) {
        return (<TurnPhoneH content="Turn your phone to side to run this app" />)
    } else
        return (
            <>
                <div className="body-bg-gradient">
                    <div className="container splash-screen-container">
                        <div className="container-inner" style={{ paddingTop: '208px' }}>
                            <img width="130px" src="/assets/svg/logo-white.gif" alt="" style={{ width: '180px' }} />
                            {/* <div className="content">
                            <div className="copy-right">
                                <span>2022 Copyright Stickball Technology Inc.
                                    All Rights Reserved Legal</span>
                            </div>
                        </div> */}
                        </div>
                    </div>
                </div>
            </>
        )
}
