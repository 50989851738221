import React, { forwardRef, useState } from "react";

import {
  IconButton,
  Dialog,
  DialogContent,
  DialogTitle,
  Slide,
  Avatar,
  AppBar,
  Toolbar,
  Box,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import PlaylistAddOutlinedIcon from "@mui/icons-material/PlaylistAddOutlined";
import TodoList from "./time-tracker/TodoList";
import SkillAssignments from "../../common/SkillAssignments";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function TodoListModal() {
  const [openModal, setOpenModal] = useState(false);
  return (
    <>
      <Avatar onClick={() => setOpenModal(true)} style={todoButtonStyle}>
        <PlaylistAddOutlinedIcon sx={{ width: "40px", height: "40px" }} />
      </Avatar>
      <Dialog
        open={openModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setOpenModal(false)}
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle
          sx={{ display: "flex", justifyContent: "flex-end", px: 0, py: 0}}
        >
          <AppBar
            sx={{
              position: "sticky",
              top: 0,
              mt: 0,
              background: "#616ded",
              borderRadius: 0,
            }}
          >
            <Toolbar>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                 <div style={{ width: "192px" }}></div>
                <Typography variant="h6" component="div">
                  To Do List
                </Typography>

                <Box dispaly="flex">
                  <SkillAssignments type="time-management" />
                  <IconButton
                        style={{ height: 'fit-content', color: "#fff" }}
                        onClick={() => {
                            setOpenModal(false)
                        }}>
                        <CloseOutlinedIcon />
                    </IconButton>
                </Box>
              </Box>
            </Toolbar>
          </AppBar>
        </DialogTitle>
        <DialogContent sx={{background: '#ecedf3'}}>
          <TodoList page="todoList" />
        </DialogContent>
      </Dialog>
    </>
  );
}
const todoButtonStyle = {
  position: "fixed",
  width: 50,
  height: 50,
  padding: 5,
  right: 30,
  bottom: 150,
  background: "#fff",
  boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.2)",
  cursor: "pointer",
  color: "#616ded",
};
