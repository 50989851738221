import React, { useState, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import {
    Grid,
    Slide,
    Card,
    CardHeader,
    CardContent,
    Divider,
} from '@mui/material'
import { useQuery, } from 'react-query'
import { getTaskLogs } from '../../../_api/time-management'

import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import TablePagination from '@mui/material/TablePagination';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import moment from 'moment'
import { LazzyLoading } from './LazzyLoading'


export default function LogList({ page: listPage }) {
    const [logData, setLogData] = useState([])
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const { isLoading, data } = useQuery('get-log-list', getTaskLogs)

    useEffect(() => {
        if (!isLoading) {
            setLogData(data?.payload || [])
        }
    }, [isLoading, data])



    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    return (
        <Slide direction="left" in={listPage === "logList"} mountOnEnter unmountOnExit>
            <Grid sx={mainPageStyle}>
                <Card sx={cardStyle}>
                    <CardHeader sx={{ textAlign: 'center' }} title="Task List" />
                    <Divider />
                    <CardContent>
                        <TableContainer>
                            <div style={{ width: '100%', height: '455px', overflow: 'auto' }}>
                                <Table aria-label="collapsible table" stickyHeader>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Task Name</TableCell>
                                            <TableCell align="left">Project Name</TableCell>
                                            <TableCell align="left">Assigned To</TableCell>
                                            <TableCell align="right">Description</TableCell>
                                            <TableCell align="right">Start Date</TableCell>
                                            <TableCell align="right">End Date</TableCell>
                                            <TableCell align="left">Priority</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            isLoading ? <>
                                                <TableRow sx={{ width: '100%' }}>
                                                    <TableCell colspan={7}>
                                                        <LazzyLoading />
                                                    </TableCell>

                                                </TableRow>
                                            </>
                                                :
                                                logData
                                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.map((row) => (
                                                        <Row key={row._id} row={row} />
                                                    ))}
                                    </TableBody>
                                </Table>
                            </div>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25, 100]}
                                component="div"
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'flex-end',
                                    '& p': {
                                        margin: 0
                                    }
                                }}
                                count={logData.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </TableContainer>
                    </CardContent>
                </Card>
            </Grid>
        </Slide>
    );
}


const mainPageStyle = {
    background: '#ecedf3',
}
const cardStyle = {
    m: '20px 70px'
}




function Row(props) {
    const { row } = props;
    return (
        <>
            <TableRow sx={{ '& td': { borderBottom: 'unset' } }}>
                <TableCell> {row.taskName}</TableCell>
                <TableCell align="left">{row?.projectName}</TableCell>
                <TableCell align="left">{row?.taskAssignedTo}</TableCell>
                <TableCell align="left">{row?.taskDescription}</TableCell>
                <TableCell align="right">{moment(row?.startDate).format('LL')}</TableCell>
                <TableCell align="right">{moment(row?.endDate).format('LL')}</TableCell>
                <TableCell align="left" sx={{ textTransform: 'capitalize' }}>{row?.priority}</TableCell>
            </TableRow>
        </>
    );
}





