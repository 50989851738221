import React, { useState, useEffect } from 'react'
import TurnPhoneH from '../other/TurnPhoneH'
import { Link } from 'react-router-dom'
import { Grid, Button, Typography, Box, Container, Link as ALink } from "@mui/material"
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { ChatBotBody } from "../common/chatbot"
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { setModuleName } from '../../redux-features/user/userSlice'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { userLog } from '../_api/index'
import isAuthenticated from '../auth/auth'
import uuid from 'react-uuid'
import Loading from '../common/Loading'
import { setRegister } from '../../redux-features/user/userSlice'
import { useMutation, useQueryClient } from 'react-query';
import { isNorthwell } from '../common/check-org'

const EveritModulePage = () => {
    const NOUNCE = uuid();
    const { t } = useTranslation();

    const dispatch = useDispatch()

    const userData = useSelector((state) => state?.user?.userData?.data)
    const user = useSelector((state) => state?.user?.userData?.user)
    const loading = useSelector(state => state?.user?.loading)
    const [nextSkillID, setNextSkillID] = useState()
    const [nextSkillName, setNextSkillName] = useState()

    const [openChatbotModal, setChatbotModal] = useState(false)

    window.name = "Success"
    useEffect(() => {
        localStorage.setItem("fetch-data", false)
        dispatch(setRegister(false))
        userData?.map((module) => {
            module?.lessons?.map((lesson) => {
                lesson?.skills?.map((skill) => {
                    if (skill?.isCompleted == false) {
                        setNextSkillID(skill?._id)
                        setNextSkillName(skill?.name)
                    }
                })
            })
        })

    }, [userData])
    const { mutate: log } = useMutation(userLog);
    const navigate = useNavigate()
    const queryClient = useQueryClient();
    const [message, setMessage] = useState("")
    const [isMobile, setIsMobile] = useState(false);
    function handleOrientation() {
        setIsMobile(window.innerWidth < 1000)
    }
    window.onresize = handleOrientation;
    useEffect(() => {
        setIsMobile(window.innerWidth < 1000)
    }, [window.innerWidth, window.innerHeight, window.orientation]);


    useEffect(() => {
        localStorage.setItem("NOUNCE", NOUNCE);
    }, [NOUNCE]);

    useEffect(() => {
        if (!isAuthenticated(localStorage.getItem("token"))) {
            navigate("/login")
        }
    }, [])

    if (!userData?.length) {
        return (<>
            {loading && <Loading />}
            {!isAuthenticated(localStorage.getItem("token")) && navigate("/login")}
        </>)
    }
    else if (isMobile) {
        return (<TurnPhoneH content="Turn your phone to side to view the App" />)
    }
    else {
        return (
            <>
                <Box sx={{ pt: 4, pb: 2 }}>
                    <Container maxWidth="lg" sx={{ pb: 3, display: 'flex', justifyContent: 'flex-start' }}>
                        <img
                            width="180px"
                            height="auto"
                            src={isNorthwell(user?.OrganizationId) ? "/assets/img/northwell-logo.png" : "/assets/img/everit-logo.png"} alt=""
                        />
                    </Container>
                    <Box sx={heroStyle}
                    >
                        <img
                            style={{
                                height: "100%",
                                position: "absolute",
                                objectFit: 'cover',
                                right: "12%"
                            }}
                            src='./assets/img/personBanner.png' />
                        <Container maxWidth="lg" sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '100%', position: 'relative', zIndex: 1 }}>

                            {
                                isNorthwell(user?.OrganizationId) ?
                                    <Typography variant="h3" sx={{ ...heroTextStyle, width: { md: "50%", xs: "70%" } }}>
                                        Northwell Health Education Center
                                    </Typography>
                                    :
                                    <Typography variant="h3" sx={heroTextStyle}>
                                        Mayor Carlo DeMaria's <br /> Summer Youth Job Program
                                    </Typography>
                            }
                            {
                                isNorthwell(user?.OrganizationId) ?
                                    <ALink
                                        href="https://www.northwell.edu/"
                                        sx={btnBackToBBStyle}
                                    >
                                        <ArrowBackIosIcon sx={{ width: { sm: "24px", xs: "12px" }, height: { sm: "24px", xs: "12px" } }} />
                                        Back to Northwell Health
                                    </ALink>
                                    :
                                    <ALink
                                        href="https://cityofeverett.com/resident-services/children-services/everett-summer-programs/mayor-carlo-demarias-summer-youth-job-program/"
                                        sx={btnBackToBBStyle}
                                    >
                                        <ArrowBackIosIcon sx={{ width: { sm: "24px", xs: "12px" }, height: { sm: "24px", xs: "12px" } }} />
                                        Back to City of Everett
                                    </ALink>
                            }

                        </Container>
                    </Box>
                </Box >
                <Container maxWidth="lg" >
                    <Grid container sx={{ mb: 5, gap: { xs: 7 }, mt: 7 }} justifyContent="center">
                        {userData !== undefined &&
                            userData?.map((item, index) => (
                                <Grid item md={3.5} sm={5.5} xs={12}
                                    sx={boxStyle}>
                                    <Box sx={{ display: 'flex', gap: 3, alignItems: 'center', justifyContent: "flex-start" }}>
                                        <Box>
                                            <img style={{ width: item?.icon.height ? "fit-content" : "50px", height: item.icon.height ? item.icon.height : "fit-content" }} src={item?.icon?.svg} />
                                        </Box>
                                        <Typography sx={{ fontWeight: 700, color: 'white', fontSize: { xs: "20px", md: "25px" }, gap: "1rem" }} vriant="h4">
                                            {item?.name}
                                        </Typography>

                                    </Box>
                                    <Box>
                                        <Link
                                            onClick={() => { dispatch(setModuleName) }}
                                            to={!item?.lessons?.length ? "/module" : `/lesson?id=${item?._id}`}
                                            style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", cursor: "pointer" }}
                                        >
                                            <Button disabled={!item?.lessons?.length} sx={{ backgroundColor: (theme) => theme.palette.secondary.main, borderRadius: "0.6rem", color: "white", width: "100%", margin: "0.25rem 0", "&:hover": { backgroundColor: (theme) => theme.palette.secondary.dark } }}>
                                                <Typography sx={{ fontSize: "15px", color: "white", textAlign: "center", }}>Get Started</Typography>
                                            </Button>
                                        </Link>
                                    </Box>
                                </Grid>
                            ))
                        }
                        {/* Money Button */}
                        <Grid item xs={11} sm={6} md={3.5} container sx={boxStyle}>
                            <Box sx={{ display: 'flex', gap: 3, alignItems: 'center', justifyContent: "flex-start" }}>
                                <img style={{ width: "250px" }} src={'assets/img/money-logo.png'} />
                            </Box>

                            <ALink href={`${process.env.REACT_APP_BUDG_LINK}?path=module&token=${localStorage.getItem("token")}&isIframe=false`}>
                                <Button sx={{ backgroundColor: (theme) => theme.palette.secondary.main, borderRadius: "0.6rem", color: "white", width: "100%", margin: "0.25rem 0", "&:hover": { backgroundColor: (theme) => theme.palette.secondary.dark } }}>
                                    <Typography sx={{ fontSize: "15px", color: "white", textAlign: "center", }}>Get Started</Typography>
                                </Button>
                            </ALink>

                        </Grid>

                        {/* Ask Anything button */}
                        <Grid item xs={11} sm={6} md={3.5} container sx={boxStyle}>
                            <Box sx={{ display: 'flex', gap: 3, alignItems: 'center', justifyContent: "flex-start" }}>
                                <img style={{ height: 70 }} src={'assets/img/bot/bot-icon-white.svg'} />
                                <Typography sx={{ fontWeight: 700, color: 'white', fontSize: { xs: "20px", md: "25px" }, gap: "1rem" }} vriant="h4">
                                    Ask Anything
                                </Typography>
                            </Box>

                            <Button
                                onClick={() => setChatbotModal(true)}
                                sx={{ backgroundColor: (theme) => theme.palette.secondary.main, borderRadius: "0.6rem", color: "white", width: "100%", margin: "0.25rem 0", "&:hover": { backgroundColor: (theme) => theme.palette.secondary.dark } }}>
                                <Typography sx={{ fontSize: "15px", color: "white", textAlign: "center", }}>Get Started</Typography>
                            </Button>
                        </Grid>
                    </Grid >
                    <ChatBotBody user={user} setChatBotModal={setChatbotModal} data={userData?.map(module => module?.name)} pageName={"Module"} chatBotModal={openChatbotModal} />
                </Container >
            </>
        )

    }
}

export default EveritModulePage

const boxStyle = {
    p: 3,
    borderRadius: '2rem',
    backgroundColor: (theme) => theme.palette.primary.main,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    gap: 2
}

const heroStyle = {
    position: "relative",
    background: "#eeedf2", // 'linear-gradient(to bottom, #f0f0f0 0%, #bcbcbc 90%)',
    height: { sm: '300px', xs: "100px" },
    width: '100%',
}

const btnStyle = {
    backgroundColor: "#829d00",
    minHeight: { sm: '48px', xs: "fit-content" },
    padding: '0 clamp(0.625rem, 1.25vw, 1.25rem)',
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: { sm: 'bold', xs: 500 },
    color: '#fff',
    fontSize: '1rem',
    border: 0,
    width: 'fit-content',
    '&:hover': {
        backgroundColor: "#106EAC",
        color: 'white'
    }
}

const btnBackToBBStyle = {
    backgroundColor: "transparent",
    minHeight: { sm: '48px', xs: "fit-content" },
    padding: { sm: '10px', xs: "5px" },
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: { sm: 700, xs: 500 },
    fontSize: { sm: '1rem', xs: "10px" },
    width: 'fit-content',
    borderRadius: { sm: "10px", xs: '5px' },
    color: (theme) => theme.palette.primary.main,
    borderColor: (theme) => theme.palette.primary.main,
    border: `1px solid`,
    '&:hover': {
        backgroundColor: (theme) => theme.palette.primary.light,
        color: (theme) => theme.palette.primary.main,
        borderColor: (theme) => theme.palette.primary.main,
    }
}

const heroTextStyle = {
    maxWidth: "80%",
    color: 'dark',
    fontWeight: 700,
    mb: { sm: 3, xs: 1.5 },
    fontSize: { md: "3rem", sm: "2.3rem", xs: "18px" }
}