import React, { useState, useEffect } from 'react'
import TurnPhoneH from '../other/TurnPhoneH'
import { useNavigate } from 'react-router-dom'
import KeyboardBackspaceRoundedIcon from '@mui/icons-material/KeyboardBackspaceRounded';
import TipsModal from './TipsModal'
import isAuthenticated from '../auth/auth'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useSelector } from 'react-redux';

import { userLog } from '../_api/index'
import { useMutation } from 'react-query';
import { useTranslation } from 'react-i18next'

import EmptyRow from './EmptyRow'
import CurrentRow from './CurrentRow'
import Guessess from './Guesses'
import Keyboard from './Keyboard'
import GameWin from './GameWin'
import GameOver from './GameOver'
import { wordList } from './valid_guesses'
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';

export default function WordsCape() {
    const { t } = useTranslation();
    let words = useSelector(state => state?.user?.words)
    const navigate = useNavigate()
    const { mutate: log } = useMutation(userLog);
    const [open, setOpen] = useState(false)
    const [word, setWord] = useState("Example")
    const [guesses, setGuesses] = useState([])
    const [value, setValue] = useState("")
    const [guess, setGuess] = useState([])
    const [openSuc, setOpenSuc] = useState(false)
    const [openErr, setOpenErr] = useState(false)
    let emptyRows = [1, 2, 3, 4, 5, 6]
    const [isMobile, setIsMobile] = useState(false);
    function handleOrientation() {
        setIsMobile(window.innerHeight < window.innerWidth && window.innerWidth < 1000)
    }
    window.onresize = handleOrientation;
    useEffect(() => {
        setIsMobile(window.innerHeight < window.innerWidth && window.innerWidth < 1000)
    }, []);

    useEffect(() => {
        let temp = []
        let temp2 = []
        if (words) {
            words.map(wrd => {
                if (!wrd.isCompleted) {
                    temp.push(wrd.name.toUpperCase())
                }
                return (null)
            })
            wordList.map(lst => {
                temp2.push(lst?.toUpperCase())
                return (null)
            })
            setWord(temp[Math.floor(Math.random() * temp.length)]?.toUpperCase())
            setGuesses(temp.concat(temp2))
        }
    }, [words])

    const handleClick = () => {
        let len = guess.length
        if (value.length === word.length) {
            setGuess((old) => [...old, value])
            if (word === value) {
                toast.success('Good Job!', {
                    position: "top-center",
                    autoClose: 2000,
                    hideProgressBar: true,
                });
                setTimeout(() => {
                    setOpenSuc(true)
                }, 2000)
            }
            if (len === 5 && word !== value) {
                toast.error('The word was ' + word, {
                    position: "top-center",
                    autoClose: 2000,
                    hideProgressBar: true,
                });
                setTimeout(() => {
                    setOpenErr(true)
                }, 1000)
            }
            setValue("")
        }
    }
    if (isMobile) {
        return (<TurnPhoneH content={t("wordleGame.turn")} />)
    } else if (words?.length === 0) {
        return (<>
            <div className="back back2 get-started-code-back" style={{
                position: "absolute",
                boxShadow: '1px 1px 2px rgba(0,0,0,0.1)',
                left: '0px',
                top: '0px',
                width: '100%',
            }}>
                <div className="back-wrapper">
                    <button
                        onClick={() => window.history.go(-1)}
                        className="mybtn customizedbtn"
                        style={{ background: "transparent" }}
                    >
                        <img src="assets/img/arrowback.png" style={{ paddingRight: ".5rem" }} />
                        Back
                    </button>

                    <div className="header-title">
                        <h3 className="header-title">Wordle</h3>
                        <p>{t("wordleGame.headSub")}</p>
                    </div>

                </div>
                <button
                    type="button"
                    className="skip-code"
                >
                    <div data-toggle="modal" data-target="#tipsModal" onClick={() => {
                        log({
                            pageName: 'Words Cape',
                            buttonName: 'How to Play'
                        })
                        setOpen(true)
                    }}>
                        {/* <QuestionMarkIcon /> */}
                    </div>

                </button>
            </div>
            <div className="container-wordscape p-5 mt-sm-5">
                <h3 className="text-danger text-center wordlegameerror">{t("wordleGame.error")}</h3>
            </div>
        </>)
    } else
        return (
            <>
                {!isAuthenticated(localStorage.getItem("token")) && navigate("/login")}
                <GameWin open={openSuc} setOpen={setOpenSuc} totalTries={guess?.length} />
                <GameOver open={openErr} setOpen={setOpenErr} totalTries={guess?.length} />
                <TipsModal open={open} setOpen={setOpen} />
                <ToastContainer />
                <div className="back back2 get-started-code-back" style={{
                    position: "absolute",
                    boxShadow: '1px 1px 2px rgba(0,0,0,0.1)',
                    left: '0px',
                    top: '0px',
                    width: '100%',
                }}>
                    <div className="back-wrapper">
                        <a
                            href="#/"
                            style={{
                                marginLeft: window.innerWidth > 769 ? '50px' : '0px',
                            }}
                            className="bg-primary1 back-btn"
                            onClick={(e) => {
                                log({
                                    pageName: 'Words Cape',
                                    buttonName: 'Back'
                                })
                                window.history.go(-1)
                            }}>
                            <KeyboardBackspaceRoundedIcon />
                        </a>
                        <div className="header-title">
                            <h3 className="header-title">Wordle</h3>
                            <p>{t("wordleGame.headSub")}</p>
                        </div>

                    </div>
                    <button
                        type="button"
                        className="skip-code"
                    >
                        <div data-toggle="modal" data-target="#tipsModal" onClick={() => {
                            log({
                                pageName: 'Words Cape',
                                buttonName: 'How to Play'
                            })
                            setOpen(true)
                        }}>
                            <QuestionMarkIcon />
                        </div>

                    </button>
                </div>
                <div className="container-wordscape px-2 mt-5">
                    {
                        guess?.map((gs, i) => {
                            return (word && <Guessess value={gs} word={word} keyVal={i} />)
                        })
                    }
                    {
                        guess.length < 6 ?
                            word && <CurrentRow word={word} value={value} />
                            : <></>
                    }

                    {
                        guess.length < 5 ?
                            emptyRows.slice(1, emptyRows.length - guess.length).map((_, i) => {
                                return (word && <EmptyRow word={word} keyVal={i} />)
                            })
                            : <></>
                    }

                </div>
                <div className="keyboard-container">
                    <Keyboard value={value} setValue={setValue} word={word} />
                    <div className="row d-flex justify-content-center" style={{ margin: '11px 16px 20px 16px' }}>
                        <div className="col-md-4">
                            <button style={{ margin: 0 }} type="submit" className="submit-btn"

                                onClick={() => {
                                    log({
                                        pageName: 'Words Cape',
                                        buttonName: 'Submit Word'
                                    })
                                    handleClick()
                                }}
                            >{t("wordleGame.btnSubmit")}</button>
                        </div>
                    </div>
                </div>
            </>
        )
}
