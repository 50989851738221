import React, { useState, useEffect } from 'react'
import TurnPhoneV from '../../other/TurnPhoneV'
import SkillsBar from './SkillsBar'
import { Link, useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { useMutation } from 'react-query';
import isAuthenticated from '../../auth/auth'
import SkillSection from '../details/SkillSection'
import { userLog } from '../../_api/index'
import { setSession, userEnroll, emptyStore } from '../../../redux-features/user/userSlice'

import PropTypes from 'prop-types';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Notes from '../notes/Notes'

import { Grid } from "@mui/material"
import Swal from 'sweetalert2'
import GoalsModal from '../../common/GoalsModal'
import Notifications from '../../notifications'
import ChatBot from '../../common/chatbot'
import Logo from '../../common/Logo'

function CircularProgressWithLabel(props) {
    return (
        <Box className="next" sx={{ position: 'relative', display: 'inline-flex' }}>
            <CircularProgress variant="determinate" {...props} />
            <Box
                sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Typography
                    variant="caption"
                    component="div"
                    style={{ color: '#616ded' }}
                >{(props.value / props.divident).toFixed(0)}</Typography>
            </Box>
        </Box>
    );
}
CircularProgressWithLabel.propTypes = {
    value: PropTypes.number.isRequired,
};

export default function SkillSims() {
    const dispatch = useDispatch()
    const { mutate: log } = useMutation(userLog);
    const [showModal, setShowModal] = useState(false);
    const navigate = useNavigate();


    const [nextSrc, setNextSrc] = useState("../../assets/img/next.png")
    const [backSrc, setBackSrc] = useState("../../assets/img/backk.png")

    const skillSections = useSelector(state => state.user.skillSections)

    const hasWaitTime = useSelector(state => state.user.hasWaitTime)
    const waitTime = useSelector(state => state.user.waitTime)
    const isNextEnabled = useSelector(state => state.user?.isNextEnabled)

    const [ind, setInd] = useState(0)
    const [openTranslate, setOpenTranslate] = useState(false)
    const [simsContent, setSimsContent] = useState([])
    const user = useSelector((state) => state?.user?.userData?.user)
    const chatbot = useSelector(state => state.user?.chatbot)

    const [flag, setFlag] = useState(false)

    const [nextTimer, setNextTimer] = useState(waitTime)
    const [divident, setDivident] = useState(100 / waitTime);




    useEffect(() => {
        const timer = setInterval(() => {
            setNextTimer((prevProgress) => (prevProgress > 0 ? prevProgress - 1 : 0));
        }, 1000);
        return () => {
            clearInterval(timer);
        };
    }, []);

    useEffect(() => {
        let temp = skillSections?.filter(ss => { return (ss?.name === "SIMS") })
        setSimsContent(temp)
        if (temp.length === 0) {
            navigate("/skill-detail-quiz")
        }
    }, [skillSections])

    const handleEnrollment = (data, index) => {
        let fc = simsContent.filter(cont => { return (!cont.isCompleted) })
        if (fc.length === 0) {
            navigate("/skill-detail-quiz")
        } else {
            try {

                if (simsContent.length - 1 === index) {
                    dispatch(setSession(data))
                    navigate("/skill-detail-quiz")
                } else {
                    dispatch(userEnroll(data))
                    setNextTimer(waitTime)
                }
            } catch (err) {
            }
        }
    }


    const [isMobile, setIsMobile] = useState(false)
    const [open, setOpen] = useState(false)
    function handleOrientation() {
        setIsMobile(window.innerHeight > window.innerWidth)
    }
    window.onresize = handleOrientation;
    useEffect(() => {
        setIsMobile(window.innerHeight > window.innerWidth)
    }, [window.innerWidth, window.innerHeight, window.orientation]);
    if (isMobile) {
        return (<TurnPhoneV />)
    } else
        return (
            <> {!isAuthenticated(localStorage.getItem("token")) && navigate("/login")}
                {
                    showModal &&
                    <div className="journal-modal">
                        <Notes />
                    </div>
                }
                <SkillsBar page="Skill Detail Sims" />
                <div className="skills-details-container" style={{ paddingBottom: "0" }}>
                    <Grid container>
                        <Grid container>
                            <Grid xs={12} sm={8} container sx={{ paddingLeft: "2rem" }}>
                                <Logo  />
                            </Grid>
                            <Grid container xs={12} sm={4} justifyContent="flex-end">
                                <Grid item container justifyContent="flex-end" alignItems="center">

                                    <>
                                        <div className="translate-buttons" style={{ marginRight: "1rem" }}>

                                            <Link to="/" className="translate-it"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    setOpenTranslate(true)
                                                    log({
                                                        pageName: 'Alt Space-vr',
                                                        buttonName: 'Translate It'
                                                    })
                                                }}>
                                                Translate It
                                            </Link>
                                        </div>
                                    </>
                                    <Link
                                        to="/wordscape"
                                        onClick={() =>
                                            log({
                                                pageName: "Skill Details Assess",
                                                buttonName: "Word Game",
                                            })
                                        }
                                        style={{ display: "flex", justifyContent: "center", alignItems: "center", marginRight: "1rem" }}
                                    >
                                        <div style={{ display: 'flex', padding: '0.5rem' }} className="wordscape__container">
                                            <img src="../assets/svg/main-home/word-game.svg" style={{ width: "35px", marginRight: "1rem" }} alt="" />
                                            <h1 className="lessonheading">Wordle</h1>
                                        </div>
                                    </Link>

                                    <div className="logoutIcon" onClick={() => {
                                        if (sessionStorage.getItem("access") === "ok") {
                                            Swal.fire({
                                                title: '',
                                                text: "It's a teacher view, so you can't logout!",
                                                icon: 'info',
                                                confirmButtonText: 'OK'
                                            })
                                            return
                                        }
                                        dispatch(emptyStore())
                                        localStorage.removeItem("token")
                                        log({
                                            pageName: 'Module',
                                            buttonName: 'Logout Button'
                                        })
                                        navigate("/get-started")
                                    }}>
                                        <div className="logged">
                                            <div class="block">
                                                <span className="animatedIcony">
                                                    <div class="div1" >
                                                        <img src="/assets/img/logout1.png" />
                                                        {/* <EastIcon className="arrowLoggedIcon myico right" sx={{color: "#616DED", marginLeft: "4px"}} /> */}
                                                    </div>
                                                    <div class="rect">
                                                        <img src="/assets/img/logout.png" />
                                                    </div>
                                                    <div class="div2">
                                                        <img src="/assets/img/logout1.png" />
                                                        {/* <EastIcon className="arrowLoggedIcon myico right" sx={{color: "#616DED", marginLeft: "4px"}} /> */}
                                                    </div>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <Notifications />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    {
                        simsContent.filter(content => { return (content.isCompleted) }).length === simsContent.length ?
                            <>
                                <div className="row justify-content-center mt-3 sidebarr" style={{ margin: "0 0.5rem", width: "100%", overflowX: "hidden" }}>
                                    <SkillSection content={simsContent[ind]} />
                                    <button className="next" onClick={() => {
                                        log({
                                            pageName: 'Skill Assess',
                                            buttonName: 'Next'
                                        })
                                        simsContent[ind + 1] ? setInd(ind + 1)
                                            :
                                            <>
                                                {
                                                    dispatch(setSession(simsContent[ind]._id))}
                                                {
                                                    navigate("/skill-detail-quiz")
                                                }
                                            </>
                                    }}>
                                        <img src={nextSrc} onMouseEnter={() => { setNextSrc("../../assets/img/next.png") }} onMouseOut={() => { setNextSrc("../../assets/img/nextt.png") }} alt="" />
                                    </button>
                                </div>
                                <div className="prev" onClick={() => {
                                    log({
                                        pageName: 'Skill Assess',
                                        buttonName: 'Prev'
                                    })
                                    ind === 0 ? window.history.go(-1) : setInd(ind - 1)
                                }}>
                                    <img src={backSrc} onMouseEnter={() => { setBackSrc("../../assets/img/prev.png") }} onMouseOut={() => { setBackSrc("../../assets/img/backk.png") }} alt="" />
                                </div>
                            </>
                            : <></>
                    }
                    {
                        simsContent?.map((content, index) => {
                            return (
                                content.isEnrolled && !content.isCompleted ?
                                    <>
                                        <div key={index} className="row justify-content-center mt-3 sidebarr" style={{ margin: "0 0.5rem", overflowY: "scroll", width: "100%", overflowX: "hidden" }}>
                                            <SkillSection content={content} />
                                            {
                                                nextTimer > 0 && hasWaitTime ?
                                                    <CircularProgressWithLabel style={{ color: '#616ded' }} divident={divident} value={nextTimer * divident} />
                                                    :
                                                    <button className="next"
                                                        disabled={isNextEnabled}
                                                        onClick={() => {

                                                            log({
                                                                pageName: 'Skill Details Sims',
                                                                buttonName: 'Next'
                                                            })
                                                            handleEnrollment(content?._id, index)
                                                            // if (content?.allContent?.type === "Metaverse") {
                                                            //     setTimeout(() => {
                                                            //         window.location.reload()
                                                            //     }, 500)
                                                            // }
                                                        }}>
                                                        <img src={nextSrc} onMouseEnter={() => { setNextSrc("../../assets/img/next.png") }} onMouseOut={() => { setNextSrc("../../assets/img/nextt.png") }} alt="" />
                                                    </button>
                                            }

                                        </div>
                                        <div className="prev" onClick={() => {
                                            log({
                                                pageName: 'Skill Detail Sims',
                                                buttonName: 'Prev'
                                            })
                                            let temp = skillSections?.filter(ss => {
                                                return (ss?.name === "Assess")
                                            })
                                            if (!temp.length) {
                                                window.history.go(-2)
                                                return
                                            }
                                            window.history.go(-1)
                                        }}>
                                            <img src={backSrc} onMouseEnter={() => { setBackSrc("../../assets/img/prev.png") }} onMouseOut={() => { setBackSrc("../../assets/img/backk.png") }} alt="" />
                                        </div>
                                    </>
                                    : <></>
                            )
                        })
                    }



                </div>
                <GoalsModal />
                <ChatBot data={{name:skillSections[ind]?.allContent?.name, description: skillSections[ind]?.allContent?.description}} pageName={"Skill Details"}/>
            </>
        )
}
