import * as Axios from 'axios';
const API_URL = process.env.REACT_APP_API_LINK;

const axios = Axios.create({
    'headers': {
        'Authorization': `Stickball ${localStorage.getItem('token')}`,
        'x-api-key': process.env.REACT_APP_X_API_KEY
    }
})

export const getPlannerList = async () => {
    const res = await axios.get(`${API_URL.replace('/client', "")}/planner`, {
        'headers': {
            'Authorization': `Stickball ${localStorage.getItem('token')}`
        }
    })
    return (res.data)
}