import React, {
  useState,
  useEffect,
  useRef,
  forwardRef,
  useCallback,
} from "react";
import { AdminQuillEditor, QuillEditor } from "../details/quil-editor";
import FileViewer from "../../FileViewer";
import PdfViewer from "../../PdfViewer";
import { convertToEmbedUrl } from '../../embedUtils';
import { useSelector } from "react-redux";
import ReactPlayer from "react-player";
import { TikTokEmbed } from "react-social-media-embed";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import ReplayIcon from "@mui/icons-material/Replay";
import DriveFileRenameOutlineRoundedIcon from "@mui/icons-material/DriveFileRenameOutlineRounded";
import CollapsibleTable from "./collapse";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import {
  Tabs,
  Tab,
  Divider,
  IconButton,
  Grid,
  Box,
  Button,
  Typography,
  Dialog,
  Slide,
  TextField,
} from "@mui/material";
import Avatar from "@mui/material/Avatar";
import { Unity, useUnityContext } from "react-unity-webgl";
import SimpleImageSlider from "react-simple-image-slider";
import UploadIcon from "@mui/icons-material/Upload";
import { InputLabel, MenuItem, FormControl, Select } from "@mui/material";
import SaveAsRoundedIcon from "@mui/icons-material/SaveAsRounded";
import { getForms, getJotForms, getAllIcons, getAllChatbots } from "../../_api";
import { useQuery } from "react-query";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import Autocomplete from "@mui/material/Autocomplete";
import Stack from "@mui/material/Stack";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import toast from "react-hot-toast";
import { useFormik } from "formik";
import * as Yup from "yup";
import ClosedCaptionIcon from "@mui/icons-material/ClosedCaption";
import CloseIcon from "@mui/icons-material/Close";
import Notes from "../notes/Notes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBook } from "@fortawesome/free-solid-svg-icons";
import { setNextEnabled } from "../../../redux-features/user/userSlice";
import { useDispatch } from "react-redux";
import Webcam from "react-webcam";
import TodoListModal from "../notes/TodoListModal";
/*----------------------------------------------------------------
    Views for adimin to view add or edit content types
-------------------------------------------------------------------*/
const isAdminEdit = () => sessionStorage.getItem("view") === "admin-edit"; //admin-edit || admin
const isAdminAdd = () => sessionStorage.getItem("view") === "admin-add"; //admin-add || admin
const isAdminView = () => sessionStorage.getItem("view") === "admin"; //admin-view || admin
const isSpanish = () => sessionStorage.getItem("lang") === "sp";
const DATA_TYPE = {
  QUILL: 'QUILL_EDITOR',
  MEDIA: "MEDIA",
  LINK: "LINK",
  MULTI: "MULTI",
  FORM: "FORM",
  METAVERSE: 'METAVERSE',
}
let UNITY_LINK = process.env.REACT_APP_UNITY_APP_LINK
const METAVERSE_DATA = [
  {
    label: "Stickball Mall",
    value: UNITY_LINK + "mall"
  },
  {
    label: "Stickball Show Room",
    value: UNITY_LINK + "showroom"
  },
  {
    label: "Financial Advisor",
    value: UNITY_LINK + "financial-advisor"
  },
  {
    label: "Stickball Grocery Store",
    value: UNITY_LINK + "grocery-store"
  },
  {
    label: "Doctor Office",
    value: UNITY_LINK + "doctor-office"
  },
  {
    label: "Hospital",
    value: UNITY_LINK + "hospital"
  },
  {
    label: "Human Resources",
    value: UNITY_LINK + "human-resources"
  },
  {
    label: "Grocery Stock",
    value: UNITY_LINK + "grocery-stock"
  },
  {
    label: "Personal Banking",
    value: UNITY_LINK + "personal-banking"
  },
  {
    label: "Banking Roles",
    value: UNITY_LINK + "banking-roles"
  },
  {
    label: "First Tech",
    value: UNITY_LINK + "first-tech"
  },
  {
    label: "Stickball Bank",
    value: UNITY_LINK + "stickball-bank"
  },
  {
    label: "Fidelity Investments",
    value: UNITY_LINK + "fidelity-investments"
  },
  {
    label: "Stickball Company",
    value: UNITY_LINK + "stickball-company"
  },
  {
    label: "Musketeers Mall",
    value: UNITY_LINK + "musketeers-mall"
  },
  {
    label: "Stickball Apartments",
    value: UNITY_LINK + "stickabll-apartments"
  },
  {
    label: "Smart Shoping Mall",
    value: UNITY_LINK + "smart-shop-mall"
  },
  {
    label: "Stickball Office",
    value: UNITY_LINK + "polygon-office"
  },
  {
    label: "Stickball School",
    value: UNITY_LINK + "school"
  },
  {
    label: "Careers",
    value: UNITY_LINK + "careers"
  },
  {
    label: "Stickball Business",
    value: UNITY_LINK + "stickball-business"
  },
  {
    label: "Jobs",
    value: UNITY_LINK + "jobs"
  },
  {
    label: "Investing",
    value: UNITY_LINK + "investing"
  },
  {
    label: "Job Performance",
    value: UNITY_LINK + "job-performance"
  },
  {
    label: "Brookline Bank",
    value: UNITY_LINK + "brookline-bank"
  },
  {
    label: "Passumpsic Bank",
    value: UNITY_LINK + "passumpsic-bank"
  },
  {
    label: "St. Vincents Thrift",
    value: UNITY_LINK + "vincents-thrift"
  },
  {
    label: "Fast Food Cashier",
    value: UNITY_LINK + "fast-food"
  },
  {
    label: "Save Mart",
    value: UNITY_LINK + "save-mart"
  },
  {
    label: "Direct to Work Program",
    value: UNITY_LINK + "direct-to-work-program"
  },
  {
    label: 'Healthy Relationship',
    value: UNITY_LINK + 'healthy-relationship',
  },
  {
    label: 'Personality Style',
    value: UNITY_LINK + 'personality-style',
  }
]
const ADMIN_LINK = process.env.REACT_APP_ADMIN_APP

if (ADMIN_LINK.includes("northwell")) {
  METAVERSE_DATA.unshift({
    label: 'Apartment Unwelcome Visit',
    value: UNITY_LINK + 'apartments',
  })
  METAVERSE_DATA.unshift({
    label: 'Financial Tension at the Bank',
    value: UNITY_LINK + 'bank',
  })
  METAVERSE_DATA.unshift({
    label: 'Academic Tension Among Friends',
    value: UNITY_LINK + 'academic-tension',
  })
  METAVERSE_DATA.unshift({
    label: 'Workplace Dilemma at the Café',
    value: UNITY_LINK + 'cafe',
  })
  METAVERSE_DATA.unshift({
    label: 'Cafe Confrontation',
    value: UNITY_LINK + 'cafe-confrontation',
  })
  METAVERSE_DATA.unshift({
    label: 'Online Dating Scam',
    value: UNITY_LINK + 'online-dating-scam',
  })
}

/*----------------------------------------------------------------
    Main render function
-------------------------------------------------------------------*/
export default function SkillSection({ content: clientContent }) {
  const dispatch = useDispatch();
  let content = clientContent;
  const [points, setPoints] = useState();
  const [showModal, setShowModal] = useState(false);
  const [showContent, setShowContent] = useState(true);
  const user = useSelector((state) => state?.user?.userData?.user);
  content = {
    ...content,
    allContent: content?.allContent || content?.allContentID,
  };

  const handleIframeUpdate = (data) => {
    if (data?.data?.adminForm) {
      dispatch(setNextEnabled(!data?.data?.adminForm?.isSubmitted));
    }
  };

  useEffect(() => {
    window.addEventListener("message", handleIframeUpdate, false);
    return function () {
      window.removeEventListener("message", handleIframeUpdate);
    };
  }, []);

  useEffect(() => {
    if (content?.allContent?.type !== "GoogleForm") {
      dispatch(setNextEnabled(false));
    }
  }, [content]);
  useEffect(() => {
    setShowContent(false);
    return setTimeout(() => setShowContent(true), 100);
  }, [content?.allContent]);
  if (!content?.allContent)
    return <h5 className="mt-5 text-center pt-5">No data to show!</h5>;
  content = {
    ...content,
    allContent: content?.allContent || content?.allContentID,
  };
  const { type } = content?.allContent;

  const openModal = () => {
    setShowModal(!showModal);
  };

  return (
    <>
      {showModal && (
        <div>
          <div className="journal-modal">
            <Notes />
          </div>
        </div>
      )}
      {isAdminAdd() && (
        <div style={pointsStyle}>
          <Adminwrapper
            dataType={DATA_TYPE.LINK}
            data={points}
            type="points"
            setData={setPoints}
            label="Points"
          >
            <p style={{ ...placeholderStyle, margin: 0 }}>
              Points: {points ? points : 0}
            </p>
          </Adminwrapper>
        </div>
      )}
      {showContent && (
        <>
          {type === "Image-Stickbot-Audio" && (
            <RenderImageStickbotAudio
              content={{ ...content, points }}
              user={user}
            />
          )}
          {type === "Stickbot-Camera" && (
            <RenderStickbotCamera
              content={{ ...content, points }}
              user={user}
            />
          )}
          {type === "Video-Stickbot-Audio" && (
            <RenderVideoStickbotAudio
              content={{ ...content, points }}
              user={user}
            />
          )}
          {type === "Metaverse" && (
            <Metaverse content={{ ...content, points }} />
          )}
          {type === "CareerHistory" && (
            <CareerHistory content={{ ...content, points }} />
          )}
          {type === "Text" && <RenderText content={{ ...content, points }} />}
          {(type === "Wireframe-Stickbot" ||
            type === "Stickbot-Wireframe" ||
            type === "Youtube-Stickbot") && (
              <WireframeStickbot user={user} content={{ ...content, points }} />
            )}
          {type === "Matterport-Stickbot" || type === "Money-Stickbot" ? (
            <RenderMatterportStickbot
              content={{ ...content, points }}
              user={user}
            />
          ) : (
            <></>
          )}
          {type === "Iframe-Iframe" || type === "Iframe-TikTok" ? (
            <RenderIframIfram content={{ ...content, points }} />
          ) : (
            <></>
          )}
          {type === "Iframe-Audio" ? (
            <RenderIframAudio content={{ ...content, points }} />
          ) : (
            <></>
          )}
          {type === "Image-Video" ? (
            <RenderImageVideo content={{ ...content, points }} />
          ) : (
            <></>
          )}
          {type === "Image-Audio" ? (
            <RenderImageAudio content={{ ...content, points }} />
          ) : (
            <></>
          )}
          {type === "Iframe-Video" ? (
            <RenderIframVideo content={{ ...content, points }} />
          ) : (
            <></>
          )}
          {type === "Video-Stickbot" ? (
            <RenderVideoStickbot content={{ ...content, points }} />
          ) : (
            <></>
          )}
          {type === "Video" && (
            <ContentVideo content={{ ...content, points }} />
          )}
          {type === "Audio" && <RenderAudio content={{ ...content, points }} />}
          {type === "Doodle-Video" && (
            <RenderDoodleVideo content={{ ...content, points }} />
          )}
          {type === "Youtube" && (
            <RenderYoutube content={{ ...content, points }} />
          )}
          {type === "TikTok" && (
            <RenderTiktok content={{ ...content, points }} />
          )}
          {type === "Tiktok-Hybrid" && (
            <RenderTiktokHybrid content={{ ...content, points }} />
          )}
          {type === "Video-Six-Grid" && (
            <VideoSixGrid content={{ ...content, points }} />
          )}
          {type === "Video-Grid-Zoom" && (
            <VideoGridZoom content={{ ...content, points }} />
          )}
          {type === "Image-Grid" && (
            <RenderImageGrid content={{ ...content, points }} />
          )}
          {type === "Image-Grid-Use-Cases" && (
            <RenderImageGridUseCases content={{ ...content, points }} />
          )}
          {type === "Image" && <RenderImage content={{ ...content, points }} />}
          {type === "PPT-Content-Image" && (
            <PPTImage content={{ ...content, points }} />
          )}
          {type === "Content-Stickbot-Vertical" && (
            <RenderStickbotVertical
              content={{ ...content, points }}
              user={user}
            />
          )}
          {type === "Content-Image-Vertical" && (
            <RenderImageVertical content={{ ...content, points }} />
          )}
          {(type === "Content-Image-Wireframe" ||
            type === "Content-Image-Stickbot") && (
              <RenderImageWireframeAndImageStickbot
                user={user}
                content={{ ...content, points }}
              />
            )}
          {type === "Wireframe" && (
            <RenderWireframe content={{ ...content, points }} user={user} />
          )}
          {type === "Matterport" && (
            <RenderMatterport content={{ ...content, points }} />
          )}
          {(type === "GoogleForm" || type === "FormAutomation") && (
            <GoogleForm content={{ ...content, points }} />
          )}
          {type === "Chatbot" && <Chatbot content={{ ...content, points }} />}
          {type === "Dropdown" && (
            <RenderDropdown content={{ ...content, points }} />
          )}
          {type === "PDF" && <RenderPDF content={{ ...content, points }} />}
          {type === "Google-Sheet" && (
            <RenderGoogleSheet content={{ ...content, points }} />
          )}
          {type === "Power-Point" && (
            <RenderPowerPoint content={{ ...content, points }} />
          )}
          {type === "Video-Audio" && (
            <RenderVideoAudio content={{ ...content, points }} />
          )}
        </>
      )}
      <Box sx={{ height: '75px', width: '100%' }} />
      <Box sx={{ height: '75px', position: 'fixed', bottom: 0, right: 0, width: '100%', background: 'white', }} />
      {isAdminAdd() || isAdminEdit() || isAdminView() ? (
        <></>
      ) : (
        sessionStorage.getItem("access") !== "ok" && (
          <>
            <button
              onClick={openModal}
              // className="btn-journal"
              style={{ ...journalButtonStyle }}
            >
              {showModal ? (
                <CloseIcon fontSize="large" />
              ) : (
                <FontAwesomeIcon
                  style={{
                    fontSize: "1.8rem",
                    color: "#616ded",
                    marginTop: "-2px",
                  }}
                  icon={faBook}
                />
              )}
            </button >
            <TodoListModal />
          </>
        )
      )
      }
    </>
  );
}

/*----------------------------------------------------------------
    Placeholder when there is nothing in a field for Add or Edit
-------------------------------------------------------------------*/
const renderPlaceholder = (data, placeholderText) => {
  return (
    (isAdminAdd() || isAdminEdit()) &&
    !data && <p style={placeholderStyle}>{placeholderText}</p>
  );
};

/*----------------------------------------------------------------
    Custom Audio Player used for playing audio
-------------------------------------------------------------------*/
const CCTransition = forwardRef(function Transition(props, ref) {
  return (
    <Slide
      direction="up"
      style={{
        justifyContent: "center",
      }}
      ref={ref}
      {...props}
    />
  );
});
const CustomAudioPlayer = (props) => {
  const { src, autoPlay, text } = props;
  const [ccModal, setCCModal] = useState(false);

  return (
    <>
      <Dialog
        open={ccModal}
        TransitionComponent={CCTransition}
        keepMounted
        onClose={() => setCCModal(false)}
        aria-describedby="alert-dialog-slide-description"
        maxWidth="xxs"
        style={{
          backgroundColor: "rgba(1000, 1000, 1000, 0.2)",
          overflow: "visible",
        }}
      >
        <DialogTitle sx={{ display: "flex", justifyContent: "space-between" }}>
          Transcript
          <IconButton onClick={() => setCCModal(false)}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <p style={audioText}>{text}</p>
      </Dialog>
      <div style={audioPlayerStyle}>
        <AudioPlayer autoPlay={autoPlay} src={src} />
        <IconButton style={ccButton} onClick={() => setCCModal(true)}>
          <ClosedCaptionIcon fontSize="large" />
        </IconButton>
      </div>
    </>
  );
};

const CustomVideoPlayer = (props) => {
  const { url, text, height } = props;
  const [ccModal, setCCModal] = useState(false);
  const [showButton, setShowButton] = useState(false);

  return (
    <>
      <Dialog
        open={ccModal}
        TransitionComponent={CCTransition}
        keepMounted
        onClose={() => setCCModal(false)}
        aria-describedby="alert-dialog-slide-description"
        maxWidth="xxs"
        style={{
          backgroundColor: "rgba(1000, 1000, 1000, 0.2)",
          overflow: "visible",
        }}
      >
        <DialogTitle sx={{ display: "flex", justifyContent: "space-between" }}>
          Transcript
          <IconButton onClick={() => setCCModal(false)}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <p style={audioText}>{text}</p>
      </Dialog>
      <div
        style={audioPlayerStyle}
        onMouseEnter={() => setShowButton(true)}
        onMouseLeave={() => setShowButton(false)}
      >
        <ReactPlayer
          url={url}
          playing={true}
          loop={true}
          controls={true}
          width="100%"
          maxHeight={height || "550px"}
          height={height}
        />
        {showButton && (
          <IconButton style={ccVideoButton} onClick={() => setCCModal(true)}>
            <ClosedCaptionIcon fontSize="large" />
          </IconButton>
        )}
      </div>
    </>
  );
};

/*----------------------------------------------------------------
   Iframe links checking if valid
-------------------------------------------------------------------*/
const isValidUrl = (urlString) => {
  try {
    return Boolean(new URL(urlString));
  } catch (e) {
    return false;
  }
};

const getName = (user) => {
  if (user?.firstName && user?.lastName) {
    return `${user?.firstName} ${user?.lastName}`;
  } else if (user?.firstName) {
    return user?.firstName;
  } else if (user?.lastName) {
    return user?.lastName;
  } else {
    return "_User";
  }
};

/*----------------------------------------------------------------
   Iframe Lazzy Loader
-------------------------------------------------------------------*/
const LazzyLoading = () => {
  return (
    <div>
      <div className="lazzy-container d-flex flex-column">
        <h4 style={{ color: "#616ded", marginBottom: "20px" }}>Loading...</h4>
        <div class="lds-roller">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  );
};

const Video = ({ src }) => {
  const [muted, setMuted] = useState(false);
  const videoEl = useRef(null);
  const attemptPlay = () => {
    if (videoEl && videoEl?.current) {
      videoEl?.current?.play();
    }
  };
  useEffect(() => {
    attemptPlay();
  }, [videoEl?.current]);
  return (
    <>
      <div style={{ width: "fit-content", position: "relative" }}>
        <video
          playsInline
          loop
          muted={!muted}
          controls={false}
          alt="All the devices"
          ref={videoEl}
          src={src}
        ></video>
        <IconButton
          onClick={() => setMuted(!muted)}
          style={{
            position: "absolute",
            right: 12,
            top: 12,
            backgroundColor: "rgba(1000,1000,1000,0.7)",
          }}
        >
          {muted ? <VolumeUpIcon /> : <VolumeOffIcon />}
        </IconButton>
      </div>
    </>
  );
};

const isJson = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

/*----------------------------------------------------------------
    All Content Types rendering functions
-------------------------------------------------------------------*/
const RenderImageStickbotAudio = ({ content, user }) => {
  const myObj = JSON.parse(content?.allContent?.content);
  const { video_link, audio_link, heading1, heading2, stickbot_link, text } =
    myObj;
  const [isAdd, setIsAdd] = useState(false);
  const { name, description, type } = content?.allContent;
  const [isIframeReady, setIsIframeReady] = useState(false);
  const [data, setStateData] = useState({
    name,
    description,
    type,
    wireframe_link: video_link,
    wireframe_link1: audio_link,
    heading1,
    heading2,
    link_1: stickbot_link,
    text,
  });
  const { QUILL, MEDIA, LINK, MULTI } = DATA_TYPE;
  const imagesVisibility = () => {
    return data?.wireframe_link?.map((image) => {
      if (!image.name)
        return { url: process.env.REACT_APP_S3_BUCKET_LINK + image };
      return { url: URL.createObjectURL(image) };
    });
  };

  const setData = (obj) => {
    setStateData(obj);
    contentFormik.setValues(obj);
  };
  const contentFormik = useFormik({
    initialValues: {
      name: data.name,
      type: data.type,
      description: data.description,
      heading1: data?.heading1,
      heading2: data?.heading2,
      wireframe_link: data?.wireframe_link,
      wireframe_link1: data?.wireframe_link1,
      link_1: data?.link_1,
      text: data?.text,
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .required("Name is Required")
        .test("matches", "Name is required", checkQuilValidation),
      description: Yup.string()
        .required("Description is Required")
        .test("matches", "Description is required", checkQuilValidation),
      heading1: Yup.string().required("Heading is Required"),
      heading2: Yup.string().required("Heading is Required"),
      wireframe_link: Yup.array()
        .min(1, "At least one image is required")
        .required("At least one image is Required"),
      wireframe_link1: Yup.string().required("Audio is Required"),
      link_1: Yup.string().required("Stickbot link is Required"),
      text: Yup.string().required("Audio Subtitle is Required"),
    }),
    onSubmit: () => {
      handleAdminUpdate();
    },
  });
  const handleAdminUpdate = () => {
    window.parent.postMessage(
      isAdminAdd() ? { ...data, points: content?.points } : data,
      ADMIN_LINK
    );
  };
  return (
    <div className="col-12" style={containerStyle}>
      {isAdminEdit() && (
        <button style={btnUpdateStyle} onClick={contentFormik.handleSubmit}>
          Update
        </button>
      )}
      {isAdminAdd() && (
        <button style={btnAddStyle} onClick={contentFormik.handleSubmit}>
          ADD
        </button>
      )}
      <div className="container-ss">
        <Adminwrapper
          errorText={contentFormik.errors.name}
          dataType={QUILL}
          data={data}
          setData={setData}
          type="name"
        >
          {renderPlaceholder(data?.name, "Enter Name")}
          <QuillEditor sx={quilStyle} value={data.name} readOnly={true} />
        </Adminwrapper>
        <Adminwrapper
          errorText={contentFormik.errors.description}
          dataType={QUILL}
          data={data}
          setData={setData}
          type="description"
        >
          {renderPlaceholder(data?.description, "Enter Description")}
          <QuillEditor
            sx={quilStyle}
            value={data.description}
            readOnly={true}
          />
        </Adminwrapper>
        <Grid container>
          <Grid xs={6}>
            <Adminwrapper
              errorText={contentFormik.errors.heading1}
              dataType={LINK}
              data={data}
              setData={setData}
              type="heading1"
              label={"Heading 1"}
            >
              {renderPlaceholder(data?.heading1, "Enter Heading 1")}
              <Typography variant="h5" sx={{ textAlign: "center", m: 2 }}>
                {data?.heading1}
              </Typography>
            </Adminwrapper>

            <Box
              style={{
                position: "relative",
                height: "450px",
                overflowY: "auto",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Adminwrapper
                errorText={contentFormik.errors.wireframe_link}
                dataType={MULTI}
                data={data}
                type="link1"
                setData={setData}
              >
                <SimpleImageSlider
                  width="100%"
                  height="400px"
                  images={imagesVisibility()}
                  showBullets={false}
                  showNavs={false}
                  autoPlay={true}
                  autoPlayDelay={parseFloat(myObj?.timer) || 1}
                />
              </Adminwrapper>

              {isAdminAdd() && (
                <>
                  <div style={addDropdown}>
                    <Button startIcon={<UploadIcon />}>
                      <input
                        type="file"
                        name="file"
                        style={inputStyle}
                        onChange={(e) => {
                          setData({
                            ...data,
                            wireframe_link: [
                              ...data?.wireframe_link,
                              e.target.files[0],
                            ],
                          });
                        }}
                        accept="image/*"
                      />
                      Choose Image {data?.wireframe_link?.length + 1}
                    </Button>
                  </div>
                </>
              )}
            </Box>
            <Box sx={{ py: 2 }}>
              {!!Object.keys(data).length && (
                <Adminwrapper
                  errorText={contentFormik.errors.wireframe_link1}
                  dataType={MEDIA}
                  data={data}
                  setData={setData}
                  type="link2"
                  mediaType={"AUDIO"}
                >
                  <CustomAudioPlayer
                    autoPlay={false}
                    src={
                      data?.wireframe_link1.includes("https")
                        ? data?.wireframe_link1.replace(process.env.REACT_APP_S3_BUCKET_LINK, (process.env.REACT_APP_S3_BUCKET_LINK + (isSpanish() ? "spanish/" : "")))
                        : process.env.REACT_APP_S3_BUCKET_LINK + (isSpanish() ? "spanish/" : "") +
                        data?.wireframe_link1
                    }
                    text={data?.text || "No subtitle to show"}
                  />
                </Adminwrapper>
              )}
              {(isAdminAdd() || isAdminEdit()) && (
                <Adminwrapper
                  errorText={contentFormik.errors.text}
                  dataType={LINK}
                  data={data}
                  setData={setData}
                  type="text"
                  label="Audio Subtitle"
                >
                  {renderPlaceholder(data?.text, "Enter Audio Subtitle")}
                  <QuillEditor
                    sx={quilStyle}
                    value={data?.text}
                    readOnly={true}
                  />
                </Adminwrapper>
              )}
            </Box>
          </Grid>
          <Grid xs={6}>
            <Adminwrapper
              errorText={contentFormik.errors.heading2}
              dataType={LINK}
              data={data}
              setData={setData}
              type="heading2"
              label={"Heading 2"}
            >
              {renderPlaceholder(data?.heading2, "Enter Heading 2")}
              <Typography variant="h5" sx={{ textAlign: "center", m: 2 }}>
                {data?.heading2}
              </Typography>
            </Adminwrapper>

            <Adminwrapper
              errorText={contentFormik.errors.link_1}
              dataType={LINK}
              data={data}
              setData={setData}
              type="link_1"
              label={"Stickbot Link"}
            >
              {!isIframeReady && <LazzyLoading />}
              <iframe
                onLoad={() => setIsIframeReady(true)}
                style={{
                  width: "100%",
                  height: "400px",
                  paddingX: "5px",
                  opacity: isIframeReady ? 1 : 0,
                }}
                src={
                  isValidUrl(data?.link_1)
                    ? data?.link_1 + `?userId=${getName(user)}`
                    : "https://www.google.com"
                }
                frameBorder="0"
              ></iframe>
            </Adminwrapper>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

const RenderVideoStickbotAudio = ({ content, user }) => {
  const myObj = JSON.parse(content?.allContent?.content);
  const {
    video_link,
    audio_link,
    heading1,
    heading2,
    stickbot_link,
    text,
    vidText,
  } = myObj;
  const { name, description, type } = content?.allContent;
  const [isIframeReady, setIsIframeReady] = useState(false);
  const [data, setStateData] = useState({
    name,
    description,
    type,
    wireframe_link: video_link,
    wireframe_link1: audio_link,
    heading1,
    heading2,
    link_1: stickbot_link,
    text,
    vidText,
  });
  const { QUILL, MEDIA, LINK } = DATA_TYPE;
  const videoVisibility = () => {
    if (!data?.wireframe_link?.name)
      return process.env.REACT_APP_S3_BUCKET_LINK + data?.wireframe_link;
    return URL.createObjectURL(data?.wireframe_link);
  };
  const setData = (obj) => {
    setStateData(obj);
    contentFormik.setValues(obj);
  };
  const contentFormik = useFormik({
    initialValues: {
      name: data.name,
      type: data.type,
      description: data.description,
      heading1: data?.heading1,
      heading2: data?.heading2,
      wireframe_link: data?.wireframe_link,
      wireframe_link1: data?.wireframe_link1,
      link_1: data?.link_1,
      text: data?.text,
      vidText: data?.vidText,
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .required("Name is Required")
        .test("matches", "Name is required", checkQuilValidation),
      description: Yup.string()
        .required("Description is Required")
        .test("matches", "Description is required", checkQuilValidation),
      heading1: Yup.string().required("Heading is Required"),
      heading2: Yup.string().required("Heading is Required"),
      wireframe_link: Yup.string().required("Video is Required"),
      wireframe_link1: Yup.string().required("Audio is Required"),
      link_1: Yup.string().required("Stickbot link is Required"),
      text: Yup.string().required("Audio Subtitle is Required"),
      vidText: Yup.string().required("Video Subtitle is Required"),
    }),
    onSubmit: () => {
      handleAdminUpdate();
    },
  });

  const handleAdminUpdate = () => {
    window.parent.postMessage(
      isAdminAdd() ? { ...data, points: content?.points } : data,
      ADMIN_LINK
    );
  };
  return (
    <div className="col-12" style={containerStyle}>
      {isAdminEdit() && (
        <button style={btnUpdateStyle} onClick={contentFormik.handleSubmit}>
          Update
        </button>
      )}
      {isAdminAdd() && (
        <button style={btnAddStyle} onClick={contentFormik.handleSubmit}>
          ADD
        </button>
      )}
      <div className="container-ss">
        <Adminwrapper
          errorText={contentFormik.errors.name}
          dataType={QUILL}
          data={data}
          setData={setData}
          type="name"
        >
          {renderPlaceholder(data?.name, "Enter Name")}
          <QuillEditor sx={quilStyle} value={data?.name} readOnly={true} />
        </Adminwrapper>
        <Grid container>
          <Grid xs={6}>
            <Adminwrapper
              errorText={contentFormik.errors.heading1}
              dataType={LINK}
              data={data}
              setData={setData}
              type="heading1"
              label="Heading 1"
            >
              {renderPlaceholder(data?.heading1, "Enter Heading")}
              <Typography variant="h5" sx={{ textAlign: "center", m: 2 }}>
                {data?.heading1}
              </Typography>
            </Adminwrapper>
            <Box sx={{ width: "100%", height: "400px" }}>
              <Adminwrapper
                errorText={contentFormik.errors.content}
                dataType={MEDIA}
                data={data}
                setData={setData}
                type="content"
                mediaType={"VIDEO"}
              >
                <CustomVideoPlayer
                  url={videoVisibility()}
                  text={data?.vidText || "No Transcript Added"}
                  autoPlay={true}
                />
              </Adminwrapper>
              {(isAdminAdd() || isAdminEdit()) && (
                <Adminwrapper
                  errorText={contentFormik.errors.vidText}
                  dataType={LINK}
                  data={data}
                  setData={setData}
                  type="vidText"
                  label="Video Subtitle"
                >
                  {renderPlaceholder(data?.vidText, "Enter Video Subtitle")}
                  <QuillEditor
                    sx={quilStyle}
                    value={data?.vidText}
                    readOnly={true}
                  />
                </Adminwrapper>
              )}
              {/* <Adminwrapper errorText={contentFormik.errors.wireframe_link} dataType={MEDIA} data={data} setData={setData} type="link1" mediaType={"VIDEO"}>
                                <ReactPlayer width="100%" height="400px" url={videoVisibility()} controls={true} />
                            </Adminwrapper> */}
            </Box>
            <Box
              sx={{
                p: 2,
                height: 200,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                mt: 5,
              }}
            >
              <Adminwrapper
                errorText={contentFormik.errors.wireframe_link1}
                dataType={MEDIA}
                data={data}
                setData={setData}
                type="link2"
                mediaType={"AUDIO"}
              >
                <CustomAudioPlayer
                  autoPlay={false}
                  src={
                    data?.wireframe_link1.includes("https")
                      ? data?.wireframe_link1
                      : process.env.REACT_APP_S3_BUCKET_LINK +
                      data?.wireframe_link1
                  }
                  text={data?.text || "No subtitle to show"}
                />
              </Adminwrapper>
              {(isAdminAdd() || isAdminEdit()) && (
                <Adminwrapper
                  errorText={contentFormik.errors.text}
                  dataType={LINK}
                  data={data}
                  setData={setData}
                  type="text"
                  label="Audio Subtitle"
                >
                  {renderPlaceholder(data?.text, "Enter Audio Subtitle")}
                  <QuillEditor
                    sx={quilStyle}
                    value={data?.text}
                    readOnly={true}
                  />
                </Adminwrapper>
              )}
            </Box>
          </Grid>
          <Grid xs={6}>
            <Adminwrapper
              errorText={contentFormik.errors.heading2}
              dataType={LINK}
              data={data}
              setData={setData}
              type="heading2"
              label="Heading 2"
            >
              {renderPlaceholder(data?.heading2, "Enter Heading")}
              <Typography variant="h5" sx={{ textAlign: "center", m: 2 }}>
                {data?.heading2}
              </Typography>
            </Adminwrapper>
            <Adminwrapper
              errorText={contentFormik.errors.link_1}
              dataType={LINK}
              data={data}
              setData={setData}
              type="link_1"
              label={"Stickbot Link"}
            >
              {!isIframeReady && <LazzyLoading />}
              <iframe
                onLoad={() => setIsIframeReady(true)}
                style={{
                  width: "100%",
                  height: "550px",
                  paddingX: "5px",
                  opacity: isIframeReady ? 1 : 0,
                }}
                src={
                  isValidUrl(data?.link_1)
                    ? data?.link_1 + `?userId=${getName(user)}`
                    : "https://www.google.com"
                }
                frameBorder="0"
              ></iframe>
            </Adminwrapper>
          </Grid>
        </Grid>
        <Adminwrapper
          errorText={contentFormik.errors.description}
          dataType={QUILL}
          data={data}
          setData={setData}
          type="description"
        >
          {renderPlaceholder(data?.description, "Enter Description")}
          <QuillEditor
            sx={quilStyle}
            value={data?.description}
            readOnly={true}
          />
        </Adminwrapper>
      </div>
    </div>
  );
};

const RenderVideoAudio = ({ content, user }) => {
  const myObj = JSON.parse(content?.allContent?.content);
  const { wireframe_link, wireframe_link1, text, vidText } = myObj;
  const { name, description, type } = content?.allContent;
  const [isIframeReady, setIsIframeReady] = useState(false);
  const [data, setStateData] = useState({
    name,
    description,
    type,
    wireframe_link,
    wireframe_link1,
    text,
    vidText,
  });
  const { QUILL, MEDIA, LINK } = DATA_TYPE;
  const videoVisibility = () => {
    if (!data?.wireframe_link?.name)
      return process.env.REACT_APP_S3_BUCKET_LINK + data?.wireframe_link;
    return URL.createObjectURL(data?.wireframe_link);
  };
  const setData = (obj) => {
    setStateData(obj);
    contentFormik.setValues(obj);
  };
  const contentFormik = useFormik({
    initialValues: {
      name: data.name,
      type: data.type,
      description: data.description,
      wireframe_link: data?.wireframe_link,
      wireframe_link1: data?.wireframe_link1,
      text: data?.text,
      vidText: data?.vidText,
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .required("Name is Required")
        .test("matches", "Name is required", checkQuilValidation),
      description: Yup.string()
        .required("Description is Required")
        .test("matches", "Description is required", checkQuilValidation),
      wireframe_link: Yup.string().required("Video is Required"),
      wireframe_link1: Yup.string().required("Audio is Required"),
      text: Yup.string().required("Audio Subtitle is Required"),
      vidText: Yup.string().required("Video Subtitle is Required"),
    }),
    onSubmit: () => {
      handleAdminUpdate();
    },
  });

  const handleAdminUpdate = () => {
    window.parent.postMessage(
      isAdminAdd() ? { ...data, points: content?.points } : data,
      ADMIN_LINK
    );
  };
  return (
    <div
      className="col-12"
      style={{ ...containerStyle, paddingLeft: "100px", paddingRight: "100px" }}
    >
      {isAdminEdit() && (
        <button style={btnUpdateStyle} onClick={contentFormik.handleSubmit}>
          Update
        </button>
      )}
      {isAdminAdd() && (
        <button style={btnAddStyle} onClick={contentFormik.handleSubmit}>
          ADD
        </button>
      )}
      <div className="container-ss d-flex flex-column align-items-center">
        <Adminwrapper
          errorText={contentFormik.errors.name}
          dataType={QUILL}
          data={data}
          setData={setData}
          type="name"
        >
          {renderPlaceholder(data?.name, "Enter Name")}
          <QuillEditor sx={quilStyle} value={data?.name} readOnly={true} />
        </Adminwrapper>

        <Box sx={{ width: "80%", height: "400px" }}>
          <Adminwrapper
            errorText={contentFormik.errors.content}
            dataType={MEDIA}
            data={data}
            setData={setData}
            type="content"
            mediaType={"VIDEO"}
          >
            <CustomVideoPlayer
              url={videoVisibility()}
              text={data?.vidText || "No Transcript Added"}
              autoPlay={true}
            />
          </Adminwrapper>
          {(isAdminAdd() || isAdminEdit()) && (
            <Adminwrapper
              errorText={contentFormik.errors.vidText}
              dataType={LINK}
              data={data}
              setData={setData}
              type="vidText"
              label="Video Subtitle"
            >
              {renderPlaceholder(data?.vidText, "Enter Video Subtitle")}
              <QuillEditor
                sx={quilStyle}
                value={data?.vidText}
                readOnly={true}
              />
            </Adminwrapper>
          )}
        </Box>
        <Adminwrapper
          errorText={contentFormik.errors.description}
          dataType={QUILL}
          data={data}
          setData={setData}
          type="description"
        >
          {renderPlaceholder(data?.description, "Enter Description")}
          <QuillEditor
            sx={quilStyle}
            value={data?.description}
            readOnly={true}
          />
        </Adminwrapper>

        <Box
          sx={{
            height: 200,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            mt: 1,
            width: "80%",
          }}
        >
          <Adminwrapper
            errorText={contentFormik.errors.wireframe_link1}
            dataType={MEDIA}
            data={data}
            setData={setData}
            type="link2"
            mediaType={"AUDIO"}
          >
            <CustomAudioPlayer
              autoPlay={true}
              src={
                data?.wireframe_link1.includes("https")
                  ? data?.wireframe_link1
                  : process.env.REACT_APP_S3_BUCKET_LINK + data?.wireframe_link1
              }
              text={data?.text || "No subtitle to show"}
            />
          </Adminwrapper>
          {(isAdminAdd() || isAdminEdit()) && (
            <Adminwrapper
              errorText={contentFormik.errors.text}
              dataType={LINK}
              data={data}
              setData={setData}
              type="text"
              label="Audio Subtitle"
            >
              {renderPlaceholder(data?.text, "Enter Audio Subtitle")}
              <QuillEditor sx={quilStyle} value={data?.text} readOnly={true} />
            </Adminwrapper>
          )}
        </Box>
      </div>
    </div>
  );
};

const Metaverse = ({ content }) => {
  const [isLoaded, setIsLoaded] = useState(false)
  const { type, name, description } = content?.allContent
  const [data, setData] = useState({
    content: content?.allContent?.content,
    type,
    name,
    description,
  })




  const getUnityLink = (value) => {
    switch (value) {
      case 'mall':
        return UNITY_LINK + "mall"
      case 'room':
        return UNITY_LINK + "showroom"
      case 'advisor':
        return UNITY_LINK + "financial-advisor"
      case 'doctor-office':
        return UNITY_LINK + "doctor-office"
      case 'hospital':
        return UNITY_LINK + "hospital"
      case 'stock':
        return UNITY_LINK + "grocery-stock"
      case 'resources':
        return UNITY_LINK + "human-resources"
      case 'grocery':
        return UNITY_LINK + "grocery-store"
      case 'personal-banking':
        return UNITY_LINK + "personal-banking"
      case 'banking-roles':
        return UNITY_LINK + "banking-roles"
      case 'first-tech':
        return UNITY_LINK + "first-tech"
      case 'stickball-bank':
        return UNITY_LINK + "stickball-bank"
      case 'fidelity-investments':
        return UNITY_LINK + "fidelity-investments"
      case 'stickball-company':
        return UNITY_LINK + "stickball-company"
      case 'musketeers-mall':
        return UNITY_LINK + "musketeers-mall"
      case 'smart-shoping-mall':
        return UNITY_LINK + "smart-shop-mall"
      case 'stickball-apartments':
        return UNITY_LINK + "stickball-apartments"
      case 'polygon-office':
        return UNITY_LINK + "polygon-office"
      case 'stickball-school':
        return UNITY_LINK + "school"
      case 'careers':
        return UNITY_LINK + "careers"
      case 'stickball-business':
        return UNITY_LINK + "business"
      case 'jobs':
        return UNITY_LINK + "jobs"
      case 'investing':
        return UNITY_LINK + "investing"
      default:
        return value;
    }

  }


  const { QUILL, MEDIA, METAVERSE } = DATA_TYPE
  const handleAdminUpdate = () => {
    window.parent.postMessage(isAdminAdd() ? { ...data, points: content?.points, name: 'Name', description: 'Description' } : data, ADMIN_LINK)
  }
  return (
    <>
      {isAdminEdit() && <button style={btnUpdateStyle} onClick={handleAdminUpdate}>Update</button>}
      {isAdminAdd() && <button style={btnAddStyle} onClick={handleAdminUpdate}>ADD</button>}
      <div style={{ paddingLeft: 200, paddingRight: 200 }}>

        <Adminwrapper errorText={null} dataType={QUILL} data={data} setData={setData} type="name" >
          {renderPlaceholder(data?.name, "Enter Name")}
          <QuillEditor
            sx={{ marginTop: "20px", fontSize: '16px' }}
            value={data?.name}
            readOnly={true}
          />
        </Adminwrapper>
        <Adminwrapper errorText={null} setData={setData} type="description" dataType={QUILL} data={data} >
          {renderPlaceholder(data?.description, "Enter Description")}
          <QuillEditor
            sx={{ marginTop: "20px", fontSize: '16px', color: 'black' }}
            value={data?.description}
            readOnly={true}
          />
        </Adminwrapper>
      </div>
      <Adminwrapper setData={setData} type="content" dataType={METAVERSE} data={data} >
        {
          isAdminAdd() ? (
            <div style={mallStyle}>
              Metaverse will appear here
            </div>
          )
            :
            <>
              {
                !isLoaded && <LazzyLoading />
              }

              <iframe
                onLoad={() => setIsLoaded(true)}
                allowFullScreen="true"
                src={getUnityLink(data.content)}
                style={{
                  width: '100%',
                  height: '70vh',
                }}
              ></iframe>
            </>
        }
      </Adminwrapper>
    </>
  )
}

const CareerHistory = ({ content }) => {
  const [value, setValue] = useState(0);
  const [year1, setYear1] = useState(dayjs());
  const [year2, setYear2] = useState(dayjs());
  const [year3, setYear3] = useState(dayjs());
  const [year4, setYear4] = useState(dayjs());
  const [year5, setYear5] = useState(dayjs());
  const [svgLink, setSvgLink] = useState("");
  const [careerDescription1, setCareerDescription1] = useState("");
  const [careerDescription2, setCareerDescription2] = useState("");
  const [careerDescription3, setCareerDescription3] = useState("");
  const [careerDescription4, setCareerDescription4] = useState("");
  const [careerDescription5, setCareerDescription5] = useState("");
  const [openCareerModal, setOpenCareerModal] = useState(false);
  const [careerIndex, setCareerIndex] = useState(0);
  const { QUILL, MEDIA } = DATA_TYPE;
  const { name, description, type } = content?.allContent;
  const [data, setStateData] = useState({
    name,
    description,
    type,
    content: JSON.parse(content?.allContent?.content) || [],
  });
  const [history, setHistory] = useState([]);
  const { isLoading, data: allIcons } = useQuery("icons", getAllIcons);

  useEffect(() => {
    if (data?.content) {
      setHistory(data?.content[0]?.history || []);
    }
  }, [data]);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const setData = (obj) => {
    setStateData(obj);
    contentFormik.setValues(obj);
  };
  const contentFormik = useFormik({
    initialValues: {
      name: data.name,
      type: data.type,
      description: data.description,
      content: data?.content,
    },
    validationSchema: Yup.object({
      // name: Yup.string().required("Name is Required").matches(/(((<\w+>)+[ \n(<br>)]*(<\/\w+>)+)+)|<br>/g, "Name is Required matches"),
      name: Yup.string()
        .required("Name is Required")
        .test("matches", "Name is required", checkQuilValidation),
      content: Yup.array()
        .required("At least one Career History is Required")
        .min(1, "At least one Career History is Required"),
    }),
    onSubmit: () => {
      handleAdminUpdate();
    },
  });
  const handleAdminUpdate = () => {
    window.parent.postMessage(
      isAdminAdd() ? { ...data, points: content?.points } : data,
      ADMIN_LINK
    );
  };
  return (
    <div className="px-3">
      {isAdminAdd() && (
        <button style={btnAddStyle} onClick={contentFormik.handleSubmit}>
          ADD
        </button>
      )}
      <Dialog
        open={openCareerModal}
        onClose={() => setOpenCareerModal(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle style={{ textAlign: "center" }} id="alert-dialog-title">
          {"Add a Career History"}
        </DialogTitle>
        <DialogContent sx={{ width: "600px" }}>
          <Grid item md={12} xs={12}>
            <Autocomplete
              id="country-select-demo"
              sx={{ width: "100%" }}
              options={allIcons}
              autoHighlight
              onChange={(event, newValue) => setSvgLink(newValue?.svg)}
              getOptionLabel={(option) => option?.name}
              renderOption={(props, option) => (
                <Box
                  component="li"
                  sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                  {...props}
                >
                  <div style={selectImageStyle}>
                    <img loading="lazy" width="20" src={option?.svg} alt="" />
                  </div>
                  {option?.name}
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Choose Icon"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "new-password", // disable autocomplete and autofill
                  }}
                />
              )}
            />

            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Stack sx={{ mt: 2 }}>
                <DatePicker
                  views={["year"]}
                  label="Select Year"
                  value={year1}
                  onChange={(newValue) => {
                    setYear1(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} helperText={null} />
                  )}
                />
              </Stack>
            </LocalizationProvider>
            <AdminQuillEditor
              placeholder="Describe History"
              sx={{ height: 200, marginTop: "20px" }}
              value={careerDescription1}
              onChange={(e) => setCareerDescription1(e)}
            />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Stack sx={{ mt: 2 }}>
                <DatePicker
                  views={["year"]}
                  label="Select Year"
                  value={year2}
                  onChange={(newValue) => {
                    setYear2(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} helperText={null} />
                  )}
                />
              </Stack>
            </LocalizationProvider>
            <AdminQuillEditor
              placeholder="Describe History"
              sx={{ height: 200, marginTop: "20px" }}
              quiz={true}
              onChange={(e) => setCareerDescription2(e)}
            />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Stack sx={{ mt: 2 }}>
                <DatePicker
                  views={["year"]}
                  label="Select Year"
                  value={year3}
                  onChange={(newValue) => {
                    setYear3(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} helperText={null} />
                  )}
                />
              </Stack>
            </LocalizationProvider>
            <AdminQuillEditor
              placeholder="Describe History"
              sx={{ height: 200, marginTop: "20px" }}
              quiz={true}
              onChange={(e) => setCareerDescription3(e)}
            />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Stack sx={{ mt: 2 }}>
                <DatePicker
                  views={["year"]}
                  label="Select Year"
                  value={year4}
                  onChange={(newValue) => {
                    setYear4(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} helperText={null} />
                  )}
                />
              </Stack>
            </LocalizationProvider>
            <AdminQuillEditor
              placeholder="Describe History"
              sx={{ height: 200, marginTop: "20px" }}
              quiz={true}
              onChange={(e) => setCareerDescription4(e)}
            />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Stack sx={{ mt: 2 }}>
                <DatePicker
                  views={["year"]}
                  label="Select Year"
                  value={year5}
                  onChange={(newValue) => {
                    setYear5(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} helperText={null} />
                  )}
                />
              </Stack>
            </LocalizationProvider>
            <AdminQuillEditor
              placeholder="Describe History"
              sx={{ height: 200, marginTop: "20px" }}
              quiz={true}
              onChange={(e) => setCareerDescription5(e)}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenCareerModal(false)}>Cancel</Button>
          <Button
            onClick={() => {
              let careerHistory = data?.content || [];
              if (svgLink !== "") {
                careerHistory.push({
                  icon: svgLink,
                  history: [
                    {
                      year: year1.$y,
                      description: careerDescription1,
                    },
                    {
                      year: year2.$y,
                      description: careerDescription2,
                    },
                    {
                      year: year3.$y,
                      description: careerDescription3,
                    },
                    {
                      year: year4.$y,
                      description: careerDescription4,
                    },
                    {
                      year: year5.$y,
                      description: careerDescription5,
                    },
                  ],
                });
                setData({ ...data, content: careerHistory });
                setSvgLink("");
                setCareerDescription1("");
                setCareerDescription2("");
                setCareerDescription3("");
                setCareerDescription4("");
                setCareerDescription5("");
                setOpenCareerModal(false);
              } else {
                toast.error("Please Select Icon");
              }
            }}
            autoFocus
          >
            Add
          </Button>
        </DialogActions>
      </Dialog>

      <Adminwrapper
        errorText={contentFormik.errors.name}
        dataType={QUILL}
        data={data}
        setData={setData}
        type="name"
      >
        {renderPlaceholder(data?.name, "Enter Name")}
        <QuillEditor
          sx={{ marginTop: "20px", fontSize: "16px" }}
          value={data?.name}
          readOnly={true}
        />
      </Adminwrapper>

      <div
        className="row d-flex justify-content-center pt-3"
        style={{ marginLeft: "30px" }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          style={{ width: "fit-content" }}
          aria-label="icon label tabs example"
        >
          {data?.content?.map((career, i) => {
            return (
              <Tab
                icon={
                  <Avatar
                    sx={{ width: 66, height: 66 }}
                    style={{ backgroundColor: "#ccc", padding: 10 }}
                    key={i + "career2"}
                    alt="X"
                    src={career?.icon}
                  />
                }
                style={{ backgroundColor: "transparent" }}
                onClick={() => {
                  setHistory(career?.history);
                }}
              />
            );
          })}
        </Tabs>
        <Divider />
      </div>
      <div
        className="row d-flex justify-content-center pt-5 mt-5 ml-3"
        style={{ marginLeft: "30px" }}
      >
        {history?.slice(0, 3)?.map((his, ind) => {
          return (
            <div className="col-4 d-flex flex-column justify-content-center">
              <div className="side-history" key={ind}>
                <img
                  src={
                    ind === 0
                      ? "assets/img/money.png"
                      : ind === 1
                        ? "assets/img/cloud.png"
                        : "assets/img/bulb.png"
                  }
                  alt=""
                />
                <h4>{his?.year}</h4>
                <QuillEditor
                  sx={{ marginTop: "20px" }}
                  value={his?.description}
                  readOnly={true}
                />
              </div>
            </div>
          );
        })}
        {history?.slice(3, 5)?.map((his, ind) => {
          return (
            <div className="col-4 d-flex flex-column justify-content-center pt-5 mt-5">
              <div className="side-history-right" key={ind}>
                <img
                  src={
                    ind === 0
                      ? "assets/img/building.png"
                      : "assets/img/desk.png"
                  }
                  alt=""
                />
                <h4>{his?.year}</h4>
                <QuillEditor
                  sx={{ marginTop: "20px" }}
                  value={his?.description}
                  readOnly={true}
                />
              </div>
            </div>
          );
        })}
      </div>
      {isAdminAdd() && (
        <>
          <Button
            sx={{ m: 1, width: "fit-content", height: "fit-content" }}
            variant="contained"
            onClick={() => setOpenCareerModal(true)}
          >
            Add Career History
          </Button>
          {!!contentFormik?.errors?.content && (
            <p style={errorStyle}>{contentFormik?.errors?.content}</p>
          )}
        </>
      )}
    </div>
  );
};

const RenderText = ({ content }) => {
  const { QUILL, MEDIA } = DATA_TYPE;
  const { type, name, description } = content?.allContent;
  const [data, setStateData] = useState({
    type,
    name,
    description,
    content: content?.allContent.content,
  });

  const setData = (obj) => {
    setStateData(obj);
    contentFormik.setValues(obj);
  };
  const contentFormik = useFormik({
    initialValues: {
      name: data.name,
      type: data.type,
      description: data.description,
      content: data?.content,
    },
    validationSchema: Yup.object({
      // name: Yup.string().required("Name is Required").matches(/(((<\w+>)+[ \n(<br>)]*(<\/\w+>)+)+)|<br>/g, "Name is Required matches"),
      name: Yup.string()
        .required("Name is Required")
        .test("matches", "Name is required", checkQuilValidation),
      description: Yup.string()
        .required("Description is Required")
        .test("matches", "Description is required", checkQuilValidation),
      content: Yup.string()
        .required("Content is Required")
        .test("matches", "Content is required", checkQuilValidation),
    }),
    onSubmit: () => {
      handleAdminUpdate();
    },
  });

  const handleAdminUpdate = () => {
    window.parent.postMessage(
      isAdminAdd() ? { ...data, points: content?.points } : data,
      ADMIN_LINK
    );
  };
  return (
    <div className="col-12" style={containerStyle}>
      {isAdminEdit() && (
        <button style={btnUpdateStyle} onClick={contentFormik.handleSubmit}>
          Update
        </button>
      )}
      {isAdminAdd() && (
        <button style={btnAddStyle} onClick={contentFormik.handleSubmit}>
          ADD
        </button>
      )}
      <div className="container-ss">
        <Adminwrapper
          errorText={contentFormik.errors.name}
          setData={setData}
          type="name"
          dataType={QUILL}
          data={data}
        >
          {renderPlaceholder(data?.name, "Enter Name")}
          <QuillEditor sx={quilStyle} value={data?.name} readOnly={true} />
        </Adminwrapper>
        <Adminwrapper
          errorText={contentFormik.errors.content}
          setData={setData}
          type="content"
          dataType={QUILL}
          data={data}
        >
          {renderPlaceholder(data?.content, "Enter Content")}
          <QuillEditor
            // placeholder="Write something"
            value={data?.content}
            readOnly={true}
          />
        </Adminwrapper>
        <Adminwrapper
          errorText={contentFormik.errors.description}
          setData={setData}
          type="description"
          dataType={QUILL}
          data={data}
        >
          {renderPlaceholder(data?.description, "Enter Description")}
          <QuillEditor
            sx={{ marginTop: "20px", fontSize: "16px", color: "black" }}
            value={data?.description}
            readOnly={true}
          />
        </Adminwrapper>
      </div>
    </div>
  );
};

const WireframeStickbot = ({ content, user }) => {
  const myOBJ = JSON.parse(content?.allContent?.content);
  const [activeLink, setActiveLink] = useState(1);
  const { QUILL, LINK, MEDIA } = DATA_TYPE;
  const { name, description, type } = content?.allContent;
  const [isAdd, setIsAdd] = useState(false);
  const [stickbotName, setStickbotName] = useState("");
  const [stickbotLink, setStickbotLink] = useState("");
  const [isIframeReady, setIsIframeReady] = useState(false);
  const [data, setStateData] = useState({
    name,
    description,
    type,
    content: myOBJ,
    wireframe_link: myOBJ.wireframe_link,
    wireframe_link1: myOBJ.skill_assignment,
  });
  const setData = (obj) => {
    setStateData(obj);
    contentFormik.setValues(obj);
  };
  const contentFormik = useFormik({
    initialValues: {
      name: data.name,
      type: data.type,
      description: data.description,
      content: data?.content,
      wireframe_link: data.wireframe_link,
      wireframe_link1: data.wireframe_link1,
    },
    validationSchema: Yup.object({
      // name: Yup.string().required("Name is Required").matches(/(((<\w+>)+[ \n(<br>)]*(<\/\w+>)+)+)|<br>/g, "Name is Required matches"),
      name: Yup.string()
        .required("Name is Required")
        .test("matches", "Name is required", checkQuilValidation),
      description: Yup.string()
        .required("Description is Required")
        .test("matches", "Description is required", checkQuilValidation),
      content: Yup.object().required("Add at least one stickbot"),
      wireframe_link: Yup.string().required("Please enter link here"),
      wireframe_link1: Yup.string(),
    }),
    onSubmit: () => {
      handleAdminUpdate();
    },
  });
  const handleAdminUpdate = () => {
    window.parent.postMessage(
      isAdminAdd() ? { ...data, points: content?.points } : data,
      ADMIN_LINK
    );
  };
  return (
    <div className="d-flex flex-column align-items-center">
      {isAdminEdit() && (
        <button style={btnUpdateStyle} onClick={contentFormik.handleSubmit}>
          Update
        </button>
      )}
      {isAdminAdd() && (
        <button style={btnAddStyle} onClick={contentFormik.handleSubmit}>
          ADD
        </button>
      )}
      {isAdminAdd() && (
        <>
          {!isAdd && (
            <Button
              sx={{ m: 1, width: "fit-content" }}
              variant="contained"
              onClick={() => setIsAdd(true)}
            >
              Add Stickbot
            </Button>
          )}
          {isAdd && (
            <div style={addDropdown}>
              <TextField
                placeholder="Name"
                variant="outlined"
                label="Name"
                style={{ width: "400px" }}
                onChange={(e) => setStickbotName(e.target.value)}
              />
              <TextField
                placeholder="Link"
                variant="outlined"
                label="Link"
                style={{ width: "400px" }}
                onChange={(e) => setStickbotLink(e.target.value)}
              />
              <Button
                sx={{ m: 1 }}
                variant="contained"
                onClick={() => {
                  let tempStickbots = data?.content || [];
                  if (!tempStickbots?.link_1_name) {
                    tempStickbots.link_1_name = stickbotName;
                    tempStickbots.link_1 = stickbotLink;
                  } else if (!tempStickbots?.link_2_name) {
                    tempStickbots.link_2_name = stickbotName;
                    tempStickbots.link_2 = stickbotLink;
                  } else if (!tempStickbots?.link_3_name) {
                    tempStickbots.link_3_name = stickbotName;
                    tempStickbots.link_3 = stickbotLink;
                  } else if (!tempStickbots?.link_4_name) {
                    tempStickbots.link_4_name = stickbotName;
                    tempStickbots.link_4 = stickbotLink;
                  } else {
                    tempStickbots.link_5_name = stickbotName;
                    tempStickbots.link_5 = stickbotLink;
                  }
                  setIsAdd(false);

                  setData(...(data.content = tempStickbots));
                }}
              >
                Save
              </Button>
            </div>
          )}
        </>
      )}
      {data?.type === "Stickbot-Wireframe" ? (
        <Grid container style={gridStyle}>
          <Adminwrapper
            errorText={contentFormik.errors.name}
            dataType={QUILL}
            data={data}
            setData={setData}
            type="name"
          >
            {renderPlaceholder(data?.name, "Enter Name")}
            <QuillEditor sx={quilStyle} value={data?.name} readOnly={true} />
          </Adminwrapper>
          <div style={stickbotButtons}>
            <Button variant="contained" onClick={() => setActiveLink(1)}>
              {data?.content?.link_1_name}
            </Button>
            {!!data?.content?.link_2_name && (
              <Button variant="contained" onClick={() => setActiveLink(2)}>
                {data?.content?.link_2_name}
              </Button>
            )}
            {!!data?.content?.link_3_name && (
              <Button variant="contained" onClick={() => setActiveLink(3)}>
                {data?.content?.link_3_name}
              </Button>
            )}
            {!!data?.content?.link_4_name && (
              <Button variant="contained" onClick={() => setActiveLink(4)}>
                {data?.content?.link_4_name}
              </Button>
            )}
            {!!data?.content?.link_5_name && (
              <Button variant="contained" onClick={() => setActiveLink(5)}>
                {data?.content?.link_5_name}
              </Button>
            )}
          </div>
          <Adminwrapper
            errorText={contentFormik.errors.content}
            dataType={LINK}
            data={data}
            setData={setData}
            type="multilinks"
            gridIndex={activeLink}
            label={"Iframe Link"}
          >
            {!isIframeReady && <LazzyLoading />}
            <iframe
              onLoad={() => setIsIframeReady(true)}
              style={{ ...stickbotIframeStyle, opacity: isIframeReady ? 1 : 0 }}
              className="i-frame"
              src={
                activeLink === 1
                  ? isValidUrl(data?.content?.link_1)
                    ? data?.content?.link_1 + `?userId=${getName(user)}`
                    : ""
                  : activeLink === 2
                    ? isValidUrl(data?.content?.link_2)
                      ? data?.content?.link_2 + `?userId=${getName(user)}`
                      : ""
                    : activeLink === 3
                      ? isValidUrl(data?.content?.link_3)
                        ? data?.content?.link_3 + `?userId=${getName(user)}`
                        : ""
                      : activeLink === 4
                        ? isValidUrl(data?.content?.link_4)
                          ? data?.content?.link_4 + `?userId=${getName(user)}`
                          : ""
                        : isValidUrl(data?.content?.link_5)
                          ? data?.content?.link_5 + `?userId=${getName(user)}`
                          : ""
              }
              frameBorder="0"
            ></iframe>
          </Adminwrapper>
          <Adminwrapper
            errorText={contentFormik.errors.wireframe_link1}
            dataType={QUILL}
            data={data}
            setData={setData}
            type="link2"
          >
            {renderPlaceholder(data?.wireframe_link1, "Add Skill Assignment")}
            <QuillEditor
              sx={quilStyle}
              value={data.wireframe_link1}
              readOnly={true}
            />
          </Adminwrapper>
          <Adminwrapper
            errorText={contentFormik.errors.wireframe_link}
            dataType={LINK}
            data={data}
            setData={setData}
            type="link1"
            label={"Iframe Link"}
          >
            <iframe
              style={{ ...stickbotIframeStyle, height: 700 }}
              className="i-frame"
              src={
                isValidUrl(data?.wireframe_link)
                  ? data?.wireframe_link +
                  "?token=" +
                  localStorage.getItem("token") +
                  "&isIframe=true"
                  : "https://www.google.com"
              }
              frameBorder="0"
            ></iframe>
          </Adminwrapper>
          <Adminwrapper
            errorText={contentFormik.errors.description}
            dataType={QUILL}
            data={data}
            setData={setData}
            type="description"
          >
            {renderPlaceholder(data?.description, "Enter Description")}
            <QuillEditor
              sx={quilStyle}
              value={data?.description}
              readOnly={true}
            />
          </Adminwrapper>
        </Grid>
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={6} sm={6} md={6} lg={6}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Adminwrapper
                errorText={contentFormik.errors.name}
                dataType={QUILL}
                data={data}
                setData={setData}
                type="name"
              >
                {renderPlaceholder(data?.name, "Enter Name")}
                <QuillEditor
                  sx={quilStyle}
                  value={data?.name}
                  readOnly={true}
                />
              </Adminwrapper>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Adminwrapper
                errorText={contentFormik.errors.description}
                dataType={QUILL}
                data={data}
                setData={setData}
                type="description"
              >
                {renderPlaceholder(data?.description, "Enter Description")}
                <QuillEditor
                  sx={quilStyle}
                  value={data?.description}
                  readOnly={true}
                />
              </Adminwrapper>
            </Grid>
            {data?.type === "Wireframe-Stickbot" ? (
              <>
                <Adminwrapper
                  errorText={contentFormik.errors.wireframe_link}
                  dataType={LINK}
                  data={data}
                  setData={setData}
                  type="link1"
                  label={"'Iframe Link"}
                >
                  {!isIframeReady && <LazzyLoading />}
                  <iframe
                    onLoad={() => setIsIframeReady(true)}
                    style={{
                      ...stickbotIframeStyle,
                      opacity: isIframeReady ? 1 : 0,
                      height: 700,
                    }}
                    className="i-frame"
                    src={
                      isValidUrl(data?.wireframe_link)
                        ? data?.wireframe_link +
                        "?token=" +
                        localStorage.getItem("token") +
                        "&isIframe=true"
                        : "www.google.com"
                    }
                    frameBorder="0"
                  ></iframe>
                </Adminwrapper>

                <Adminwrapper
                  errorText={contentFormik.errors.wireframe_link1}
                  dataType={QUILL}
                  data={data}
                  setData={setData}
                  type="link2"
                >
                  {renderPlaceholder(
                    data?.wireframe_link1,
                    "Add Skill Assignment"
                  )}
                  <QuillEditor
                    sx={quilStyle}
                    value={data?.wireframe_link1}
                    readOnly={true}
                  />
                </Adminwrapper>
              </>
            ) : (
              <Adminwrapper
                errorText={contentFormik.errors.wireframe_link}
                dataType={LINK}
                data={data}
                setData={setData}
                type="link1"
                label={"Youtube Link"}
              >
                <ReactPlayer
                  className="i-frame-video"
                  url={data?.wireframe_link}
                  playing={true}
                  loop={true}
                  controls={true}
                />
              </Adminwrapper>
            )}
          </Grid>
          <Grid item xs={5} sm={5} md={5} lg={5} ml={10}>
            <>
              <div style={stickbotButtons}>
                <Button variant="contained" onClick={() => setActiveLink(1)}>
                  {data?.content?.link_1_name}
                </Button>
                {!!data?.content?.link_2_name && (
                  <Button variant="contained" onClick={() => setActiveLink(2)}>
                    {data?.content?.link_2_name}
                  </Button>
                )}
                {!!data?.content?.link_3_name && (
                  <Button variant="contained" onClick={() => setActiveLink(3)}>
                    {data?.content?.link_3_name}
                  </Button>
                )}
                {!!data?.content?.link_4_name && (
                  <Button variant="contained" onClick={() => setActiveLink(4)}>
                    {data?.content?.link_4_name}
                  </Button>
                )}
                {!!data?.content?.link_5_name && (
                  <Button variant="contained" onClick={() => setActiveLink(5)}>
                    {data?.content?.link_5_name}
                  </Button>
                )}
              </div>
              <Adminwrapper
                errorText={contentFormik.errors.content}
                dataType={LINK}
                data={data}
                setData={setData}
                type="multilinks"
                gridIndex={activeLink}
                label={"Stickbot-Wireframe Link"}
              >
                {!isIframeReady && <LazzyLoading />}
                <iframe
                  onLoad={() => setIsIframeReady(true)}
                  style={{
                    ...stickbotIframeStyle,
                    opacity: isIframeReady ? 1 : 0,
                    height: "70vh",
                  }}
                  className="i-frame"
                  src={
                    activeLink === 1
                      ? isValidUrl(data?.content?.link_1)
                        ? data?.content?.link_1 + `?userId=${getName(user)}`
                        : ""
                      : activeLink === 2
                        ? isValidUrl(data?.content?.link_2)
                          ? data?.content?.link_2 + `?userId=${getName(user)}`
                          : ""
                        : activeLink === 3
                          ? isValidUrl(data?.content?.link_3)
                            ? data?.content?.link_3 + `?userId=${getName(user)}`
                            : ""
                          : activeLink === 4
                            ? isValidUrl(data?.content?.link_4)
                              ? data?.content?.link_4 + `?userId=${getName(user)}`
                              : ""
                            : isValidUrl(data?.content?.link_5)
                              ? data?.content?.link_5 + `?userId=${getName(user)}`
                              : ""
                  }
                  frameBorder="0"
                ></iframe>
              </Adminwrapper>
            </>
          </Grid>
        </Grid>
      )}
    </div>
  );
};

const RenderMatterportStickbot = ({ content, user }) => {
  const { QUILL, MEDIA, LINK } = DATA_TYPE;
  const myObj = JSON.parse(content?.allContent?.content);
  const { wireframe_link, wireframe_link1 } = myObj;
  const { name, description, type } = content?.allContent;
  const [isIframeReady, setIsIframeReady] = useState(false);
  const [data, setStateData] = useState({
    name,
    description,
    type,
    wireframe_link,
    wireframe_link1,
  });

  const setData = (obj) => {
    setStateData(obj);
    contentFormik.setValues(obj);
  };
  const contentFormik = useFormik({
    initialValues: {
      name: data.name,
      type: data.type,
      description: data.description,
      wireframe_link: data.wireframe_link,
      wireframe_link1: data.wireframe_link1,
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .required("Name is Required")
        .test("matches", "Name is required", checkQuilValidation),
      description: Yup.string()
        .required("Description is Required")
        .test("matches", "Description is required", checkQuilValidation),
      wireframe_link: Yup.string().required("Link is Required"),
      wireframe_link1: Yup.string().required("Link is Required"),
    }),
    onSubmit: () => {
      handleAdminUpdate();
    },
  });
  const handleAdminUpdate = () => {
    window.parent.postMessage(
      isAdminAdd() ? { ...data, points: content?.points } : data,
      ADMIN_LINK
    );
  };
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Adminwrapper
          errorText={contentFormik.errors.name}
          dataType={QUILL}
          data={data}
          setData={setData}
          type="name"
        >
          {renderPlaceholder(data?.name, "Enter Name")}
          <QuillEditor sx={quilStyle} value={data?.name} readOnly={true} />
        </Adminwrapper>
      </Grid>
      {!!data?.description?.replace(/<[^>]*>/g, "").trim() && <Grid item xs={data?.description?.replace(/<[^>]*>/g, "").trim() ? 12 : 0}>
        <Adminwrapper
          errorText={contentFormik.errors.description}
          dataType={QUILL}
          data={data}
          setData={setData}
          type="description"
        >
          {renderPlaceholder(data?.description, "Enter Description")}
          <QuillEditor
            sx={quilStyle}
            value={data?.description}
            readOnly={true}
          />
        </Adminwrapper>
      </Grid>}
      <Grid item xs={wireframe_link?.includes("interview-simulator") ? 8 : 6}>
        <Adminwrapper
          errorText={contentFormik.errors.wireframe_link}
          dataType={LINK}
          data={data}
          setData={setData}
          type="link1"
          label={
            data?.type === "Money-Stickbot" ? "Money Link" : "Matterport Link"
          }
        >
          {!isIframeReady && <LazzyLoading />}
          <div
            style={{
              width: "100%",
              overflow: "auto",
            }}
          >
            <iframe
              onLoad={() => setIsIframeReady(true)}
              style={{
                opacity: isIframeReady ? 1 : 0,
                width: data?.wireframe_link?.includes("properties") && "1000px",
              }}
              // className="i-matport"
              allow="camera; microphone"
              width="100%"
              height="450px"
              src={
                isValidUrl(data?.wireframe_link)
                  ? data?.wireframe_link +
                  "?token=" +
                  localStorage.getItem("token") +
                  "&isIframe=true"
                  : "www.google.com"
              }
              frameBorder="0"
            ></iframe>
          </div>
        </Adminwrapper>
      </Grid>
      <Grid item xs={wireframe_link?.includes("interview-simulator") ? 4 : 6}>
        <Adminwrapper
          errorText={contentFormik.errors.wireframe_link1}
          dataType={LINK}
          data={data}
          setData={setData}
          type="link2"
          label={"Stickbot Link"}
        >
          {!isIframeReady && <LazzyLoading />}
          <iframe
            onLoad={() => setIsIframeReady(true)}
            style={{
              width: "100%",
              height: "450px",
              opacity: isIframeReady ? 1 : 0,
            }}
            className="i-frame"
            src={
              isValidUrl(data?.wireframe_link1)
                ? data?.wireframe_link1 + `?userId=${getName(user)}`
                : "www.google.com"
            }
            frameBorder="0"
          ></iframe>
        </Adminwrapper>
      </Grid>
      {isAdminEdit() && (
        <button style={btnUpdateStyle} onClick={contentFormik.handleSubmit}>
          Update
        </button>
      )}
      {isAdminAdd() && (
        <button style={btnAddStyle} onClick={contentFormik.handleSubmit}>
          ADD
        </button>
      )}
    </Grid>
  );
};

const RenderIframIfram = ({ content }) => {
  const { QUILL, MEDIA, LINK } = DATA_TYPE;
  const myObj = JSON.parse(content?.allContent?.content);
  const { wireframe_link, wireframe_link1 } = myObj;
  const { name, description, type } = content?.allContent;
  const [isIframeReady, setIsIframeReady] = useState(false);
  const [data, setStateData] = useState({
    name,
    description,
    type,
    wireframe_link,
    wireframe_link1,
  });
  const setData = (obj) => {
    setStateData(obj);
    contentFormik.setValues(obj);
  };
  const contentFormik = useFormik({
    initialValues: {
      name: data.name,
      type: data.type,
      description: data.description,
      wireframe_link: data.wireframe_link,
      wireframe_link1: data.wireframe_link1,
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .required("Name is Required")
        .test("matches", "Name is required", checkQuilValidation),
      description: Yup.string()
        .required("Description is Required")
        .test("matches", "Description is required", checkQuilValidation),
      wireframe_link: Yup.string().required("Link is Required"),
      wireframe_link1: Yup.string().required("Link is Required"),
    }),
    onSubmit: () => {
      handleAdminUpdate();
    },
  });
  const handleAdminUpdate = () => {
    window.parent.postMessage(
      isAdminAdd() ? { ...data, points: content?.points } : data,
      ADMIN_LINK
    );
  };
  return (
    <Grid container style={gridStyle}>
      {isAdminEdit() && (
        <button style={btnUpdateStyle} onClick={contentFormik.handleSubmit}>
          Update
        </button>
      )}
      {isAdminAdd() && (
        <button style={btnAddStyle} onClick={contentFormik.handleSubmit}>
          ADD
        </button>
      )}
      <Adminwrapper
        errorText={contentFormik.errors.name}
        dataType={QUILL}
        data={data}
        setData={setData}
        type="name"
      >
        {renderPlaceholder(data?.name, "Enter Name")}
        <QuillEditor sx={quilStyle} value={data?.name} readOnly={true} />
      </Adminwrapper>
      {data?.type === "Iframe-Iframe" ? (
        <>
          <Adminwrapper
            errorText={contentFormik.errors.wireframe_link}
            dataType={LINK}
            data={data}
            setData={setData}
            type="link1"
            label={"Iframe-Iframe Link"}
          >
            {!isIframeReady && <LazzyLoading />}
            <iframe
              onLoad={() => setIsIframeReady(true)}
              style={{
                width: "844px",
                height: "700px",
                padding: "5px",
                marginTop: "15px",
                opacity: isIframeReady ? 1 : 0,
              }}
              className="i-frame"
              src={
                isValidUrl(data?.wireframe_link)
                  ? data?.wireframe_link +
                  "?token=" +
                  localStorage.getItem("token") +
                  "&isIframe=true"
                  : "www.google.com"
              }
              frameBorder="0"
            ></iframe>
          </Adminwrapper>
          <Adminwrapper
            errorText={contentFormik.errors.description}
            dataType={QUILL}
            data={data}
            setData={setData}
            type="description"
          >
            {renderPlaceholder(data?.description, "Enter Description")}
            <QuillEditor
              sx={quilStyle}
              value={data?.description}
              readOnly={true}
            />
          </Adminwrapper>
          <Adminwrapper
            errorText={contentFormik.errors.wireframe_link1}
            dataType={LINK}
            data={data}
            setData={setData}
            type="link2"
            label={"Iframe-Iframe Link1"}
          >
            {!isIframeReady && <LazzyLoading />}
            <iframe
              onLoad={() => setIsIframeReady(true)}
              style={{
                width: "844px",
                height: "700px",
                padding: "5px",
                marginTop: "15px",
                opacity: isIframeReady ? 1 : 0,
              }}
              className="i-frame"
              src={
                isValidUrl(data?.wireframe_link1)
                  ? data?.wireframe_link1 +
                  "?token=" +
                  localStorage.getItem("token") +
                  "&isIframe=true"
                  : "www.google.com"
              }
              frameBorder="0"
            ></iframe>
          </Adminwrapper>
        </>
      ) : (
        <>
          <Adminwrapper
            errorText={contentFormik.errors.wireframe_link}
            dataType={LINK}
            data={data}
            setData={setData}
            type="link1"
            label={"Iframe-Iframe Link"}
          >
            {!isIframeReady && <LazzyLoading />}
            <iframe
              onLoad={() => setIsIframeReady(true)}
              style={{
                width: "844px",
                height: "700px",
                padding: "5px",
                marginTop: "15px",
                opacity: isIframeReady ? 1 : 0,
              }}
              className="i-frame"
              src={
                isValidUrl(data?.wireframe_link)
                  ? data?.wireframe_link +
                  "?token=" +
                  localStorage.getItem("token") +
                  "&isIframe=true"
                  : "www.google.com"
              }
              frameBorder="0"
            ></iframe>
          </Adminwrapper>
          <Adminwrapper
            errorText={contentFormik.errors.description}
            dataType={QUILL}
            data={data}
            setData={setData}
            type="description"
          >
            {renderPlaceholder(data?.description, "Enter Description")}
            <QuillEditor
              sx={quilStyle}
              value={data?.description}
              readOnly={true}
            />
          </Adminwrapper>
          {
            <Adminwrapper
              errorText={contentFormik.errors.wireframe_link1}
              dataType={LINK}
              data={data}
              setData={setData}
              type="link2"
              label={"Tiktok Link"}
            >
              <TikTokEmbed url={data?.wireframe_link1} />
            </Adminwrapper>
          }
        </>
      )}
    </Grid>
  );
};


const RenderIframAudio = ({ content }) => {
  const myObj = JSON.parse(content?.allContent?.content);
  const { wireframe_link, wireframe_link1, text } = myObj;
  const { name, description, type } = content?.allContent;
  const [isIframeReady, setIsIframeReady] = useState(false);
  const [data, setStateData] = useState({
    name,
    description,
    type,
    wireframe_link,
    wireframe_link1,
    text,
  });
  const { QUILL, MEDIA, LINK } = DATA_TYPE;
  const setData = (obj) => {
    setStateData(obj);
    contentFormik.setValues(obj);
  };
  const contentFormik = useFormik({
    initialValues: {
      name: data.name,
      type: data.type,
      description: data.description,
      wireframe_link: data.wireframe_link,
      wireframe_link1: data.wireframe_link1,
      text: data.text,
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .required("Name is Required")
        .test("matches", "Name is required", checkQuilValidation),
      description: Yup.string()
        .required("Description is Required")
        .test("matches", "Description is required", checkQuilValidation),
      wireframe_link: Yup.string().required("Link is Required"),
      wireframe_link1: Yup.string().required("Audio is Required"),
      text: Yup.string().required("Audio Subtitle is Required"),
    }),
    onSubmit: () => {
      handleAdminUpdate();
    },
  });

  const handleAdminUpdate = () => {
    window.parent.postMessage(
      isAdminAdd() ? { ...data, points: content?.points } : data,
      ADMIN_LINK
    );
  };
  return (
    <Grid container style={gridStyle}>
      {isAdminEdit() && (
        <button style={btnUpdateStyle} onClick={contentFormik.handleSubmit}>
          Update
        </button>
      )}
      {isAdminAdd() && (
        <button style={btnAddStyle} onClick={contentFormik.handleSubmit}>
          ADD
        </button>
      )}
      <Adminwrapper
        errorText={contentFormik.errors.name}
        dataType={QUILL}
        data={data}
        setData={setData}
        type="name"
      >
        {renderPlaceholder(data?.name, "Enter Name")}
        <QuillEditor sx={quilStyle} value={data?.name} readOnly={true} />
      </Adminwrapper>
      <Adminwrapper
        errorText={contentFormik.errors.wireframe_link}
        dataType={LINK}
        data={data}
        setData={setData}
        type="link1"
      >
        {!isIframeReady && <LazzyLoading />}
        <iframe
          onLoad={() => setIsIframeReady(true)}
          style={{
            width: "844px",
            height: "700px",
            padding: "5px",
            marginTop: "15px",
            opacity: isIframeReady ? 1 : 0,
          }}
          className="i-frame"
          src={
            isValidUrl(data?.wireframe_link)
              ? data?.wireframe_link +
              "?token=" +
              localStorage.getItem("token") +
              "&isIframe=true"
              : "www.google.com"
          }
          frameBorder="0"
        ></iframe>
      </Adminwrapper>
      <Adminwrapper
        errorText={contentFormik.errors.description}
        dataType={QUILL}
        data={data}
        setData={setData}
        type="description"
      >
        {renderPlaceholder(data?.description, "Enter Description")}
        <QuillEditor sx={quilStyle} value={data?.description} readOnly={true} />
      </Adminwrapper>
      <Adminwrapper
        errorText={contentFormik.errors.wireframe_link1}
        dataType={MEDIA}
        data={data}
        setData={setData}
        type="link2"
        mediaType={"AUDIO"}
      >
        <CustomAudioPlayer
          autoPlay={false}
          src={
            data?.wireframe_link1.includes("https")
              ? data?.wireframe_link1
              : process.env.REACT_APP_S3_BUCKET_LINK + data?.wireframe_link1
          }
          text={data?.text || "No subtitle to show"}
        />
      </Adminwrapper>
      {(isAdminAdd() || isAdminEdit()) && (
        <Adminwrapper
          errorText={contentFormik.errors.text}
          dataType={LINK}
          data={data}
          setData={setData}
          type="text"
          label="Audio Subtitle"
        >
          {renderPlaceholder(data?.text, "Enter Audio Subtitle")}
          <QuillEditor sx={quilStyle} value={data?.text} readOnly={true} />
        </Adminwrapper>
      )}
    </Grid>
  );
};

const RenderImageVideo = ({ content }) => {
  const { QUILL, MEDIA, LINK } = DATA_TYPE;
  const myObj = JSON.parse(content?.allContent?.content);
  const { wireframe_link, wireframe_link1, text } = myObj;
  const { name, description, type } = content?.allContent;
  const [data, setStateData] = useState({
    name,
    description,
    type,
    wireframe_link,
    wireframe_link1,
    text,
  });
  const setData = (obj) => {
    setStateData(obj);
    contentFormik.setValues(obj);
  };
  const contentFormik = useFormik({
    initialValues: {
      name: data.name,
      type: data.type,
      description: data.description,
      wireframe_link: data.wireframe_link,
      wireframe_link1: data.wireframe_link1,
      text: data?.text,
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .required("Name is Required")
        .test("matches", "Name is required", checkQuilValidation),
      description: Yup.string()
        .required("Description is Required")
        .test("matches", "Description is required", checkQuilValidation),
      wireframe_link: Yup.string().required("Image is Required"),
      wireframe_link1: Yup.string().required("Video is Required"),
      text: Yup.string().required("Video Transcript is Required"),
    }),
    onSubmit: () => {
      handleAdminUpdate();
    },
  });

  const handleImageVisibility = () => {
    if (!data?.wireframe_link)
      return (
        process.env.REACT_APP_S3_BUCKET_LINK +
        "d82ced10-9bcc-11ed-8bd2-abba50f2c66f.jpeg"
      );
    if (!data?.wireframe_link?.name)
      return process.env.REACT_APP_S3_BUCKET_LINK + data?.wireframe_link;
    return URL.createObjectURL(data?.wireframe_link);
  };

  const handleVideoVisibility = () => {
    if (!data?.wireframe_link1) return "https://youtu.be/9xwazD5SyVg";
    if (!data?.wireframe_link1.name)
      return process.env.REACT_APP_S3_BUCKET_LINK + data?.wireframe_link1;
    return URL.createObjectURL(data?.wireframe_link1);
  };
  const handleAdminUpdate = () => {
    window.parent.postMessage(
      isAdminAdd() ? { ...data, points: content?.points } : data,
      ADMIN_LINK
    );
  };
  return (
    <div className-="px-2">
      {isAdminEdit() && (
        <button style={btnUpdateStyle} onClick={contentFormik.handleSubmit}>
          Update
        </button>
      )}
      {isAdminAdd() && (
        <button style={btnAddStyle} onClick={contentFormik.handleSubmit}>
          ADD
        </button>
      )}
      <Adminwrapper
        errorText={contentFormik.errors.name}
        dataType={QUILL}
        data={data}
        setData={setData}
        type="name"
      >
        {renderPlaceholder(data?.name, "Enter Name")}
        <QuillEditor
          sx={{ marginTop: "20px", paddingX: "200px" }}
          value={data?.name}
          readOnly={true}
        />
      </Adminwrapper>
      <Adminwrapper
        errorText={contentFormik.errors.description}
        dataType={QUILL}
        data={data}
        setData={setData}
        type="description"
      >
        {renderPlaceholder(data?.description, "Enter Description")}
        <QuillEditor
          sx={{ paddingX: "200px", marginTop: "20px" }}
          value={data?.description}
          readOnly={true}
        />
      </Adminwrapper>
      <Grid container>
        <Grid
          xs={12}
          md={6}
          lg={6}
          sm={12}
          style={{
            display: "flex",
            alignItems: "center",
            width: "fit-content",
            position: "relative",
          }}
        >
          <Adminwrapper
            errorText={contentFormik.errors.wireframe_link}
            dataType={MEDIA}
            data={data}
            setData={setData}
            type="link1"
            mediaType={"IMAGE"}
          >
            <img width="100%" src={handleImageVisibility()} alt="" />
          </Adminwrapper>
        </Grid>
        <Grid
          xs={12}
          md={6}
          lg={6}
          sm={12}
          style={{
            display: "flex",
            alignItems: "center",
            width: "fit-content",
            position: "relative",
          }}
        >
          <Adminwrapper
            errorText={contentFormik.errors.wireframe_link1}
            dataType={MEDIA}
            data={data}
            setData={setData}
            type="link2"
            mediaType={"VIDEO"}
          >
            <CustomVideoPlayer
              url={handleVideoVisibility()}
              text={data?.text || "No Transcript Added"}
            />
          </Adminwrapper>
        </Grid>
      </Grid>
      {(isAdminAdd() || isAdminEdit()) && (
        <Adminwrapper
          errorText={contentFormik.errors.text}
          dataType={LINK}
          data={data}
          setData={setData}
          type="text"
          label="Video Transcript"
        >
          {renderPlaceholder(data?.text, "Enter Video Transcript")}
          <QuillEditor sx={quilStyle} value={data?.text} readOnly={true} />
        </Adminwrapper>
      )}
    </div>
  );
};

const RenderImageAudio = ({ content }) => {
  const myObj = JSON.parse(content?.allContent?.content);
  const { wireframe_link, wireframe_link1, text } = myObj;
  const { name, description, type } = content?.allContent;
  const [data, setStateData] = useState({
    name,
    description,
    type,
    wireframe_link,
    wireframe_link1,
    text,
  });
  const { QUILL, MEDIA, LINK } = DATA_TYPE;
  const setData = (obj) => {
    setStateData(obj);
    contentFormik.setValues(obj);
  };
  const contentFormik = useFormik({
    initialValues: {
      name: data.name,
      type: data.type,
      text: data.text,
      description: data.description,
      wireframe_link: data.wireframe_link,
      wireframe_link1: data.wireframe_link1,
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .required("Name is Required")
        .test("matches", "Name is required", checkQuilValidation),
      description: Yup.string()
        .required("Description is Required")
        .test("matches", "Description is required", checkQuilValidation),
      wireframe_link: Yup.string().required("Image is Required"),
      wireframe_link1: Yup.string().required("Audio is Required"),
      text: Yup.string().required("Audio Subtitle is Required"),
    }),
    onSubmit: () => {
      handleAdminUpdate();
    },
  });
  const handleImageVisibility = () => {
    if (!data?.wireframe_link)
      return (
        process.env.REACT_APP_S3_BUCKET_LINK +
        "d82ced10-9bcc-11ed-8bd2-abba50f2c66f.jpeg"
      );
    if (!data?.wireframe_link?.name)
      return process.env.REACT_APP_S3_BUCKET_LINK + data?.wireframe_link;
    return URL.createObjectURL(data?.wireframe_link);
  };
  const handleAdminUpdate = () => {
    window.parent.postMessage(
      isAdminAdd() ? { ...data, points: content?.points } : data,
      ADMIN_LINK
    );
  };
  return (
    <Grid container style={gridStyle}>
      {isAdminEdit() && (
        <button style={btnUpdateStyle} onClick={contentFormik.handleSubmit}>
          Update
        </button>
      )}
      {isAdminAdd() && (
        <button style={btnAddStyle} onClick={contentFormik.handleSubmit}>
          ADD
        </button>
      )}
      <Adminwrapper
        errorText={contentFormik.errors.name}
        dataType={QUILL}
        data={data}
        setData={setData}
        type="name"
      >
        {renderPlaceholder(data?.name, "Enter Name")}
        <QuillEditor
          sx={{ marginTop: "25px", fontSize: "16px", overflow: "hidden" }}
          value={data?.name}
          readOnly={true}
        />
      </Adminwrapper>
      <Adminwrapper
        errorText={contentFormik.errors.description}
        dataType={QUILL}
        data={data}
        setData={setData}
        type="description"
      >
        {renderPlaceholder(data?.description, "Enter Description")}
        <div style={{ width: "50%", marginTop: "25px" }}>
          <QuillEditor
            sx={{ fontSize: "16px" }}
            value={data?.description}
            readOnly={true}
          />
        </div>
      </Adminwrapper>
      <Adminwrapper
        errorText={contentFormik.errors.wireframe_link}
        dataType={MEDIA}
        data={data}
        setData={setData}
        type="link1"
        mediaType={"IMAGE"}
      >
        <img
          style={{ marginTop: "25px" }}
          width="20%"
          src={handleImageVisibility()}
          frameBorder="0"
        />
      </Adminwrapper>

      <Adminwrapper
        errorText={contentFormik.errors.wireframe_link1}
        dataType={MEDIA}
        data={data}
        setData={setData}
        type="link2"
        mediaType={"AUDIO"}
      >
        <div style={{ width: "50%", marginTop: "25px" }}>
          <CustomAudioPlayer
            style={{}}
            autoPlay={false}
            src={
              data?.wireframe_link1.includes("https")
                ? data?.wireframe_link1.replace(process.env.REACT_APP_S3_BUCKET_LINK, process.env.REACT_APP_S3_BUCKET_LINK + (isSpanish() ? "spanish/" : ""))
                : process.env.REACT_APP_S3_BUCKET_LINK + (isSpanish() ? "spanish/" : "") + data?.wireframe_link1
            }
            text={data?.text || "No subtile to show"}
          />
        </div>
      </Adminwrapper>
      {(isAdminAdd() || isAdminEdit()) && (
        <Adminwrapper
          errorText={contentFormik.errors.text}
          dataType={LINK}
          data={data}
          setData={setData}
          type="text"
          label="Audio Subtitle"
        >
          {renderPlaceholder(data?.text, "Enter Audio Subtitle")}
          <QuillEditor sx={quilStyle} value={data?.text} readOnly={true} />
        </Adminwrapper>
      )}
    </Grid>
  );
};

const RenderVideoStickbot = ({ content }) => {
  const myObj = JSON.parse(content?.allContent?.content);
  const { wireframe_link, wireframe_link1, text } = myObj;
  const { name, description, type } = content?.allContent;
  const [isIframeReady, setIsIframeReady] = useState(false);
  const [data, setStateData] = useState({
    name,
    description,
    type,
    wireframe_link,
    wireframe_link1,
    text,
  });
  const { QUILL, MEDIA, LINK } = DATA_TYPE;

  const setData = (obj) => {
    setStateData(obj);
    contentFormik.setValues(obj);
  };

  const contentFormik = useFormik({
    initialValues: {
      name: data.name,
      type: data.type,
      description: data.description,
      wireframe_link: data.wireframe_link,
      wireframe_link1: data.wireframe_link1,
      text: data?.text,
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .required("Name is Required")
        .test("matches", "Name is required", checkQuilValidation),
      description: Yup.string()
        .required("Description is Required")
        .test("matches", "Description is required", checkQuilValidation),
      wireframe_link: Yup.string().required("Link is Required"),
      wireframe_link1: Yup.string().required("Video is Required"),
      text: Yup.string().required("Video Transcript is Required"),
    }),
    onSubmit: () => {
      handleAdminUpdate();
    },
  });
  const handleVideoVisibility = () => {
    if (!data?.wireframe_link1) return "https://youtu.be/9xwazD5SyVg";
    if (!data?.wireframe_link1.name)
      return process.env.REACT_APP_S3_BUCKET_LINK + data?.wireframe_link1;
    return URL.createObjectURL(data?.wireframe_link1);
  };
  const handleAdminUpdate = () => {
    window.parent.postMessage(
      isAdminAdd() ? { ...data, points: content?.points } : data,
      ADMIN_LINK
    );
  };
  return (
    <Grid container style={gridStyle}>
      {isAdminEdit() && (
        <button style={btnUpdateStyle} onClick={contentFormik.handleSubmit}>
          Update
        </button>
      )}
      {isAdminAdd() && (
        <button style={btnAddStyle} onClick={contentFormik.handleSubmit}>
          ADD
        </button>
      )}
      <Adminwrapper
        errorText={contentFormik.errors.name}
        dataType={QUILL}
        data={data}
        setData={setData}
        type="name"
      >
        {renderPlaceholder(data?.name, "Enter Name")}
        <QuillEditor sx={quilStyle} value={data?.name} readOnly={true} />
      </Adminwrapper>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <Adminwrapper
            errorText={contentFormik.errors.wireframe_link1}
            dataType={MEDIA}
            data={data}
            setData={setData}
            type="link2"
            mediaType={"VIDEO"}
          >
            <CustomVideoPlayer
              url={handleVideoVisibility()}
              text={data?.text || "No Transcript Added"}
              autoPlay={true}
            />
          </Adminwrapper>
          {(isAdminAdd() || isAdminEdit()) && (
            <Adminwrapper
              errorText={contentFormik.errors.text}
              dataType={LINK}
              data={data}
              setData={setData}
              type="text"
              label="Video Subtitle"
            >
              {renderPlaceholder(data?.text, "Enter Video Subtitle")}
              <QuillEditor sx={quilStyle} value={data?.text} readOnly={true} />
            </Adminwrapper>
          )}
        </Grid>
        <Grid item xs={6}>
          <Adminwrapper
            errorText={contentFormik.errors.wireframe_link}
            dataType={LINK}
            data={data}
            setData={setData}
            type="link1"
          >
            {!isIframeReady && <LazzyLoading />}
            <iframe
              onLoad={() => setIsIframeReady(true)}
              style={{
                width: "100%",
                height: "100%",
                // height: "700px",
                // padding: "5px",
                // marginTop: "15px",
                opacity: isIframeReady ? 1 : 0,
              }}
              src={
                isValidUrl(data?.wireframe_link)
                  ? data?.wireframe_link +
                  "?token=" +
                  localStorage.getItem("token") +
                  "&isIframe=true"
                  : "https://www.google.com"
              }
              frameBorder="0"
            ></iframe>
          </Adminwrapper>
        </Grid>
      </Grid>

      <Adminwrapper
        errorText={contentFormik.errors.description}
        dataType={QUILL}
        data={data}
        setData={setData}
        type="description"
      >
        {renderPlaceholder(data?.description, "Enter Description")}
        <QuillEditor sx={quilStyle} value={data?.description} readOnly={true} />
      </Adminwrapper>
    </Grid>
  );
};

const RenderIframVideo = ({ content }) => {
  const myObj = JSON.parse(content?.allContent?.content);
  const { wireframe_link, wireframe_link1, text } = myObj;
  const { name, description, type } = content?.allContent;
  const [isIframeReady, setIsIframeReady] = useState(false);
  const [data, setStateData] = useState({
    name,
    description,
    type,
    wireframe_link,
    wireframe_link1,
    text,
  });
  const { QUILL, MEDIA, LINK } = DATA_TYPE;

  const setData = (obj) => {
    setStateData(obj);
    contentFormik.setValues(obj);
  };

  const contentFormik = useFormik({
    initialValues: {
      name: data.name,
      type: data.type,
      description: data.description,
      wireframe_link: data.wireframe_link,
      wireframe_link1: data.wireframe_link1,
      text: data?.text,
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .required("Name is Required")
        .test("matches", "Name is required", checkQuilValidation),
      description: Yup.string()
        .required("Description is Required")
        .test("matches", "Description is required", checkQuilValidation),
      wireframe_link: Yup.string().required("Link is Required"),
      wireframe_link1: Yup.string().required("Video is Required"),
      text: Yup.string().required("Video Transcript is Required"),
    }),
    onSubmit: () => {
      handleAdminUpdate();
    },
  });
  const handleVideoVisibility = () => {
    if (!data?.wireframe_link1) return "https://youtu.be/9xwazD5SyVg";
    if (!data?.wireframe_link1.name)
      return process.env.REACT_APP_S3_BUCKET_LINK + data?.wireframe_link1;
    return URL.createObjectURL(data?.wireframe_link1);
  };
  const handleAdminUpdate = () => {
    window.parent.postMessage(
      isAdminAdd() ? { ...data, points: content?.points } : data,
      ADMIN_LINK
    );
  };
  return (
    <Grid container style={gridStyle}>
      {isAdminEdit() && (
        <button style={btnUpdateStyle} onClick={contentFormik.handleSubmit}>
          Update
        </button>
      )}
      {isAdminAdd() && (
        <button style={btnAddStyle} onClick={contentFormik.handleSubmit}>
          ADD
        </button>
      )}
      <Adminwrapper
        errorText={contentFormik.errors.name}
        dataType={QUILL}
        data={data}
        setData={setData}
        type="name"
      >
        {renderPlaceholder(data?.name, "Enter Name")}
        <QuillEditor sx={quilStyle} value={data?.name} readOnly={true} />
      </Adminwrapper>
      <Adminwrapper
        errorText={contentFormik.errors.wireframe_link}
        dataType={LINK}
        data={data}
        setData={setData}
        type="link1"
      >
        {!isIframeReady && <LazzyLoading />}
        <iframe
          onLoad={() => setIsIframeReady(true)}
          style={{
            width: "100%",
            height: "700px",
            padding: "5px",
            marginTop: "15px",
            opacity: isIframeReady ? 1 : 0,
          }}
          className="i-frame"
          width="100%"
          src={
            isValidUrl(data?.wireframe_link)
              ? data?.wireframe_link +
              "?token=" +
              localStorage.getItem("token") +
              "&isIframe=true"
              : "www.google.com"
          }
          frameBorder="0"
        ></iframe>
      </Adminwrapper>
      <Adminwrapper
        errorText={contentFormik.errors.description}
        dataType={QUILL}
        data={data}
        setData={setData}
        type="description"
      >
        {renderPlaceholder(data?.description, "Enter Description")}
        <QuillEditor sx={quilStyle} value={data?.description} readOnly={true} />
      </Adminwrapper>

      <Adminwrapper
        errorText={contentFormik.errors.wireframe_link1}
        dataType={MEDIA}
        data={data}
        setData={setData}
        type="link2"
        mediaType={"VIDEO"}
      >
        <CustomVideoPlayer
          url={handleVideoVisibility()}
          text={data?.text || "No Transcript Added"}
          autoPlay={true}
        />
      </Adminwrapper>
      {(isAdminAdd() || isAdminEdit()) && (
        <Adminwrapper
          errorText={contentFormik.errors.text}
          dataType={LINK}
          data={data}
          setData={setData}
          type="text"
          label="Video Subtitle"
        >
          {renderPlaceholder(data?.text, "Enter Video Subtitle")}
          <QuillEditor sx={quilStyle} value={data?.text} readOnly={true} />
        </Adminwrapper>
      )}

      {/* <Adminwrapper errorText={contentFormik.errors.wireframe_link1} dataType={MEDIA} data={data} setData={setData} type="link2" mediaType={"VIDEO"}>
                <ReactPlayer
                    url={handleVideoVisibility()}
                    className="i-frame1"
                    playing={true}
                    loop={true}
                    controls={true}
                    style={{ width: '80%' }}
                    width="100%"
                />
            </Adminwrapper> */}
    </Grid>
  );
};

const ContentVideo = ({ content }) => {
  const [videoPlay, setVideoPlay] = useState(true);
  const { name, description, type } = content?.allContent;
  const { QUILL, MEDIA, LINK } = DATA_TYPE;
  const [data, setStateData] = useState({
    type,
    name,
    description,
    content: isJson(content?.allContent.content)
      ? JSON.parse(content?.allContent.content)?.link
      : content?.allContent.content,
    text: isJson(content?.allContent.content)
      ? JSON.parse(content?.allContent.content)?.text
      : "",
  });

  const videoVisibility = () => {
    if (!data?.content) return "https://youtu.be/9xwazD5SyVg";
    if (!data?.content.name) return data?.content;
    return URL.createObjectURL(data?.content);
  };
  const setData = (obj) => {
    setStateData(obj);
    contentFormik.setValues(obj);
  };
  const contentFormik = useFormik({
    initialValues: {
      name: data.name,
      type: data.type,
      description: data.description,
      content: data?.content,
      text: data?.text,
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .required("Name is Required")
        .test("matches", "Name is required", checkQuilValidation),
      description: Yup.string()
        .required("Description is Required")
        .test("matches", "Description is required", checkQuilValidation),
      content: Yup.mixed().required("Audio is Required"),
      text: Yup.string().required("Audio Subtitle is Required!"),
    }),
    onSubmit: () => {
      handleAdminUpdate();
    },
  });

  const handleAdminUpdate = () => {
    window.parent.postMessage(
      isAdminAdd() ? { ...data, points: content?.points } : data,
      ADMIN_LINK
    );
  };
  return (
    <div
      className="col-12 d-flex flex-column align-items-center"
      style={{ height: "100%", width: "100%" }}
    >
      {isAdminEdit() && (
        <button style={btnUpdateStyle} onClick={contentFormik.handleSubmit}>
          Update
        </button>
      )}
      {isAdminAdd() && (
        <button style={btnAddStyle} onClick={contentFormik.handleSubmit}>
          ADD
        </button>
      )}
      <div
        className="container-ss"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Adminwrapper
          errorText={contentFormik.errors.name}
          dataType={QUILL}
          data={data}
          setData={setData}
          type="name"
        >
          {renderPlaceholder(data?.name, "Enter Name")}
          <QuillEditor sx={quilStyle} value={data?.name} readOnly={true} />
        </Adminwrapper>
        <Adminwrapper
          errorText={contentFormik.errors.content}
          dataType={MEDIA}
          data={data}
          setData={setData}
          type="content"
          mediaType={"VIDEO"}
        >
          <div style={{ width: "70%" }}>
            <CustomVideoPlayer
              url={videoVisibility()}
              text={data?.text || "No Transcript Added"}
              autoPlay={true}
            />
          </div>
        </Adminwrapper>
        {(isAdminAdd() || isAdminEdit()) && (
          <Adminwrapper
            errorText={contentFormik.errors.text}
            dataType={LINK}
            data={data}
            setData={setData}
            type="text"
            label="Video Subtitle"
          >
            {renderPlaceholder(data?.text, "Enter Video Subtitle")}
            <QuillEditor sx={quilStyle} value={data?.text} readOnly={true} />
          </Adminwrapper>
        )}
        <Adminwrapper
          errorText={contentFormik.errors.description}
          dataType={QUILL}
          data={data}
          setData={setData}
          type="description"
        >
          {renderPlaceholder(data?.name, "Enter Description")}
          <QuillEditor
            sx={quilStyle}
            value={data?.description}
            readOnly={true}
          />
        </Adminwrapper>
      </div>
    </div>
  );
};

const RenderAudio = ({ content }) => {
  const { QUILL, MEDIA, LINK } = DATA_TYPE;
  const { type, name, description } = content?.allContent;
  const isJson = (str) => {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  };
  const [data, setStateData] = useState({
    type,
    name,
    description,
    content: isJson(content?.allContent.content)
      ? JSON.parse(content?.allContent.content)?.link
      : content?.allContent.content,
    text: isJson(content?.allContent.content)
      ? JSON.parse(content?.allContent.content)?.text
      : "",
  });
  const setData = (obj) => {
    setStateData(obj);
    contentFormik.setValues(obj);
  };
  const contentFormik = useFormik({
    initialValues: {
      name: data.name,
      type: data.type,
      description: data.description,
      content: data?.content,
      text: data?.text,
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .required("Name is Required")
        .test("matches", "Name is required", checkQuilValidation),
      description: Yup.string()
        .required("Description is Required")
        .test("matches", "Description is required", checkQuilValidation),
      content: Yup.mixed().required("Audio is Required"),
      text: Yup.string().required("Audio Subtitle is Required!"),
    }),
    onSubmit: () => {
      handleAdminUpdate();
    },
  });
  const handleAdminUpdate = () => {
    window.parent.postMessage(
      isAdminAdd() ? { ...data, points: content?.points } : data,
      ADMIN_LINK
    );
  };

  return (
    <div
      className="col-12 d-flex flex-column align-items-center"
      style={{ height: "100%", width: "100%" }}
    >
      {isAdminEdit() && (
        <button style={btnUpdateStyle} onClick={contentFormik.handleSubmit}>
          Update
        </button>
      )}
      {isAdminAdd() && (
        <button style={btnAddStyle} onClick={contentFormik.handleSubmit}>
          ADD
        </button>
      )}
      <div
        className="container-ss"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Adminwrapper
          errorText={contentFormik.errors.name}
          dataType={QUILL}
          data={data}
          setData={setData}
          type="name"
        >
          {renderPlaceholder(data?.name, "Enter Name")}
          <QuillEditor sx={quilStyle} value={data?.name} readOnly={true} />
        </Adminwrapper>
        <Adminwrapper
          errorText={contentFormik.errors.content}
          dataType={MEDIA}
          data={data}
          setData={setData}
          type="content"
          mediaType={"AUDIO"}
        >
          <CustomAudioPlayer
            style={{ marginTop: "40px" }}
            autoPlay={false}
            src={data?.content}
            text={data?.text || "No subtitle to show"}
          />
        </Adminwrapper>
        {(isAdminAdd() || isAdminEdit()) && (
          <Adminwrapper
            errorText={contentFormik.errors.text}
            dataType={LINK}
            data={data}
            setData={setData}
            type="text"
            label="Audio Subtitle"
          >
            {renderPlaceholder(data?.text, "Enter Audio Subtitle")}
            <QuillEditor sx={quilStyle} value={data?.text} readOnly={true} />
          </Adminwrapper>
        )}
        <Adminwrapper
          errorText={contentFormik.errors.description}
          dataType={QUILL}
          data={data}
          setData={setData}
          type="description"
        >
          {renderPlaceholder(data?.description, "Enter Description")}
          <QuillEditor
            sx={{ marginTop: "40px", fontSize: "16px" }}
            value={data?.description}
            readOnly={true}
          />
        </Adminwrapper>
      </div>
    </div>
  );
};

const RenderDoodleVideo = ({ content }) => {
  const { QUILL, MEDIA, LINK } = DATA_TYPE;
  const { type, name, description } = content?.allContent;
  const [data, setStateData] = useState({
    type,
    name,
    description,
    content: isJson(content?.allContent.content)
      ? JSON.parse(content?.allContent.content)?.link
      : content?.allContent.content,
    text: isJson(content?.allContent.content)
      ? JSON.parse(content?.allContent.content)?.text
      : "",
  });
  const videoVisibility = () => {
    if (!data?.content) return "https://youtu.be/9xwazD5SyVg";
    if (!data?.content.name) return data?.content.replace(process.env.REACT_APP_S3_BUCKET_LINK, (process.env.REACT_APP_S3_BUCKET_LINK + (isSpanish() ? "spanish/" : "")));
    return URL.createObjectURL(data?.content);
  };
  const setData = (obj) => {
    setStateData(obj);
    contentFormik.setValues(obj);
  };
  const contentFormik = useFormik({
    initialValues: {
      name: data.name,
      type: data.type,
      description: data.description,
      content: data?.content,
      text: data?.text,
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .required("Name is Required")
        .test("matches", "Name is required", checkQuilValidation),
      description: Yup.string()
        .required("Description is Required")
        .test("matches", "Description is required", checkQuilValidation),
      content: Yup.mixed().required("Video is Required"),
      text: Yup.mixed().required("Text is Required"),
    }),
    onSubmit: () => {
      handleAdminUpdate();
    },
  });
  const handleAdminUpdate = () => {
    window.parent.postMessage(
      isAdminAdd() ? { ...data, points: content?.points } : data,
      ADMIN_LINK
    );
  };
  return (
    <div
      className="col-12 d-flex flex-column align-items-center"
      style={{ height: "100%", width: "100%" }}
    >
      {isAdminEdit() && (
        <button style={btnUpdateStyle} onClick={contentFormik.handleSubmit}>
          Update
        </button>
      )}
      {isAdminAdd() && (
        <button style={btnAddStyle} onClick={contentFormik.handleSubmit}>
          ADD
        </button>
      )}
      <div
        className="container-s1"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Adminwrapper
          errorText={contentFormik.errors.name}
          dataType={QUILL}
          data={data}
          setData={setData}
          type="name"
        >
          {renderPlaceholder(data?.name, "Enter Name")}
          <QuillEditor sx={quilStyle} value={data?.name} readOnly={true} />
        </Adminwrapper>

        <Adminwrapper
          errorText={contentFormik.errors.content}
          dataType={MEDIA}
          data={data}
          setData={setData}
          type="content"
          mediaType={"VIDEO"}
        >
          <div style={{ width: "90%" }}>
            <CustomVideoPlayer
              url={videoVisibility()}
              text={data?.text || "No Transcript Added"}
              autoPlay={true}
              height={"87vh"}
            />
          </div>
        </Adminwrapper>
        {(isAdminAdd() || isAdminEdit()) && (
          <Adminwrapper
            errorText={contentFormik.errors.text}
            dataType={LINK}
            data={data}
            setData={setData}
            type="text"
            label="Video Subtitle"
          >
            {renderPlaceholder(data?.text, "Enter Video Subtitle")}
            <QuillEditor sx={quilStyle} value={data?.text} readOnly={true} />
          </Adminwrapper>
        )}
        <Adminwrapper
          errorText={contentFormik.errors.description}
          dataType={QUILL}
          data={data}
          setData={setData}
          type="description"
        >
          {renderPlaceholder(data?.description, "Enter Description")}
          <QuillEditor
            sx={quilStyle}
            value={data?.description}
            readOnly={true}
          />
        </Adminwrapper>
      </div>
    </div>
  );
};

const RenderYoutube = ({ content }) => {
  const { QUILL, MEDIA, LINK } = DATA_TYPE;
  const { type, name, description } = content?.allContent;
  const [data, setStateData] = useState({
    type,
    name,
    description,
    content: content?.allContent.content,
  });
  const setData = (obj) => {
    setStateData(obj);
    contentFormik.setValues(obj);
  };
  const contentFormik = useFormik({
    initialValues: {
      name: data.name,
      type: data.type,
      description: data.description,
      content: data?.content,
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .required("Name is Required")
        .test("matches", "Name is required", checkQuilValidation),
      description: Yup.string()
        .required("Description is Required")
        .test("matches", "Description is required", checkQuilValidation),
      content: Yup.mixed().required("Video is Required"),
    }),
    onSubmit: () => {
      handleAdminUpdate();
    },
  });
  const handleAdminUpdate = () => {
    window.parent.postMessage(
      isAdminAdd() ? { ...data, points: content?.points } : data,
      ADMIN_LINK
    );
  };
  return (
    <div className="col-12" style={containerStyle}>
      {isAdminEdit() && (
        <button style={btnUpdateStyle} onClick={contentFormik.handleSubmit}>
          Update
        </button>
      )}
      {isAdminAdd() && (
        <button style={btnAddStyle} onClick={contentFormik.handleSubmit}>
          ADD
        </button>
      )}
      <div className="container-ss">
        <Adminwrapper
          errorText={contentFormik.errors.name}
          dataType={QUILL}
          data={data}
          setData={setData}
          type="name"
        >
          {renderPlaceholder(data?.name, "Enter Name")}
          <QuillEditor sx={quilStyle} value={data?.name} readOnly={true} />
        </Adminwrapper>
        <br />
        <Adminwrapper
          errorText={contentFormik.errors.content}
          dataType={LINK}
          data={data}
          setData={setData}
          type="content"
          label={"Youtube Link"}
        >
          <ReactPlayer
            // className="i-frame-video"
            // height="800"
            className="i-frame-video"
            url={data?.content}
            playing={true}
            loop={true}
            controls={true}
          />
        </Adminwrapper>

        <Adminwrapper
          errorText={contentFormik.errors.description}
          dataType={QUILL}
          data={data}
          setData={setData}
          type="description"
        >
          {renderPlaceholder(data?.description, "Enter Description")}
          <QuillEditor
            sx={quilStyle}
            value={data?.description}
            readOnly={true}
          />
        </Adminwrapper>
      </div>
    </div>
  );
};

const RenderTiktok = ({ content }) => {
  const { QUILL, LINK } = DATA_TYPE;
  const { type, name, description } = content?.allContent;
  const [data, setStateData] = useState({
    type,
    name,
    description,
    content: content?.allContent.content,
  });
  const setData = (obj) => {
    setStateData(obj);
    contentFormik.setValues(obj);
  };
  const contentFormik = useFormik({
    initialValues: {
      name: data.name,
      type: data.type,
      description: data.description,
      content: data?.content,
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .required("Name is Required")
        .test("matches", "Name is required", checkQuilValidation),
      description: Yup.string()
        .required("Description is Required")
        .test("matches", "Description is required", checkQuilValidation),
      content: Yup.mixed().required("Video is Required"),
    }),
    onSubmit: () => {
      handleAdminUpdate();
    },
  });
  const handleAdminUpdate = () => {
    window.parent.postMessage(
      isAdminAdd() ? { ...data, points: content?.points } : data,
      ADMIN_LINK
    );
  };
  return (
    <div className="col-12" style={containerStyle}>
      {isAdminEdit() && (
        <button style={btnUpdateStyle} onClick={contentFormik.handleSubmit}>
          Update
        </button>
      )}
      {isAdminAdd() && (
        <button style={btnAddStyle} onClick={contentFormik.handleSubmit}>
          ADD
        </button>
      )}
      <div className="container-ss">
        <Adminwrapper
          errorText={contentFormik.errors.name}
          dataType={QUILL}
          data={data}
          setData={setData}
          type="name"
        >
          {renderPlaceholder(data?.name, "Enter Name")}
          <QuillEditor sx={quilStyle} value={data?.name} readOnly={true} />
        </Adminwrapper>
        <div
          className="row d-flex justify-content-between px-3 mt-3"
          style={{ width: "100%" }}
        >
          <div className="col-sm-6">
            <Adminwrapper
              errorText={contentFormik.errors.description}
              dataType={QUILL}
              data={data}
              setData={setData}
              type="description"
            >
              {renderPlaceholder(data?.description, "Enter Description")}
              <QuillEditor
                sx={quilStyle}
                value={data?.description}
                readOnly={true}
              />
            </Adminwrapper>
          </div>
          <div className="col-sm-6">
            <Adminwrapper
              errorText={contentFormik.errors.content}
              dataType={LINK}
              data={data}
              setData={setData}
              type="content"
              label={"Tiktok Link"}
            >
              <TikTokEmbed url={data?.content} />
            </Adminwrapper>
          </div>
        </div>
      </div>
    </div>
  );
};

const RenderTiktokHybrid = ({ content }) => {
  // const data?.content = JSON.parse(content?.allContent?.content)
  const { QUILL, MEDIA } = DATA_TYPE;
  const { type, name, description } = content?.allContent;
  const [data, setStateData] = useState({
    type,
    name,
    description,
    content: content?.allContent.content,
  });

  const videoVisibility = () => {
    if (!data?.content) return "https://youtu.be/9xwazD5SyVg";
    if (!data?.content.name) return data?.content?.replace(process.env.REACT_APP_S3_BUCKET_LINK, (process.env.REACT_APP_S3_BUCKET_LINK + (isSpanish() ? "spanish/" : "")));
    return URL.createObjectURL(data?.content);
  };
  const setData = (obj) => {
    setStateData(obj);
    contentFormik.setValues(obj);
  };
  const contentFormik = useFormik({
    initialValues: {
      name: data.name,
      type: data.type,
      description: data.description,
      content: data?.content,
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .required("Name is Required")
        .test("matches", "Name is required", checkQuilValidation),
      description: Yup.string()
        .required("Description is Required")
        .test("matches", "Description is required", checkQuilValidation),
      content: Yup.mixed().required("Video is Required"),
    }),
    onSubmit: () => {
      handleAdminUpdate();
    },
  });
  const handleAdminUpdate = () => {
    window.parent.postMessage(
      isAdminAdd() ? { ...data, points: content?.points } : data,
      ADMIN_LINK
    );
  };

  return (
    <div className="col-12" style={containerStyle}>
      {isAdminEdit() && (
        <button style={btnUpdateStyle} onClick={contentFormik.handleSubmit}>
          Update
        </button>
      )}
      {isAdminAdd() && (
        <button style={btnAddStyle} onClick={contentFormik.handleSubmit}>
          ADD
        </button>
      )}
      <div className="container-ss">
        <Adminwrapper
          errorText={contentFormik.errors.name}
          dataType={QUILL}
          data={data}
          setData={setData}
          type="name"
        >
          {renderPlaceholder(data?.name, "Enter Name")}
          <QuillEditor sx={quilStyle} value={data?.name} readOnly={true} />
        </Adminwrapper>
        <div
          className="row d-flex justify-content-between px-3 mt-3"
          style={{ width: "100%" }}
        >
          <div className="col-sm-6">
            <Adminwrapper
              errorText={contentFormik.errors.description}
              dataType={QUILL}
              data={data}
              setData={setData}
              type="description"
            >
              {renderPlaceholder(data?.description, "Enter Description")}
              <QuillEditor
                sx={quilStyle}
                value={data?.description}
                readOnly={true}
              />
            </Adminwrapper>
          </div>
          <div className="col-sm-6">
            <Adminwrapper
              errorText={contentFormik.errors.content}
              dataType={MEDIA}
              data={data}
              setData={setData}
              type="content"
              mediaType={"VIDEO"}
            >
              <ReactPlayer
                url={videoVisibility()}
                playing={true}
                loop={true}
                controls={true}
                style={{ width: "300px", height: "300px" }}
                width="100%"
              />
            </Adminwrapper>
          </div>
        </div>
      </div>
    </div>
  );
};

const VideoSixGrid = ({ content }) => {
  const [muted, setMuted] = useState(false);
  const [replay, setReplay] = useState(false);
  const [ccModal, setCCModal] = useState(false);
  const [text, setText] = useState("");
  const [showButton, setShowButton] = useState(false);
  let contentArr = [];
  let gridText = [];
  if (isJson(content.allContent?.content)) {
    let tempContent = JSON.parse(content?.allContent?.content);
    contentArr = tempContent.videoNames?.split("$");
    gridText = tempContent.gridTranscripts;
  } else {
    contentArr = content?.allContent?.content?.split("$") || [];
  }

  const { name, description, type } = content?.allContent;
  const [data, setStateData] = useState({
    name,
    description,
    type,
    grid: contentArr,
  });
  const [nextPlay, setNextPlay] = useState([]);
  const { QUILL, MEDIA } = DATA_TYPE;

  const setData = (obj) => {
    setStateData(obj);
    contentFormik.setValues(obj);
  };
  const contentFormik = useFormik({
    initialValues: {
      name: data.name,
      type: data.type,
      description: data.description,
      content: data?.grid,
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .required("Name is Required")
        .test("matches", "Name is required", checkQuilValidation),
      description: Yup.string()
        .required("Description is Required")
        .test("matches", "Description is required", checkQuilValidation),
      content: Yup.array().min(2, "Upload 2 videos at minimum!"),
    }),
    onSubmit: () => {
      handleAdminUpdate();
    },
  });

  const handleAdminUpdate = () => {
    window.parent.postMessage(
      isAdminAdd() ? { ...data, points: content?.points } : data,
      ADMIN_LINK
    );
  };
  const videoVisibility = (url) => {
    if (!url?.name) return process.env.REACT_APP_S3_BUCKET_LINK + (isSpanish() ? "spanish/" : "") + url;
    return URL.createObjectURL(url);
  };
  useEffect(() => {
    data?.grid?.map((item, i) => {
      if (i === 0) {
        setNextPlay((old) => [...old, true]);
      } else {
        setNextPlay((old) => [...old, false]);
      }
    });
  }, [data]);
  function playVid(index) {
    var vid = document.getElementById(index + 1);
    if (vid) {
      vid.play();
    }

    if (data?.grid?.length > 4) {
      if (data?.grid?.length === index + 2) {
        setReplay(true);
      } else {
        setReplay(false);
      }
    } else {
      if (data?.grid?.length === index + 3) {
        setReplay(true);
      } else {
        setReplay(false);
      }
    }
  }
  return (
    <div className="col-12" style={containerStyle}>
      {isAdminEdit() && (
        <button style={btnUpdateStyle} onClick={contentFormik.handleSubmit}>
          Update
        </button>
      )}
      {isAdminAdd() && (
        <button style={btnAddStyle} onClick={contentFormik.handleSubmit}>
          ADD
        </button>
      )}
      <div className="container-ss container">
        <Adminwrapper
          errorText={contentFormik.errors.name}
          dataType={QUILL}
          data={data}
          setData={setData}
          type="name"
        >
          {renderPlaceholder(data?.name, "Enter Name")}
          <QuillEditor sx={quilStyle} value={data?.name} readOnly={true} />
        </Adminwrapper>
        <Adminwrapper
          errorText={contentFormik.errors.description}
          dataType={QUILL}
          data={data}
          setData={setData}
          type="description"
        >
          {renderPlaceholder(data?.description, "Enter Description")}
          <QuillEditor
            sx={quilStyle}
            value={data?.description}
            readOnly={true}
          />
        </Adminwrapper>
        <Grid style={gridWraper}>
          <Grid container sx={{ px: 25 }} style={gridContainer}>
            {!!data?.grid?.length && (
              <div style={actionBtns}>
                <IconButton
                  className="no-style"
                  style={{ color: "#fff" }}
                  onClick={() => setMuted(!muted)}
                  aria-label="Volume"
                >
                  {muted ? <VolumeOffIcon /> : <VolumeUpIcon />}
                </IconButton>
                <IconButton
                  className="no-style"
                  style={{ color: replay ? "#fff" : "#ccc" }}
                  disabled={!replay}
                  aria-label="Replay"
                  onClick={() => playVid(-1)}
                >
                  <ReplayIcon />
                </IconButton>
              </div>
            )}
            {data?.grid?.map((arr, index) => {
              return (
                arr !== "" && (
                  <Grid
                    xs={6}
                    style={{
                      display: "flex",
                      justifyContent:
                        (index + 1) % 2 === 0 ? "flex-end" : "flex-start",
                      alignItems: "center",
                      width: "fit-content",
                      position: "relative",
                      marginTop: index > 1 && "30px",
                    }}
                    key={arr}
                  >
                    <Adminwrapper
                      dataType={MEDIA}
                      data={data}
                      setData={setData}
                      type="grid"
                      gridIndex={index}
                      mediaType={"VIDEO"}
                    >
                      <div
                        style={{
                          position: "relative",
                          width: "90%",
                          height: "fit-content",
                        }}
                        onMouseEnter={() => setShowButton(true)}
                        onMouseLeave={() => setShowButton(false)}
                      >
                        <video
                          style={{ width: "100%", height: "100%" }}
                          id={index}
                          controls={false}
                          autoPlay={nextPlay[index]}
                          onEnded={(e) => {
                            // setPlayVideo((index + 1).toString())
                            playVid(index);
                          }}
                          muted={muted}
                        >
                          <source type="video/mp4" src={videoVisibility(arr)} />
                        </video>

                        {showButton && (
                          <IconButton
                            style={ccVideoButtonSix}
                            onClick={() => {
                              setCCModal(true);
                              setText(gridText[index]);
                            }}
                          >
                            <ClosedCaptionIcon fontSize="small" />
                          </IconButton>
                        )}
                      </div>
                    </Adminwrapper>
                  </Grid>
                )
              );
            })}

            {isAdminAdd() && (
              <>
                <div style={addDropdown}>
                  <Button startIcon={<UploadIcon />}>
                    <input
                      type="file"
                      name="file"
                      style={inputStyle}
                      onChange={(e) => {
                        setData({
                          ...data,
                          grid: [...data?.grid, e.target.files[0]],
                        });
                      }}
                      accept="video/*"
                    />
                    Choose Video {parseInt(data?.grid?.length || 0) + 1}
                  </Button>
                </div>
              </>
            )}
            {(isAdminAdd() || isAdminEdit()) &&
              !!contentFormik.errors.content && (
                <p style={errorStyle}>{contentFormik.errors.content}</p>
              )}
          </Grid>
        </Grid>
      </div>

      <Dialog
        open={ccModal}
        TransitionComponent={CCTransition}
        keepMounted
        onClose={() => setCCModal(false)}
        aria-describedby="alert-dialog-slide-description"
        maxWidth="xxs"
        style={{
          backgroundColor: "rgba(1000, 1000, 1000, 0.2)",
          overflow: "visible",
        }}
      >
        <DialogTitle sx={{ display: "flex", justifyContent: "space-between" }}>
          Transcript
          <IconButton onClick={() => setCCModal(false)}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <p style={audioText}>{text}</p>
      </Dialog>
    </div>
  );
};

const VideoGridZoom = ({ content }) => {
  const [muted, setMuted] = useState(false);
  const [replay, setReplay] = useState(false);
  const [ccModal, setCCModal] = useState(false);
  const [text, setText] = useState("");
  const [showButton, setShowButton] = useState(false);
  let tempContent = JSON.parse(content?.allContent?.content);
  let contentArr = tempContent.videoNames?.split("$");
  let gridText = tempContent.gridTranscripts;

  const { name, description, type } = content?.allContent;
  const [data, setStateData] = useState({
    name,
    description,
    type,
    grid: contentArr,
  });
  const [nextPlay, setNextPlay] = useState([]);
  const { QUILL, MEDIA } = DATA_TYPE;

  const setData = (obj) => {
    setStateData(obj);
    contentFormik.setValues(obj);
  };
  const contentFormik = useFormik({
    initialValues: {
      name: data.name,
      type: data.type,
      description: data.description,
      content: data?.grid,
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .required("Name is Required")
        .test("matches", "Name is required", checkQuilValidation),
      description: Yup.string()
        .required("Description is Required")
        .test("matches", "Description is required", checkQuilValidation),
      content: Yup.array().min(2, "Upload 2 videos at minimum!"),
    }),
    onSubmit: () => {
      handleAdminUpdate();
    },
  });

  const handleAdminUpdate = () => {
    window.parent.postMessage(
      isAdminAdd() ? { ...data, points: content?.points } : data,
      ADMIN_LINK
    );
  };
  const videoVisibility = (url) => {
    if (!url?.name) return process.env.REACT_APP_S3_BUCKET_LINK + url;
    return URL.createObjectURL(url);
  };
  useEffect(() => {
    data?.grid?.map((item, i) => {
      if (i === 0) {
        setNextPlay((old) => [...old, true]);
      } else {
        setNextPlay((old) => [...old, false]);
      }
    });
  }, [data]);
  function playVid(index) {
    var vid = document.getElementById(index + 1);
    if (vid) {
      vid.play();
    }

    if (data?.grid?.length > 4) {
      if (data?.grid?.length === index + 2) {
        setReplay(true);
      } else {
        setReplay(false);
      }
    } else {
      if (data?.grid?.length === index + 3) {
        setReplay(true);
      } else {
        setReplay(false);
      }
    }
  }
  return (
    <div className="col-12" style={containerStyle}>
      {isAdminEdit() && (
        <button style={btnUpdateStyle} onClick={contentFormik.handleSubmit}>
          Update
        </button>
      )}
      {isAdminAdd() && (
        <button style={btnAddStyle} onClick={contentFormik.handleSubmit}>
          ADD
        </button>
      )}
      <div className="container-ss">
        <div style={{ padding: "0px 150px" }}>
          <Adminwrapper
            errorText={contentFormik.errors.name}
            dataType={QUILL}
            data={data}
            setData={setData}
            type="name"
          >
            {renderPlaceholder(data?.name, "Enter Name")}
            <QuillEditor sx={quilStyle} value={data?.name} readOnly={true} />
          </Adminwrapper>
        </div>
        <Grid style={{ ...gridWraper, padding: "0px 200px" }}>
          <Grid
            container
            style={{ ...gridContainer, height: "65vh", overflow: "auto" }}
          >
            {!!data?.grid?.length && (
              <div style={actionBtns}>
                <IconButton
                  className="no-style"
                  style={{ color: "#fff" }}
                  onClick={() => setMuted(!muted)}
                  aria-label="Volume"
                >
                  {muted ? <VolumeOffIcon /> : <VolumeUpIcon />}
                </IconButton>
                <IconButton
                  className="no-style"
                  style={{ color: replay ? "#fff" : "#ccc" }}
                  disabled={!replay}
                  aria-label="Replay"
                  onClick={() => playVid(-1)}
                >
                  <ReplayIcon />
                </IconButton>
              </div>
            )}
            {data?.grid?.map((arr, index) => {
              return (
                arr !== "" && (
                  <Grid
                    xs={6}
                    style={{
                      display: "flex",
                      justifyContent:
                        (index + 1) % 2 === 0 ? "flex-end" : "flex-start",
                      alignItems: "center",
                      width: "fit-content",
                      position: "relative",
                    }}
                    key={arr}
                  >
                    <Adminwrapper
                      dataType={MEDIA}
                      data={data}
                      setData={setData}
                      type="grid"
                      gridIndex={index}
                      mediaType={"VIDEO"}
                    >
                      <div
                        style={{
                          position: "relative",
                          width: "99%",
                          height: "fit-content",
                        }}
                        onMouseEnter={() => setShowButton(true)}
                        onMouseLeave={() => setShowButton(false)}
                      >
                        <video
                          style={{ width: "100%", height: "100%" }}
                          id={index}
                          controls={false}
                          autoPlay={nextPlay[index]}
                          onEnded={(e) => {
                            // setPlayVideo((index + 1).toString())
                            playVid(index);
                          }}
                          muted={muted}
                        >
                          <source type="video/mp4" src={videoVisibility(arr)} />
                        </video>

                        {showButton && (
                          <IconButton
                            style={ccVideoButtonSix}
                            onClick={() => {
                              setCCModal(true);
                              setText(gridText[index]);
                            }}
                          >
                            <ClosedCaptionIcon fontSize="small" />
                          </IconButton>
                        )}
                      </div>
                    </Adminwrapper>
                  </Grid>
                )
              );
            })}

            {isAdminAdd() && (
              <>
                <div style={addDropdown}>
                  <Button startIcon={<UploadIcon />}>
                    <input
                      type="file"
                      name="file"
                      style={inputStyle}
                      onChange={(e) => {
                        setData({
                          ...data,
                          grid: [...data?.grid, e.target.files[0]],
                        });
                      }}
                      accept="video/*"
                    />
                    Choose Video {parseInt(data?.grid?.length || 0) + 1}
                  </Button>
                </div>
              </>
            )}
            {(isAdminAdd() || isAdminEdit()) &&
              !!contentFormik.errors.content && (
                <p style={errorStyle}>{contentFormik.errors.content}</p>
              )}
          </Grid>
        </Grid>
        <div style={{ padding: "0px 150px" }}>
          <Adminwrapper
            errorText={contentFormik.errors.description}
            dataType={QUILL}
            data={data}
            setData={setData}
            type="description"
          >
            {renderPlaceholder(data?.description, "Enter Description")}
            <QuillEditor
              sx={quilStyle}
              value={data?.description}
              readOnly={true}
            />
          </Adminwrapper>
        </div>
      </div>

      <Dialog
        open={ccModal}
        TransitionComponent={CCTransition}
        keepMounted
        onClose={() => setCCModal(false)}
        aria-describedby="alert-dialog-slide-description"
        maxWidth="xxs"
        style={{
          backgroundColor: "rgba(1000, 1000, 1000, 0.2)",
          overflow: "visible",
        }}
      >
        <DialogTitle sx={{ display: "flex", justifyContent: "space-between" }}>
          Transcript
          <IconButton onClick={() => setCCModal(false)}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <p style={audioText}>{text}</p>
      </Dialog>
    </div>
  );
};

const RenderImageGrid = ({ content }) => {
  const contentArr = content?.allContent?.content?.split("$") || [];
  const { QUILL, MEDIA } = DATA_TYPE;
  const { name, description, type } = content?.allContent;
  const [data, setStateData] = useState({
    name,
    description,
    type,
    grid: contentArr,
  });
  const imageVisibility = (url) => {
    if (!url?.name) return process.env.REACT_APP_S3_BUCKET_LINK + url;
    return URL.createObjectURL(url);
  };
  const setData = (obj) => {
    setStateData(obj);
    contentFormik.setValues(obj);
  };
  const contentFormik = useFormik({
    initialValues: {
      name: data.name,
      type: data.type,
      description: data.description,
      content: data?.grid,
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .required("Name is Required")
        .test("matches", "Name is required", checkQuilValidation),
      description: Yup.string()
        .required("Description is Required")
        .test("matches", "Description is required", checkQuilValidation),
      content: Yup.array().min(2, "Upload 2 Images at minimum!"),
    }),
    onSubmit: () => {
      handleAdminUpdate();
    },
  });
  const handleAdminUpdate = () => {
    window.parent.postMessage(
      isAdminAdd() ? { ...data, points: content?.points } : data,
      ADMIN_LINK
    );
  };
  return (
    <div className="col-12" style={containerStyle}>
      {isAdminEdit() && (
        <button style={btnUpdateStyle} onClick={contentFormik.handleSubmit}>
          Update
        </button>
      )}
      {isAdminAdd() && (
        <button style={btnAddStyle} onClick={contentFormik.handleSubmit}>
          ADD
        </button>
      )}
      <div className="container-ss">
        <Adminwrapper
          errorText={contentFormik.errors.name}
          dataType={QUILL}
          data={data}
          setData={setData}
          type="name"
        >
          {renderPlaceholder(data?.name, "Enter Name")}
          <QuillEditor sx={quilStyle} value={data?.name} readOnly={true} />
        </Adminwrapper>

        <Grid style={gridWraper}>
          <Grid container style={gridContainer}>
            {data?.grid?.map((arr, index) => {
              return (
                arr !== "" && (
                  <Grid xs={6} style={gridItem} key={arr}>
                    <Adminwrapper
                      dataType={MEDIA}
                      data={data}
                      setData={setData}
                      type="grid"
                      gridIndex={index}
                      mediaType={"IMAGE"}
                    >
                      <img width="100%" src={imageVisibility(arr)} alt="" />
                    </Adminwrapper>
                  </Grid>
                )
              );
            })}
            {isAdminAdd() && (
              <>
                <div style={addDropdown}>
                  <Button startIcon={<UploadIcon />}>
                    <input
                      type="file"
                      name="file"
                      style={inputStyle}
                      onChange={(e) => {
                        setData({
                          ...data,
                          grid: [...data.grid, e.target.files[0]],
                        });
                      }}
                      accept="image/*"
                    />
                    Choose Image {parseInt(data?.grid?.length || 0) + 1}
                  </Button>
                </div>
              </>
            )}

            {(isAdminAdd() || isAdminEdit()) &&
              !!contentFormik.errors.content && (
                <p style={errorStyle}>{contentFormik.errors.content}</p>
              )}
          </Grid>
        </Grid>
        <Adminwrapper
          errorText={contentFormik.errors.description}
          dataType={QUILL}
          data={data}
          setData={setData}
          type="description"
        >
          {renderPlaceholder(data?.description, "Enter Description")}
          <QuillEditor
            sx={quilStyle}
            value={data?.description}
            readOnly={true}
          />
        </Adminwrapper>
      </div>
    </div>
  );
};

const RenderImageGridUseCases = ({ content }) => {
  const contentArr = content?.allContent?.content?.split("$") || [];
  const { QUILL, MEDIA } = DATA_TYPE;
  const { name, description, type } = content?.allContent;
  const [data, setStateData] = useState({
    name,
    description,
    type,
    grid: contentArr,
  });
  const imageVisibility = (url) => {
    if (!url?.name) return process.env.REACT_APP_S3_BUCKET_LINK + url;
    return URL.createObjectURL(url);
  };
  const setData = (obj) => {
    setStateData(obj);
    contentFormik.setValues(obj);
  };
  const contentFormik = useFormik({
    initialValues: {
      name: data.name,
      type: data.type,
      description: data.description,
      content: data?.grid,
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .required("Name is Required")
        .test("matches", "Name is required", checkQuilValidation),
      description: Yup.string()
        .required("Description is Required")
        .test("matches", "Description is required", checkQuilValidation),
      content: Yup.array().min(2, "Upload 2 Images at minimum!"),
    }),
    onSubmit: () => {
      handleAdminUpdate();
    },
  });
  const handleAdminUpdate = () => {
    window.parent.postMessage(
      isAdminAdd() ? { ...data, points: content?.points } : data,
      ADMIN_LINK
    );
  };
  return (
    <div className="col-12" style={containerStyle}>
      {isAdminEdit() && (
        <button style={btnUpdateStyle} onClick={contentFormik.handleSubmit}>
          Update
        </button>
      )}
      {isAdminAdd() && (
        <button style={btnAddStyle} onClick={contentFormik.handleSubmit}>
          ADD
        </button>
      )}
      <div className="container-ss">
        <Adminwrapper
          errorText={contentFormik.errors.name}
          dataType={QUILL}
          data={data}
          setData={setData}
          type="name"
        >
          {renderPlaceholder(data?.name, "Enter Name")}
          <QuillEditor sx={quilStyle} value={data?.name} readOnly={true} />
        </Adminwrapper>

        <Grid style={{ ...gridWraper, padding: "0px 50px" }}>
          <Grid
            container
            style={{ ...gridContainer, height: "60vh", overflow: "auto" }}
          >
            {data?.grid?.map((arr, index) => {
              return (
                arr !== "" && (
                  <Grid xs={4} style={gridItem} key={arr}>
                    <Adminwrapper
                      dataType={MEDIA}
                      data={data}
                      setData={setData}
                      type="grid"
                      gridIndex={index}
                      mediaType={"IMAGE"}
                    >
                      <img width="97%" src={imageVisibility(arr)} alt="" />
                    </Adminwrapper>
                  </Grid>
                )
              );
            })}
            {isAdminAdd() && (
              <>
                <div style={addDropdown}>
                  <Button startIcon={<UploadIcon />}>
                    <input
                      type="file"
                      name="file"
                      style={inputStyle}
                      onChange={(e) => {
                        setData({
                          ...data,
                          grid: [...data.grid, e.target.files[0]],
                        });
                      }}
                      accept="image/*"
                    />
                    Choose Image {parseInt(data?.grid?.length || 0) + 1}
                  </Button>
                </div>
              </>
            )}

            {(isAdminAdd() || isAdminEdit()) &&
              !!contentFormik.errors.content && (
                <p style={errorStyle}>{contentFormik.errors.content}</p>
              )}
          </Grid>
        </Grid>
        <Adminwrapper
          errorText={contentFormik.errors.description}
          dataType={QUILL}
          data={data}
          setData={setData}
          type="description"
        >
          {renderPlaceholder(data?.description, "Enter Description")}
          <QuillEditor
            sx={quilStyle}
            value={data?.description}
            readOnly={true}
          />
        </Adminwrapper>
      </div>
    </div>
  );
};

const RenderImage = ({ content }) => {
  const { QUILL, MEDIA } = DATA_TYPE;
  const { type, name, description } = content?.allContent;
  const [data, setStateData] = useState({
    type,
    name,
    description,
    content: content?.allContent.content,
  });

  const setData = (obj) => {
    setStateData(obj);
    contentFormik.setValues(obj);
  };
  const contentFormik = useFormik({
    initialValues: {
      name: data.name,
      type: data.type,
      description: data.description,
      content: data?.content,
    },
    validationSchema: Yup.object({
      // name: Yup.string().required("Name is Required").matches(/(((<\w+>)+[ \n(<br>)]*(<\/\w+>)+)+)|<br>/g, "Name is Required matches"),
      name: Yup.string()
        .required("Name is Required")
        .test("matches", "Name is required", checkQuilValidation),
      description: Yup.string()
        .required("Description is Required")
        .test("matches", "Description is required", checkQuilValidation),
      content: Yup.mixed().required("Image is Required"),
    }),
    onSubmit: () => {
      handleAdminUpdate();
    },
  });

  const handleImageVisibility = () => {
    if (!data?.content) return "../assets/img/dummy/img.jpeg";
    if (!data?.content.name) return data?.content;
    return URL.createObjectURL(data?.content);
  };

  const handleAdminUpdate = () => {
    window.parent.postMessage(
      isAdminAdd() ? { ...data, points: content?.points } : data,
      ADMIN_LINK
    );
  };

  return (
    <div className="col-12" style={containerStyle}>
      {isAdminAdd() && (
        <button style={btnAddStyle} onClick={contentFormik.handleSubmit}>
          ADD
        </button>
      )}
      {isAdminEdit() && (
        <button style={btnUpdateStyle} onClick={contentFormik.handleSubmit}>
          Update
        </button>
      )}
      <div className="container">
        <Adminwrapper
          errorText={contentFormik.errors.name}
          dataType={QUILL}
          data={data}
          setData={setData}
          type="name"
        >
          {renderPlaceholder(data?.name, "Enter Name")}
          <QuillEditor sx={quilStyle} value={data?.name} readOnly={true} />
        </Adminwrapper>
        <Adminwrapper
          errorText={contentFormik.errors.content}
          dataType={MEDIA}
          data={data}
          setData={setData}
          mediaType={"IMAGE"}
          type="content"
        >
          <img style={imgStyle} src={handleImageVisibility()} alt="" />
        </Adminwrapper>

        <Adminwrapper
          errorText={contentFormik.errors.description}
          dataType={QUILL}
          data={data}
          setData={setData}
          type="description"
        >
          {renderPlaceholder(data?.name, "Enter Description")}
          <QuillEditor
            sx={quilStyle}
            value={data?.description}
            readOnly={true}
          />
        </Adminwrapper>
      </div>
    </div>
  );
};

const PPTImage = ({ content }) => {
  const skillName = useSelector((state) => state?.user?.skillName);
  const subSkillName = useSelector((state) => state?.user?.subSkillName);
  const { QUILL, MEDIA } = DATA_TYPE;
  const { type, name, description } = content?.allContent;
  const [data, setStateData] = useState({
    type,
    name,
    description,
    content: content?.allContent.content,
  });

  const setData = (obj) => {
    setStateData(obj);
    contentFormik.setValues(obj);
  };
  const contentFormik = useFormik({
    initialValues: {
      name: data.name,
      type: data.type,
      description: data.description,
      content: data?.content,
    },
    validationSchema: Yup.object({
      // name: Yup.string().required("Name is Required").matches(/(((<\w+>)+[ \n(<br>)]*(<\/\w+>)+)+)|<br>/g, "Name is Required matches"),
      name: Yup.string()
        .required("Name is Required")
        .test("matches", "Name is required", checkQuilValidation),
      description: Yup.string()
        .required("Description is Required")
        .test("matches", "Description is required", checkQuilValidation),
      content: Yup.mixed().required("Image is Required"),
    }),
    onSubmit: () => {
      console.log("asdfasdfasdf");
      handleAdminUpdate();
    },
  });

  const handleImageVisibility = () => {
    if (!data?.content) return "../assets/img/dummy/img.jpeg";
    if (!data?.content.name) return data?.content;
    return URL.createObjectURL(data?.content);
  };
  const handleAdminUpdate = () => {
    window.parent.postMessage(
      isAdminAdd() ? { ...data, points: content?.points } : data,
      ADMIN_LINK
    );
  };

  return (
    <div className="d-flex align-items-center">
      {isAdminEdit() && (
        <button style={btnUpdateStyle} onClick={contentFormik.handleSubmit}>
          {" "}
          Update
        </button>
      )}
      {isAdminAdd() && (
        <button style={btnAddStyle} onClick={contentFormik.handleSubmit}>
          ADD
        </button>
      )}
      <div
        className="col-6"
        style={{
          textDescription: "none",
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
          marginLeft: "90px",
        }}
      >
        <h1
          style={{ textAlign: "left" }}
          className="text-primary1"
          data-aos="fade-up"
          data-aos-easing="linear"
        >
          {skillName || "{Skill Name}"}
        </h1>
        <h1
          style={{ textAlign: "left" }}
          className="text-danger"
          data-aos="fade-up"
          data-aos-easing="linear"
        >
          {subSkillName || "{Sub Skill Name}"}
        </h1>
        <div
          data-aos="fade-down"
          data-aos-delay="2000"
          data-aos-easing="linear"
        >
          <Adminwrapper
            errorText={contentFormik.errors.description}
            dataType={QUILL}
            data={data}
            setData={setData}
            type="description"
          >
            {renderPlaceholder(data?.description, "Enter Description")}
            <QuillEditor
              sx={{ marginTop: "20px", fontSize: "18px" }}
              value={data?.description}
              readOnly={true}
            />
          </Adminwrapper>
        </div>
      </div>
      <div
        className="col-5 text-right d-flex flex-column align-items-center justify-content-center"
        style={{ textDescription: "none" }}
      >
        <Adminwrapper
          errorText={contentFormik.errors.content}
          dataType={MEDIA}
          data={data}
          setData={setData}
          type="content"
          mediaType={"IMAGE"}
        >
          <img
            style={{
              width: "80%",
              marginTop: "-5px",
            }}
            src={handleImageVisibility()}
            alt=""
            data-aos="fade-right"
            data-aos-delay="2000"
            data-aos-easing="linear"
          />
        </Adminwrapper>
        <div data-aos="fade-up" data-aos-delay="3000" data-aos-easing="linear">
          <Adminwrapper
            errorText={contentFormik.errors.name}
            dataType={QUILL}
            data={data}
            setData={setData}
            type="name"
          >
            {renderPlaceholder(data?.name, "Enter Name")}
            <QuillEditor
              sx={{ marginTop: "0px" }}
              value={data?.name}
              readOnly={true}
            />
          </Adminwrapper>
        </div>
      </div>
    </div>
  );
};

const RenderStickbotVertical = ({ content, user }) => {
  // const data?.content = JSON.parse(content?.allContent?.content)
  const { QUILL, LINK } = DATA_TYPE;
  const { name, type, description } = content?.allContent;
  const [isIframeReady, setIsIframeReady] = useState(false);
  const [data, setStateData] = useState({
    name,
    description,
    type,
    content: content?.allContent?.content,
  });
  const setData = (obj) => {
    setStateData(obj);
    contentFormik.setValues(obj);
  };
  const contentFormik = useFormik({
    initialValues: {
      name: data.name,
      type: data.type,
      description: data.description,
      content: data?.content,
    },
    validationSchema: Yup.object({
      description: Yup.string()
        .required("Description is Required")
        .test("matches", "Description is required", checkQuilValidation),
      content: Yup.mixed().required("Image is Required"),
    }),
    onSubmit: () => {
      console.log("asdfasdfasdf");
      handleAdminUpdate();
    },
  });
  const handleAdminUpdate = () => {
    window.parent.postMessage(
      isAdminAdd() ? { ...data, points: content?.points } : data,
      ADMIN_LINK
    );
  };
  return (
    <>
      {isAdminEdit() && (
        <button style={btnUpdateStyle} onClick={contentFormik.handleSubmit}>
          {" "}
          Update
        </button>
      )}
      {isAdminAdd() && (
        <button style={btnAddStyle} onClick={contentFormik.handleSubmit}>
          ADD
        </button>
      )}
      <div className="row d-flex align-items-center" style={{ height: "80vh" }}>
        <div
          className="col-5 px-2"
          style={{
            textDescription: "none",
            display: "flex",
            flexDirection: "column",
            alignItems: "start",
            marginLeft: "90px",
          }}
        >
          <Adminwrapper
            errorText={contentFormik.errors.description}
            dataType={QUILL}
            data={data}
            setData={setData}
            type="description"
          >
            {renderPlaceholder(data?.description, "Enter Description")}
            <QuillEditor
              sx={{ marginTop: "20px", fontSize: "18px" }}
              value={data?.description}
              readOnly={true}
            />
          </Adminwrapper>
        </div>
        <div className="col-6 text-right d-flex flex-column align-items-center justify-content-center">
          <Adminwrapper
            errorText={contentFormik.errors.content}
            dataType={LINK}
            data={data}
            setData={setData}
            type="content"
            label={"Iframe Link"}
          >
            {!isIframeReady && <LazzyLoading />}
            <iframe
              onLoad={() => setIsIframeReady(true)}
              style={{ width: "85%", opacity: isIframeReady ? 1 : 0 }}
              src={
                isValidUrl(data?.content)
                  ? data?.content + `?userId=${getName(user)}`
                  : "www.google.com"
              }
              width="85%"
              height="600px"
              frameBorder="0"
            ></iframe>
          </Adminwrapper>
        </div>
      </div>
    </>
  );
};

const RenderImageVertical = ({ content }) => {
  const { QUILL, MEDIA } = DATA_TYPE;
  const { type, name, description } = content?.allContent;
  const [data, setStateData] = useState({
    type,
    name,
    description,
    content: content?.allContent.content,
  });
  const setData = (obj) => {
    setStateData(obj);
    contentFormik.setValues(obj);
  };
  const contentFormik = useFormik({
    initialValues: {
      name: data.name,
      type: data.type,
      description: data.description,
      content: data?.content,
    },
    validationSchema: Yup.object({
      // name: Yup.string().required("Name is Required").matches(/(((<\w+>)+[ \n(<br>)]*(<\/\w+>)+)+)|<br>/g, "Name is Required matches"),
      name: Yup.string()
        .required("Name is Required")
        .test("matches", "Name is required", checkQuilValidation),
      description: Yup.string()
        .required("Description is Required")
        .test("matches", "Description is required", checkQuilValidation),
      content: Yup.mixed().required("Image is Required"),
    }),
    onSubmit: () => {
      console.log("asdfasdfasdf");
      handleAdminUpdate();
    },
  });

  const handleImageVisibility = () => {
    if (!data?.content) return "../assets/img/dummy/img.jpeg";
    if (!data?.content.name) return data?.content;
    return URL.createObjectURL(data?.content);
  };
  const handleAdminUpdate = () => {
    window.parent.postMessage(
      isAdminAdd() ? { ...data, points: content?.points } : data,
      ADMIN_LINK
    );
  };
  return (
    <div className="d-flex align-items-top">
      {isAdminAdd() && (
        <button style={btnAddStyle} onClick={contentFormik.handleSubmit}>
          ADD
        </button>
      )}
      {isAdminEdit() && (
        <button style={btnUpdateStyle} onClick={contentFormik.handleSubmit}>
          Update
        </button>
      )}
      <div
        className="col-6 px-2"
        style={{
          textDescription: "none",
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
          marginLeft: "60px",
        }}
      >
        <Adminwrapper
          errorText={contentFormik.errors.description}
          dataType={QUILL}
          data={data}
          setData={setData}
          type="description"
        >
          {renderPlaceholder(data?.description, "Enter Description")}
          <QuillEditor
            sx={{ marginTop: "0px", fontSize: "18px" }}
            value={data?.description}
            readOnly={true}
          />
        </Adminwrapper>
      </div>
      <div
        style={{
          textDescription: "none", width: "100%", height: "70vh",
        }}
      >
        <Adminwrapper
          errorText={contentFormik.errors.content}
          dataType={MEDIA}
          data={data}
          setData={setData}
          type="content"
          mediaType={"IMAGE"}
        >
          <img
            style={{
              width: "100%",
              objectFit: 'contain',
              height: "100%",
            }}
            src={handleImageVisibility()}
            alt=""
          />
        </Adminwrapper>
        <Adminwrapper
          errorText={contentFormik.errors.name}
          dataType={QUILL}
          data={data}
          setData={setData}
          type="name"
        >
          {renderPlaceholder(data?.name, "Enter Name")}
          <QuillEditor
            sx={{ marginTop: "0px" }}
            value={data?.name}
            readOnly={true}
          />
        </Adminwrapper>
      </div>
    </div>
  );
};

const RenderImageWireframeAndImageStickbot = ({ content, user }) => {
  const myObj = JSON.parse(content?.allContent?.content);
  const { wireframe_link, wireframe_link1 } = myObj;
  const { name, description, type } = content?.allContent;
  const [isIframeReady, setIsIframeReady] = useState(false);
  const [data, setStateData] = useState({
    name,
    description,
    type,
    wireframe_link,
    wireframe_link1,
  });
  const { QUILL, LINK, MEDIA } = DATA_TYPE;

  const imageVisibility = () => {
    if (!data?.wireframe_link1)
      return (
        process.env.REACT_APP_S3_BUCKET_LINK +
        "d82ced10-9bcc-11ed-8bd2-abba50f2c66f.jpeg"
      );
    if (!data?.wireframe_link1?.name)
      return process.env.REACT_APP_S3_BUCKET_LINK + data?.wireframe_link1;
    return URL.createObjectURL(data?.wireframe_link1);
  };
  const setData = (obj) => {
    setStateData(obj);
    contentFormik.setValues(obj);
  };
  const contentFormik = useFormik({
    initialValues: {
      name: data.name,
      type: data.type,
      description: data.description,
      wireframe_link: data?.wireframe_link,
      wireframe_link1: data?.wireframe_link1,
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .required("Name is Required")
        .test("matches", "Name is required", checkQuilValidation),
      description: Yup.string()
        .required("Description is Required")
        .test("matches", "Description is required", checkQuilValidation),
      wireframe_link: Yup.string().required("Link is Required"),
      wireframe_link1: Yup.string().required("Image is Required"),
    }),
    onSubmit: () => {
      handleAdminUpdate();
    },
  });

  const handleAdminUpdate = () => {
    window.parent.postMessage(
      isAdminAdd() ? { ...data, points: content?.points } : data,
      ADMIN_LINK
    );
  };
  return (
    <>
      <div
        className="d-flex align-items-center"
        style={{ height: "fit-content" }}
      >
        {isAdminEdit() && (
          <button style={btnUpdateStyle} onClick={contentFormik.handleSubmit}>
            Update
          </button>
        )}
        {isAdminAdd() && (
          <button style={btnAddStyle} onClick={contentFormik.handleSubmit}>
            ADD
          </button>
        )}
        <div
          className="col-6"
          style={{
            textDescription: "none",
            display: "flex",
            flexDirection: "column",
            alignItems: "space-between",
          }}
        >
          <Adminwrapper
            errorText={contentFormik.errors.description}
            dataType={QUILL}
            data={data}
            setData={setData}
            type="description"
          >
            {renderPlaceholder(data?.description, "Enter Description")}
            <QuillEditor
              sx={{ marginTop: "20px", fontSize: "18px" }}
              value={data?.description}
              readOnly={true}
            />
          </Adminwrapper>
        </div>
        <div className="col-5 text-right" style={{ textDescription: "none" }}>
          <Adminwrapper
            errorText={contentFormik.errors.wireframe_link1}
            dataType={MEDIA}
            data={data}
            setData={setData}
            type="link2"
            mediaType={"IMAGE"}
          >
            <img
              style={{
                width: "80%",
                marginTop: "-5px",
              }}
              src={imageVisibility()}
              alt=""
            />
          </Adminwrapper>
          <Adminwrapper
            errorText={contentFormik.errors.name}
            dataType={QUILL}
            data={data}
            setData={setData}
            type="name"
          >
            {renderPlaceholder(data?.name, "Enter Name")}
            <QuillEditor
              sx={{ marginTop: "0px" }}
              value={data?.name}
              readOnly={true}
            />
          </Adminwrapper>
        </div>
      </div>
      <div className="col-12 mt-3">
        <Adminwrapper
          errorText={contentFormik.errors.wireframe_link}
          dataType={LINK}
          data={data}
          setData={setData}
          type="link1"
        >
          {!isIframeReady && <LazzyLoading />}
          <iframe
            onLoad={() => setIsIframeReady(true)}
            style={{ opacity: isIframeReady ? 1 : 0, height: 700 }}
            className="i-frame"
            src={
              isValidUrl(data?.wireframe_link)
                ? data?.wireframe_link +
                `?userId=${getName(user)}` +
                "&token=" +
                localStorage.getItem("token") +
                "&isIframe=true"
                : "www.google.com"
            }
            frameBorder="0"
          ></iframe>
        </Adminwrapper>
      </div>
    </>
  );
};

const RenderWireframe = ({ content, user }) => {
  const { QUILL, LINK } = DATA_TYPE;
  const { name, type, description } = content?.allContent;
  const [isIframeReady, setIsIframeReady] = useState(false);
  const [data, setStateData] = useState({
    type,
    name,
    description,
    content: content?.allContent.content,
  });
  const setData = (obj) => {
    setStateData(obj);
    contentFormik.setValues(obj);
  };

  const isTypeBot = content?.allContent?.content
    ? content?.allContent?.content?.includes("stickbot-viewer.vercel.app")
    : false;

  const contentFormik = useFormik({
    initialValues: {
      name: data.name,
      type: data.type,
      description: data.description,
      content: data?.content,
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .required("Name is Required")
        .test("matches", "Name is required", checkQuilValidation),
      description: Yup.string()
        .required("Description is Required")
        .test("matches", "Description is required", checkQuilValidation),
      content: Yup.mixed().required("Link is Required"),
    }),
    onSubmit: () => {
      handleAdminUpdate();
    },
  });
  const handleAdminUpdate = () => {
    window.parent.postMessage(
      isAdminAdd() ? { ...data, points: content?.points } : data,
      ADMIN_LINK
    );
  };

  const vURL = isValidUrl(data?.content) ? data?.content : "";

  return (
    <div
      className="col-12"
      style={{
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      {isAdminAdd() && (
        <button style={btnAddStyle} onClick={contentFormik.handleSubmit}>
          ADD
        </button>
      )}
      {isAdminEdit() && (
        <button style={btnUpdateStyle} onClick={contentFormik.handleSubmit}>
          Update
        </button>
      )}
      <div className="container-ss" style={{ width: "100%" }}>
        <Adminwrapper
          errorText={contentFormik.errors.name}
          dataType={QUILL}
          data={data}
          setData={setData}
          type="name"
        >
          {renderPlaceholder(data?.name, "Enter Name")}
          <QuillEditor sx={quilStyle} value={data?.name} readOnly={true} />
        </Adminwrapper>
        <Adminwrapper
          errorText={contentFormik.errors.description}
          dataType={QUILL}
          data={data}
          setData={setData}
          type="description"
        >
          {renderPlaceholder(data?.description, "Enter Description")}
          <QuillEditor
            sx={quilStyle}
            value={data?.description}
            readOnly={true}
          />
        </Adminwrapper>
        <Adminwrapper
          errorText={contentFormik.errors.content}
          dataType={LINK}
          data={data}
          setData={setData}
          type="content"
          label={"i-frame"}
        >
          {!isIframeReady && <LazzyLoading />}
          <iframe
            onLoad={() => setIsIframeReady(true)}
            style={{ opacity: isIframeReady ? 1 : 0, height: 700 }}
            className="i-frame"
            src={`${vURL}${vURL.includes('/?') ? "&" : "?"}userId=${getName(user)}&token=${localStorage.getItem(
              "token"
            )}&isIframe=true`}
            frameBorder="0"
          ></iframe>
        </Adminwrapper>
      </div>
    </div>
  );
};

const RenderMatterport = ({ content }) => {
  const { QUILL, LINK, MULTI } = DATA_TYPE;
  const { name, description, type } = content?.allContent;
  const [isIframeReady, setIsIframeReady] = useState(false);
  const [data, setStateData] = useState({
    name,
    description,
    type,
    content: content?.allContent?.content,
  });
  const setData = (obj) => {
    setStateData(obj);
    contentFormik.setValues(obj);
  };
  const contentFormik = useFormik({
    initialValues: {
      name: data.name,
      type: data.type,
      description: data.description,
      content: data?.content,
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .required("Name is Required")
        .test("matches", "Name is required", checkQuilValidation),
      description: Yup.string()
        .required("Description is Required")
        .test("matches", "Description is required", checkQuilValidation),
      content: Yup.mixed().required("Link is Required"),
    }),
    onSubmit: () => {
      handleAdminUpdate();
    },
  });
  const handleAdminUpdate = () => {
    // var data = { type: 'Matterport' }
    window.parent.postMessage(
      isAdminAdd() ? { ...data, points: content?.points } : data,
      ADMIN_LINK
    );
  };
  return (
    <div
      className="col-12"
      style={{
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      {isAdminEdit() && (
        <button style={btnUpdateStyle} onClick={contentFormik.handleSubmit}>
          Update
        </button>
      )}
      {isAdminAdd() && (
        <button style={btnAddStyle} onClick={contentFormik.handleSubmit}>
          ADD
        </button>
      )}
      <Adminwrapper
        errorText={contentFormik.errors.name}
        dataType={QUILL}
        data={data}
        setData={setData}
        type="name"
      >
        {renderPlaceholder(data?.name, "Enter Name")}
        <QuillEditor
          sx={{ marginTop: "10px", fontSize: "16px" }}
          value={data?.name}
          readOnly={true}
        />
      </Adminwrapper>
      <Adminwrapper
        errorText={contentFormik.errors.content}
        dataType={LINK}
        data={data}
        label={"Matterport Link"}
        setData={setData}
        type="content"
      >
        {!isIframeReady && <LazzyLoading />}
        <iframe
          onLoad={() => setIsIframeReady(true)}
          style={{ marginLeft: "60px", opacity: isIframeReady ? 1 : 0 }}
          className="i-matport"
          width="100%"
          height="100%"
          src={isValidUrl(data?.content) ? data?.content : "www.google.com"}
          // src="https://www.w3schools.com/default.asp"
          frameBorder="0"
        ></iframe>
      </Adminwrapper>

      <Adminwrapper
        errorText={contentFormik.errors.description}
        dataType={QUILL}
        data={data}
        setData={setData}
        type="description"
      >
        {renderPlaceholder(data?.description, "Enter Description")}
        <QuillEditor
          sx={{ marginTop: "100px", fontSize: "16px" }}
          value={data?.description}
          readOnly={true}
        />
      </Adminwrapper>
    </div>
  );
};

const GoogleForm = ({ content }) => {
  const user = useSelector((state) => state?.user?.userData?.user);
  const { QUILL, FORM } = DATA_TYPE;
  const [formData, setFormData] = useState([]);
  const { name, description, type } = content?.allContent;
  const [isIframeReady, setIsIframeReady] = useState(false);
  const [data, setStateData] = useState({
    name,
    description,
    type,
    content: content?.allContent?.content,
  });
  const setData = (obj) => {
    setStateData(obj);
    contentFormik.setValues(obj);
  };
  const contentFormik = useFormik({
    initialValues: {
      name: data.name,
      type: data.type,
      description: data.description,
      content: data?.content,
    },
    validationSchema: Yup.object({
      // name: Yup.string().required("Name is Required").matches(/(((<\w+>)+[ \n(<br>)]*(<\/\w+>)+)+)|<br>/g, "Name is Required matches"),
      name: Yup.string()
        .required("Name is Required")
        .test("matches", "Name is required", checkQuilValidation),
      description: Yup.string()
        .required("Description is Required")
        .test("matches", "Description is required", checkQuilValidation),
      content: Yup.string().required("Content is Required"),
    }),
    onSubmit: () => {
      handleAdminUpdate();
    },
  });
  const handleAdminUpdate = () => {
    window.parent.postMessage(
      isAdminAdd() ? { ...data, points: content?.points } : data,
      ADMIN_LINK
    );
  };
  useEffect(() => {
    getFormsData();
  }, []);

  const getFormsData = async () => {
    try {
      if (type === "GoogleForm") {
        const res = await getForms();
        setFormData(res);
      } else {
        const res = await getJotForms();
        setFormData(res?.content || []);
      }
    } catch (error) { }
  };
  const getLink = () => {
    if (!data?.content) return "";
    if (type === "GoogleForm")
      return (
        process.env.REACT_APP_GOOLE_FORM_LINK +
        data.content +
        "?userID=" +
        user?._id
      );
    return (
      process.env.REACT_APP_JOT_FORM_LINK +
      data.content +
      "?userID=" +
      user?._id
    );
  };

  return (
    <div className="col-12" style={containerStyle}>
      {isAdminAdd() && (
        <button style={btnAddStyle} onClick={contentFormik.handleSubmit}>
          ADD
        </button>
      )}
      {isAdminEdit() && (
        <button style={btnUpdateStyle} onClick={contentFormik.handleSubmit}>
          Update
        </button>
      )}
      <div className="container-ss">
        <Adminwrapper
          errorText={contentFormik.errors.name}
          dataType={QUILL}
          data={data}
          setData={setData}
          type="name"
        >
          {renderPlaceholder(data?.name, "Enter Name")}
          {/* <QuillEditor
                        sx={quilStyle}
                        value={data?.name}
                        readOnly={true}
                    /> */}
        </Adminwrapper>

        <Adminwrapper
          errorText={contentFormik.errors.content}
          dataType={FORM}
          formData={formData}
          data={data}
          setData={setData}
          type="content"
        >
          {!isIframeReady && <LazzyLoading />}
          <iframe
            onLoad={() => setIsIframeReady(true)}
            style={{ opacity: isIframeReady ? 1 : 0 }}
            className="i-frame-google-form"
            src={getLink()}
          ></iframe>
        </Adminwrapper>

        <Adminwrapper
          errorText={contentFormik.errors.description}
          dataType={QUILL}
          data={data}
          setData={setData}
          type="description"
        >
          {renderPlaceholder(data?.description, "Enter Description")}
          {/* <QuillEditor
                        sx={quilStyle}
                        value={data?.description}
                        readOnly={true}
                    /> */}
        </Adminwrapper>
      </div>
    </div>
  );
};

const Chatbot = ({ content }) => {
  const user = useSelector((state) => state?.user?.userData?.user);
  const { QUILL, FORM } = DATA_TYPE;
  const [formData, setFormData] = useState([]);
  const { name, description, type } = content?.allContent;
  const [isIframeReady, setIsIframeReady] = useState(false);
  const [data, setStateData] = useState({
    name,
    description,
    type,
    content: content?.allContent?.content,
  });
  const setData = (obj) => {
    setStateData(obj);
    contentFormik.setValues(obj);
  };
  const contentFormik = useFormik({
    initialValues: {
      name: data.name,
      type: data.type,
      description: data.description,
      content: data?.content,
    },
    validationSchema: Yup.object({
      // name: Yup.string().required("Name is Required").matches(/(((<\w+>)+[ \n(<br>)]*(<\/\w+>)+)+)|<br>/g, "Name is Required matches"),
      name: Yup.string()
        .required("Name is Required")
        .test("matches", "Name is required", checkQuilValidation),
      description: Yup.string()
        .required("Description is Required")
        .test("matches", "Description is required", checkQuilValidation),
      content: Yup.string().required("Content is Required"),
    }),
    onSubmit: () => {
      handleAdminUpdate();
    },
  });
  const handleAdminUpdate = () => {
    window.parent.postMessage(
      isAdminAdd() ? { ...data, points: content?.points } : data,
      ADMIN_LINK
    );
  };
  useEffect(() => {
    getFormsData();
  }, []);

  const getFormsData = async () => {
    try {
      const res = await getAllChatbots();
      console.log("Chatbots: ", res);
      setFormData(
        res?.filter((item) =>
          item?.description?.includes("https://stickbot-viewer.vercel.app")
        )
      );
    } catch (error) { }
  };

  return (
    <div className="col-12" style={containerStyle}>
      {isAdminAdd() && (
        <button style={btnAddStyle} onClick={contentFormik.handleSubmit}>
          ADD
        </button>
      )}
      {isAdminEdit() && (
        <button style={btnUpdateStyle} onClick={contentFormik.handleSubmit}>
          Update
        </button>
      )}
      <div className="container-ss">
        <Adminwrapper
          errorText={contentFormik.errors.name}
          dataType={QUILL}
          data={data}
          setData={setData}
          type="name"
        >
          {renderPlaceholder(data?.name, "Enter Name")}
          <QuillEditor sx={quilStyle} value={data?.name} readOnly={true} />
        </Adminwrapper>

        <Adminwrapper
          errorText={contentFormik.errors.content}
          dataType={FORM}
          formData={formData}
          data={data}
          setData={setData}
          type="content"
        >
          {!isIframeReady && <LazzyLoading />}
          <iframe
            onLoad={() => setIsIframeReady(true)}
            style={{ opacity: isIframeReady ? 1 : 0, height: "80vh" }}
            className="i-frame"
            src={data?.content || ""}
          ></iframe>
        </Adminwrapper>

        <Adminwrapper
          errorText={contentFormik.errors.description}
          dataType={QUILL}
          data={data}
          setData={setData}
          type="description"
        >
          {renderPlaceholder(data?.description, "Enter Description")}
          <QuillEditor
            sx={quilStyle}
            value={data?.description}
            readOnly={true}
          />
        </Adminwrapper>
      </div>
    </div>
  );
};

const RenderDropdown = ({ content }) => {
  const { QUILL, MEDIA, MULTI } = DATA_TYPE;

  const { name, description, type } = content?.allContent;
  const [isAdd, setIsAdd] = useState(false);
  const [dropdownName, setDropdownName] = useState("");
  const [dropdownContent, setDropdownContent] = useState("");
  const [data, setStateData] = useState({
    name,
    description,
    type,
    dropdowns: JSON.parse(content?.allContent?.content),
  });

  const setData = (obj) => {
    setStateData(obj);
    contentFormik.setValues(obj);
  };
  const contentFormik = useFormik({
    initialValues: {
      name: data.name,
      type: data.type,
      description: data.description,
      dropdowns: data?.dropdowns,
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .required("Name is Required")
        .test("matches", "Name is required", checkQuilValidation),
      description: Yup.string()
        .required("Description is Required")
        .test("matches", "Description is required", checkQuilValidation),
      dropdowns: Yup.array().min(1, "Dropdown must be at least 1"),
    }),
    onSubmit: () => {
      handleAdminUpdate();
    },
  });
  const handleAdminUpdate = () => {
    window.parent.postMessage(
      isAdminAdd() ? { ...data, points: content?.points } : data,
      ADMIN_LINK
    );
  };
  return (
    <>
      <div className="col-12" style={containerStyle}>
        {isAdminEdit() && (
          <button style={btnUpdateStyle} onClick={contentFormik.handleSubmit}>
            Update
          </button>
        )}
        {isAdminAdd() && (
          <button style={btnAddStyle} onClick={contentFormik.handleSubmit}>
            ADD
          </button>
        )}
        <div className="container-ss">
          <Adminwrapper
            errorText={contentFormik.errors.name}
            dataType={QUILL}
            data={data}
            type="name"
            setData={setData}
          >
            {renderPlaceholder(data?.name, "Enter Name")}
            <QuillEditor
              sx={{ marginTop: "20px" }}
              value={data?.name}
              readOnly={true}
            />
          </Adminwrapper>
          <Adminwrapper
            errorText={contentFormik.errors.description}
            dataType={QUILL}
            data={data}
            type="description"
            setData={setData}
          >
            {renderPlaceholder(data?.description, "Enter Description")}
            <QuillEditor value={data?.description} readOnly={true} />
          </Adminwrapper>
          <CollapsibleTable
            Adminwrapper={Adminwrapper}
            DATA_TYPE={DATA_TYPE}
            data={data}
            setData={setData}
          />

          {(isAdminAdd() || isAdminEdit()) && (
            <>
              {!isAdd && (
                <Button
                  sx={{ m: 1 }}
                  variant="contained"
                  onClick={() => setIsAdd(true)}
                >
                  Add DropDown
                </Button>
              )}
              {isAdd && (
                <div style={addDropdown}>
                  <TextField
                    placeholder="Name"
                    variant="outlined"
                    label="Name"
                    style={{ width: "400px" }}
                    onChange={(e) => setDropdownName(e.target.value)}
                  />
                  <AdminQuillEditor
                    placeholder="Dropdown description"
                    sx={adminQuillStyle}
                    onChange={(e) => setDropdownContent(e)}
                  />
                  <Button
                    sx={{ m: 1 }}
                    variant="contained"
                    onClick={() => {
                      let tempDropdowns = data?.dropdowns || [];
                      tempDropdowns?.push({
                        title: dropdownName,
                        detail: dropdownContent,
                      });
                      setIsAdd(false);
                      let tempData = data;
                      tempData.dropdowns = tempDropdowns;
                      setData(tempData);
                    }}
                  >
                    Save
                  </Button>
                </div>
              )}
            </>
          )}

          {(isAdminAdd() || isAdminEdit()) &&
            !!contentFormik.errors.dropdowns && (
              <p style={errorStyle}>{contentFormik.errors.dropdowns}</p>
            )}
        </div>
      </div>
    </>
  );
};

const RenderStickbotCamera = ({ content, user }) => {
  // const data?.content = JSON.parse(content?.allContent?.content)
  const { QUILL, LINK } = DATA_TYPE;
  const { name, type, description } = content?.allContent;
  const [isIframeReady, setIsIframeReady] = useState(false);
  const [data, setStateData] = useState({
    name,
    description,
    type,
    content: content?.allContent?.content,
  });
  const setData = (obj) => {
    setStateData(obj);
    contentFormik.setValues(obj);
  };
  const contentFormik = useFormik({
    initialValues: {
      name: data.name,
      type: data.type,
      description: data.description,
      content: data?.content,
    },
    validationSchema: Yup.object({
      description: Yup.string()
        .required("Description is Required")
        .test("matches", "Description is required", checkQuilValidation),
      content: Yup.mixed().required("Image is Required"),
    }),
    onSubmit: () => {
      handleAdminUpdate();
    },
  });
  const handleAdminUpdate = () => {
    window.parent.postMessage(
      isAdminAdd() ? { ...data, points: content?.points } : data,
      ADMIN_LINK
    );
  };
  const webcamRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const [capturing, setCapturing] = useState(false);
  const [recordedChunks, setRecordedChunks] = useState([]);
  const [timer, setTimer] = useState(0);
  const [isRunning, setIsRunning] = useState(false);

  const handleStartCaptureClick = useCallback(() => {
    setCapturing(true);
    setTimer(0);
    setIsRunning(true);
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, {
      mimeType: isSafari ? "video/mp4" : "video/webm",
    });
    mediaRecorderRef.current.addEventListener(
      "dataavailable",
      handleDataAvailable
    );
    mediaRecorderRef.current.start();
  }, [webcamRef, setCapturing, mediaRecorderRef]);

  const handleDataAvailable = useCallback(
    ({ data }) => {
      if (data.size > 0) {
        setRecordedChunks((prev) => prev.concat(data));
      }
    },
    [setRecordedChunks]
  );

  const handleStopCaptureClick = useCallback(() => {
    mediaRecorderRef.current.stop();
    setCapturing(false);
    setIsRunning(false);
  }, [mediaRecorderRef, webcamRef, setCapturing]);

  const handleDownload = useCallback(() => {
    if (recordedChunks.length) {
      const blob = new Blob(recordedChunks, {
        type: "video/webm",
      });
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      document.body.appendChild(a);
      a.style = "display: none";
      a.href = url;
      a.download = "video.webm";
      a.click();
      window.URL.revokeObjectURL(url);
      setRecordedChunks([]);
    }
  }, [recordedChunks]);

  useEffect(() => {
    let intervalId;

    if (isRunning) {
      intervalId = setInterval(() => {
        setTimer((prevTimer) => prevTimer + 1000); // Increase the timer by 10 milliseconds
      }, 1000);
    }

    return () => {
      clearInterval(intervalId); // Clean up the interval on component unmount
    };
  }, [isRunning]);

  const videoConstraints = {
    // width: "auto",
    width: "350",
    height: "350",
    facingMode: "user",
  };

  const formatTime = (time) => {
    const hours = Math.floor(time / 3600000);
    const minutes = Math.floor((time % 3600000) / 60000);
    const seconds = Math.floor((time % 60000) / 1000);

    const formattedHours = String(hours).padStart(2, "0");
    const formattedMinutes = String(minutes).padStart(2, "0");
    const formattedSeconds = String(seconds).padStart(2, "0");

    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  };

  return (
    <>
      {isAdminEdit() && (
        <button style={btnUpdateStyle} onClick={contentFormik.handleSubmit}>
          {" "}
          Update
        </button>
      )}
      {isAdminAdd() && (
        <button style={btnAddStyle} onClick={contentFormik.handleSubmit}>
          ADD
        </button>
      )}
      <Adminwrapper
        errorText={contentFormik.errors.name}
        dataType={QUILL}
        data={data}
        setData={setData}
        type="name"
      >
        {renderPlaceholder(data?.name, "Enter Name")}
        <QuillEditor sx={quilStyle} value={data?.name} readOnly={true} />
      </Adminwrapper>
      <div className="row d-flex align-items-center" style={{ height: "80vh" }}>
        <div
          className="col-5 px-2"
          style={{
            textDescription: "none",
            display: "flex",
            flexDirection: "column",
            alignItems: "start",
            marginLeft: "90px",
          }}
        >
          <Adminwrapper
            errorText={contentFormik.errors.content}
            dataType={LINK}
            data={data}
            setData={setData}
            type="content"
            label={"Stikcbot Link"}
          >
            {!isIframeReady && <LazzyLoading />}
            <iframe
              onLoad={() => setIsIframeReady(true)}
              style={{
                width: "100%",
                height: "60vh",
                opacity: isIframeReady ? 1 : 0,
              }}
              src={
                isValidUrl(data?.content)
                  ? data?.content + `?userId=${getName(user)}`
                  : "www.google.com"
              }
              width="100%"
              frameBorder="0"
            ></iframe>
          </Adminwrapper>
        </div>
        {!isAdminEdit() && !isAdminAdd() && (
          <div className="col-6 text-right d-flex flex-column align-items-center justify-content-center">
            <div className="web-cam">
              <Webcam
                ref={webcamRef}
                audio={true}
                muted
                mirrored
                width="100%"
                videoConstraints={videoConstraints}
              />

              <div
                className="cam-stream-time"
                style={{ backgroundColor: capturing ? "#ff2525" : "#ccc" }}
              >
                {formatTime(timer)}
              </div>
              <div className="cam-buttons">
                {capturing ? (
                  <>
                    <button
                      className="cam-stop"
                      onClick={handleStopCaptureClick}
                    >
                      <div className="stop-icon"></div>
                    </button>
                  </>
                ) : (
                  <button
                    className="cam-start"
                    onClick={handleStartCaptureClick}
                  ></button>
                )}
              </div>
            </div>
            {recordedChunks.length > 0 && !capturing && (
              <button className="cam-download" onClick={handleDownload}>
                Upload Your Video
              </button>
            )}
          </div>
        )}
      </div>
      <Adminwrapper
        errorText={contentFormik.errors.description}
        dataType={QUILL}
        data={data}
        setData={setData}
        type="description"
      >
        {renderPlaceholder(data?.description, "Enter Description")}
        <QuillEditor
          sx={{ marginTop: "20px", fontSize: "18px" }}
          value={data?.description}
          readOnly={true}
        />
      </Adminwrapper>
    </>
  );
};

const RenderPDF = ({ content }) => {
  const { QUILL, MEDIA } = DATA_TYPE;
  const { type, name, description } = content?.allContent;

  // Initialize the state and formik values
  const [data, setStateData] = useState({
    type,
    name,
    description,
    content: content?.allContent?.content,
  });

  const setData = (obj) => {
    setStateData(obj);
    contentFormik.setValues(obj);
  };

  const contentFormik = useFormik({
    initialValues: {
      name: data?.name,
      type: data?.type,
      description: data?.description,
      content: data?.content,
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .required("Name is Required")
        .test("matches", "Name is required", checkQuilValidation),
      description: Yup.string()
        .required("Description is Required")
        .test("matches", "Description is required", checkQuilValidation),
      content: Yup.mixed().required("Pdf is Required"),
    }),
    onSubmit: () => {
      handleAdminUpdate();
    },
  });

  const handlePdfVisibility = () => {
    if (!data?.content) return "../assets/img/dummy/pdf.png";
    return data?.content;
  };

  const handleAdminUpdate = () => {
    window.parent.postMessage(
      isAdminAdd() ? { ...data, points: content?.points } : data,
      ADMIN_LINK
    );
  };

  return (
    <div className="col-12" style={containerStyle}>
      {isAdminAdd() && (
        <button style={btnAddStyle} onClick={contentFormik.handleSubmit}>
          ADD
        </button>
      )}
      {isAdminEdit() && (
        <button style={btnUpdateStyle} onClick={contentFormik.handleSubmit}>
          Update
        </button>
      )}
      <div className="container-fluid">
        <Adminwrapper
          errorText={contentFormik.errors.name}
          dataType={QUILL}
          data={data}
          setData={setData}
          type="name"
        >
          {renderPlaceholder(data?.name, "Enter Name")}
          <QuillEditor sx={quilStyle} value={data?.name} readOnly={true} />
        </Adminwrapper>
        <Adminwrapper
          errorText={contentFormik.errors.content}
          dataType={MEDIA}
          data={data}
          setData={setData}
          mediaType={"PDF"}
          type="content"
        >
          {data?.content ? (
            <div className="d-flex justify-content-center">
              <PdfViewer pdfUrl={handlePdfVisibility()} />
            </div>
          ) : (
            <img
              src={handlePdfVisibility()}
              alt="Placeholder Image"
              width="500px"
              height="auto"
            />
          )}
        </Adminwrapper>
        <Adminwrapper
          errorText={contentFormik.errors.description}
          dataType={QUILL}
          data={data}
          setData={setData}
          type="description"
        >
          {renderPlaceholder(data?.name, "Enter Description")}
          <QuillEditor
            sx={quilStyle}
            value={data?.description}
            readOnly={true}
          />
        </Adminwrapper>
      </div>
    </div>
  );
};

const RenderGoogleSheet = ({ content }) => {
  const { QUILL, MEDIA } = DATA_TYPE;
  const { type, name, description } = content?.allContent;
  const [data, setStateData] = useState({
    type,
    name,
    description,
    content: content?.allContent.content,
  });

  const setData = (obj) => {
    setStateData(obj);
    contentFormik.setValues(obj);
  };
  const contentFormik = useFormik({
    initialValues: {
      name: data.name,
      type: data.type,
      description: data.description,
      content: data?.content,
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .required("Name is Required")
        .test("matches", "Name is required", checkQuilValidation),
      description: Yup.string()
        .required("Description is Required")
        .test("matches", "Description is required", checkQuilValidation),
      content: Yup.mixed().required("Googlesheet is Required"),
    }),
    onSubmit: () => {
      handleAdminUpdate();
    },
  });

  // function convertSheetToEmbedUrl(fileUrl) {
  //   const regex = /\/spreadsheets\/d\/([\w-]+)\//;
  //   const match = fileUrl.match(regex);

  //   if (match && match[1]) {
  //     const sheetId = match[1];
  //     const embedUrl = `https://docs.google.com/spreadsheets/d/${sheetId}/pubhtml`;

  //     return embedUrl;
  //   }

  //   return null;
  // }

  const handleGoogleSheetVisibility = () => {
    if (!data?.content) return "../assets/img/dummy/googleSheet.png";

    return data?.content;
  };

  const handleAdminUpdate = () => {
    window.parent.postMessage(
      isAdminAdd() ? { ...data, points: content?.points } : data,
      ADMIN_LINK
    );
  };

  return (
    <div className="col-12" style={containerStyle}>
      {isAdminAdd() && (
        <button style={btnAddStyle} onClick={contentFormik.handleSubmit}>
          ADD
        </button>
      )}
      {isAdminEdit() && (
        <button style={btnUpdateStyle} onClick={contentFormik.handleSubmit}>
          Update
        </button>
      )}
      <div className="container">
        <Adminwrapper
          errorText={contentFormik.errors.name}
          dataType={QUILL}
          data={data}
          setData={setData}
          type="name"
        >
          {renderPlaceholder(data?.name, "Enter Name")}
          <QuillEditor sx={quilStyle} value={data?.name} readOnly={true} />
        </Adminwrapper>
        <Adminwrapper
          errorText={contentFormik.errors.content}
          dataType={MEDIA}
          data={data}
          setData={setData}
          mediaType={"GoogleSheet"}
          type="content"
        >
          {data?.content ? (
            <FileViewer fileUrl={handleGoogleSheetVisibility()} />
          ) : (
            <img
              src={handleGoogleSheetVisibility()}
              alt="Placeholder Image"
              width="inherit"
              height="auto"
            />
          )}
        </Adminwrapper>

        <Adminwrapper
          errorText={contentFormik.errors.description}
          dataType={QUILL}
          data={data}
          setData={setData}
          type="description"
        >
          {renderPlaceholder(data?.name, "Enter Description")}
          <QuillEditor
            sx={quilStyle}
            value={data?.description}
            readOnly={true}
          />
        </Adminwrapper>
      </div>
    </div>
  );
};

const RenderPowerPoint = ({ content }) => {
  const { QUILL, MEDIA } = DATA_TYPE;
  const { type, name, description } = content?.allContent;
  const [data, setStateData] = useState({
    type,
    name,
    description,
    content: content?.allContent.content,
  });

  const setData = (obj) => {
    setStateData(obj);
    contentFormik.setValues(obj);
  };
  const contentFormik = useFormik({
    initialValues: {
      name: data.name,
      type: data.type,
      description: data.description,
      content: data?.content,
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .required("Name is Required")
        .test("matches", "Name is required", checkQuilValidation),
      description: Yup.string()
        .required("Description is Required")
        .test("matches", "Description is required", checkQuilValidation),
      content: Yup.mixed().required("PowerPoint file is Required"),
    }),
    onSubmit: () => {
      handleAdminUpdate();
    },
  });

  const handlePowerPointVisibility = () => {
    if (!data?.content) return "../assets/img/dummy/ppt.png";
    return convertToEmbedUrl(data?.content);
  };

  const handleAdminUpdate = () => {
    window.parent.postMessage(
      isAdminAdd() ? { ...data, points: content?.points } : data,
      ADMIN_LINK
    );
  };

  return (
    <div className="col-12" style={containerStyle}>
      {isAdminAdd() && (
        <button style={btnAddStyle} onClick={contentFormik.handleSubmit}>
          ADD
        </button>
      )}
      {isAdminEdit() && (
        <button style={btnUpdateStyle} onClick={contentFormik.handleSubmit}>
          Update
        </button>
      )}
      <div className="container">
        <Adminwrapper
          errorText={contentFormik.errors.name}
          dataType={QUILL}
          data={data}
          setData={setData}
          type="name"
        >
          {renderPlaceholder(data?.name, "Enter Name")}
          <QuillEditor sx={quilStyle} value={data?.name} readOnly={true} />
        </Adminwrapper>
        <Adminwrapper
          errorText={contentFormik.errors.content}
          dataType={MEDIA}
          data={data}
          setData={setData}
          mediaType={"PowerPoint"}
          type="content"
        >
          {data?.content ? (
            <FileViewer fileUrl={handlePowerPointVisibility()} />
          ) : (
            <img
              src={handlePowerPointVisibility()}
              alt="Placeholder Image"
              width="inherit"
              height="auto"
            />
          )}
        </Adminwrapper>

        <Adminwrapper
          errorText={contentFormik.errors.description}
          dataType={QUILL}
          data={data}
          setData={setData}
          type="description"
        >
          {renderPlaceholder(data?.name, "Enter Description")}
          <QuillEditor
            sx={quilStyle}
            value={data?.description}
            readOnly={true}
          />
        </Adminwrapper>
      </div>
    </div>
  );
};
const Transition = forwardRef(function Transition(props, ref) {
  return (
    <Slide
      direction="up"
      style={{
        justifyContent: "right",
      }}
      ref={ref}
      {...props}
    />
  );
});

/*-----------------------------------------------------------------
    Admin Wraper for all content types
-------------------------------------------------------------------*/
const Adminwrapper = (props) => {
  const {
    children,
    dataType,
    label,
    type,
    data,
    setData,
    formData,
    mediaType,
    gridIndex,
    errorText,
    style,
  } = props;
  const [isShowModal, setShowModal] = useState(false);
  // const [data, setData] = useState(props?.data)
  const [message, setMessage] = useState("");
  // const isAdminEdit() = (localStorage.getItem("view") === "admin-edit") //admin-edit || admin
  const { QUILL, MEDIA, LINK, MULTI, FORM, METAVERSE } = DATA_TYPE;
  const [imgData, setImgData] = useState(data?.wireframe_link);
  const [showName, setShowName] = useState("");
  const [file, setFile] = useState();
  const handleDataType = () => {
    switch (dataType) {
      case MEDIA:
        return renderMedia();
      case QUILL:
        return renderQuillEditor();
      case LINK:
        return renderLink();
      case MULTI:
        return renderMulti();
      case FORM:
        return renderForm(formData);
      case METAVERSE:
        return renderMetaverse();
    }
  };
  const handleChange = (e) => {
    if (type === "name") {
      setData({
        type: data.type,
        name: e,
        description: data.description,
        content: data?.content,
        wireframe_link: data?.wireframe_link,
        wireframe_link1: data?.wireframe_link1,
        heading1: data?.heading1,
        heading2: data?.heading2,
        link_1: data?.link_1,
        grid: data?.grid,
        dropdowns: data?.dropdowns,
        text: data?.text,
        vidText: data?.vidText,
      });
    } else if (type === "content") {
      setData({
        type: data.type,
        name: data.name,
        description: data.description,
        content: e,
        wireframe_link: data?.wireframe_link,
        wireframe_link1: data?.wireframe_link1,
        heading1: data?.heading1,
        heading2: data?.heading2,
        link_1: data?.link_1,
        grid: data?.grid,
        dropdowns: data?.dropdowns,
        text: data?.text,
        vidText: data?.vidText,
      });
    } else if (type === "description") {
      setData({
        type: data.type,
        name: data.name,
        description: e,
        content: data?.content,
        wireframe_link: data?.wireframe_link,
        wireframe_link1: data?.wireframe_link1,
        heading1: data?.heading1,
        heading2: data?.heading2,
        link_1: data?.link_1,
        grid: data?.grid,
        dropdowns: data?.dropdowns,
        text: data?.text,
        vidText: data?.vidText,
      });
    } else if (type === "link1") {
      setData({
        type: data.type,
        name: data.name,
        content: data?.content,
        description: data.description,
        wireframe_link: e,
        wireframe_link1: data?.wireframe_link1,
        heading1: data?.heading1,
        heading2: data?.heading2,
        link_1: data?.link_1,
        grid: data?.grid,
        dropdowns: data?.dropdowns,
        text: data?.text,
        vidText: data?.vidText,
      });
    } else if (type === "link2") {
      setData({
        type: data.type,
        name: data.name,
        content: data?.content,
        description: data.description,
        wireframe_link: data?.wireframe_link,
        wireframe_link1: e,
        heading1: data?.heading1,
        heading2: data?.heading2,
        link_1: data?.link_1,
        grid: data?.grid,
        dropdowns: data?.dropdowns,
        text: data?.text,
        vidText: data?.vidText,
      });
    } else if (type === "heading1") {
      setData({
        type: data.type,
        name: data.name,
        content: data?.content,
        description: data.description,
        wireframe_link: data?.wireframe_link,
        wireframe_link1: data?.wireframe_link1,
        heading1: e,
        heading2: data?.heading2,
        link_1: data?.link_1,
        grid: data?.grid,
        dropdowns: data?.dropdowns,
        text: data?.text,
        vidText: data?.vidText,
      });
    } else if (type === "heading2") {
      setData({
        type: data.type,
        name: data.name,
        content: data?.content,
        description: data.description,
        wireframe_link: data?.wireframe_link,
        wireframe_link1: data?.wireframe_link1,
        heading1: data?.heading1,
        heading2: e,
        link_1: data?.link_1,
        grid: data?.grid,
        dropdowns: data?.dropdowns,
        text: data?.text,
        vidText: data?.vidText,
      });
    } else if (type === "link_1") {
      setData({
        type: data.type,
        name: data.name,
        content: data?.content,
        description: data.description,
        wireframe_link: data?.wireframe_link,
        wireframe_link1: data?.wireframe_link1,
        heading1: data?.heading1,
        heading2: data?.heading2,
        link_1: e,
        dropdowns: data?.dropdowns,
        text: data?.text,
        vidText: data?.vidText,
      });
    } else if (type === "grid") {
      setData({
        type: data?.type,
        name: data?.name,
        content: data?.content,
        description: data?.description,
        wireframe_link: data?.wireframe_link,
        wireframe_link1: data?.wireframe_link1,
        heading1: data?.heading1,
        heading2: data?.heading2,
        link_1: data?.link_1,
        grid: e,
        dropdowns: data?.dropdowns,
        text: data?.text,
        vidText: data?.vidText,
      });
    } else if (type === "dropdownTitle" || type === "dropdownDetail") {
      setData({
        type: data.type,
        name: data.name,
        content: data?.content,
        description: data.description,
        wireframe_link: data?.wireframe_link,
        wireframe_link1: data?.wireframe_link1,
        heading1: data?.heading1,
        heading2: data?.heading2,
        link_1: data?.link_1,
        grid: data?.grid,
        dropdowns: e,
        text: data?.text,
        vidText: data?.vidText,
      });
    } else if (type === "multilinks") {
      setData({
        type: data.type,
        name: data.name,
        description: data.description,
        content: e,
        wireframe_link: data?.wireframe_link,
        wireframe_link1: data?.wireframe_link1,
        heading1: data?.heading1,
        heading2: data?.heading2,
        link_1: data?.link_1,
        grid: data?.grid,
        dropdowns: data?.dropdowns,
        text: data?.text,
        vidText: data?.vidText,
      });
    } else if (type === "text") {
      setData({
        type: data.type,
        name: data.name,
        description: data.description,
        content: data?.content,
        wireframe_link: data?.wireframe_link,
        wireframe_link1: data?.wireframe_link1,
        heading1: data?.heading1,
        heading2: data?.heading2,
        link_1: data?.link_1,
        grid: data?.grid,
        dropdowns: data?.dropdowns,
        text: e,
        vidText: data?.vidText,
      });
    } else if (type === "vidText") {
      setData({
        type: data.type,
        name: data.name,
        description: data.description,
        content: data?.content,
        wireframe_link: data?.wireframe_link,
        wireframe_link1: data?.wireframe_link1,
        heading1: data?.heading1,
        heading2: data?.heading2,
        link_1: data?.link_1,
        grid: data?.grid,
        dropdowns: data?.dropdowns,
        text: data?.text,
        vidText: e,
      });
    }
  };

  const renderPdfMedia = (
    <>
      <Button startIcon={<UploadIcon />}>
        <input
          type="file"
          name="file"
          style={inputStyle}
          onChange={(e) => {
            handleChange(e.target.files[0]);
            setShowName(e.target.files[0]?.name);
          }}
          accept=".pdf"
        />
        Upload PDF
      </Button>
      <Typography color="#FF0C06" variant="subtitle2">
        {" "}
        {message}{" "}
      </Typography>
    </>
  );

  const renderGoogleSheetMedia = (
    <>
      <Button startIcon={<UploadIcon />}>
        <input
          type="file"
          name="file"
          style={inputStyle}
          onChange={(e) => {
            handleChange(e.target.files[0]);
            setShowName(e.target.files[0]?.name);
          }}
          accept=".xls ,.xlsx"
        />
        Upload Google Sheet
      </Button>
    </>
  );

  const renderImageVideoAndAudio = (
    <>
      <Button startIcon={<UploadIcon />}>
        <input
          type="file"
          name="file"
          style={inputStyle}
          onChange={(e) => {
            if (e.target.files[0]?.size > 5242900) {
              setMessage(
                "File size is too big. Try to upload less than or equal to 4MBs"
              );
            } else if (type === "grid") {
              let tempGrid = data?.grid;
              tempGrid[gridIndex] = e.target.files[0];
              handleChange(tempGrid);
            } else {
              setMessage("");
              handleChange(e.target.files[0]);
              setShowName(e.target.files[0]?.name);
            }
          }}
          accept={
            mediaType === "VIDEO"
              ? "video/*"
              : mediaType === "IMAGE"
                ? "image/*"
                : "audio/*"
          }
        />
        Upload file
      </Button>
      <Typography color="#FF0C06" variant="subtitle2">
        {" "}
        {message}{" "}
      </Typography>
    </>
  );

  const renderPowerPointMedia = (
    <>
      <Button startIcon={<UploadIcon />}>
        <input
          type="file"
          name="file"
          style={inputStyle}
          onChange={(e) => {
            handleChange(e.target.files[0]);
            setShowName(e.target.files[0]?.name);
          }}
          accept=".pptx"
        />
        Upload Power Point File
      </Button>
    </>
  );

  const renderMedia = () => {
    return (
      <Grid container spacing={3}>
        <Grid item md={4} sx={{ mt: "5%" }}>
          {mediaType == "PDF"
            ? { renderPdfMedia }
            : mediaType == "GoogleSheet"
              ? { renderGoogleSheetMedia }
              : mediaType == "PowerPoint"
                ? { renderPowerPointMedia }
                : { renderImageVideoAndAudio }}
        </Grid>
      </Grid>
    );
  };
  const renderQuillEditor = () => {
    return (
      <AdminQuillEditor
        placeholder="Write something"
        sx={{ height: 400, width: 800 }}
        value={
          type === "name"
            ? data.name
            : type === "content"
              ? data?.content
              : type === "description"
                ? data?.description
                : type === "link2"
                  ? data?.wireframe_link1
                  : type === "dropdownDetail"
                    ? data.dropdowns[gridIndex]?.detail
                    : ""
        }
        onChange={(e) => {
          if (type === "dropdownDetail") {
            let tempData = data?.dropdowns?.map((item, i) =>
              i === gridIndex ? { detail: e, title: item?.title } : item
            );
            handleChange(tempData);
          } else handleChange(e);
        }}
      />
    );
  };
  const renderLink = () => {
    const getLink = () => {
      if (type === "multilinks") {
        if (gridIndex === 1) return data?.content?.link_1;
        if (gridIndex === 2) return data?.content?.link_2;
        if (gridIndex === 3) return data?.content?.link_3;
        if (gridIndex === 4) return data?.content?.link_4;
        if (gridIndex === 5) return data?.content?.link_5;
        return "";
      }
    };
    return (
      <TextField
        sx={{ width: 500 }}
        type={type === "points" ? "number" : "text"}
        label={label || "New Link"}
        name="contentContent"
        onChange={(e) => {
          if (type === "dropdownTitle") {
            let tempData = data?.dropdowns?.map((item, i) =>
              i === gridIndex
                ? { title: e.target.value, detail: item.detail }
                : item
            );
            handleChange(tempData);
          } else if (type === "multilinks") {
            let tempData = data?.content;
            if (gridIndex === 1)
              tempData = { ...tempData, link_1: e.target.value };
            if (gridIndex === 2)
              tempData = { ...tempData, link_2: e.target.value };
            if (gridIndex === 3)
              tempData = { ...tempData, link_3: e.target.value };
            if (gridIndex === 4)
              tempData = { ...tempData, link_4: e.target.value };
            if (gridIndex === 5)
              tempData = { ...tempData, link_5: e.target.value };
            handleChange(tempData);
          } else if (type === "points") {
            if (e.target.value > 0) {
              setData(e.target.value);
            } else {
              setData(null);
            }
          } else handleChange(e.target.value);
        }}
        required
        value={
          type === "text"
            ? data?.text
            : type === "points"
              ? data
              : type === "content"
                ? data?.content
                : type === "link1"
                  ? data?.wireframe_link
                  : type === "link2"
                    ? data?.wireframe_link1
                    : type === "heading1"
                      ? data?.heading1
                      : type === "heading2"
                        ? data?.heading2
                        : type === "dropdownTitle"
                          ? data?.dropdowns[gridIndex].title
                          : type === "link_1"
                            ? data?.link_1
                            : type === "multilinks"
                              ? getLink()
                              : ""
        }
      />
    );
  };
  const renderMulti = () => {
    return (
      <Grid
        container
        spacing={4}
        style={{
          width: 400,
        }}
      >
        {imgData &&
          imgData.map((img, i) => (
            <>
              <Grid item md={6} sm={6} key={i}>
                <img
                  width="100%"
                  height={100}
                  src={process.env.REACT_APP_S3_BUCKET_LINK + img}
                  alt=""
                  type="image/png"
                />
              </Grid>
              <Grid item md={6} sm={6} key={i}>
                <Button
                  startIcon={<UploadIcon />}
                  sx={{
                    mt: 3,
                  }}
                >
                  <input
                    type="file"
                    name="file"
                    style={{
                      position: "absolute",
                      left: "1px",
                      backgroundColor: "white",
                      padding: "10px",
                      opacity: "0%",
                      width: "50%",
                    }}
                    onChange={(e) => {
                      if (e.target.files[0]?.size > 5242900) {
                        setMessage(
                          "File size is too big. Try to upload less than or equal to 4MBs"
                        );
                      } else {
                        setMessage("");
                        setImgData([
                          ...imgData.map((item, index) =>
                            index == i ? e.target.files[0] : item
                          ),
                        ]);
                        handleChange([
                          ...imgData.map((item, index) =>
                            index == i ? e.target.files[0] : item
                          ),
                        ]);
                      }
                    }}
                    accept={"image/*"}
                  />
                  Upload Image
                </Button>
              </Grid>
              <Grid item md={12} sm={12}>
                <Typography color="#FF0C06" variant="subtitle2">
                  {message}
                </Typography>
              </Grid>
            </>
          ))}
      </Grid>
    );
  };
  const renderForm = (formData) => {
    return (
      <FormControl sx={{ minWidth: 220 }}>
        <InputLabel id="demo-simple-select-label">
          {data?.type === "Chatbot" ? "Select Chatbot" : "Select Form"}
        </InputLabel>
        <Select
          labelId="demo-simple-select-label"
          name="contentContent"
          id="demo-simple-select"
          onChange={(e) => handleChange(e.target.value)}
          value={data.content}
        >
          {formData?.map((form) => {
            return data?.type === "GoogleForm" ? (
              <MenuItem key={form._id} value={form._id}>
                {form.name}
              </MenuItem>
            ) : data?.type === "Chatbot" ? (
              <MenuItem key={form._id} value={form?.description?.slice(8)}>
                {form.name}
              </MenuItem>
            ) : (
              <MenuItem key={form.id} value={form.id}>
                {form.title}
              </MenuItem>
            );
          })}
          {/* {formLoading ? (
                            <MenuItem>
                                <CircularProgress />
                            </MenuItem>
                        ) : (
                            forms?.map((form) => {
                                return (
                                    <MenuItem key={form.name} value={form._id}>
                                        {form.name}
                                    </MenuItem>
                                );
                            })
                        )} */}
        </Select>
      </FormControl>
    );
  };
  const renderMetaverse = () => {
    return (
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Select Mataverse</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          name="contentContent"
          id="demo-simple-select"
          onChange={(e) => {
            handleChange(e.target.value);
          }}
          style={{ minWidth: 200 }}
          value={data.content}
          label="Content"
        >
          {METAVERSE_DATA?.map((item) => (
            <MenuItem key={item.value} value={item.value}>
              {" "}
              {item.label}{" "}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };
  if (!isAdminEdit() && !isAdminAdd()) return children;
  if (isAdminEdit() || isAdminAdd())
    return (
      <>
        <div style={adminWraperEdit}>
          {children}
          <IconButton
            onClick={() => setShowModal(true)}
            style={editButtonStyle}
          >
            <DriveFileRenameOutlineRoundedIcon style={{ color: "#fff" }} />
          </IconButton>
          <Dialog
            open={isShowModal}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => setShowModal(false)}
            aria-describedby="alert-dialog-slide-description"
            maxWidth="xxs"
            style={{
              backgroundColor: "rgba(1000, 1000, 1000, 0.2)",
              overflow: "visible",
            }}
          >
            <IconButton
              onClick={() => setShowModal(false)}
              style={crossButtonStyle}
            >
              <SaveAsRoundedIcon style={{ color: "#fff" }} />
            </IconButton>
            {handleDataType()}
          </Dialog>
        </div>
        {!!errorText && <p style={errorStyle}>{errorText}</p>}
      </>
    );
};

const checkQuilValidation = (value) => {
  if (!value) return true;
  const regex = /<[^>]+>(.*?)<\/[^>]+>/gi;
  const matches = value.match(regex);
  return matches.some((match) => match !== "<p><br></p>");
};

/*-----------------------------------------------------------------
    Styles
-------------------------------------------------------------------*/

const btnUpdateStyle = {
  position: "fixed",
  zIndex: 100,
  left: 15,
  bottom: 50,
  padding: "7px 20px",
  backgroundColor: "#fff",
  border: "none",
  borderRadius: "10px",
  color: "#616ded",
  width: "fit-content",
  boxShadow: "0 0 10px 0 rgba(0, 0, 0, 0.4)",
};
const btnAddStyle = {
  position: "fixed",
  zIndex: 100,
  left: 20,
  bottom: 50,
  padding: "7px 30px",
  backgroundColor: "#fff",
  border: "none",
  borderRadius: "10px",
  color: "#616ded",
  width: "fit-content",
  boxShadow: "0 0 10px 0 rgba(0, 0, 0, 0.4)",
};

const containerStyle = {
  height: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
};

const gridStyle = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  width: "80%",
};

const quilStyle = {
  fontSize: "16px",
  marginBottom: "20px",
  marginTop: "20px",
};

const imgStyle = {
  width: "80%",
  maxWidth: "60%",
  borderRadius: "10px",
};

const inputStyle = {
  position: "absolute",
  left: "1px",
  backgroundColor: "white",
  width: "95%",
  opacity: "0%",
  textAlign: "center",
};
const gridWraper = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  margin: "20px 0px",
  overflow: "hidden",
};
const gridContainer = {
  display: "flex",
  justifyContent: "center",
  margin: "0px",
  width: "80%",
  position: "relative",
};
const actionBtns = {
  position: "absolute",
  top: 0,
  right: 0,
  zIndex: 100,
  backgroundColor: "rgba(0,0,0,0.7)",
  color: "#fff",
};
const gridItem = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "fit-content",
  position: "relative",
};
const stickbotIframeStyle = {
  width: "844px",
  height: "390px",
  padding: "5px",
  marginTop: "15px",
};
const stickbotButtons = {
  width: "90%",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-around",
  alignItems: "center",
  margin: "28px 30px 10px 30px",
};
const editButtonStyle = {
  float: "right",
  alignItems: "center",
  justifyContent: "center",
  display: "flex",
  margin: 0,
  padding: 5,
  position: "absolute",
  top: "5px",
  right: "5px",
  zIndex: 1000,
  backgroundColor: "rgba(0,0,0,0.7)",
};
const adminWraperEdit = {
  border: "1px solid #B9C0C0",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",
  padding: 10,
  margin: 10,
  borderRadius: "15px",
  boxShadow: "0px 0px 30px 0px rgba(0, 0, 0, 0.1)",
  overflow: "hidden",
  position: "relative",
  backgroundColor: "#fff",
};
const crossButtonStyle = {
  padding: 5,
  position: "absolute",
  right: 10,
  top: -40,
  boxShadow: "0 0 20px 0 rgba(255,255,255,0.3)",
  backgroundColor: "rgba(0,0,0,0.5)",
};
const adminQuillStyle = {
  height: 250,
  width: 400,
  borderRadius: "5px",
};
const addDropdown = {
  marginTop: 10,
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "20px",
};
const selectImageStyle = {
  backgroundColor: "#5048E5",
  borderRadius: "15%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "28px",
  height: "28px",
  marginRight: 5,
};
const errorStyle = {
  margin: 0,
  color: "red",
  textAlign: "left",
  paddingLeft: "20px",
  fontSize: 12,
};
const placeholderStyle = {
  margin: 10,
};
const pointsStyle = {
  width: "150px",
  position: "fixed",
  left: "-17px",
  bottom: "95px",
};
const mallStyle = {
  height: "500px",
  width: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  textAlign: "center",
};

const audioPlayerStyle = {
  position: "relative",
  width: "100%",
};
const ccButton = {
  position: "absolute",
  bottom: "2px",
  left: "50px",
};
const ccVideoButton = {
  boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.2)",
  position: "absolute",
  top: "5px",
  right: "5px",
  color: "#fff",
  backgroundColor: "#ddd",
};

const ccVideoButtonSix = {
  boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.2)",
  position: "absolute",
  bottom: "20px",
  right: "10px",
  color: "#fff",
  backgroundColor: "#ddd",
};

const audioText = {
  minWidth: 200,
  maxWidth: 500,
  textAlign: "left",
  padding: "20px",
  margin: 0,
  overflow: "auto",
};

const journalButtonStyle = {
  position: 'fixed',
  width: 50,
  height: 50,
  padding: 7,
  right: 30,
  bottom: 210,
  background: '#fff',
  boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.2)',
  cursor: 'pointer',
  border: 'none',
  borderRadius: '50%'
}