const config = {
    palette: {
        primary: {
            main: "#616DED",
            light: "#F0F1FF",
            dark: "#4753bc",
            contrastText: '#fff',
        },
        secondary: {
            main: "#FB785C",
            light: "#f9b7a9",
            dark: "#f95534",
            contrastText: "#fff",
        },
        light: {
            main: "#fff"
        }
    }
}

export default config