import React, { useState, useEffect } from 'react'
import Header from '../common/NewHeader'
import { Link, useNavigate } from 'react-router-dom'
import TurnPhoneH from '../other/TurnPhoneH'
import isAuthenticated from '../auth/auth'
import Loading from '../common/Loading'

import { userLog } from '../_api/index'
import { useDispatch } from 'react-redux'


import { Grid, Button, Box } from "@mui/material"

import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { useQuery, useMutation, useQueryClient } from 'react-query';
import { getJobs } from '../_api/index'
import { setJobData, setDataFetch } from '../../redux-features/job/JobSlice'

import KeyboardBackspaceRoundedIcon from '@mui/icons-material/KeyboardBackspaceRounded';

export default function SuccessSkillIntro() {
    const { t } = useTranslation();
    const navigate = useNavigate()
    const { mutate: log } = useMutation(userLog);
    useEffect(() => {
        localStorage.setItem("fetch-jobs", true)
    }, [])


    const queryClient = useQueryClient();
    const { isLoading: jobsLoading, data: jobs } = useQuery("fetching-jobs", getJobs);


    const dispatch = useDispatch()

  useEffect(() => {
    if (jobs) {
      if (!jobsLoading) {
        dispatch(setJobData(jobs))
      } else {
        dispatch(setDataFetch(true))
      }
    }
  }, [jobs, jobsLoading])


   
    const isLoading = useSelector(state => state?.job?.dataFetch)

    const [isMobile, setIsMobile] = useState(false);
    function handleOrientation() {
        setIsMobile(window.innerHeight < window.innerWidth && window.innerWidth < 1000)
    }
    window.onresize = handleOrientation;
    useEffect(() => {
        setIsMobile(window.innerHeight < window.innerWidth && window.innerWidth < 1000)
    }, [window.innerWidth, window.innerHeight, window.orientation]);

  


    if (jobsLoading) {
        return (<Loading />)
    } else if (isMobile) {
        return (<TurnPhoneH content={t("successSkillsIntro.turn")} />)
    } else
        return (
            <> {!isAuthenticated(localStorage.getItem("token")) && navigate("/login")}
               
                <Grid container sx={{height: "100vh"}}>
                    <Header logo page="Success Skill Intro" />
                    <Grid container mt={10} className="_success-skill-mob-scr">
                        <Grid item xs={6} container justifyContent="center" alignItems="center">
                            <Box>
                                <h1 className="successskill__intro">{t("successSkillsIntro.doYouHaveTheSkills")} a</h1>
                                <h1 style={{ marginTop: '10px'}}>
                                    <div className="vwrap">
                                        <div className="vmove">
                                            <h1 className="successskill__jobtitle">{jobs[0]?.name ? jobs[0]?.name : "Job 1"}</h1>
                                            {
                                                            jobs?.map((job, index) => {
                                                                return (
                                                                    <h1 key={index} className="successskill__jobtitle">
                                                                        {job?.name}?
                                                                    </h1>
                                                                )
                                                            })
                                                        }
                                        </div>
                                    </div>
                                </h1>

                        <Link to="/success-skills-form">
                            <Button  variant="contained" className="loginscreen__button loginscreen__buttonone _getstartbtn" onClick={() => log({
                                    pageName: 'Success Skill Intro',
                                    buttonName: 'Get Started'
                                })}>
                                {t("successSkillsIntro.btnGetStarted")}
                            </Button>
                        </Link>



                            </Box>
              
                        </Grid>
                        <Grid item xs={6} container flexDirection="column" justifyContent="center" alignItems="center">
                            <img src="../assets/img/skills-success.svg" alt="" />
                            <br /> <img style={{ marginTop: '58px' }} src="../assets/icons/tick.svg" alt="" />
                        </Grid>
                    </Grid>
                </Grid>

            </>
        )
}











// <div className="body-bg-gradient body-bg-white">
// <div className="container splash-screen-container">
//     <div className="container-inner">
//         <div className="content">
//             <div className="main" style={{ marginTop: '37px' }}>
//                 <img src="../assets/img/skills-success.svg" alt="" />
//                 <br /> <img style={{ marginTop: '58px' }} src="../assets/icons/tick.svg" alt="" />
//                 <h1 style={{ marginTop: '26px', color: '#181818' }}>{t("successSkillsIntro.doYouHaveTheSkills")} a</h1>
//                 <h1 style={{ marginTop: '10px', color: '#181818', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

//                     <div className="vwrap">
//                         <div className="vmove d-flex flex-column align-items-center" style={{ color: '#616ded' }}>
//                             {
//                                 jobs?.map((job, index) => {
//                                     return (
//                                         <div key={index} className="vslide text-center">
//                                             {job?.name}?
//                                         </div>
//                                     )
//                                 })
//                             }
//                         </div>
//                     </div>
//                 </h1>
//                 <Link to="/success-skills-form" className="btn btn-default bg-primary1" style={{ marginTop: '72px' }}
//                     onClick={() => log({
//                         pageName: 'Success Skill Intro',
//                         buttonName: 'Get Started'
//                     })}
//                 >{t("successSkillsIntro.btnGetStarted")}</Link>
//             </div>
//         </div>
//     </div>
// </div>
// </div>

