import * as Axios from 'axios';
const API_URL = process.env.REACT_APP_API_LINK;

const axios = Axios.create({
  'headers': {
    'x-api-key': process.env.REACT_APP_X_API_KEY
  }
})

export const freezCard = async () => {

  const res = await axios.put(`${API_URL}/creditcard`, {}, {
    'headers': {
      'Authorization': `Stickball ${localStorage.getItem('token')}`
    }
  });
  return res.data;
}

export const getCard = async () => {
  const res = await axios.get(`${API_URL}/creditcard`, {
    'headers': {
      'Authorization': `Stickball ${localStorage.getItem('token')}`
    }
  })
  return (res.data)
}

export const getCardHistory = async () => {
  const res = await axios.get(`${API_URL}/creditcard/history`, {
    'headers': {
      'Authorization': `Stickball ${localStorage.getItem('token')}`
    }
  })
  return (res.data)
}
export const requestAmount = async (data) => {
  const res = await axios.post(`${API_URL.replace("/client", "")}/user/request-loan`, data,
    {
      headers: {
        'Authorization': `Stickball ${localStorage.getItem('token')}`
      }
    }
  );
  return res.data;
}

export const syncOnlinePurchase = async () => {
  const res = await axios.get(`${API_URL}/user/toggle_transaction_sync`,
    {
      headers: {
        'Authorization': `Stickball ${localStorage.getItem('token')}`
      }
    }
  );
  return res.data;
}