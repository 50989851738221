import React, { useState, useEffect } from "react";
import TurnPhoneV from "../../other/TurnPhoneV";
import SkillsBar from "./SkillsBar";
import { Link, useNavigate } from "react-router-dom";
// import LocalAtmRoundedIcon from "@mui/icons-material/LocalAtmRounded";
import { useSelector, useDispatch } from "react-redux";
import { useMutation } from "react-query";
import isAuthenticated from "../../auth/auth";
import RewardsModal from "../details/RewardsModal";
import TranslateModal from '../details/TranslateModal'

import { userLog } from "../../_api/index";
import { setSession, userEnroll, emptyStore } from "../../../redux-features/user/userSlice";
import SkillSection from "../details/SkillSection";

import PropTypes from "prop-types";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBook } from '@fortawesome/free-solid-svg-icons'
import Notes from '../notes/Notes'
import Swal from 'sweetalert2'

import { Grid } from "@mui/material";
import GoalsModal from '../../common/GoalsModal';
import Notifications from "../../notifications";
import ChatBot from "../../common/chatbot";
import Logo from "../../common/Logo";

function CircularProgressWithLabel(props) {

    return (
        <Box className="next" sx={{ position: "relative", display: "inline-flex" }}>
            <CircularProgress variant="determinate" {...props} />
            <Box
                sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: "absolute",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <Typography
                    variant="caption"
                    component="div"
                    style={{ color: "#616ded" }}
                >
                    {(props.value / props.divident).toFixed(0)}
                </Typography>
            </Box>
        </Box>
    );
}
CircularProgressWithLabel.propTypes = {
    value: PropTypes.number.isRequired,
};

export default function SkillAssess() {
    const dispatch = useDispatch();

    const user = useSelector((state) => state?.user?.userData?.user);
    const skillSections = useSelector((state) => state.user.skillSections);

    const hasWaitTime = useSelector(state => state.user.hasWaitTime)
    const waitTime = useSelector(state => state.user.waitTime)

    const { mutate: log } = useMutation(userLog);
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);

    const [nextSrc, setNextSrc] = useState("../../assets/img/nextt.png")
    const [backSrc, setBackSrc] = useState("../../assets/img/backk.png")


    const [assessContent, setAssessContent] = useState([]);
    const [nextContent, setNextContent] = useState();
    const [ind, setInd] = useState(0);
    const [nextTimer, setNextTimer] = useState(waitTime);
    const [divident, setDivident] = useState(100 / waitTime)
    const [quizIndex, setQuizIndex] = useState(0);
    const [submit, setSubmit] = useState([]);
    const [correctAnswers, setCorrectAnswers] = useState(0);
    const [quizCompleted, setQuizCompleted] = useState(false)
    const [totalQuestions, setTotalQuestions] = useState(0)
    const [openTranslate, setOpenTranslate] = useState(false)
    const [showModal, setShowModal] = useState(false);
    const skillName = useSelector(state => state.user?.skillName)
    const openModal = () => {
        setShowModal(!showModal);
    };



    useEffect(() => {
        const timer = setInterval(() => {
            setNextTimer((prevProgress) => (prevProgress > 0 ? prevProgress - 1 : 0));
        }, 1000);
        return () => {
            clearInterval(timer);
        };
    }, []);

    const [details, setDetails] = useState(true)



    useEffect(() => {
        let temp = skillSections?.filter((ss) => {
            return ss?.name === "Assess";
        });
        let temp1 = skillSections?.filter((ss) => {
            return ss?.name === "SIMS";
        });

        setNextContent(temp1);
        setAssessContent(temp);
        if (temp.length === 0) {
            navigate("/skill-detail-sims");
        }
        if (temp[ind]?.allContent?.type === "Quiz") {
            setTotalQuestions(temp[ind]?.allContent?.quiz?.length)
            if (
                temp?.filter((ss) => {
                    return !ss?.isCompleted;
                })?.length === 0
            ) {
                temp[ind]?.allContent?.quiz.map((qu) => {
                    setSubmit((old) => [...old, true]);
                });
            }

        }
    }, [skillSections, ind]);

    const handleEnrollment = (data, index) => {
        // let header = {
        //     skillSectionID: data,
        //     rating: 5
        // }
        if (nextContent?.length === 0) {
            let fc = assessContent.filter((cont) => {
                return !cont.isCompleted;
            });
            if (fc.length === 0) {
                navigate("/skill-detail-quiz");
            } else {
                if (assessContent.length - 1 === index) {
                    dispatch(setSession(data));
                    navigate("/skill-detail-quiz");
                } else {
                    dispatch(userEnroll(data));
                    setNextTimer(waitTime);
                }
            }
        } else {
            let fc = assessContent.filter((cont) => {
                return !cont.isCompleted;
            });
            if (fc.length === 0) {
                navigate("/skill-detail-sims");
            } else {
                try {
                    dispatch(userEnroll(data));
                    setNextTimer(waitTime);
                    if (assessContent.length - 1 === index) {
                        navigate("/skill-detail-sims");
                    }
                } catch (err) { }
            }
        }
    };

 

    const [isMobile, setIsMobile] = useState(false);
    const isNextEnabled = useSelector(state => state.user?.isNextEnabled)
    function handleOrientation() {
        setIsMobile(window.innerHeight > window.innerWidth);
    }
    useEffect(() => {
        setDetails(true)
        setQuizCompleted(false)
        setQuizIndex(0);
        setSubmit([]);
        setCorrectAnswers(0)
    }, [ind]);
    window.onresize = handleOrientation;
    useEffect(() => {
        setIsMobile(window.innerHeight > window.innerWidth);
    }, [window.innerWidth, window.innerHeight, window.orientation]);
    if (isMobile) {
        return <TurnPhoneV />;
    } else
        return (
            <>
                {!isAuthenticated(localStorage.getItem("token")) &&
                    navigate("/login")}
                <RewardsModal open={open} setOpen={setOpen} />
                {
                    showModal &&
                    <div className="journal-modal">
                        <Notes />
                    </div>
                }
                <TranslateModal openTranslate={openTranslate} setOpenTranslate={setOpenTranslate} />
                <SkillsBar page="Skill Detail Assess" />

                <div className="skills-details-container">
                    <div className="row text-center m-0 p-0">
                        {/* <h6>Module 1</h6> */}
                        {/* <h4 className="mt-3">{moduleName}</h4> */}
                    </div>


                    <Grid container>
                        <Grid container>
                            <Grid xs={12} sm={8} container sx={{ paddingLeft: "2rem" }}>
                                <Logo  />
                            </Grid>
                            <Grid container xs={12} sm={4} justifyContent="flex-end">
                                <Grid item container justifyContent="flex-end" alignItems="center">

                                    <>
                                        <div className="translate-buttons" style={{ marginRight: "1rem" }}>

                                            <Link to="/" className="translate-it"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    setOpenTranslate(true)
                                                    log({
                                                        pageName: 'Alt Space-vr',
                                                        buttonName: 'Translate It'
                                                    })
                                                }}>
                                                Translate It
                                            </Link>


                                        </div>

                                    </>


                                    <Link
                                        to="/wordscape"
                                        onClick={() =>
                                            log({
                                                pageName: "Skill Details Assess",
                                                buttonName: "Word Game",
                                            })
                                        }
                                        style={{ display: "flex", justifyContent: "center", alignItems: "center", marginRight: "1rem" }}
                                    >
                                        <div style={{ display: 'flex', padding: '0.5rem' }} className="wordscape__container">
                                            <img src="../assets/svg/main-home/word-game.svg" style={{ width: "35px", marginRight: "1rem" }} alt="" />
                                            <h1 className="lessonheading">Wordle</h1>
                                        </div>
                                    </Link>

                                    <div className="logoutIcon" onClick={() => {
                                        if (sessionStorage.getItem("access") === "ok") {
                                            Swal.fire({
                                                title: '',
                                                text: "It's a teacher view, so you can't logout!",
                                                icon: 'info',
                                                confirmButtonText: 'OK'
                                            })
                                            return
                                        }
                                        dispatch(emptyStore())
                                        localStorage.removeItem("token")
                                        log({
                                            pageName: 'Module',
                                            buttonName: 'Logout Button'
                                        })
                                        navigate("/get-started")
                                    }}>
                                        <div className="logged">
                                            <div class="block">
                                                <span className="animatedIcony">
                                                    <div class="div1" >
                                                        <img src="/assets/img/logout1.png" />
                                                        {/* <EastIcon className="arrowLoggedIcon myico right" sx={{color: "#616DED", marginLeft: "4px"}} /> */}
                                                    </div>
                                                    <div class="rect">
                                                        <img src="/assets/img/logout.png" />
                                                    </div>
                                                    <div class="div2">
                                                        <img src="/assets/img/logout1.png" />
                                                        {/* <EastIcon className="arrowLoggedIcon myico right" sx={{color: "#616DED", marginLeft: "4px"}} /> */}
                                                    </div>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <Notifications />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography varinat={'h3'} padding={"0 2rem"} sx={{ color: "#616DED", fontSize: "32px", marginTop: "1rem", fontWeight: "700" }} >
                                {/* {skillName ? skillName : 'Skill Name'} */}
                            </Typography>
                        </Grid>
                    </Grid>




                    {assessContent.filter((content) => {
                        return content.isCompleted;
                    }).length === assessContent.length ? (
                        <>
                            {assessContent[ind]?.allContent?.type === "Quiz" ? (
                                <>
                                </>
                            ) : (
                                <>
                                    <div
                                        className="row justify-content-center mt-3"

                                        style={{ width: "100%", overflowY: "auto", overflowX: "hidden", paddingLeft: "1rem" }}
                                    >
                                        <SkillSection content={assessContent[ind]} />
                                        <button
                                            className="next"
                                            onClick={() => {
                                                log({
                                                    pageName: "Skill Assess",
                                                    buttonName: "Next",
                                                });
                                                assessContent[ind + 1]
                                                    ? setInd(ind + 1)
                                                    : navigate("/skill-detail-sims");
                                            }}
                                        >
                                            <img src={nextSrc} onMouseEnter={() => { setNextSrc("../../assets/img/next.png") }} onMouseOut={() => { setNextSrc("../../assets/img/nextt.png") }} alt="" />
                                        </button>
                                    </div>
                                    <div
                                        className="prev"
                                        onClick={() => {
                                            log({
                                                pageName: "Skill Assess",
                                                buttonName: "Prev",
                                            });
                                            ind === 0 ? window.history.go(-1) : setInd(ind - 1);
                                        }}
                                    >
                                        <img src={backSrc} onMouseEnter={() => { setBackSrc("../../assets/img/prev.png") }} onMouseOut={() => { setBackSrc("../../assets/img/backk.png") }} alt="" />
                                    </div>
                                </>
                            )}
                        </>
                    ) : (
                        <></>
                    )}
                    {assessContent?.map((content, index) => {
                        return content.isEnrolled && !content.isCompleted ? (
                            <>
                                {assessContent[ind]?.allContent?.type === "Quiz" ? (
                                    <></>
                                ) : (
                                    <>
                                        <div
                                            key={index}
                                            className="row justify-content-center mt-3"
                                            style={{ paddingLeft: "1rem" }}
                                        >
                                            <SkillSection content={content} />
                                            {(nextTimer > 0 && hasWaitTime) ? (
                                                <CircularProgressWithLabel
                                                    style={{ color: "#616ded" }}
                                                    value={nextTimer * divident}
                                                    divident={divident}
                                                />
                                            ) : (
                                                <button
                                                    disabled={isNextEnabled}
                                                    className="next"
                                                    onClick={() => {
                                                        log({
                                                            pageName: "Skill Details Assess",
                                                            buttonName: "Next",
                                                        });
                                                        handleEnrollment(content?._id, index);
                                                        // if (content?.allContent?.type === "Metaverse") {
                                                        //     setTimeout(() => {
                                                        //         window.location.reload()
                                                        //     }, 500)
                                                        // }
                                                    }}
                                                >
                                                    <img src={nextSrc} onMouseEnter={() => { setNextSrc("../../assets/img/next.png") }} onMouseOut={() => { setNextSrc("../../assets/img/nextt.png") }} alt="" />
                                                </button>
                                            )}
                                        </div>
                                        <div
                                            className="prev"
                                            onClick={() => {
                                                log({
                                                    pageName: "Skill Details Assess",
                                                    buttonName: "Prev",
                                                });
                                                let temp = skillSections?.filter(ss => {
                                                    return (ss?.name === "Intro")
                                                })
                                                if (!temp.length) {
                                                    window.history.go(-2)
                                                    return
                                                }
                                                window.history.go(-1);
                                            }}
                                        >
                                            <img src={backSrc} onMouseEnter={() => { setBackSrc("../../assets/img/prev.png") }} onMouseOut={() => { setBackSrc("../../assets/img/backk.png") }} alt="" />
                                        </div>
                                    </>
                                )}
                            </>
                        ) : (
                            <></>
                        );
                    })}



                    {/* {
                        sessionStorage.getItem('access') !== 'ok' &&
                        <button
                            onClick={openModal}
                            className="btn-journal"
                        >
                            {
                                showModal ?
                                    <CloseIcon fontSize="large" />
                                    :
                                    <FontAwesomeIcon style={{ fontSize: '1.8rem', color: '#616ded', marginTop: '-2px' }} icon={faBook} />
                            }
                        </button>
                    } */}
                </div>
                <GoalsModal />
                <ChatBot data={{name:skillSections[ind]?.allContent?.name, description: skillSections[ind]?.allContent?.description}} pageName={"Skill Details"}/>
            </>
        );
}
