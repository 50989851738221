import React, { useState, useEffect, useRef } from 'react'
import TurnPhoneV from '../other/TurnPhoneV'
import { Link, useNavigate } from 'react-router-dom'
import SideBar from './details/SideBar'
import LocalAtmRoundedIcon from '@mui/icons-material/LocalAtmRounded';
import { useSelector } from 'react-redux'
import isAuthenticated from '../auth/auth'
import Loading from '../common/Loading'
import ReactPlayer from 'react-player'
import RewardsModal from './details/RewardsModal'
import TranslateModal from './details/TranslateModal'
import Header from "../common/NewHeader";

import { userLog } from '../_api/index'
import { useMutation } from 'react-query';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import LockIcon from '@mui/icons-material/Lock';
import LinearProgress from '@mui/material/LinearProgress';
import { Unity, useUnityContext } from "react-unity-webgl";
export default function AltSpaceVRWS() {
    const { unityProvider, requestFullscreen, loadingProgression, isLoaded } = useUnityContext({
        loaderUrl: "https://stickball.s3.amazonaws.com/BuildMall/built+41.loader.js",
        dataUrl: "https://stickball.s3.amazonaws.com/BuildMall/built+41.data",
        frameworkUrl: "https://stickball.s3.amazonaws.com/BuildMall/built+41.framework.js",
        codeUrl: "https://stickball.s3.amazonaws.com/BuildMall/built+41.wasm",
    });
    const { unityProvider: unityProviderRoom, requestFullscreen: requestFullscreenRoom, loadingProgression: loadingProgressionRoom, isLoaded: isLoadedRoom } = useUnityContext({
        loaderUrl: "https://stickball.s3.amazonaws.com/Build/Built.loader.js",
        dataUrl: "https://stickball.s3.amazonaws.com/Build/Built.data",
        frameworkUrl: "https://stickball.s3.amazonaws.com/Build/Built.framework.js",
        codeUrl: "https://stickball.s3.amazonaws.com/Build/Built.wasm"
    });
    const menu_content = ["Bowling Alley", "Bistro", "Food Court", "Restaurant", "Gym", "Hair Salon", "Lounge", "Movie Theatre", "Casino", "Coffee Shop", "Spa"]
    const video_links = [
        "https://stickball.s3.amazonaws.com/altspace-bowling-alley.mp4",
        "https://stickball.s3.amazonaws.com/altspace-bistro.mp4",
        "https://stickball.s3.amazonaws.com/altspace-food-court.mp4",
        "https://stickball.s3.amazonaws.com/altspace-restaurant.mp4",
        "https://stickball.s3.amazonaws.com/altspace-gym.mp4",
        "https://stickball.s3.amazonaws.com/altspace-hair-saloom.mp4",
        "https://stickball.s3.amazonaws.com/altspace-lounge.mp4",
        "https://stickball.s3.amazonaws.com/altspace-movie-theatre.mp4",
        "https://stickball.s3.amazonaws.com/altspace-casino.mp4",
        "https://stickball.s3.amazonaws.com/altspace-hookah-lounge.mp4",
        "https://stickball.s3.amazonaws.com/altspace-spa.mp4"
    ]

    const userData = useSelector(state => state.user?.userData?.data)
    const user = useSelector((state) => state?.user?.userData?.user)
    let points = user?.earnedPoints
    const navigate = useNavigate()
    const progressRef = useRef(() => { });
    const { mutate: log } = useMutation(userLog);
    const [videoPlay, setVideoPlay] = useState(false)
    const [open, setOpen] = useState(false)
    const [openTranslate, setOpenTranslate] = useState(false)
    const [collapse, setCollapse] = useState(false)
    const [active, setActive] = useState(0)
    const [videoLink, setVideoLink] = useState(video_links[0])
    const [isLocked, setIsLocked] = useState(points < 100)
    const [index, setIndex] = useState(0)
    const [showMall, setShowMall] = useState(false)
    const [showRoom, setShowRoom] = useState(false)
    const [buffer, setBuffer] = useState(10);

    const [nextLink, setNextLink] = useState(userData?.filter(module => { return (module?.isEnrolled && !module.isCompleted) })[0]?._id)
   
   
   

   
   
   
    useEffect(() => {
        setNextLink(userData?.filter(module => { return (module?.isEnrolled && !module.isCompleted) })[0]?._id)
        if (userData && userData?.length === userData?.filter(module => { return (module?.isCompleted) })?.length) {
            setNextLink(userData[0]?._id)
        }
    }, [userData]);
    useEffect(() => {
        progressRef.current = () => {
            if (Math.round(loadingProgression * 100) > 100) {
                setBuffer(10);
            } else {
                const diff = Math.random() * 10;
                const diff2 = Math.random() * 10;
                setBuffer(Math.round(loadingProgression * 100) + diff + diff2);
            }
        };
    });
    useEffect(() => {
        const timer = setInterval(() => {
            progressRef.current();
        }, 500);

        return () => {
            clearInterval(timer);
        };
    }, []);

    const [isMobile, setIsMobile] = useState(false);
    function handleOrientation() {
        setIsMobile(window.innerHeight > window.innerWidth && window.innerWidth < 1000)
    }
    window.onresize = handleOrientation;
    useEffect(() => {
        setIsMobile(window.innerHeight > window.innerWidth && window.innerWidth < 1000)
    }, [window.innerWidth, window.innerHeight, window.orientation]);

    function handleClickEnterFullscreen() {
        requestFullscreen(true);
    }
    function handleClickEnterFullscreenRoom() {
        requestFullscreenRoom(true);
    }
    if (isMobile) {
        return (<TurnPhoneV />)
    } else if (userData === undefined) {
        return (
            <>
                {!isAuthenticated(localStorage.getItem("token")) && navigate("/login")}
                <Loading />
            </>
        )
    } else
        return (
            <> {!isAuthenticated(localStorage.getItem("token")) && navigate("/login")}
               
               
               <Header
                backLink="/"
                // title="Settings"
                // icon={<img src="./assets/new-svgs/arrow_back.svg" />}
                page="Alt Space-vr"
                />
                    
                <RewardsModal open={open} setOpen={setOpen} />
                <TranslateModal openTranslate={openTranslate} setOpenTranslate={setOpenTranslate} />
              
                <div className="container-altspace-vr">
                    <div className="content"
                        style={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column'

                        }}
                    >
                        <div className="video-player">
                            {
                                (!videoPlay && !showMall && !showRoom) &&
                                <div className="image-mall" data-aos="fade-down">
                                    <img src="../assets/img/Stickball-Mall.jpg" alt="" />
                                </div>
                            }

                            {
                                (videoPlay && !showMall && !showRoom) &&
                                <div className="video-content d-flex">
                                    {
                                        isLocked &&
                                        <div className="locked-video">
                                            <LockIcon style={{ fontSize: '200px' }} />
                                            <div className="text-under-lock">
                                                Gain more {(index + 1) * 100 - points} points to unlock <br /> the  {menu_content[index]}
                                            </div>
                                        </div>
                                    }

                                    <ReactPlayer
                                        className="i-frame-video1"
                                        url={videoLink}
                                        playing={!isLocked}
                                        loop={true}
                                        controls={false}
                                        muted={true}
                                    />
                                    <div className="right-sidebar">
                                        <div className="sidebar-button">
                                            <button onClick={() => setCollapse(!collapse)}> {collapse ? <KeyboardArrowLeftIcon /> : <ChevronRightIcon />} </button>
                                        </div>
                                        <div className="menu-sidebar" style={{ width: collapse ? "0px" : window.innerWidth < 1300 ? "170px" : "250px", height: "93%" }}>
                                            <ul>
                                                {
                                                    menu_content.map((item, ind) => {
                                                        return (
                                                            <li className={active === ind && "active"} onClick={
                                                                () => {
                                                                    setActive(ind)
                                                                    setVideoLink(video_links[ind])
                                                                    setIsLocked(points < (ind + 1) * 100)
                                                                    setIndex(ind)
                                                                }
                                                            }>
                                                                <span>{ind + 1}. {item}</span>
                                                                {
                                                                    points < (ind + 1) * 100 &&
                                                                    <LockIcon />
                                                                }
                                                            </li>
                                                        )
                                                    })
                                                }
                                            </ul>

                                        </div>

                                    </div>
                                </div>
                            }
                        </div>
                        {
                            videoPlay &&
                            <>
                                {/* <button className="btn btn-primary bg-primary1 prev"
                                    style={{ fontSize: '12px', left: '260px', padding: '5px' }}
                                    onClick={() => {
                                        setShowRoom(true)
                                        setShowMall(false)
                                    }}
                                >
                                    Stickball Showroom
                                </button> */}
                                <button className="btn btn-primary bg-primary1 prev"
                                    style={{ fontSize: '12px', left: '190px', padding: '5px' }}
                                    onClick={() => {
                                        setShowMall(true)
                                        setShowRoom(false)
                                    }}
                                >
                                    Stickball Mall
                                </button>
                            </>
                        }
                        {
                            (showMall && !showRoom) &&
                            <>
                                {
                                    !isLoaded && <>
                                        {/* <LinearProgress style={{ width: '80%', height: '30px', marginTop: '100px', backgroundColor: '#eee' }} variant="buffer" value={Math.round(loadingProgression * 100)} valueBuffer={buffer} /> */}
                                        <span>Loading...{Math.round(loadingProgression * 100)}%</span>
                                    </>
                                }
                                <button onClick={handleClickEnterFullscreen} style={{ visibility: isLoaded ? "visible" : "hidden" }}>Enter Fullscreen</button>
                                <Unity
                                    unityProvider={unityProvider}
                                    style={{ visibility: isLoaded ? "visible" : "hidden", width: 1024, height: 768 }}
                                />
                            </>
                        }
                        {
                            (!showMall && showRoom) &&
                            <>
                                {
                                    !isLoadedRoom && <>

                                        <span>Loading...{Math.round(loadingProgressionRoom * 100)}%</span>
                                    </>
                                }
                                <button onClick={handleClickEnterFullscreenRoom} style={{ visibility: isLoadedRoom ? "visible" : "hidden" }}>Enter Fullscreen</button>
                                <Unity
                                    unityProvider={unityProvider}
                                    style={{ visibility: isLoadedRoom ? "visible" : "hidden", width: 1024, height: 768 }}
                                />
                            </>
                        }
                        {
                            (!videoPlay && !showMall && !showRoom) &&
                            <>
                                <button className="btn btn-primary bg-primary1 btn-metaverse px-5"
                                    style={{ fontSize: '20px' }}
                                    onClick={() => setVideoPlay(true)}
                                >
                                    <img src="../assets/svg/lesson/metaverse.svg" alt="" />
                                </button>
                            </>
                        }
                    </div>



                    <div className="flexer flexer-2">


                    </div>


                  

                   
                    {/* <div className="prev back-button"
                        onClick={(e) => 
                            {
                            log({
                            pageName: 'Alt Space-vr',
                            buttonName: 'Prev'
                        })
                        e.preventDefault()
                        window.history.go(-1)
                    }
                    }
                        >
                        <img src="../assets/icons/prev-btn.svg" alt="" />
                    </div> */}
                </div>

            </>
        )
}
