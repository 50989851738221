import React, { useState, useEffect } from 'react'
import TurnPhoneV from '../../other/TurnPhoneV'
import SkillsBar from './SkillsBar'
import { Link, useNavigate } from 'react-router-dom'
import Feedback from '../details/Feedback'
import { useSelector, useDispatch } from 'react-redux'
import isAuthenticated from '../../auth/auth'
import RewardsModal from '../details/RewardsModal'
import TranslateModal from '../details/TranslateModal'
import { userLog } from '../../_api/index'
import { setQuizPoints, emptyStore } from '../../../redux-features/user/userSlice'
import { Grid } from "@mui/material"
import { useMutation, useQueryClient, useQuery } from 'react-query';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { ProgressBar } from 'react-bootstrap';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBook } from '@fortawesome/free-solid-svg-icons'
import Notes from '../notes/Notes'
import Swal from 'sweetalert2'
import GoalsModal from '../../common/GoalsModal';
import TodoListModal from '../notes/TodoListModal';
import Notifications from '../../notifications';
import ChatBot from '../../common/chatbot'
import Logo from '../../common/Logo'

export default function SkillQuiz() {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [nextSrc, setNextSrc] = useState("../../assets/img/next.png")
    const [backSrc, setBackSrc] = useState("../../assets/img/backk.png")

    const [isComplete, setIsComplete] = useState(false)
    const [content, setContent] = useState()
    const [value, setValue] = useState(0);
    const [points, setPoints] = useState(0)
    const [answers, setAnswers] = useState([])
    const [submit, setSubmit] = useState([])
    const [ind, setInd] = useState(0)
    const [open, setOpen] = useState(false)
    const [openTranslate, setOpenTranslate] = useState(false)
    const [skillComplete, setSkillComplete] = useState();

    const temp = useSelector(state => state?.user?.quiz)
    const skillSections = useSelector(state => state?.user?.skillSections)
    const moduleID = useSelector(state => state.user?.moduleID)
    const cModule = useSelector(state => state.user?.module)

    const [showModal, setShowModal] = useState(false);
    const openModal = () => {
        setShowModal(!showModal);
    };

    const { mutate: log } = useMutation(userLog);
    useEffect(() => {

        setContent(temp)

        if (skillSections) {
            if (skillSections.filter(ss => (!ss.isCompleted))?.length === 0) {
                setSkillComplete(true)
                temp?.map(que => {
                    setSubmit(old => [...old, true])
                    setAnswers(old => [...old, que.trueOption])
                })
                if (temp?.length === 0) {
                    if (cModule.isCompleted) {
                        navigate('/module')
                    } else {
                        navigate("/lesson?id=" + moduleID)
                    }
                }
            } else {
                setSkillComplete(false)
                if (temp?.length === 0) {
                    setIsComplete(true)
                }
            }
        }


    }, [skillSections, temp])



    const handleSubmit = (e) => {
        e.preventDefault()
        dispatch(setQuizPoints(points))
        let temp = submit
        temp[ind] = true
        setSubmit(temp)
        setPoints(0)
        setValue(0)
    };
    const retryQuiz = () => {
        dispatch(setQuizPoints(0))
        setSubmit([])
        setAnswers([])
        setIsComplete(false)
        setInd(0)
        setPoints(0)
        setValue(0)
    }
    const queryClient = useQueryClient();

    const [isMobile, setIsMobile] = useState(false);

    function handleOrientation() {
        setIsMobile(window.innerHeight > window.innerWidth && window.innerWidth < 1000)
    }
    window.onresize = handleOrientation;
    useEffect(() => {
        setIsMobile(window.innerHeight > window.innerWidth && window.innerWidth < 1000)
    }, [window.innerWidth, window.innerHeight, window.orientation]);



    if (isMobile) return (<TurnPhoneV />)
    // if (quiz.length == 0) return <Loading />
    return (
        content ?
            <> {!isAuthenticated(localStorage.getItem("token")) && navigate("/login")}
                <RewardsModal open={open} setOpen={setOpen} />
                <button
                    onClick={openModal}
                    // className="btn-journal"
                    style={journalButtonStyle}
                >
                    {
                        showModal ?
                            <CloseIcon fontSize="large" />
                            :
                            <FontAwesomeIcon style={{ fontSize: '1.8rem', color: '#616ded', marginTop: '-2px' }} icon={faBook} />
                    }
                </button>
                <TranslateModal openTranslate={openTranslate} setOpenTranslate={setOpenTranslate} />
                {
                    showModal &&
                    <div className="journal-modal">
                        <Notes />
                    </div>
                }
                <SkillsBar page="Quiz" />
                <div className="skills-details-container">

                    <Grid container>
                        <Grid container>
                            <Grid xs={12} sm={8} container sx={{ paddingLeft: "2rem" }}>
                                <Logo  />
                            </Grid>
                            <Grid container xs={12} sm={4} justifyContent="flex-end">
                                <Grid item container justifyContent="flex-end" alignItems='center'>


                                    <>
                                        <div className="translate-buttons" style={{ marginRight: "1rem" }}>

                                            <Link to="/" className="translate-it"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    setOpenTranslate(true)
                                                    log({
                                                        pageName: 'Alt Space-vr',
                                                        buttonName: 'Translate It'
                                                    })
                                                }}>
                                                Translate It
                                            </Link>


                                        </div>

                                    </>


                                    <Link
                                        to="/wordscape"
                                        onClick={() =>
                                            log({
                                                pageName: "Skill Details Assess",
                                                buttonName: "Word Game",
                                            })
                                        }
                                        style={{ display: "flex", justifyContent: "center", alignItems: "center", marginRight: "1rem" }}
                                    >
                                        <div style={{ display: 'flex', padding: '0.5rem' }} className="wordscape__container">
                                            <img src="../assets/svg/main-home/word-game.svg" style={{ width: "35px", marginRight: "1rem" }} alt="" />
                                            <h1 className="lessonheading">Wordle</h1>
                                        </div>
                                    </Link>

                                    <div className="logoutIcon" onClick={() => {
                                        if (sessionStorage.getItem("access") === "ok") {
                                            Swal.fire({
                                                title: '',
                                                text: "It's a teacher view, so you can't logout!",
                                                icon: 'info',
                                                confirmButtonText: 'OK'
                                            })
                                            return
                                        }
                                        dispatch(emptyStore())
                                        localStorage.removeItem("token")
                                        log({
                                            pageName: 'Module',
                                            buttonName: 'Logout Button'
                                        })
                                        navigate("/get-started")
                                    }}>
                                        <div className="logged">
                                            <div class="block">
                                                <span className="animatedIcony">
                                                    <div class="div1" >
                                                        <img src="/assets/img/logout1.png" />
                                                        {/* <EastIcon className="arrowLoggedIcon myico right" sx={{color: "#616DED", marginLeft: "4px"}} /> */}
                                                    </div>
                                                    <div class="rect">
                                                        <img src="/assets/img/logout.png" />
                                                    </div>
                                                    <div class="div2">
                                                        <img src="/assets/img/logout1.png" />
                                                        {/* <EastIcon className="arrowLoggedIcon myico right" sx={{color: "#616DED", marginLeft: "4px"}} /> */}
                                                    </div>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <Notifications  />
                                </Grid>
                            </Grid>
                        </Grid>

                    </Grid>




                    <div className="row text-center m-0 p-0">
                        {/* <h6>Module 1</h6> */}
                        {/* <h4 className="mt-3">{moduleName}</h4> */}
                    </div>
                    {
                        isComplete ?
                            <Feedback retry={retryQuiz} nextLink="/quiz-result" />
                            :
                            <>
                                <div className="row" style={{ height: '82vh', justifyContent: 'center', alignItems: 'center' }}>

                                    <form
                                        style={{
                                            width: 'fit-content',
                                            minWidth: '50%',
                                            maxWidth: '65%',
                                            padding: '10px 20px',
                                            borderRadius: '16px',
                                            border: '1px solid grey',
                                            fontSize: '16px',
                                            textAlign: 'left',
                                        }}
                                    >
                                        <ProgressBar style={{
                                            height: '20px',
                                            marginTop: '12px',
                                            padding: '0px'
                                        }}
                                            className="my-progrss"
                                            now={(ind + 1) / content?.length * 100}
                                            label={`${((ind + 1) / content?.length * 100).toFixed(0)}%`}
                                        />
                                        <FormControl sx={{ m: 3 }} variant="standard">
                                            <FormLabel id="demo-error-radios"><p className="text-primary1" style={{ fontSize: '16px', fontWeight: '600' }}>Q{ind + 1}.  {content[ind]?.question}</p></FormLabel>
                                            <RadioGroup
                                                aria-labelledby="demo-error-radios"
                                                name={content[ind]?._id}
                                                value={answers[ind] || null}
                                                onChange={(event) => {
                                                    setValue(event.target.value);
                                                    // if (!submit[ind]) {
                                                    let temp = answers;
                                                    temp[ind] = event.target.value;
                                                    setAnswers(temp)
                                                    // }

                                                    content[ind]?.trueOption.toString() === event.target.value.toString() ? setPoints(content[ind]?.points) : setPoints(0)

                                                }}
                                            >
                                                <FormControlLabel value={1} control={<Radio
                                                    sx={{
                                                        color: '#616ded',
                                                        '&.Mui-checked': {
                                                            color: content[ind]?.trueOption === 1 && submit[ind] ? '#4bb543' : '#616ded',
                                                        },
                                                    }}

                                                    disabled={submit[ind] && content[ind]?.trueOption !== 1 && answers[ind] == "1"}
                                                />} label={<>
                                                    {content[ind]?.answer01}
                                                    {
                                                        content[ind]?.trueOption === 1 && submit[ind] && answers[ind] == "1" &&
                                                        <DoneIcon style={{ fontSize: '25px', color: "#4bb543" }} />
                                                    }
                                                    {
                                                        submit[ind] && answers[ind] === "1" && content[ind]?.trueOption !== 1 &&
                                                        <CloseIcon style={{ fontSize: '25px', color: "red" }} />
                                                    }
                                                </>} />
                                                <FormControlLabel value={2} control={<Radio
                                                    sx={{
                                                        color: '#616ded',
                                                        '&.Mui-checked': {
                                                            color: content[ind]?.trueOption === 2 && submit[ind] ? '#4bb543' : '#616ded',
                                                        },
                                                    }}
                                                    disabled={submit[ind] && content[ind]?.trueOption !== 2 && answers[ind] == "2"}
                                                />} label={
                                                    <>
                                                        {content[ind]?.answer02}
                                                        {
                                                            content[ind]?.trueOption === 2 && submit[ind] && answers[ind] == "2" &&
                                                            <DoneIcon style={{ fontSize: '25px', color: "#4bb543" }} />
                                                        }
                                                        {
                                                            submit[ind] && answers[ind] === "2" && content[ind]?.trueOption !== 2 &&
                                                            <CloseIcon style={{ fontSize: '25px', color: "red" }} />
                                                        }
                                                    </>
                                                } />
                                                {
                                                    (content[ind]?.answer03 !== "null" && content[ind]?.answer03.trim() !== "") &&
                                                    <FormControlLabel value={3} control={<Radio
                                                        sx={{
                                                            color: '#616ded',
                                                            '&.Mui-checked': {
                                                                color: content[ind]?.trueOption === 3 && submit[ind] ? '#4bb543' : '#616ded',
                                                            },
                                                        }}
                                                        disabled={submit[ind] && content[ind]?.trueOption !== 3 && answers[ind] == "3"}
                                                    />} label={
                                                        <>
                                                            {content[ind]?.answer03}
                                                            {
                                                                content[ind]?.trueOption === 3 && submit[ind] && answers[ind] == "3" &&
                                                                <DoneIcon style={{ fontSize: '25px', color: "#4bb543" }} />
                                                            }
                                                            {
                                                                submit[ind] && answers[ind] === "3" && content[ind]?.trueOption !== 3 &&
                                                                <CloseIcon style={{ fontSize: '25px', color: "red" }} />
                                                            }
                                                        </>
                                                    } />
                                                }
                                                {
                                                    (content[ind]?.answer04 !== "null" && content[ind]?.answer04.trim() !== "") &&
                                                    <FormControlLabel value={4} control={<Radio
                                                        sx={{
                                                            color: '#616ded',
                                                            '&.Mui-checked': {
                                                                color: content[ind]?.trueOption === 4 ? '#4bb543' : '#616ded',
                                                            },
                                                        }}
                                                        disabled={submit[ind] && content[ind]?.trueOption !== 4 && answers[ind] == "4"}
                                                    />} label={
                                                        <>
                                                            {content[ind]?.answer04}
                                                            {
                                                                content[ind]?.trueOption === 4 && submit[ind] && answers[ind] == "4" &&
                                                                <DoneIcon style={{ fontSize: '25px', color: "#4bb543" }} />
                                                            }
                                                            {
                                                                submit[ind] && answers[ind] === "4" && content[ind]?.trueOption !== 4 &&
                                                                <CloseIcon style={{ fontSize: '25px', color: "red" }} />
                                                            }
                                                        </>
                                                    } />
                                                }
                                            </RadioGroup>
                                        </FormControl>


                                        <button className="next"
                                            disabled={value === 0 && !submit[ind]}

                                            onClick={(e) => {
                                                e.preventDefault()
                                                log({
                                                    pageName: 'Quiz',
                                                    buttonName: 'Next'
                                                })

                                                if (submit[ind]) {
                                                    if (content[ind].trueOption != answers[ind]) {
                                                        return
                                                    }
                                                    else {
                                                        content[ind + 1] ? setInd(ind + 1) : <>{skillComplete ? navigate("/lesson?id=" + moduleID) : setIsComplete(true)}</>
                                                    }
                                                } else {

                                                    handleSubmit(e)
                                                }
                                            }}>
                                            <img src={nextSrc} onMouseEnter={() => { setNextSrc("../../assets/img/next.png") }} onMouseOut={() => { setNextSrc("../../assets/img/nextt.png") }} alt="" />
                                        </button>
                                    </form>
                                </div>
                                <div className="prev" onClick={() => {
                                    log({
                                        pageName: 'Quiz',
                                        buttonName: 'Prev'
                                    })
                                    let temp = skillSections?.filter(ss => {
                                        return (
                                            ss?.name === "SIMS")
                                    })
                                    if (ind === 0 && !temp.length) {
                                        window.history.go(-2)
                                        return
                                    }
                                    ind === 0 ? window.history.go(-1) : setInd(ind - 1)

                                }}>
                                    <img src={backSrc} onMouseEnter={() => { setBackSrc("../../assets/img/prev.png") }} onMouseOut={() => { setBackSrc("../../assets/img/backk.png") }} alt="" />
                                </div>
                            </>
                    }

                </div>
                <GoalsModal  />
                <TodoListModal  />
                <ChatBot data={{question:content[ind]?.question}} pageName={"Quiz"}/>
            </> : <>

                <SkillsBar page="Quiz" />
                <h2 className="mt-5 pt-5 text-center">No data to show!</h2>
            </>
    )
}

const journalButtonStyle = {
    position: 'fixed',
    width: 50,
    height: 50,
    padding: 7,
    right: 30,
    bottom: 210,
    background: '#fff',
    boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.2)',
    cursor: 'pointer',
    border: 'none',
    borderRadius: '50%'
  }