import React, { useEffect, useState } from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import LiveHelpIcon from "@mui/icons-material/LiveHelp";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import CardContent from "@mui/material/CardContent";
import { CardActionArea, Card, Autocomplete } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Avatar from "@mui/material/Avatar";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Tooltip from "@mui/material/Tooltip";
import { useSelector } from "react-redux";
import AttachmentIcon from "@mui/icons-material/Attachment";
import EditIcon from "@mui/icons-material/Edit";
import Stack from "@mui/material/Stack";
import AddIcon from "@mui/icons-material/Add";
import ShareIcon from "@mui/icons-material/Share";
import SaveIcon from "@mui/icons-material/Save";
import TimeTracker from "./time-tracker";
import {
  getJournals,
  getQuestions,
  addJournal,
  updateJournal,
  deleteJournal,
  shareJournal,
  getAdminList,
} from "../../_api/journal";
import { getUsers } from "../../_api/index";
import { useQuery, useMutation, useQueryClient } from "react-query";
import toast from "react-hot-toast";

import { createTheme } from "@mui/material/styles";
import QuizIcon from "@mui/icons-material/Quiz";
import Backdrop from "@mui/material/Backdrop";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import SkillAssignments from "../../common/SkillAssignments";
import WarningModal from "../../modals/WarningModal";
import { QuillEditor } from '../../common/quill-editor'

const theme = createTheme({
  palette: {
    primary: {
      main: "#616ded",
    },
  },
});
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  // boxShadow: 24,
  p: 4,
  borderRadius: 3,
};
const style1 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 270,
  bgcolor: "background.paper",
  // boxShadow: 24,
  p: 3,
  borderRadius: 5,
};
const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `0px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.7rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(0),
}));
const actions = [
  { icon: <LiveHelpIcon />, name: "Question", type: "question" },
  { icon: <TextSnippetIcon />, name: "Note", type: "notes" },
];
const drawerWidth = 240;
const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    position: "relative",
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,

    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    margin: "0px",
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));
export default function Notes() {
  const queryClient = useQueryClient();
  const theme = useTheme();
  const [open, setOpen] = useState(true);
  const [data, setData] = useState([]);
  // const [questions, setQuestions] = useState([])
  const [isAdd, setIsAdd] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [type, setType] = useState("");
  const [noteTitle, setNoteTitle] = useState("");
  const [noteContent, setNoteContent] = useState("");
  const [isTable, setIsTable] = useState(false);
  const [bread1, setBread1] = useState("");
  const [bread2, setBread2] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [teacher, setTeacher] = useState("");
  const [answer, setAnswer] = useState("");
  const [isAnswered, setIsAnswered] = useState(false);
  const [noteID, setNoteID] = useState("");
  const open1 = Boolean(anchorEl);
  const [teacherModal, setTeacherModal] = useState(false);
  const [opens, setOpens] = useState(false);
  const handleOpens = () => setOpens(true);
  const handleCloses = () => setOpens(false);
  const [isAddUser, setIsAddUser] = useState(false);
  const [opens11, setOpens11] = useState(false);
  const handleCloses11 = () => setOpens11(false);
  const [opens1, setOpens1] = useState(false);
  const handleOpens1 = () => setOpens1(true);
  const handleCloses1 = () => setOpens1(false);
  const [expanded, setExpanded] = useState("panel1");
  const [sharedWithMe, setSharedWithMe] = useState([]);
  const [sharedWith, setSharedWith] = useState([]);
  const [userID, setUserID] = useState("");
  const regName = /^[a-zA-Z\s-, ]+$/;
  const user = useSelector((state) => state?.user?.userData?.user);
  const [link, setLink] = useState("");
  const [isLink, setIsLink] = useState(false);
  const [activeLink, setActiveLink] = useState(false);
  const [teacherID, setTeacherID] = useState("");
  console.log("🚀 ~ Notes ~ teacherID:", teacherID)
  const [searchInput, setSearchInput] = useState('');
  const [selectedUser, setSelectedUser] = useState();
  const [openModal, setOpenModal] = useState(false);

  let isNorthwell = process.env.REACT_APP_API_LINK.includes("northwell")
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const { isLoading, data: journalData } = useQuery(
    "get-journals",
    getJournals,
    {
      onError: (err) => {
        toast.dismiss();
        toast.error("Something went wrong");
      },
    }
  );

  const { adminsLoading, data: admins } = useQuery("get-admins", getAdminList, {
    onError: (err) => {
      toast.error("Something went wrong");
    },
  });
  const {
    // mutateAsync: mutateUser,
    data: users,
  } = useQuery("get-users", getUsers);
  const { isLoading: addLoading, mutate: journalAdd } = useMutation(
    addJournal,
    {
      onSuccess: (res) => {
        if (res?.data) {
          setNoteID(res.data._id);
        }
        queryClient.invalidateQueries("get-journals");
        toast.dismiss();
        setOpens11(true);
        setTimeout(() => {
          setOpens11(false);
        }, 1000);
        // toast.success("Saved");
      },
      onError: (err) => {
        toast.dismiss();
        toast.error("Please fill out the title and content!");
        setIsAdd(true);
        setIsUpdate(false);
      },
    }
  );
  const { mutate: journalShare } = useMutation(shareJournal, {
    onSuccess: (res) => {
      queryClient.invalidateQueries("get-journals");
      toast.dismiss();
      setOpens1(false);
      setUserID("");
      queryClient.invalidateQueries("get-journals");
      toast.success("Shared");
      setIsAdd(false);
      setIsUpdate(false);
      setBread1("");
      setBread2("");
    },
    onError: (err) => {
      toast.dismiss();
      toast.error("Journal is already shared with selected user");
      setSelectedUser(null);
    },
  });
  const { isLoading: deleteLoading, mutate: journalDelete } = useMutation(
    deleteJournal,
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries("get-journals");
        toast.dismiss();
        toast.success("Deleted");
      },
      onError: (err) => {
        toast.dismiss();
        toast.error("Something went wrong");
      },
    }
  );
  const { isLoading: updateLoading, mutate: journalUpdate } = useMutation(
    updateJournal,
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries("get-journals");
        toast.dismiss();
        // toast.success("Saved");
        setOpens11(true);
        setTimeout(() => {
          setOpens11(false);
        }, 1000);
      },
      onError: (err) => {
        toast.dismiss();
        toast.error("Content can't be empty!");
      },
    }
  );

  useEffect(() => {
    if (isLoading) {
      toast.loading("Loading...");
    } else {
      toast.dismiss();
      setData(journalData?.data);
      setSharedWithMe(journalData?.sharedWithMe);
    }
  }, [isLoading, journalData]);
  useEffect(() => {
    if (addLoading) {
      toast.loading("Saving...");
    }
  }, [addLoading]);
  useEffect(() => {
    if (updateLoading) {
      toast.loading("Saving...");
    }
  }, [updateLoading]);
  useEffect(() => {
    if (deleteLoading) {
      toast.loading("Deleting...");
    }
  }, [deleteLoading]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleDelete = () => {
    let temp = {
      id: noteID,
    };
    journalDelete(temp);
    handleCloseModal();
    setAnchorEl(null);
    setNoteContent("");
    setNoteTitle("");
    setType("");
    setIsAdd(false);
    setIsUpdate(false);
    setBread1("");
    setBread2("");
  };
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const handleAddNote = (type) => {
    if (type === "question") {
      setTeacherModal(true);
    }
    setType(type);
    setIsAdd(true);
    setIsUpdate(false);
    setNoteContent("");
    setNoteTitle("");
    setIsTable(false);
    setTeacher("");
    setAnswer("");
    setBread1(type === "notes" ? "Notes" : "Questions");
    setBread2("");
    setOpens(false);
    setLink("");
  };
  const handleSaveNote = () => {
    if (isAdd && !isUpdate) {
      if (type === "question") {
        let temp = {
          type: type,
          question: noteContent,
          title: noteTitle,
          teacherId: teacherID,
          teacher: admins?.data?.filter((admin) => admin?._id === teacherID)[0]
            .username,
        };

        journalAdd(temp);
      } else {
        let temp = {
          type: type,
          content: noteContent,
          title: noteTitle,
          attachment: link,
        };
        journalAdd(temp);
      }
    }
    if (isUpdate) {
      let temp = {
        type: type,
        id: noteID,
        question: noteContent,
        title: noteTitle,
        // isAnswer: isAnwered,
        teacher: teacher,
        content: noteContent,
        answer: answer,
        attachment: link,
      };
      journalUpdate(temp);
    }
    setIsUpdate(true);
    setIsAdd(false);
    setBread2(noteTitle);
    setIsLink(false);
    setActiveLink(false);

    // setNoteContent("")
    // setNoteTitle("")
    // setType("")
    // setIsAdd(false)
    // setIsUpdate(false)
    // setBread1("")
    // setBread2("")
  };
  const filterData = (value) => {
    let tempVal = value.toLowerCase();
    let temp = journalData?.data?.filter((el) => {
      let item = el?.title.toLowerCase();
      let find = item?.search(tempVal);
      if (find === -1) {
        return false;
      } else {
        return true;
      }
    });
    let temp1 = journalData?.sharedWithMe?.filter((el) => {
      let item = el?.title.toLowerCase();
      let find = item?.search(tempVal);

      if (find === -1) {
        return false;
      } else {
        return true;
      }
    });
    setData(temp);
    setSharedWithMe(temp1);
  };
  return (
    <Grid
      theme={theme}
      style={{
        position: "relative",
        height: "100%",
        width: "100%",
        margin: "0px",
        padding: 0,
        overflow: "hidden",
        color: "#000",
      }}
    >
      <Dialog
        open={teacherModal}
        onClose={() => {
          setTeacherModal(false);
          // setTeacher("")
          setIsAdd(false);
          setIsUpdate(false);
          setIsTable(false);
        }}
      >
        <DialogContent>
          <DialogContentText>
            Please enter the person's name who will answer this question.
          </DialogContentText>
          {/* <TextField
            autoFocus
            id="name"
            label="Select Teacher Name"
            type="text"
            fullWidth
            select
            variant="standard"
            sx={{ marginTop: "20px", background: "transparent" }}
            className="text-primary1"
            value={teacherID}
            onChange={(e) => {
              setTeacherID(e.target.value);
            }}
          >
            {admins?.data?.map((admin) => {
              return (
                <MenuItem
                  sx={{ marginTop: "20px", background: "transparent" }}
                  k
                  ey={admin?._id}
                  value={admin?._id}
                >
                  {admin?.username}
                </MenuItem>
              );
            })}
          </TextField> */}

          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={admins?.data?.filter(
              admin =>
                admin.username.toLowerCase().includes(searchInput.toLowerCase())
            )}
            getOptionLabel={(option) => option.username} // To display the usernames in the options
            sx={{ marginTop: 1 }}
            onChange={(event, newValue) => {
              if (newValue) {
                setTeacherID(newValue._id);
              } else {
                setTeacherID(""); // If cleared, set to null
              }
            }}
            renderInput={(params) => (
              <TextField
                fullWidth
                {...params}
                label={isNorthwell?"Teacher / Mentor Name":"Teacher Name"}
                onChange={(e) => setSearchInput(e.target.value)}
              />
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button
            className="text-secondary"
            onClick={() => {
              setTeacherModal(false);
              setTeacher("");
              setIsAdd(false);
              setIsUpdate(false);
              setIsTable(false);
            }}
          >
            Cancel
          </Button>
          <Button
            className="text-primary1"
            disabled={!teacherID}
            onClick={() => setTeacherModal(false)}
          >
            Okay
          </Button>
        </DialogActions>
      </Dialog>

      <Box
        sx={{
          display: "flex",
          overflowY: "auto",
          padding: 0,
          position: "relative",
        }}
      >
        <CssBaseline />
        <AppBar className="app-bar" position="absolute" open={open}>
          <Toolbar sx={{ width: "fit-content" }}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{ mr: 2, ...(open && { display: "none" }) }}
            >
              <KeyboardDoubleArrowRightIcon />
            </IconButton>
            <Breadcrumbs aria-label="breadcrumb">
              <Typography>Home</Typography>
              {bread1 !== "" && <Typography>{bread1} </Typography>}
              {bread2 !== "" && (
                <Typography color="text.primary">{bread2}</Typography>
              )}
            </Breadcrumbs>
          </Toolbar>
          <div className="journal-buttons">
            <SkillAssignments type="journal" />
            <Tooltip title="New">
              <button className="menu" onClick={handleOpens}>
                <AddIcon />
              </button>
            </Tooltip>

            {(isUpdate || isAdd) && (
              <>
                {isAdd && !!noteTitle && (
                  <Tooltip title="Save">
                    <button className="menu" onClick={handleSaveNote}>
                      <SaveIcon />
                    </button>
                  </Tooltip>
                )}

                {isUpdate && (
                  <>
                    {/* <button className="save">Share</button> */}
                    <Tooltip title="Share">
                      <button className="menu" onClick={handleOpens1}>
                        <ShareIcon />
                      </button>
                    </Tooltip>
                    <button className="menu" onClick={handleClick}>
                      <MoreHorizIcon />
                    </button>
                  </>
                )}
              </>
            )}
          </div>
        </AppBar>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open1}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          <MenuItem disabled onClick={handleClose}>
            Duplicate
          </MenuItem>
          <MenuItem
            onClick={() => {
              setOpenModal(true);
            }}
          >
            Delete
          </MenuItem>
        </Menu>
        <WarningModal
          content={`Are you sure you want to delete this ${bread1 === "Notes" ? "Note?" : "Question?"
            }`}
          handleDelete={handleDelete}
          openModal={openModal}
          handleClose={handleCloseModal}
        />

        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: drawerWidth,
              boxSizing: "border-box",
              position: "relative",
              margin: 0,
              height: "76vh",
              boxShadow: "1px 0px 10px rgba(0, 0, 0, 0.1)",
              borderRadius: "20px",
              overflowY: "auto",
            },
            padding: "0px",
            position: "relative",
            height: "100%",
            overflowY: "auto",
          }}
          className="side_bar_jour"
          variant="persistent"
          anchor="left"
          open={open}
        >
          <DrawerHeader
            sx={{ margin: 0, display: "flex", justifyContent: "space-between" }}
            style={{ maxHeight: "30px" }}
          >
            <Typography variant="h6">Journal</Typography>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === "ltr" ? (
                <KeyboardDoubleArrowLeftIcon />
              ) : (
                <ChevronRightIcon />
              )}
            </IconButton>
          </DrawerHeader>
          <input
            type="text"
            className="journal-search"
            placeholder="Search here quickly"
            onChange={(e) => filterData(e.target.value)}
          />

          <Accordion
            expanded={expanded === "panel4"}
            onChange={handleChange("panel4")}
            className="accordion_journal"
          >
            <AccordionSummary
              aria-controls="panel1d-content"
              id="panel1d-header"
            >
              <h2 className="left-headings">Notes</h2>
            </AccordionSummary>
            <AccordionDetails>
              <List className="side-list">
                {data?.map((text, index) => {
                  return (
                    text?.type === "notes" && (
                      <ListItem
                        className="journal-list-item"
                        key={text?.id}
                        disablePadding
                      >
                        <ListItemButton
                          onClick={() => {
                            setBread1("Notes");
                            setBread2(text?.title || "Untitled");
                            setNoteTitle(text?.title || "");
                            setNoteContent(text?.content || "");
                            setType(text?.type);
                            setIsUpdate(true);
                            setIsTable(false);
                            setNoteID(text?._id);
                            setIsAdd(false);
                            setTeacher("");
                            setSharedWith(text?.sharedWith);
                            setLink(text?.attachment || "");
                            setSelectedUser(null);
                          }}
                        >
                          <ListItemIcon className="list-icon">
                            <TextSnippetIcon style={{ color: "#fb785c" }} />
                          </ListItemIcon>
                          <ListItemText
                            fontSize={10}
                            primary={
                              <p
                                style={{
                                  margin: 0,
                                  padding: 0,
                                  fontSize: "12px",
                                }}
                              >
                                {text?.title || "Untitled"}
                              </p>
                            }
                          />
                        </ListItemButton>
                      </ListItem>
                    )
                  );
                })}
                {!data?.filter((x) => x.type === "notes").length && (
                  <ListItem className="journal-list-item" disablePadding>
                    <ListItemButton>
                      {/* <ListItemIcon className="list-icon">
                                        <TextSnippetIcon />
                                    </ListItemIcon> */}
                      <ListItemText
                        fontSize={10}
                        primary={
                          <p
                            style={{ margin: 0, padding: 0, fontSize: "12px" }}
                          >
                            No Result Found
                          </p>
                        }
                      />
                    </ListItemButton>
                  </ListItem>
                )}
              </List>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel2"}
            onChange={handleChange("panel2")}
            className="accordion_journal"
          >
            <AccordionSummary
              aria-controls="panel2d-content"
              id="panel2d-header"
            >
              <h2 className="left-headings">
                Question
                <Tooltip title="Questions Table">
                  <img
                    style={{ cursor: "pointer" }}
                    width="30px"
                    src="assets/img/question.png"
                    alt=""
                    className="img_accordiion"
                    onClick={() => {
                      setIsTable(!isTable);
                      setBread1(!isTable ? "Questions" : "");
                      setBread2("");
                      setIsAdd(false);
                      setIsUpdate(false);
                    }}
                  />
                </Tooltip>
              </h2>
            </AccordionSummary>
            <AccordionDetails>
              <List className="side-list">
                {data?.map((text, index) => {
                  return (
                    text?.type === "question" && (
                      <ListItem
                        className="journal-list-item"
                        key={text?._id}
                        disablePadding
                      >
                        <ListItemButton
                          onClick={() => {
                            setBread1("Questions");
                            setBread2(text?.title || "Untitled");
                            setNoteTitle(text?.title || "");
                            setNoteContent(text?.question || "");
                            setTeacher(text?.teacher);
                            setAnswer(text?.answer || "");
                            setType(text?.type);
                            setIsAnswered(text?.isAnswered);
                            setNoteID(text?._id);
                            setIsUpdate(true);
                            setIsTable(false);
                            setIsAdd(false);
                            setSharedWith(text?.sharedWith);
                            setSelectedUser(null);
                          }}
                        >
                          <ListItemIcon className="list-icon">
                            <QuizIcon style={{ color: "#fb785c" }} />
                          </ListItemIcon>
                          <ListItemText
                            fontSize={10}
                            primary={
                              <p
                                style={{
                                  margin: 0,
                                  padding: 0,
                                  fontSize: "12px",
                                }}
                              >
                                {text?.title || "Untitled"}
                              </p>
                            }
                          />
                        </ListItemButton>
                      </ListItem>
                    )
                  );
                })}
                {!data?.filter((x) => x.type === "question").length && (
                  <ListItem className="journal-list-item" disablePadding>
                    <ListItemButton>
                      {/* <ListItemIcon className="list-icon">
                                        <TextSnippetIcon />
                                    </ListItemIcon> */}
                      <ListItemText
                        fontSize={10}
                        primary={
                          <p
                            style={{ margin: 0, padding: 0, fontSize: "12px" }}
                          >
                            No Result Found
                          </p>
                        }
                      />
                    </ListItemButton>
                  </ListItem>
                )}
              </List>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel3"}
            onChange={handleChange("panel3")}
            className="accordion_journal"
          >
            <AccordionSummary
              aria-controls="panel3d-content"
              id="panel3d-header"
            >
              <h2 className="left-headings">Shared with me</h2>
            </AccordionSummary>
            <AccordionDetails>
              <List className="side-list">
                {sharedWithMe?.map((text, index) => {
                  return (
                    <ListItem
                      className="journal-list-item"
                      key={text?.id}
                      disablePadding
                    >
                      <ListItemButton
                        onClick={() => {
                          setBread1("Notes");
                          setBread2(text?.title || "Untitled");
                          setNoteTitle(text?.title || "");
                          setNoteContent(text?.content || "");
                          setType(text?.type);
                          setIsUpdate(true);
                          setIsTable(false);
                          // setNoteID(text?._id);
                          // setIsAdd(false);
                          setTeacher("");
                          setSelectedUser(null);
                        }}
                      >
                        <ListItemIcon className="list-icon">
                          <TextSnippetIcon style={{ color: "#fb785c" }} />
                        </ListItemIcon>
                        <ListItemText
                          fontSize={10}
                          primary={
                            <p
                              style={{
                                margin: 0,
                                padding: 0,
                                fontSize: "12px",
                              }}
                            >
                              {text?.title || "Untitled"}
                            </p>
                          }
                        />
                      </ListItemButton>
                    </ListItem>
                  );
                })}
                {!sharedWithMe?.length && (
                  <ListItem className="journal-list-item" disablePadding>
                    <ListItemButton>
                      {/* <ListItemIcon className="list-icon">
                                        <TextSnippetIcon />
                                    </ListItemIcon> */}
                      <ListItemText
                        fontSize={10}
                        primary={
                          <p
                            style={{ margin: 0, padding: 0, fontSize: "12px" }}
                          >
                            No Result Found
                          </p>
                        }
                      />
                    </ListItemButton>
                  </ListItem>
                )}
              </List>
            </AccordionDetails>
          </Accordion>
          <TimeTracker />
        </Drawer>
        <Main open={open} className="main-journal">
          <DrawerHeader />
          {(isAdd || isUpdate) && !isTable ? (
            <div className="main-area">
              <input
                type="text"
                className="journal-title"
                placeholder={
                  type === "notes"
                    ? "Enter Title of Note"
                    : "Enter Title of Question"
                }
                onChange={(e) => {
                  if (isUpdate) {
                    setIsAdd(true);
                  }
                  setNoteTitle(e.target.value);
                }}
                value={noteTitle}
              />
              <QuillEditor
                style={{ height: type === "notes" ? 300 : 200 }}
                className="journal-text"
                placeholder="Click here to start typing"
                onChange={(e) => {
                  if (isUpdate) {
                    setIsAdd(true);
                  }
                  setNoteContent(e);
                }}
                value={noteContent}
              />

              {type === "notes" && (
                <Stack direction="row" alignItems="center" spacing={2}>
                  {(!link || activeLink) && (
                    <>
                      {isLink ? (
                        <IconButton
                          aria-label="attachment"
                          component="label"
                          className="ms-3"
                          style={{ width: "42px" }}
                          onClick={() => setIsLink(false)}
                        >
                          <AttachmentIcon color="primary" />
                        </IconButton>
                      ) : (
                        <div className="journal-buttons">
                          <Tooltip title="Attach Link">
                            <button
                              className="menu"
                              onClick={() => setIsLink(true)}
                            >
                              <AttachmentIcon color="primary" />
                            </button>
                          </Tooltip>
                        </div>
                      )}
                    </>
                  )}

                  {isLink ? (
                    <input
                      className="journal-text p-0"
                      value={link}
                      style={{ width: "80%", borderBottom: "1px solid #ccc" }}
                      onChange={(e) => {
                        setActiveLink(true);
                        setLink(e.target.value);
                        if (isUpdate) {
                          setIsAdd(true);
                        }
                      }}
                      placeholder="Place your attachment link"
                    />
                  ) : (
                    <>
                      <a
                        style={{ width: "80%", overflow: "hidden" }}
                        href={link}
                        target="_blank"
                      >
                        {link}
                      </a>
                      {!!link && (
                        <IconButton
                          aria-label="attachment"
                          component="label"
                          className="ms-3"
                          style={{ width: "42px" }}
                          onClick={() => {
                            setIsLink(true);
                            setActiveLink(true);
                          }}
                        >
                          <EditIcon />
                        </IconButton>
                      )}
                    </>
                  )}
                </Stack>
              )}

              {type === "question" && !!answer && (
                <>
                  <span>
                    Answered by: <b>{teacher}</b>
                  </span>
                  <textarea
                    rows={5}
                    className="journal-text"
                    width="100%"
                    placeholder="Answer"
                    disabled={true}
                    // onChange={(e) => {
                    //   setIsAdd(true);
                    //   setAnswer(e.target.value);
                    // }}
                    value={answer}
                  />
                </>
              )}
            </div>
          ) : isTable ? (
            <TableContainer component={Paper} sx={{ overflow: "auto" }}>
              <Table sx={{ gap: 3 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center" className="bg-primary1 table-th">
                      Question
                    </TableCell>
                    <TableCell
                      align="center"
                      className="bg-secondary1 table-th"
                    >
                      Person to Ask
                    </TableCell>
                    <TableCell align="center" className="bg-success table-th">
                      Answered
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data?.length === 0 && (
                    <TableRow>
                      <TableCell
                        align="center"
                        colspan="3"
                        className="table-td"
                      >
                        Question will display here, not added yet
                      </TableCell>
                    </TableRow>
                  )}
                  {data?.map((que) => {
                    return (
                      que?.type === "question" && (
                        <TableRow
                          key={que.name}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell
                            onClick={() => {
                              setIsTable(false);
                              setIsUpdate(true);
                              setNoteTitle(que?.title);
                              setNoteContent(que?.question);
                              setAnswer(que?.answer || "");
                              setNoteID(que._id);
                              setIsAdd(false);
                              setType(que.type);
                              setBread2(que.title);
                            }}
                            align="left"
                            className="table-td"
                          >
                            {que?.title}
                          </TableCell>
                          <TableCell align="center" className="table-td">
                            {que.teacher}
                          </TableCell>
                          <TableCell align="center" className="table-td">
                            {que.answer ? (
                              <img
                                width="30px"
                                src="assets/img/check.svg"
                                alt=""
                              />
                            ) : (
                              <img
                                width="30px"
                                src="assets/img/cross.svg"
                                alt=""
                              />
                            )}
                          </TableCell>
                        </TableRow>
                      )
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <div
              className="main-area d-flex justify-content-center align-items-center"
              style={{ height: "80%" }}
            >
              <img
                className="btn-add-journal"
                onClick={handleOpens}
                width="30%"
                src="assets/img/blank-screen.svg"
                alt=""
              />
            </div>
          )}
        </Main>
      </Box>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={opens}
        onClose={handleCloses}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={opens}>
          <Box sx={style}>
            <div className="row">
              <div className="col-md-6 col-6">
                <Card
                  style={{ marginTop: "0px" }}
                  className="p-0"
                  onClick={() => handleAddNote("notes")}
                >
                  <CardActionArea>
                    <CardContent className="p-0">
                      <div
                        className="text-center"
                        style={{ height: "133px", background: "#fb785c" }}
                      >
                        <img
                          src="./assets/icons/note1.svg"
                          alt=""
                          className="w-50 img_not_quest"
                        />
                      </div>
                      <Typography
                        gutterBottom
                        variant="p"
                        component="div"
                        className="text-center py-3"
                        style={{ fontSize: "14px" }}
                      >
                        Create a note
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </div>
              <div className="col-md-6 col-6">
                <Card
                  style={{ marginTop: "0px" }}
                  className="p-0"
                  onClick={() => handleAddNote("question")}
                >
                  <CardActionArea>
                    <CardContent className="p-0">
                      <div
                        className="text-center"
                        style={{ height: "133px", background: " #4c6ed7" }}
                      >
                        <img
                          src="/assets/icons/question1.svg"
                          alt=""
                          className="w-50 img_not_quest"
                        />
                      </div>
                      <Typography
                        gutterBottom
                        variant="p"
                        component="div"
                        className="text-center py-3"
                        style={{ fontSize: "14px" }}
                      >
                        Create a Question
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>
      {/* save modal */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={opens11}
        onClose={handleCloses11}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={opens11}>
          <Box sx={style1}>
            <div className="text-center">
              <h2 className="fw-bold">Saved</h2>
              <CheckCircleIcon className="check_save" />
            </div>
          </Box>
        </Fade>
      </Modal>
      {/* share modal */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={opens1}
        onClose={handleCloses1}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={opens1}>
          <Box
            sx={style}
            style={{
              padding: "27px",
              position: "relative",
              height: "64%",
              width: "350px",
            }}
          >
            <h6>Share "{noteTitle}"</h6>
            <Button
              variant="contained"
              className="w-100 mt-2 text-capitalize"
              onClick={() => setIsAddUser(!isAddUser)}
            >
              Add People
            </Button>
            <List
              sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
              className="list_ul"
            >
              <span>Shared with</span>
              {!!sharedWith?.length ? (
                sharedWith?.map((shar) => {
                  return (
                    <ListItem ListItem alignItems="flex-start">
                      <ListItemAvatar>
                        <Avatar alt="Remy Sharp" src={shar?.photo} />
                      </ListItemAvatar>
                      <ListItemText
                        primary={
                          !!shar?.firstName ? shar?.firstName : "No Name"
                        }
                        secondary={<>{shar?.email}</>}
                      />
                    </ListItem>
                  );
                })
              ) : (
                <ListItem alignItems="flex-start">
                  <ListItemText primary="Not shared to anyone!" />
                </ListItem>
              )}
              {selectedUser && (
                <>
                  <span>Selected User</span>
                  <ListItem alignItems="flex-start">
                    <ListItemAvatar>
                      <Avatar alt="Remy Sharp" src={selectedUser?.photo} />
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        !!selectedUser?.firstName
                          ? selectedUser?.firstName
                          : "No Name"
                      }
                      secondary={<>{selectedUser?.email}</>}
                    />
                  </ListItem>
                </>
              )}
            </List>

            {isAddUser && (
              <div
                className="user_add"
                style={{ top: "112px", height: 300, overflowY: "auto" }}
              >
                <List
                  sx={{
                    width: "100%",
                    maxWidth: 360,
                    bgcolor: "background.paper",
                  }}
                  className="list_ul"
                >
                  {!!users?.data?.length ? (
                    users?.data?.map((usr) => {
                      if (user?._id !== usr?._id)
                        return (
                          <ListItem
                            ListItem
                            alignItems="flex-start"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setUserID(usr._id);
                              setSelectedUser(usr);
                              setIsAddUser(false);
                            }}
                          >
                            <ListItemAvatar>
                              <Avatar alt="Remy Sharp" src={usr?.photo} />
                            </ListItemAvatar>
                            <ListItemText
                              primary={
                                !!usr?.firstName ? usr?.firstName : "No Name"
                              }
                              secondary={<>{usr.email}</>}
                            />
                          </ListItem>
                        );
                    })
                  ) : (
                    <ListItem alignItems="flex-start">
                      <ListItemText primary="No People to Add" />
                    </ListItem>
                  )}
                </List>
              </div>
            )}

            <Button
              variant="contained"
              className="done_btn"
              onClick={() => {
                let temp = {
                  userID: userID,
                  journalID: noteID,
                };
                if (!!userID) {
                  journalShare(temp);
                  toast.loading("Sharing");
                }
              }}
            >
              Done
            </Button>
          </Box>
        </Fade>
      </Modal>
    </Grid>
  );
}
