import React, { forwardRef, useState } from 'react';
import { useNavigate } from "react-router-dom";
import {
    Button,
    Dialog,
    Divider,
    AppBar,
    Toolbar,
    IconButton,
    Typography,
    Slide,
    Box,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import isAuthenticated from "../../../auth/auth";

import MainPage from './MainPage'
import TrackTime from './TrackTime';
import TaskLog from './TaskLog';
import TrackList from './TrackList'
import LogList from './LogList'
import TodoList from './TodoList'
import SkillAssignments from '../../../common/SkillAssignments';

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function TimeTracker() {
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [page, setPage] = useState("main")
    const [pageTitle, setPageTitle] = useState("")

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handlePage = () => {
        switch (page) {
            case 'main':
                return <MainPage
                    setPageTitle={setPageTitle}
                    setPage={setPage}
                    page={page}
                />
            case 'trackTime':
                return <TrackTime
                    setPageTitle={setPageTitle}
                    setPage={setPage}
                    page={page}
                />

            case 'taskLog':
                return <TaskLog
                    setPageTitle={setPageTitle}
                    setPage={setPage}
                    page={page}
                />

            case 'trackList':
                return <TrackList
                    setPageTitle={setPageTitle}
                    setPage={setPage}
                    page={page}
                />

            case 'logList':
                return <LogList
                    setPageTitle={setPageTitle}
                    setPage={setPage}
                    page={page}
                />


            case 'todoList':
                return <TodoList
                    setPageTitle={setPageTitle}
                    setPage={setPage}
                    page={page}
                />
        }
    }
    if (!isAuthenticated(localStorage.getItem("token"))) {
        navigate("/login")
    }

    return (

        <div>
            <Box >
                <Button variant="contained" sx={{ position: 'absolute', bottom: 20, left: 25, background: '#fff', color: '#000', textTransform: 'capitalize', px: 7 }} onClick={handleClickOpen}>
                    Track Time
                </Button>
            </Box>

            <Dialog
                fullScreen
                open={open}
                TransitionComponent={Transition}
                sx={{ overflow: 'auto' }}
            >
                <AppBar sx={{ position: 'sticky', top: 0, mt: 0, background: '#616ded', borderRadius: 0 }}>
                    <Toolbar>
                        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                            {
                                page !== "main" ?
                                    <>
                                        <Button startIcon={<ArrowBackIosIcon />} color="inherit" onClick={() => setPage('main')}>
                                            Back
                                        </Button>
                                        <Typography variant="h6" component="div">
                                            {pageTitle}
                                        </Typography>
                                    </> : <div></div>}
                            <Box>
                                <SkillAssignments type="time-management" />
                                {
                                    page === 'main' ?

                                        <IconButton
                                            edge="end"
                                            color="inherit"
                                            onClick={handleClose}
                                            aria-label="close"
                                        >
                                            <CloseIcon />
                                        </IconButton>
                                        : <div></div>
                                }
                            </Box>
                        </Box>
                    </Toolbar>
                </AppBar>
                {handlePage()}
            </Dialog>
        </div>
    );
}