import React, { useState, useEffect } from 'react'
import TurnPhoneH from '../other/TurnPhoneH'
import { useParams } from 'react-router-dom'
import CircularProgress from '@mui/material/CircularProgress';
import { useMutation, useQueryClient } from 'react-query';
import { emailVerification, resendCode, userLog } from '../_api/index'
import { useTranslation } from 'react-i18next'

import Header from '../common/Header'
import TermsAndConditions from '../common/TermsAndConditions'
export default function EmailVerification() {

    const { t } = useTranslation();
    const { mutate: log } = useMutation(userLog);
    const { id: email } = useParams();
    const [termCond, setTermCond] = useState(false);
    const queryClient = useQueryClient();
    const [serverError, setServerError] = useState(false)
    const [token, setToken] = useState("")
    const [message, setMessage] = useState("")
    const [resend, setResend] = useState(false)

    const { isLoading: isLoading, isSuccess: isSuccess, mutate: emailVar } = useMutation(emailVerification, {
        onSuccess: (response) => {
            queryClient.invalidateQueries('request-code');
            setMessage("Verification done Sucessfully!")
            setServerError(false)
            setTermCond(true)
        },
        onError: (error) => {
            setServerError(true)
            if (error.message === "Request failed with status code 404") {
                setMessage("Invalid confirmation token!")
            } else {
                setMessage("Internal Error, Sorry for inconvenience, Please try again!")
            }
        }
    });

    const { isLoading: resendLoading, isSuccess: resendSuccess, mutate: resendData } = useMutation(resendCode, {
        onSuccess: (response) => {
            queryClient.invalidateQueries('request-code');
            setMessage("Confirmation code is sent to provided email, please chek your email and enter the code")
            setServerError(false)
            setResend(false)
        },
        onError: (error) => {
            setServerError(true)
            if (error.message === "Request failed with status code 404") {
                setMessage("User Not Found")
            } else {
                setMessage("Internal Error, Sorry for inconvenience, Please try again!")
            }
        }
    });


    const handleSubmit = (e) => {
        e.preventDefault();
        let data = {
            confirmationToken: token,
            email: email,
        }
        emailVar(data)
    }
    const handleResend = (e) => {
        e.preventDefault();
        let data = {
            email: email,
        }
        resendData(data)
    }

    const [isMobile, setIsMobile] = useState(false);
    function handleOrientation() {
        setIsMobile(window.innerHeight < window.innerWidth && window.innerWidth < 1000)
    }
    window.onresize = handleOrientation;
    useEffect(() => {
        setIsMobile(window.innerHeight < window.innerWidth && window.innerWidth < 1000)
    }, [window.innerWidth, window.innerHeight, window.orientation]);
    if (isMobile) {
        return (<TurnPhoneH content="Turn your phone to side to verify your code and email" />)
    } else
        return (
            <>
                <Header backLink="/get-started" page="Email Verification" />
                <div className="container enter-code-container">

                    <div className="container-inner">
                        <form onSubmit={(e) => handleSubmit(e)}>
                            <div className="enter-code-wrapper">
                                <div className="enter-code">
                                    <h1>{t("emailVerification.label")}</h1>
                                    <input type="text" onChange={(e) => setToken(e.target.value)} required placeholder={t("emailVerification.placeholder")} />
                                </div>
                            </div>
                            {
                                serverError &&
                                <p className="text-danger error-text mt-3">{message}</p>
                            }
                            {
                                isSuccess || resendSuccess && !serverError ?
                                    <p className="text-danger error-text mt-3">{message}</p>
                                    : <></>
                            }
                            <button
                                type="submit"
                                className="btn submit-btn"
                                onClick={() => log(
                                    {
                                        pageName: 'Email Verification',
                                        buttonName: 'Next'
                                    })}
                            >
                                {
                                    isLoading ?
                                        <CircularProgress style={{ color: 'white' }} />
                                        : <>{t("emailVerification.btnNext")}</>
                                }
                            </button>
                            <button
                                type="button"
                                className="submit-btn-2"
                                onClick={(e) => {
                                    handleResend(e)
                                    log(
                                        {
                                            pageName: 'Email Verification',
                                            buttonName: 'Resend Code'
                                        })
                                }}
                            >
                                {
                                    resendLoading ?
                                        <CircularProgress />
                                        : <> {t("emailVerification.btnResend")}</>
                                }
                            </button>
                        </form>
                        {
                            resend &&

                            <div className="form-container">
                                <div className="form-container-inner" style={{ borderRadius: 'none', boxShadow: 'none', padding: '0px', }}>
                                </div>
                            </div>
                        }

                        <TermsAndConditions termCond={termCond} setTermCond={setTermCond} navigateLinke="/login" />
                    </div>
                </div>
            </>
        )
}
