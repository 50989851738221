import React, { useState, useEffect, useRef } from 'react'
import TurnPhoneH from '../other/TurnPhoneH'
import { Link, useNavigate } from 'react-router-dom'
import { login, userLog } from '../_api/index'
import isAuthenticated from '../auth/auth'
import { useTranslation } from 'react-i18next'
import uuid from 'react-uuid'
import { Grid, Button, Typography, Link as HLink } from "@mui/material"
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import { useSelector } from 'react-redux'
import Loading from '../common/Loading'

import { useDispatch } from 'react-redux'
import { setRegister, emptyStore } from '../../redux-features/user/userSlice'

import { useMutation, useQueryClient } from 'react-query';
import Notifications from '../notifications/index'

import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import Swal from 'sweetalert2'
import Tooltip from '@mui/material/Tooltip';
import { ChatBotBody } from "../common/chatbot"
import EveritModulePage from "./EveritModulePage"
import { isEverit, isNorthwell } from '../common/check-org'

const Modules = () => {
    const NOUNCE = uuid();
    const { t } = useTranslation();

    const dispatch = useDispatch()

    const userData = useSelector((state) => state?.user?.userData?.data)
    const user = useSelector((state) => state?.user?.userData?.user)
    console.log("User Data: ", user);
    const loading = useSelector(state => state?.user?.loading)
    const [nextSkillID, setNextSkillID] = useState()
    const [nextSkillName, setNextSkillName] = useState()

    const [openChatbotModal, setChatbotModal] = useState(false)

    window.name = "Success"
    useEffect(() => {
        localStorage.setItem("fetch-data", false)
        dispatch(setRegister(false))
        userData?.map((module) => {
            module?.lessons?.map((lesson) => {
                lesson?.skills?.map((skill) => {
                    if (skill?.isCompleted == false) {
                        setNextSkillID(skill?._id)
                        setNextSkillName(skill?.name)
                    }
                })
            })
        })

    }, [userData])
    const { mutate: log } = useMutation(userLog);
    const navigate = useNavigate()
    const queryClient = useQueryClient();
    const [message, setMessage] = useState("")
    const [isMobile, setIsMobile] = useState(false);
    function handleOrientation() {
        setIsMobile(window.innerWidth < 1000)
    }
    window.onresize = handleOrientation;
    useEffect(() => {
        setIsMobile(window.innerWidth < 1000)
    }, [window.innerWidth, window.innerHeight, window.orientation]);


    useEffect(() => {
        localStorage.setItem("NOUNCE", NOUNCE);
    }, [NOUNCE]);

    useEffect(() => {
        if (!isAuthenticated(localStorage.getItem("token"))) {
            navigate("/login")
        }
    }, [])

    if (!userData?.length) {
        return (<>
            {!isAuthenticated(localStorage.getItem("token")) && navigate("/login")}
            {loading && <Loading />}
        </>)
    }
    if (isMobile) return (<TurnPhoneH content="Turn your phone to side to view the App" />)
    if (isEverit(user?.OrganizationId) || isNorthwell(user?.OrganizationId)) return <EveritModulePage />
    return <>
        <>
            <div className="modulesredesign">
                <Grid container>
                    <Grid container item sm={12} p={2}>

                        <Grid sm={4}>

                        </Grid>

                        <Grid sm={4} container justifyContent="center">
                            <img width="150px" height="50px" src="/assets/svg/logo-white.gif" alt="" />
                        </Grid>

                        <Grid container sm={4} justifyContent="flex-end">
                            <Stack direction="row" alignItems="center" gap={2}>
                                <Link to={"/profile"}
                                    onClick={(e) => {
                                        if (sessionStorage.getItem("access") === "ok") {
                                            e.preventDefault();
                                            Swal.fire({
                                                title: '',
                                                text: "It's a teacher view, so there is no profile to show!",
                                                icon: 'info',
                                                confirmButtonText: 'OK'
                                            })
                                        }
                                    }}
                                >
                                    <Tooltip title="Profile" arrow>
                                        <Avatar sx={{ width: 50, height: 50 }}>
                                            {user?.photo && <img src={user?.photo} style={{ width: "100%" }} />}
                                        </Avatar>
                                    </Tooltip>
                                </Link>
                                <Link to="/planner"
                                    onClick={(e) => {
                                        if (sessionStorage.getItem("access") === "ok") {
                                            e.preventDefault();
                                            Swal.fire({
                                                title: '',
                                                text: "It's a teacher view, so there is no planner to show!",
                                                icon: 'info',
                                                confirmButtonText: 'OK'
                                            })
                                        }
                                    }}
                                >
                                    <Button
                                        id="basic-button"
                                    >
                                        <div className="logged hover-logged">
                                            <CalendarMonthIcon style={{ width: '40px', height: '40px', marginTop: -5, color: 'inherit' }} />
                                        </div>
                                    </Button>
                                </Link>
                                <div className="logoutIcon" onClick={() => {
                                    if (sessionStorage.getItem("access") === "ok") {
                                        Swal.fire({
                                            title: '',
                                            text: "It's a teacher view, so you can't logout!",
                                            icon: 'info',
                                            confirmButtonText: 'OK'
                                        })
                                        return
                                    }
                                    dispatch(emptyStore())
                                    localStorage.removeItem("token")
                                    log({
                                        pageName: 'Module',
                                        buttonName: 'Logout Button'
                                    })
                                    navigate("/get-started")
                                }}>
                                    <div className="logged" style={{ background: "white" }}>
                                        <div class="block">
                                            <span className="animatedIcony">
                                                <div class="div1" >
                                                    <img src="/assets/img/logout1.png" />
                                                </div>
                                                <div class="rect">
                                                    <img src="/assets/img/logout.png" />
                                                </div>
                                                <div class="div2">
                                                    <img src="/assets/img/logout1.png" />
                                                </div>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </Stack>
                            <Notifications />
                        </Grid>
                    </Grid>
                    <Grid container item sm={12} gap={2} sx={{ flexWrap: "wrap", justifyContent: "flex-start", gap: "1rem" }} className="moduleredesign__content">
                        {userData !== undefined &&
                            userData?.map((item, index) => (
                                <Grid item xs={11} sm={6} md={3.5} mt={3} mb={3} container sx={{ padding: "3vw", borderRadius: "2rem", "&:hover": { background: item?.isEnrolled ? "#5048E5" : "" } }}>
                                    <Grid item container justifyContent="space-between" xs={12}>
                                        <Grid container wrap="nowrap" className="modulesredesign__viewall" xs={12} style={{ cursor: item?.isEnrolled ? "pointer" : "text" }} mb={2} gap="1rem">
                                            <Grid className="modulesredesign__moduleName" xs={3}>
                                                <img style={{ maxWidth: "50px" }} src={item?.icon?.svg ? item?.icon?.svg : 'assets/img/v1.png'} />
                                            </Grid>
                                            <Grid item sx={{ width: "100%" }}>
                                                {console.log("item?.name", item?.name)}
                                                <Typography sx={{ fontWeight: 700, color: 'white', fontSize: { xs: "20px", md: "25px" }, gap: "1rem" }} vriant="h4">
                                                    {item?.name}
                                                </Typography>
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <Link to={item?.isEnrolled ? `/lesson?id=${item?._id}` : "/module"} style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", cursor: item?.isEnrolled ? "pointer" : "text" }}>
                                                <Button style={{ cursor: item?.isEnrolled ? "pointer" : "text" }} disabled={!item?.isEnrolled} sx={{ background: "#FB785C", borderRadius: "0.6rem", color: "white", width: "100%", margin: "0.25rem 0", "&:hover": { background: item?.isEnrolled ? "#ff6b00" : "none" } }}>
                                                    <Typography sx={{ fontSize: "15px", color: "white", textAlign: "center", }}>Get Started</Typography>
                                                </Button>

                                            </Link>
                                            {
                                                item?.name === "Business Foundation" &&

                                                <Link to="/success-skills-intro" className="btn-success text-center mb-5"
                                                    style={{ backgroundColor: '#77C343', border: 'none', display: "flex", justifyContent: "center", alignItems: "center", cursor: "pointer", padding: '5px', borderRadius: '0.6rem' }}
                                                    onClick={() => log({
                                                        pageName: 'Module',
                                                        buttonName: 'Success Skills'
                                                    })}
                                                >{t("module.btnSuccess")}</Link>
                                            }
                                        </Grid>

                                    </Grid>
                                </Grid>
                            ))
                        }
                    </Grid>
                </Grid>
            </div>
        </>
        <ChatBotBody setChatBotModal={setChatbotModal} data={userData?.map(module => module?.name)} pageName={"Module"} chatBotModal={openChatbotModal} />
    </>


}

export default Modules