import React, { useState, useEffect } from 'react'
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Link, useNavigate } from "react-router-dom";
import StickyDemo from './StickyDemo';
import { QuillEditor } from '../lessons/details/quil-editor'
import GoogleMapReact from 'google-map-react';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Geocode from "react-geocode"

function Row(props) {
  const [address, setAddress] = useState("")
  Geocode.setApiKey(process.env.REACT_APP_GOOGLE_API_KEY);
  const { data, setShowDemo, setStiky, setAssignmentID, setAssigneeData, setTitle } = props;
  const [open, setOpen] = useState(false);
  const location = {
    lat: data?.stickeyAssignmentId?.latitude,
    lng: data?.stickeyAssignmentId?.longitude,
  }

  Geocode.fromLatLng(location.lat, location.lng).then(
    (response) => {
      const address = response?.results[0]?.formatted_address;
      setAddress(address)
    },
    (error) => {
      console.error(error);
      // return ("Location not found")
    })
  return (
    <React.Fragment>
      <TableRow hover sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell className='title-first'>{data?.stickeyAssignmentId?.title}</TableCell>
        <TableCell style={{
          fontFamily: 'Poppins',
          color: '#242424'
        }}>{address}</TableCell>
        <TableCell
          onClick={() => {
            setShowDemo(true);
            setStiky(data?.stickeyAssignmentId);
            setTitle(data?.stickeyAssignmentId?.title)
            setAssignmentID(data?.stickeyAssignmentId?._id)
            setAssigneeData(data)
          }}>
          <Link to='#'>
            <span className='assigment-sticky'>
              {
                data?.status === "not submitted" ? "Start Assignment"
                  : data?.approval === "not approved" ? "Resubmit Assignment"
                    : "View Your Assignment"
              }
            </span>
          </Link></TableCell>
        <TableCell>
          {
            data?.status === "not submitted" ? <p className="btn-sub-not both-sticky">Not Submitted</p>
              : data?.status === "submitted" ? <p className="btn-sub both-sticky">Submitted</p>
                : <p className="btn-re-sub both-sticky">Resubmitted</p>
          }
          {
            data?.approval === "not approved" ? <p className="btn-sub-not both-sticky">Not Approved</p>
              : data?.approval === "approved" ? <p className="btn-sub both-sticky">Approved</p>
                : <p className="pending both-sticky">Pending</p>
          }
        </TableCell>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <img src="/assets/img/sticky/Vector 32 (1).png" alt="" /> : <img src="/assets/img/sticky/Vector 32.png" alt="" />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <div className="row my-5">
                <div className="col-12 col-lg-3 col-md-3">
                  <p className='question-text'>Question</p>
                </div>
                <div className="col-12 col-lg-8 col-md-4">
                  {data?.stickeyAssignmentId?.contentType === "image" ?
                    <img src={process.env.REACT_APP_S3_BUCKET_LINK + data?.stickeyAssignmentId?.content} style={{ width: "100%" }} />
                    :
                    data?.stickeyAssignmentId?.contentType === "video" ?
                      <video style={{ width: "100%" }} controls>
                        <source src={process.env.REACT_APP_S3_BUCKET_LINK + data?.stickeyAssignmentId?.content} type="video/mp4" />
                      </video>
                      : <QuillEditor className='paragraph'
                        value={data?.stickeyAssignmentId?.content}
                        readOnly={true}
                      />
                  }

                </div>
                {/* <div className="col-12 col-lg-5 col-md-5">
                  <img src="/assets/img/sticky/question-sticky.png" alt="" />
                </div> */}

              </div>
              <hr />
              <div className="row my-5">
                <div className="col-12 col-lg-3 col-md-3">
                  <p className='question-text'>Answer</p>
                </div>
                <div className="col-12 col-lg-9 col-md-9">
                  <p className='paragraph mb-0'>
                    {data?.answer ? (
                      data?.answer
                    ) : ("Not Attemted Yet")}
                  </p>
                </div>

              </div>
              <hr />

              <div className="row my-5">
                <div className="col-12 col-lg-3 col-md-3">
                  <p className='question-text'>Description</p>
                </div>
                <div className="col-12 col-lg-9 col-md-9">
                  <p className='paragraph mb-0'>
                    {data?.stickeyAssignmentId?.description ? (
                      <QuillEditor className='paragraph'
                        value={data?.stickeyAssignmentId?.description}
                        readOnly={true}
                      />
                    ) : ("Description not added")}
                  </p>
                </div>

              </div>
              <hr />

              <div className="row my-5">
                <div className="col-12 col-lg-3 col-md-3">
                  <p className='question-text'>Location</p>
                </div>
                <div className="col-12 col-lg-4 col-md-4">
                  <p className='paragraph'>
                    {address}
                  </p>
                </div>
                <div className="col-12 col-lg-5 col-md-5">
                  <div style={{ width: '100%', height: 300, borderRadius: 15, overflow: 'hidden' }}>
                    <GoogleMapReact
                      bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_API_KEY }}
                      defaultCenter={location}
                      defaultZoom={10}
                    >
                      <LocationOnIcon lat={location.lat} lng={location.lng} fontSize="large" style={{ color: 'red' }} />
                    </GoogleMapReact>
                  </div>
                </div>
              </div>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

const Stickytable = (props) => {
  const navigate = useNavigate()
  const { AllSticky, setShowDemo, showDemo, setTitle } = props;
  const [sticky, setStiky] = useState(false);
  const [assignmentID, setAssignmentID] = useState();
  const [assigneeData, setAssigneeData] = useState()

  return (
    <>
      {showDemo ?
        <StickyDemo assigneeData={assigneeData} sticky={sticky} assignmentID={assignmentID} setShowDemo={setShowDemo} />
        :
        <TableContainer component={Paper}>
          <Table stickyHeader aria-label="collapsible table sticky table" className='table-sticky'>
            <TableHead>
              <TableRow>
                <TableCell>Title</TableCell>
                <TableCell>Location</TableCell>
                <TableCell>Actions</TableCell>
                <TableCell>Submission/Approval</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {AllSticky?.map((data) => (
                <Row
                  setAssigneeData={setAssigneeData}
                  key={data.stickeyAssignmentId._id}
                  data={data}
                  setStiky={setStiky}
                  setShowDemo={setShowDemo}
                  setAssignmentID={setAssignmentID}
                  setTitle={setTitle}
                />
              ))}

            </TableBody>
          </Table>
        </TableContainer>
      }


    </>
  )
}

export default Stickytable
