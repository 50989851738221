import React, { useState } from 'react'
import { NavLink, Link, useNavigate } from 'react-router-dom'
import LockOpenRoundedIcon from '@mui/icons-material/LockOpenRounded';
import KeyboardBackspaceRoundedIcon from '@mui/icons-material/KeyboardBackspaceRounded';
import { useSelector } from 'react-redux'
import BudgetLogo from '../../common/BudgetCal'


import { userLog } from '../../_api/index'
import { useMutation } from 'react-query';
import { isMoneyVisible } from '../details/isMoneyVisible'
import Swal from 'sweetalert2'

export default function SkillsBar(props) {
    const { mutate: log } = useMutation(userLog);
    const skillSections = useSelector(state => state.user?.skillSections)
    const quizSkillSections = useSelector(state => state.user?.quiz)
    const moduleID = useSelector(state => state.user?.moduleID)
    const lessonID = useSelector(state => state.user?.lessonID)
    const skillID = useSelector(state => state.user?.skillID)
    const introSkillSections = skillSections?.filter(skill => { return (skill?.name === "Intro") })
    const assessSkillSections = skillSections?.filter(skill => { return (skill?.name === "Assess") })
    const simsSkillSections = skillSections?.filter(skill => { return (skill?.name === "SIMS") })
    const user = useSelector((state) => state?.user?.userData?.user)

    const [activeIntro, setActiveIntro] = useState(false)
    const [activeAssess, setActiveAssess] = useState(false)
    const [activeSims, setActiveSims] = useState(false)
    const [activeQuiz, setActiveQuiz] = useState(false)

    const introActive = () => {
        setActiveIntro(true)
        setActiveAssess(false)
        setActiveSims(false)
        setActiveQuiz(false)
    }
    const assessActive = () => {
        setActiveIntro(false)
        setActiveAssess(true)
        setActiveSims(false)
        setActiveQuiz(false)

    }

    const simsActive = () => {
        setActiveIntro(false)
        setActiveAssess(false)
        setActiveSims(true)
        setActiveQuiz(false)

    }

    const quizActive = () => {
        setActiveIntro(false)
        setActiveAssess(false)
        setActiveSims(false)
        setActiveQuiz(true)

    }
    return (
        <>
            <div className="skills-sidebar-container">
                <div className="sidebar">
                    <Link to={`/skills?moduleID=${moduleID}&lessonID=${lessonID}&skillID=${skillID}`} className="go-back"
                        style={{
                            position: 'absolute',
                            top: '30px',
                            left: '50px'
                        }}
                        onClick={() => log({
                            pageName: props?.page,
                            buttonName: 'Back'
                        })}>
                        <KeyboardBackspaceRoundedIcon style={{ fontSize: '25px' }} />
                    </Link>
                    {
                        skillSections.length !== 0 ?
                            <>
                                {
                                    introSkillSections.length !== 0 &&
                                    <NavLink to={"/skill-detail"} onClick={(e) => {
                                        log({
                                            pageName: props?.page,
                                            buttonName: 'Intro'
                                        })
                                        introSkillSections[0]?.isEnrolled || introSkillSections[0]?.isCompleted ?
                                            <></>
                                            : e.preventDefault()
                                    }}
                                        className={({ isActive }) => isActive ? "bar-item active mt-0 " + introActive() : "bar-item "} >
                                        {
                                            activeIntro ?
                                                <img src="../assets/img/intro1.svg" alt="" />
                                                :
                                                <img src="../assets/img/intro.svg" alt="" />
                                        }

                                        <p>Intro</p>
                                    </NavLink>
                                }
                                {
                                    assessSkillSections.length !== 0 &&
                                    <NavLink to={"/skill-detail-assess"}
                                        onClick={(e) => {
                                            log({
                                                pageName: props?.page,
                                                buttonName: 'Assess'
                                            })
                                            assessSkillSections[0]?.isEnrolled || assessSkillSections[0].isCompleted ?
                                                <></>
                                                : e.preventDefault()
                                        }}
                                        className={({ isActive }) => isActive ? "bar-item active " + assessActive() : "bar-item "}
                                    >
                                        {
                                            activeAssess ?
                                                <img src="../assets/img/assets1.svg" alt="" />
                                                :
                                                <img src="../assets/img/assets.svg" alt="" />
                                        }
                                        {
                                            assessSkillSections[0]?.isEnrolled || assessSkillSections[0].isCompleted ?
                                                <></> : <LockOpenRoundedIcon className="lock" />
                                        }

                                        <p>Assess</p>
                                    </NavLink>
                                }
                                {
                                    simsSkillSections.length !== 0 &&
                                    <NavLink to={"/skill-detail-sims"}
                                        onClick={(e) => {
                                            log({
                                                pageName: props?.page,
                                                buttonName: 'SIMS'
                                            })
                                            simsSkillSections[0]?.isEnrolled || simsSkillSections[0].isCompleted ?
                                                <></>
                                                : e.preventDefault()
                                        }}
                                        className={({ isActive }) => isActive ? "bar-item active " + simsActive() : "bar-item "}>
                                        {
                                            activeSims ?
                                                <img src="../assets/img/sim1.svg" alt="" />
                                                :
                                                <img src="../assets/img/sim.svg" alt="" />
                                        }
                                        {
                                            simsSkillSections[0]?.isEnrolled || simsSkillSections[0].isCompleted ?
                                                <></> : <LockOpenRoundedIcon className="lock" />
                                        }
                                        <p>Sims</p>
                                    </NavLink>
                                }
                                {
                                    quizSkillSections?.length !== 0 &&
                                    <NavLink to={"/skill-detail-quiz"}
                                        onClick={(e) => {
                                            e.preventDefault()
                                            log({
                                                pageName: props?.page,
                                                buttonName: 'Quiz'
                                            })
                                            // props.quizLock ?
                                            //     <></>
                                            //     : e.preventDefault()
                                        }
                                        }
                                        className={({ isActive }) => isActive ? "bar-item active " + quizActive() : "bar-item "}>
                                        {
                                            activeQuiz ?
                                                <img src="../assets/img/quiz1.svg" alt="" />
                                                :
                                                <img src="../assets/img/quiz.svg" alt="" />
                                        }
                                        {
                                            props.quizLock && <LockOpenRoundedIcon className="lock" />
                                        }
                                        <p>Quiz</p>
                                    </NavLink>
                                }

                                {
                                    isMoneyVisible(user) &&
                                    <a
                                        href={`${process.env.REACT_APP_BUDG_LINK}?path=${window.location.pathname}&token=${localStorage.getItem("token")}&isIframe=false`}
                                        style={{ padding: "2rem", marginTop: "1rem", position: "static" }}
                                        onClick={(e) => {
                                            if (sessionStorage.getItem("access") === "ok") {
                                                e.preventDefault();
                                                Swal.fire({
                                                    title: '',
                                                    text: "It's a teacher view, so you can't access to Money!",
                                                    icon: 'info',
                                                    confirmButtonText: 'OK'
                                                })
                                            }
                                            log({
                                                pageName: 'Skill Details',
                                                buttonName: 'Budget Calculator'
                                            })
                                        }}
                                        className="redirect-budg bar-item">
                                         <BudgetLogo />
                                    </a>
                                }
                            </> : <p className="text-light">No Data</p>
                    }



                    {/* <div className="bar-item logout m-0 p-0">
                        <p>Logout</p>
                    </div> */}



                </div>
            </div >
        </>
    )
}