import React, { useState, useEffect } from "react";
import Header from "../common/NewHeader";
import { useNavigate } from "react-router-dom";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import * as Axios from "axios";
import Listings from "./Listings";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import InfoIcon from "@mui/icons-material/Info";
import MultipleSelect from "../MultiSelect";
import { TextField, FormHelperText, Grid, InputAdornment } from "@mui/material";
import NumbersOutlinedIcon from "@mui/icons-material/NumbersOutlined";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";

import { userLog, userUpdate, getOrganizations } from "../_api/index";
import { getAdminList } from "../_api/journal";
import { requestAmount } from "../_api/card";
import { useMutation, useQueryClient, useQuery } from "react-query";
import TurnPhoneH from "../other/TurnPhoneH";
import { useSelector } from "react-redux";
import isAuthenticated from "../auth/auth";
import ExtensionOffIcon from "@mui/icons-material/ExtensionOff";
import { useTranslation } from "react-i18next";
import { Upload } from "@aws-sdk/lib-storage";
import { S3Client } from "@aws-sdk/client-s3";
import Card from "./Card";
import * as Yup from "yup";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import {
  Button,
  OutlinedInput,
  FormControl,
  InputLabel,
  Input,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { Link } from "react-router-dom";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import { hideProfileItems } from "../lessons/details/hideProfileItems";

import { IMaskInput } from "react-imask";
import { makeStyles } from "@mui/styles";

import LearningStyleInfoModal from "../modals/learningStyleInfoModal";
import moment from "moment";

const learningStyles = [
  { value: "visual", label: "Visual", description: ["Graphics", "Flashcards"] },
  { value: "auditory", label: "Auditory", description: ["Lecture", "Music"] },
  {
    value: "physical",
    label: "Physical",
    description: ["Hands-on", "Movement"],
  },
  { value: "verbal", label: "Verbal", description: ["Writing", "Presenting"] },
  {
    value: "logical",
    label: "Logical",
    description: ["Patterns", "Statistics"],
  },
  { value: "social", label: "Social", description: ["Collaboration", "Teams"] },
  {
    value: "solitary",
    label: "Solitary",
    description: ["Independent", "Individual"],
  },
];

const useStyles = makeStyles({
  underline: {
    "&&:before": {
      borderBottom: "none",
    },
    "&&:after": {
      borderBottom: "none",
    },
  },
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="000-000-0000"
      definitions={{
        "#": /[0-9]/,
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});

// const axios = Axios.create({
//   'headers': { 'x-api-key': process.env.REACT_APP_X_API_KEY }
// })

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: "2rem",
};

export default function Profile() {
  const classes = useStyles();
  const [organizations, setOrganizations] = useState([]);

  const { isLoading: isOrgLoading, data: orgs } = useQuery(
    "get-organizations",
    getOrganizations
  );
  const { isLoading: adminsLoading, data: adminList } = useQuery(
    "fetch-admins",
    getAdminList
  );

  useEffect(() => {
    if (!isOrgLoading) {
      setOrganizations(orgs?.data || []);
    }
  }, [isOrgLoading, orgs]);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { mutate: log } = useMutation(userLog);
  const [general, setGeneral] = useState(true);
  const [badge, setBadge] = useState(false);
  const [notification, setNotification] = useState(false);
  const [card, setCard] = useState(false);
  const [badges, setBadges] = useState([]);
  const [open, setOpen] = useState(false);
  const user = useSelector((state) => state?.user?.userData?.user);
  const [amount, setAmount] = useState();
  const [other, setOther] = useState("");
  const [openLearningStyleModal, setOpenLearningStyleModal] = useState(false);

  let isNorthwell =  process.env.REACT_APP_API_LINK.includes("northwell")

  useEffect(() => {
    if (!adminsLoading) {
      let res = adminList?.data?.filter(
        (admin) => admin?.username === user?.teacher
      );
      if (!res?.length) {
        setOther("Other");
      }
    }
  }, [adminsLoading, adminList]);

  const handleSubmit3 = () => {
    setOpen3(false);
  };

  const closeLearningStyleModal = () => {
    setOpenLearningStyleModal(false);
  };

  const [open2, setOpen2] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen2(false);

  const [open3, setOpen3] = React.useState(false);
  const handleOpen3 = () => setOpen3(true);
  const handleClose3 = () => setOpen3(false);

  const [open4, setOpen4] = React.useState(false);
  const handleOpen4 = () => setOpen4(true);
  const handleClose4 = () => setOpen4(false);

  const [profileImage, setProfileImage] = useState(
    "../assets/img/dp_default.png"
  );
  const [loading, setLoading] = useState(false);
  const { isLoading: updateLoading, mutate: userData } = useMutation(
    userUpdate,
    {
      onSuccess: (response) => {
        queryClient.invalidateQueries("fetching-all-data");
        toast.dismiss();
        toast.success("Updated");
      },
    }
  );
  const { mutate: reqData } = useMutation(requestAmount, {
    onSuccess: () => {
      toast.dismiss();
      toast.success("Request Sent");
    },
  });
  const moments = moment()
  const date = moments.format('MM/DD/YYYY')
  let age = Number(date?.split("/")[2]) - Number(user?.dob?.split('/')[2])
  if(user?.dob?.includes('-')){
    const momnt=moment(user?.dob)
    const userDob=momnt.format('MM/DD/YYYY')
    age=Number(date?.split("/")[2]) - Number(userDob?.split('/')[2])
    console.log("🚀 ~ Profile ~ age:", age)
  }
  // else{
  //   age = Number(date?.split("/")[2]) - Number(user?.dob?.split('/')[2])
  // }
  console.log("user.dob",age)
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      FirstName: user?.firstName || "",
      // LastName: user?.lastName || "",
      number: user?.phoneNumber || "",
      org: user?.organization || "",
      zip: user?.zipCode || "",
      learningStyles: user?.learningStyles || [],
      TeacherName: user?.teacher || "",
      class: user?.class || "",
      dob: age || "",
      email: user?.email || "",
      submit: null,
    },
    validationSchema: Yup.object({
      FirstName: Yup.string()
        .min(3, "Must be at least 3 characters")
        .max(30, "Max 30 char allowed")
        .required("Nick Name is required")
        .matches(/^[aA-zZ\s]+$/, "Please enter valid Name "),
      // LastName: Yup.string().min(3, "Must be at least 3 characters").max(30).required("Last Name is required").matches(/^[aA-zZ\s]+$/, "Please enter valid Name "),
      // number: Yup.string().required("Number is required"),
      org: Yup.string().max(30),
      // zip: Yup.number().required("Zip Code is required").typeError("Please enter a valid Zip Code"),
      // class: Yup.string().max(30).required("Class is required"),
      TeacherName: Yup.string()
        .min(4)
        .max(30)
        .required("Teacher name is required"),
      learningStyles: Yup.array().min(1, "Select at least one learning style"),
      email: Yup.string()
        .email("Please enter a valid email")
        .required("Email is required"),
      dob: Yup.number().min(1, 'age must be greater than 1'),
    }),
    onSubmit: async (values, helpers) => {
      toast.loading("Updating...");
      try {
        // NOTE: Make API request
        const currentDate = moment();
        const modifiedDate = currentDate.subtract(values.dob, 'years');
        userData({
          id: user._id,
          firstName: values.FirstName,
          lastName: values.LastName,
          phoneNumber: values.number,
          organization: values.org,
          zipCode: values.zip,
          class: values.class,
          teacher: values.TeacherName,
          learningStyles: values.learningStyles,
          dob: modifiedDate.format('MM/DD/YYYY'),
          email: values.email,
        });
        helpers.setStatus({ success: true });
        helpers.setSubmitting(false);
      } catch (err) {
        toast.dismiss();
        toast.error(err.message);
        helpers.setStatus({ success: false });
        helpers.setErrors({ submit: err.message });
        helpers.setSubmitting(false);
      }
    },
    handleChange: (e) => { },
  });
  const formik1 = useFormik({
    enableReinitialize: true,
    initialValues: {
      teacherName: "",
      schoolName: "",
      reasonForExtension: "",
      timeAsCustomer: "",
      name: "",
      applicationDate: "",
      FirstName: user?.firstName || "",
      Age: "",
      dob: "",
      gender: "",
      Number: "",
      email: "",
      present: user?._id || "",
      street1: "",
      street2: "",
      city: "",
      province: "",
      zipcode: "",
      residance: "",
      years: "",
      civil: "",
      spouseFirstName: "",
      spouseLastName: "",
      spouseNumber: "",
      dependents: "",
      payment: "",
      source: "",
      Occupation: "",
      job: "",
      yearswork: "",
      hiredate: "",
      companyName: "",
      companyAddress: "",
      companyAddress2: "",
      companyCity: "",
      companyProvince: "",
      companyZipCode: "",
      grossIncome: "",
      annualIncome: "",
      HistoryComName: "",
      position: "",
      Phone: "",
      Month: "",
      file: "",
      loan: "",
      purposeLoan: "",
      ReasonLoan: "",
      signature: "",
      datesigned: "",
      userState: "",
      loanAmount: "",
      ref1name: "",
      ref1relationship: "",
      ref1namephone: "",
      ref1companyname: "",
      ref2name: "",
      ref2relationship: "",
      ref2namephone: "",
      ref2companyname: "",
      ref3name: "",
      ref3relationship: "",
      ref3namephone: "",
      ref3companyname: "",
    },
    validationSchema: Yup.object({
      teacherName: Yup.string()
        .min(3, "Must be at least 3 characters")
        .required("Teacher Name is required"),
      schoolName: Yup.string()
        .min(3, "Must be at least 3 characters")
        .required("School Name is required"),
      reasonForExtension: Yup.string()
        .min(3, "Must be at least 3 characters")
        .required("Reason For Extension is required"),
      timeAsCustomer: Yup.string()
        .min(3, "Must be at least 3 characters")
        .required("Lenght of time is required"),
      // name: Yup.string().min(3, "Must be at least 3 characters").required("Name is required"),
      // applicationDate: Yup.string().required("Application Date is required"),
      FirstName: Yup.string()
        .min(3, "Must be at least 3 characters")
        .max(30)
        .required("First Name is required")
        .matches(/^[aA-zZ\s]+$/, "Please enter valid Name "),
      // LastName: Yup.string()
      //     .min(3, "Must be at least 3 characters")
      //     .max(30)
      //     .required("Last Name is required")
      //     .matches(/^[aA-zZ\s]+$/, "Please enter valid Name "),
      // Age: Yup.number()
      //     .required("Age is required")
      //     .typeError("Please enter a valid Age"),
      // dob: Yup.string().required("Date of Birth is required"),
      // gender: Yup.string().required("Gender is required"),
      // Number: Yup.number()
      //     .required("Phone Number is required")
      //     .typeError("Please enter a valid Number"),
      // email: Yup.string()
      //     .email("Please enter a valid email")
      //     .required("Email is required"),
      present: Yup.string().required(
        "License, Passport, Student ID, or State ID Required"
      ),
      // street1: Yup.string().required("please enter street address"),
      // street2: Yup.string().required("please enter street address"),
      // city: Yup.string().required("please enter a City Name"),
      // province: Yup.string().required("please enter a Province Name"),
      // zipcode: Yup.number()
      //     .required("Zip Code is required")
      //     .typeError("Please enter a valid Zip Code"),
      // residance: Yup.string().required("please enter a Residance Name"),
      // years: Yup.number()
      //     .required("Years is required")
      //     .typeError("Please enter a valid Years"),
      // civil: Yup.string().required("please enter Civil Status"),
      // spouseFirstName: Yup.string().required("please enter Spouse First Name"),
      // spouseLastName: Yup.string().required("please enter Spouse Last Name"),
      // spouseNumber: Yup.number().required("please enter Spouse Number"),
      // dependents: Yup.number()
      //     .required(" Number is required")
      //     .typeError("Please enter a valid Number"),
      // payment: Yup.number()
      //     .required(" Amount is required")
      //     .typeError("Please enter a Total Amount"),
      // source: Yup.string().required("please enter Source of Income"),
      // Occupation: Yup.string().required("Occupation is required"),
      // job: Yup.string().required("Job Title is required"),
      // yearswork: Yup.number()
      //     .required("Years is required")
      //     .typeError("Please enter a valid Years"),
      // hiredate: Yup.string().required("Hired Date is required"),
      // companyName: Yup.string().required("Company Name is required"),
      // companyAddress: Yup.string().required("Company Address  is required"),
      // companyAddress2: Yup.string().required("Company Address  is required"),
      // companyCity: Yup.string().required("Company City is required"),
      // companyProvince: Yup.string().required("Company Province is required"),
      // companyZipCode: Yup.number().required("Company Zip Code is required"),
      // grossIncome: Yup.number()
      //     .required(" Gross Income is required")
      //     .typeError("Please enter a valid Gross Income"),
      // annualIncome: Yup.number()
      //     .required(" Gross Income is required")
      //     .typeError("Please enter a valid Gross Income"),
      // HistoryComName: Yup.string().required("Company Name  is required"),
      // position: Yup.string().required("Position  is required"),
      // Phone: Yup.number()
      //     .required(" Number is required")
      //     .typeError("Please enter a valid Number"),
      // Month: Yup.string().required(" Months is required"),
      // file: Yup.string(),
      // loan: Yup.string().required("please enter Loan Terms"),
      // purposeLoan: Yup.string()
      //     .required("please enter Purpose Loan")
      //     .typeError("Please enter a Purpose Loan"),
      // ReasonLoan: Yup.string().required("please enter Reason Loan"),
      signature: Yup.string().required(" Signature or Name is Required"),
      datesigned: Yup.string().required("Date is required"),
      // userState: Yup.string().required("User State is required"),
      loanAmount: Yup.number().required("Loan Amount is required"),
      // ref1name: Yup.string().min(3).required("Name is required"),
      // ref1relationship: Yup.string().required("Relationship is required"),
      // ref1namephone: Yup.number()
      //     .required("Phone number is required")
      //     .typeError("Please enter a valid Number"),
      // ref1companyname: Yup.string().required("Company name is required"),
    }),
    onSubmit: async (values, helpers) => {
      toast.loading("Requesting...");
      const data = {
        applicationDate: values.applicationDate,
        applicationDetails: {
          teacherName: values.teacherName,
          schoolName: values.schoolName,
          reasonForExtension: values.reasonForExtension,
          // timeAsCustomer: values.timeAsCustomer,
          // // name:"Ashfaq"
        },
        applicantDetails: {
          firstName: values.FirstName,
          //     lastName: values.LastName,
          //     age: values.Age,
          //     dob: values.dob,
          //     gender: values.gender,
          //     phoneNumber: values.Phone,
          //     email: values.email,
          //     idType: "User ID",
          //     address: {
          //         streetAddressLine1: values.street1,
          //         streetAddressLine2: values.street2,
          //         city: values.city,
          //         state: values.state,
          //         zipCode: values.zipcode,
          //     },
          //     residenceType: values.residance,
          //     residenceYears: values.years,
          //     civilStatus: values.civil,
          //     spouseDetails: {
          //         firstName: values.spouseFirstName,
          //         lastName: values.spouseLastName,
          //         phoneNumber: values.spouseNumber,
          //     },
          //     numberOfDependents: values.dependents,
          //     totalMonthlyPayment: values.payment,
          // },
          // employementDetails: {
          //     sourceOfIncome: values.source,
          //     occupation: values.Occupation,
          //     jobTitle: values.job,
          //     workingYears: values.yearswork,
          //     hiredDate: values.hiredate,
          //     companyAddress: {
          //         streetAddressLine1: values.companyAddress,
          //         streetAddressLine2: values.companyAddress2,
          //         city: values.companyCity,
          //         state: values.state,
          //         zipCode: values.companyZipCode,
          //     },
          //     monthlyGrossIncome: values.grossIncome,
          //     monthlyNetIncome: values.annualIncome,
          //     employementHistory: [
          //         {
          //             companyName: values.HistoryComName,
          //             position: values.position,
          //             phoneNumber: values.Phone,
          //             noOfMonths: values.Month,
          //         },
          //     ],
          //     files: [values.file],
        },
        loanDetails: {
          loanAmount: values.loanAmount,
          // loanTerm: values.loan,
          // loanPurpose: values.purposeLoan,
          // reasonForLoan: values.ReasonLoan,
        },
        // refrences: [
        //     {
        //         name: values.ref1name,
        //         relationship: values.ref1relationship,
        //         phoneNumber: values.ref1namephone,
        //         companyName: values.ref1companyname,
        //     },
        //     {
        //         name: values.ref2name,
        //         relationship: values.ref2relationship,
        //         phoneNumber: values.ref2namephone,
        //         companyName: values.ref2companyname,
        //     },
        //     {
        //         name: values.ref3name,
        //         relationship: values.ref3relationship,
        //         phoneNumber: values.ref3namephone,
        //         companyName: values.ref3companyname,
        //     },
        // ],
        signature: values.signature,
        signedDate: values.datesigned,
        userId: values.present,
      };
      // axios.post(`https://api-development.stickball.biz/user/request-loan`, data)
      //   .then((res) => {
      //     console.log(res)
      //   })
      //   .catch((err) => {
      //     console.log(err)
      //   })
      // console.log(data)
      console.log("values----------", values);
      try {
        await reqData(data);
        setOpen(false);
      } catch (err) {
        toast.dismiss();
        toast.error(err.message);
        console.log(err.message);
      }
    },
  });
  const handleRadioGender = (e) => (formik1.values.gender = e);
  const handleRadioResidence = (e) => (formik1.values.residance = e);
  const handleRadioCivil = (e) => (formik1.values.civil = e);
  const handleRadioSource = (e) => (formik1.values.source = e);
  const handleRadioLoan = (e) => (formik1.values.loan = e);
  const anchor = window.location.hash.substring(1);

  const handleStyleChange = (newValue) => {
    const stringArray = newValue.map((item) => item.value);
    formik.setFieldValue("learningStyles", stringArray);
  };
  useEffect(() => {
    if (anchor === "creditCard") {
      setGeneral(false);
      setBadge(false);
      setCard(true);
    }
    if (!user?.firstName || !user?.photo) {
      setOpen4(true);
    }
  }, []);

  useEffect(() => {
    if (user?.photo) {
      setProfileImage(user?.photo);
    }

    setBadges(user?.badges);
  }, [user]);
  const [isMobile, setIsMobile] = useState(false);
  function handleOrientation() {
    setIsMobile(
      window.innerHeight < window.innerWidth && window.innerWidth < 1000
    );
  }
  window.onresize = handleOrientation;
  useEffect(() => {
    setIsMobile(
      window.innerHeight < window.innerWidth && window.innerWidth < 1000
    );
  }, [window.innerWidth, window.innerHeight, window.orientation]);

  const fileUpdate = async (file) => {
    setLoading(true);
    const target = {
      Bucket: process.env.REACT_APP_BUCKET_NAME,
      Key: file.name,
      Body: file,
    };
    const creds = {
      accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
      secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
    };
    try {
      const parallelUploads3 = new Upload({
        client: new S3Client({
          region: process.env.REACT_APP_REGION,
          credentials: creds,
        }),
        leavePartsOnError: false,
        params: target,
      });

      parallelUploads3.on("httpUploadProgress", (progress) => { });

      await parallelUploads3.done().then((res) => {
        setProfileImage(
          `https://${process.env.REACT_APP_BUCKET_NAME}.s3.amazonaws.com/${file.name}`
        );
        userData({
          photo: `https://${process.env.REACT_APP_BUCKET_NAME}.s3.amazonaws.com/${file.name}`,
        });
        setLoading(false);
      });
    } catch (e) { }
  };

  const fileUpload = async (file) => {
    toast.loading("Uploading file please wait...");
    const target = {
      Bucket: process.env.REACT_APP_BUCKET_NAME,
      Key: file.name,
      Body: file,
    };
    const creds = {
      accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
      secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
    };
    try {
      const parallelUploads3 = new Upload({
        client: new S3Client({
          region: process.env.REACT_APP_REGION,
          credentials: creds,
        }),
        leavePartsOnError: false,
        params: target,
      });

      parallelUploads3.on("httpUploadProgress", (progress) => { });

      await parallelUploads3.done().then((res) => {
        formik1.setFieldValue(
          "file",
          `https://${process.env.REACT_APP_BUCKET_NAME}.s3.amazonaws.com/${file.name}`
        );
        toast.dismiss();
        toast.success("File Uploaded");
      });
    } catch (e) { }
  };

  let earnedBadges = badges?.filter((badge) => badge?.earned) || [];
  let notEarnedBadges = badges?.filter((badge) => !badge?.earned) || [];
  if (isMobile) {
    return <TurnPhoneH content={t("profile.turn")} />;
  } else
    return (
      <>
        {!isAuthenticated(localStorage.getItem("token")) &&
          navigate("/login?returnURL=/profile#" + anchor)}
        <Modal
          open={open4}
          onClose={handleClose4}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Grid container direction="column" justifyContent="center">
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Please Enter Your Name and Upload Your Profile Picture
              </Typography>
              <Button
                onClick={() => handleClose4()}
                sx={{ minWidth: "5rem", marginTop: "2rem" }}
                variant="contained"
              >
                Ok
              </Button>
            </Grid>
          </Box>
        </Modal>
        <Modal
          open={open3}
          onClose={handleClose3}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Enter the Amount
            </Typography>
            <form onSubmit={handleSubmit3}>
              <Grid container>
                <Grid item xs={12} my={1}>
                  <FormControl>
                    <TextField
                      size="small"
                      placeholder="Enter Amount"
                      value={amount}
                      onChange={(e) => setAmount(e.target.value)}
                      fullWidth
                      id="outlined-basic"
                      sx={{
                        "& legend": { display: "none" },
                        "& fieldset": { top: 0 },
                      }}
                      variant="outlined"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <NumbersOutlinedIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} my={1}>
                  <FormControl>
                    <Button
                      variant="contained"
                      onClick={() => {
                        setOpen3(false);
                      }}
                      sx={{ width: "100%" }}
                    >
                      Submit
                    </Button>
                  </FormControl>
                </Grid>
              </Grid>
            </form>
          </Box>
        </Modal>
        <Modal
          open={open2}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Text in a modal
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
            </Typography>
          </Box>
        </Modal>
        <Header
          backLink="/"
          // title="Settings"
          // icon={<img src="./assets/new-svgs/arrow_back.svg" />}
          page="Profile"
        />
        <div className="container pb-3" >
          <form className="form " onSubmit={formik.handleSubmit}>
            <div
              className="propfile-head"
              style={{
                background: "#e8e9fd",
                borderRadius: "1rem",
                marginBottom: "1rem",
              }}
            >
              <div className="left d-flex flex-wrap justify-content-center">
                <div className="profile-photo">
                  {loading || updateLoading ? (
                    <CircularProgress />
                  ) : (
                    <>
                      <img src={profileImage} alt="" />
                      <div className="edit-profile">
                        <img src="assets/new-svgs/photo_camera.svg" alt="" />
                      </div>
                    </>
                  )}
                  <input
                    accept="image/*"
                    onChange={(e) => fileUpdate(e.target.files[0])}
                    type="file"
                    style={{
                      opacity: "0",
                      position: "absolute",
                      width: "100%",
                      height: "100%",
                      // zIndex: 1000,
                      cursor: "pointer",
                    }}
                  />
                </div>
                <div className="details">
                  <div className="name">
                    {user?.firstName ? user?.firstName : "User"}
                  </div>
                  {/* <div className="email">{user?.email}</div> */}
                </div>
              </div>
              <div className="right">
                <button
                  className="btn-logout"
                  onClick={() => {
                    log({
                      pageName: "Profile",
                      buttonName: "Logout",
                    });
                    localStorage.removeItem("token");
                    navigate("/get-started");
                  }}
                >
                  Log Out
                  <img src="assets/new-svgs/logout.svg" alt="" />
                </button>
              </div>
            </div>
            <div className="text-end">
              <Link to={`/customer-support?email=${user?.email}`}>
                <Button variant="text" className="text-capitalize mr-2">
                  Customer Support
                </Button>
              </Link>
              {/* {hideProfileItems(user) && (
                <Link to="/altspace-vrws">
                  <Button variant="contained" className="metaverse_btn ml-2">
                    Metaverse
                  </Button>
                </Link>
              )} */}
            </div>
            <div className="row tabs-row pt-3">
              <div className="tabs pl-5">
                <div className="tab general">
                  <button
                    type="button"
                    onClick={() => {
                      log({
                        pageName: "Profile",
                        buttonName: "General",
                      });

                      setGeneral(true);
                      setBadge(false);
                      setNotification(false);
                      setCard(false);
                    }}
                    className={general ? "active" : ""}
                  >
                    {t("profile.general")}
                  </button>
                </div>
                <div className="tab badges">
                  <button
                    type="button"
                    onClick={() => {
                      setGeneral(false);
                      setBadge(true);
                      setNotification(false);
                      setCard(false);
                      log({
                        pageName: "Profile",
                        buttonName: "Badges",
                      });
                    }}
                    className={badge ? "active" : ""}
                  >
                    {t("profile.badges")}
                  </button>
                </div>
                <div className="tab badges">
                  <button
                    type="button"
                    onClick={() => {
                      setGeneral(false);
                      setBadge(false);
                      setNotification(false);
                      setCard(true);
                      log({
                        pageName: "Profile",
                        buttonName: "Credit Card",
                      });
                    }}
                    className={card ? "active" : ""}
                  >
                    Bank Card
                  </button>
                </div>
                <div className="tab badges">
                  <button
                    type="button"
                    onClick={() => {
                      setGeneral(false);
                      setNotification(true);
                      setBadge(false);
                      setCard(false);
                      log({
                        pageName: "Profile",
                        buttonName: "Notifications",
                      });
                    }}
                    className={notification ? "active" : ""}
                  >
                    Notifications
                  </button>
                </div>
        
              </div>
              <div className="single-btns" style={{ width: "unset" }}>
                {card && (
                  <>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      {/* <HelpOutlineOutlinedIcon onClick={() => setOpen2(true)} /> */}
                      <HelpOutlineOutlinedIcon />
                      <button
                        className="btn-logout"
                        type="button"
                        style={{ margin: "0 -28px 0 0" }}
                        onClick={() => setOpen(true)}
                      >
                        Request Amount
                      </button>
                    </div>
                  </>
                )}
                <div style={{display:"flex",gap:5,paddingBottom:5}}>
                  <button onClick={() => {
                    navigate("/change-password",
                    { state: { email: user?.email } })
                    }} className="btn-logout">
                    Change Password
                  </button>
                  {general && (
                    <button className="btn-logout" type="submit">
                      Update
                    </button>
                  )}
                </div>
              </div>
            </div>

            {general && (
              <div
                className="row"
                style={{
                  maxHeight: "51vh",
                  overflow: "auto",
                  background: "rgb(232, 233, 253)",
                  margin: "0 0.3rem 0.3rem",
                  borderRadius: "1rem",
                  padding: "1rem 0 2rem",
                }}
              >
                <div className="col-md-6 pl-5">
                  <div className="form-group">
                    <label className="label">Nick Name</label>
                    <input
                      className="input-control"
                      name="FirstName"
                      onChange={formik.handleChange}
                      value={formik.values.FirstName}
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.FirstName && formik.errors.FirstName && (
                      <FormHelperText error>
                        {formik.errors.FirstName}
                      </FormHelperText>
                    )}
                  </div>
                  {/* <div className="form-group">
                    <label className="label" >last Name</label>
                    <input className="input-control"
                      name="LastName"
                      onChange={formik.handleChange}
                      value={formik.values.LastName}
                      onBlur={formik.handleBlur}
                    />
                    {(formik.touched.LastName && formik.errors.LastName) && (
                      <FormHelperText error>
                        {formik.errors.LastName}
                      </FormHelperText>
                    )}
                  </div> */}



                  <div className="form-group">
                    <label className="label">AGE</label>
                    <input
                      className="input-control"
                      type="number"
                      name="dob"
                      onChange={formik.handleChange}
                      value={formik.values.dob}
                      onBlur={formik.handleBlur}
                      style={{ cursor: "pointer" }}
                    />
                    {formik.touched.dob && formik.errors.dob && (
                      <FormHelperText error>{formik.errors.dob}</FormHelperText>
                    )}
                  </div>
                  {/* Input type date age*/}

                  {/* <div className="form-group">
                    <label className="label">AGE</label>
                    <input
                      className="input-control"
                      type="date"
                      name="dob"
                      onChange={formik.handleChange}
                      value={formik.values.dob}
                      onBlur={formik.handleBlur}
                      style={{ cursor: "pointer" }}
                    />
                    {formik.touched.dob && formik.errors.dob && (
                      <FormHelperText error>{formik.errors.dob}</FormHelperText>
                    )}
                  </div> */}

                  {/* <div className="form-group">
                    <label className="label">email address</label>
                    <input
                      className="input-control"
                      name="email"
                      onChange={formik.handleChange}
                      value={formik.values.email}
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.email && formik.errors.email && (
                      <FormHelperText error>
                        {formik.errors.email}
                      </FormHelperText>
                    )}
                  </div> */}



                  {/* {
                    hideProfileItems(user) &&
                    <div className="form-group">
                      <FormControl variant="standard">
                        <label className="label" >contact number</label>
                        <Input
                          InputProps={{ classes }}
                          style={{
                            backgroundColor: '#fff',
                            border: 'none',
                            outline: 'none',
                            overflow: 'hidden',

                            '&::befor': {
                              border: 'none',
                              outline: 'none',
                            }
                          }}
                          className="input-control"
                          onChange={formik.handleChange}
                          value={formik.values.number}
                          onBlur={formik.handleBlur}
                          name="number"
                          inputComponent={TextMaskCustom}
                          placeholder="000-000-000"
                        />
                        {(formik.touched.number && formik.errors.number) && (
                          <FormHelperText error>
                            {formik.errors.number}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </div>
                  } */}
                  {/* <div className="form-group">
                    <label className="label" >contact number</label>
                    <input className="input-control"
                      name="number"
                      maxLength={14}
                      onChange={formik.handleChange}
                      value={formik.values.number}
                      onBlur={formik.handleBlur}
                    />
                    {(formik.touched.number && formik.errors.number) && (
                      <FormHelperText error>
                        {formik.errors.number}
                      </FormHelperText>
                    )}
                  </div> */}

                  {/* zip code */}

                  {/* {hideProfileItems(user) && (
                    <div className="form-group">
                      <label className="label">zip code</label>
                      <input
                        className="input-control"
                        name="zip"
                        maxLength={5}
                        onChange={formik.handleChange}
                        value={formik.values.zip}
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.zip && formik.errors.zip && (
                        <FormHelperText error>
                          {formik.errors.zip}
                        </FormHelperText>
                      )}
                    </div>
                  )} */}

                  <div className="form-group">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <label className="label">Learning Style</label>
                      <IconButton
                        color="primary"
                        size="small"
                        onClick={() => {
                          setOpenLearningStyleModal(true);
                        }}
                      >
                        <InfoIcon />
                      </IconButton>
                    </div>
                    <MultipleSelect
                      options={learningStyles}
                      formik={formik}
                      name="learningStyles"
                      label="Learning Styles"
                      handleChange={handleStyleChange}
                    />
                    {formik.touched.learningStyles &&
                      formik.errors.learningStyles && (
                        <FormHelperText error>
                          {formik.errors.learningStyles}
                        </FormHelperText>
                      )}
                  </div>
                </div>

                <div className="col-md-6 pl-5">
                  {/* {hideProfileItems(user) && (
                    <div className="form-group">
                      <label className="label">Class</label>
                      <input
                        className="input-control"
                        name="class"
                        onChange={formik.handleChange}
                        value={formik.values.class}
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.class && formik.errors.class && (
                        <FormHelperText error>
                          {formik.errors.class}
                        </FormHelperText>
                      )}
                    </div>
                  )} */}
                  <div className="form-group">
                    <label className="label">{isNorthwell?"Teacher / Mentor Name":"Teacher Name"}</label>
                    <select
                      className="input-control"
                      name="TeacherName"
                      onChange={(e) => {
                        if (e.target.value !== "Other") {
                          formik.handleChange(e);
                          setOther("");
                        } else {
                          setOther(e.target.value);
                        }
                      }}
                      value={
                        other === "Other" ? other : formik.values.TeacherName
                      }
                      onBlur={formik.handleBlur}
                    >
                      {adminList?.data?.map((admin) => {
                        return (
                          <option value={admin?.username}>
                            {admin?.username}
                          </option>
                        );
                      })}
                      <option value="Other">Other</option>
                    </select>
                    {other === "Other" && (
                      <input
                        className="input-control"
                        name="TeacherName"
                        onChange={formik.handleChange}
                        value={formik.values.TeacherName}
                        onBlur={formik.handleBlur}
                      />
                    )}
                    {formik.touched.TeacherName &&
                      formik.errors.TeacherName && (
                        <FormHelperText error>
                          {formik.errors.TeacherName}
                        </FormHelperText>
                      )}
                  </div>
                  <div className="form-group">
                    <label className="label">Organization</label>
                    <input
                      className="input-control"
                      name="org"
                      onChange={formik.handleChange}
                      value={
                        isOrgLoading
                          ? "Loading..."
                          : organizations?.filter(
                            (org) => org._id === user?.OrganizationId
                          )[0]?.name || "No Organization"
                      }
                      onBlur={formik.handleBlur}
                      disabled
                    />
                    {formik.touched.org && formik.errors.org && (
                      <FormHelperText error>{formik.errors.org}</FormHelperText>
                    )}
                  </div>
                  <div className="form-group mt-4 pt-3">
                    <a
                      href="../assets/pdf/terms-of-service-and-privacy-policy.pdf"
                      className="privacy-policy"
                      onClick={() =>
                        log({
                          pageName: "Profile",
                          buttonName: "Privacy Policy",
                        })
                      }
                      download
                    >
                      <p>{t("profile.privacyPolicy")}</p>
                      <ArrowForwardIosRoundedIcon
                        style={{
                          fontSize: "12px",
                          color: "#9b9fac",
                          fontWeight: "bold",
                        }}
                      />
                    </a>
                  </div>
                </div>
              </div>
            )}
            {badge && (
              <div className="row p-3 pl-5">
                <ul className="fields" style={{ paddingLeft: 120 }}>
                  <li className="field name">
                    <h5>Earned Badges</h5>
                  </li>
                  {!!earnedBadges.length ? (
                    earnedBadges?.map((badg) => {
                      return (
                        <li key={badg?._id} className="field name">
                          <div className="main">
                            <div
                              className="icon p-2"
                              style={{
                                backgroundColor: "#616ded",
                                width: "65px",
                                height: "65px",
                              }}
                            >
                              <img
                                width="50px"
                                src={badg?.iconID?.svg}
                                alt=""
                              />
                            </div>
                            <div
                              className="content"
                              style={{ marginLeft: "20px" }}
                            >
                              <p>{badg?.name}</p>
                              <span>{badg?.description}</span>
                            </div>
                          </div>
                        </li>
                      );
                    })
                  ) : (
                    <li className="field name">
                      <div className="main">
                        <div className="icon">
                          <ExtensionOffIcon style={{ color: "#616ded" }} />
                        </div>
                        <div className="content">
                          <p>No Badge</p>
                          <span>Complete at least one lesson for badges</span>
                        </div>
                      </div>
                    </li>
                  )}

                  {!!notEarnedBadges?.length && hideProfileItems(user) && (
                    <>
                      <li className="field name">
                        <h5>You can earn next</h5>
                      </li>
                      <div style={{ display: "flex", gap: "20px" }}>
                        {notEarnedBadges?.map((badge) => {
                          return (
                            <div
                              className="icon p-2"
                              style={{
                                backgroundColor: "#616ded",
                                width: "65px",
                                height: "65px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                borderRadius: "8px",
                              }}
                            >
                              <img
                                width="50px"
                                src={badge?.iconID?.svg}
                                alt=""
                              />
                            </div>
                          );
                        })}
                      </div>
                    </>
                  )}
                </ul>
              </div>
            )}
            <div className="crCard">{card && <Card />}</div>
            {notification && <Listings />}
          </form>
        </div>
        {/* Consumer Loan Application / Request Amount */}
        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => setOpen(false)}
          aria-describedby="alert-dialog-slide-description"
          className="request_modal"
        >
          <DialogTitle
            className="request-title text-center"
            style={{ borderBottom: "1px solid #c8c8c8" }}
          >
            <h3> Consumer Loan Application </h3>
            <p className="text-center">Stickball Bank - A Bank You Can Trust</p>
          </DialogTitle>
          <div className="dialog-body">
            <h4>Subject: Request for Extension of Credit Line</h4>
            <hr />
            <form onSubmit={formik1.handleSubmit}>
              <p>
                Dear
                <input
                  className="input-controls"
                  name="teacherName"
                  maxLength={120}
                  placeholder=" [Teacher's Name]"
                  onChange={formik1.handleChange}
                  value={formik1.values.teacherName}
                  onBlur={formik1.handleBlur}
                />
                {formik1.touched.teacherName && formik1.errors.teacherName && (
                  <FormHelperText error>
                    {formik1.errors.teacherName}
                  </FormHelperText>
                )}{" "}
                ,
              </p>
              <p>
                I hope this email finds you in good health and spirits. I am
                writing to request an extension of my credit line with{" "}
                <input
                  className="input-controls"
                  name="schoolName"
                  maxLength={120}
                  placeholder="[Your School Name]"
                  onChange={formik1.handleChange}
                  value={formik1.values.schoolName}
                  onBlur={formik1.handleBlur}
                />
                {formik1.touched.schoolName && formik1.errors.schoolName && (
                  <FormHelperText error>
                    {formik1.errors.schoolName}
                  </FormHelperText>
                )}
                .
              </p>
              <p>
                Due to{" "}
                <input
                  className="input-controls"
                  name="reasonForExtension"
                  maxLength={120}
                  placeholder="[reason for extension request]"
                  onChange={formik1.handleChange}
                  value={formik1.values.reasonForExtension}
                  onBlur={formik1.handleBlur}
                />
                {formik1.touched.reasonForExtension &&
                  formik1.errors.reasonForExtension && (
                    <FormHelperText error>
                      {formik1.errors.reasonForExtension}
                    </FormHelperText>
                  )}
                , I am currently facing a temporary cash flow issue and would
                greatly appreciate the additional financial support that an
                extended credit line would provide. I am confident that I will
                be able to repay the increased credit in full once my financial
                situation improves.
              </p>
              <p>
                I have been a valued customer of{" "}
                <input
                  className="input-controls"
                  name="schoolName"
                  maxLength={120}
                  placeholder="[Your School Name]"
                  onChange={formik1.handleChange}
                  value={formik1.values.schoolName}
                  onBlur={formik1.handleBlur}
                />
                {formik1.touched.schoolName && formik1.errors.schoolName && (
                  <FormHelperText error>
                    {formik1.errors.schoolName}
                  </FormHelperText>
                )}{" "}
                for{" "}
                <input
                  className="input-controls mt-2"
                  name="timeAsCustomer"
                  maxLength={120}
                  placeholder="[length of time as a customer]"
                  onChange={formik1.handleChange}
                  value={formik1.values.timeAsCustomer}
                  onBlur={formik1.handleBlur}
                />
                {formik1.touched.timeAsCustomer &&
                  formik1.errors.timeAsCustomer && (
                    <FormHelperText error>
                      {formik1.errors.timeAsCustomer}
                    </FormHelperText>
                  )}{" "}
                and I have always made timely payments on my outstanding
                balance. I am hoping that you will consider my request and
                provide me with the extension I need.
              </p>
              <p>
                Please let me know if there are any additional documents or
                information you require from me to process this request. I would
                be happy to provide them promptly.
              </p>
              <p>
                Thank you for your time and consideration. I look forward to
                hearing from you soon.
              </p>
              <p>Best regards,</p>
              <p>{user?.firstName ? user?.firstName : "User"}</p>

              <DialogContent className="dialog_content p-0">
                <div className="form-group">
                  <label className="label-top">Application Date</label>
                  <input
                    className="input-control w-50"
                    type="date"
                    name="applicationDate"
                    onChange={formik1.handleChange}
                    value={formik1.values.applicationDate}
                    onBlur={formik1.handleBlur}
                    style={{ cursor: "pointer" }}
                  />
                  <p>Date</p>
                  {formik1.touched.applicationDate &&
                    formik1.errors.applicationDate && (
                      <FormHelperText error>
                        {formik1.errors.applicationDate}
                      </FormHelperText>
                    )}
                </div>
                {/* <div className="application-detail">
                                <h4>Applicant Details</h4>
                            </div>
                            <div className="form-group">
                                <label className="label-top">Full Name</label>
                                <div className="row m-0">
                                    <div className="col-12 col-md-6 col-lg-6 ps-0">
                                        <input
                                            className="input-control w-100"
                                            name="FirstName"
                                            placeholder="Joe-Jane"
                                            onChange={formik1.handleChange}
                                            value={formik1.values.FirstName}
                                            onBlur={formik1.handleBlur}
                                        />
                                        <p>First Name</p>
                                        {formik1.touched.FirstName &&
                                            formik1.errors.FirstName && (
                                                <FormHelperText error>
                                                    {formik1.errors.FirstName}
                                                </FormHelperText>
                                            )}
                                    </div>
                                    <div className="col-12 col-md-6 col-lg-6 pe-0">
                                        <input
                                            className="input-control w-100"
                                            name="LastName"
                                            placeholder="Smith"
                                            onChange={formik1.handleChange}
                                            value={formik1.values.LastName}
                                            onBlur={formik1.handleBlur}
                                        />
                                        <p>Last Name</p>
                                        {formik1.touched.LastName && formik1.errors.LastName && (
                                            <FormHelperText error>
                                                {formik1.errors.LastName}
                                            </FormHelperText>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <label className="label-top">Age</label>
                                <div className="row m-0">
                                    <div className="col-12 col-md-6 col-lg-6 ps-0">
                                        <input
                                            className="input-control w-100"
                                            name="Age"
                                            maxLength={3}
                                            placeholder="e.g, 23"
                                            onChange={formik1.handleChange}
                                            value={formik1.values.Age}
                                            onBlur={formik1.handleBlur}
                                        />
                                        <p>Age</p>
                                        {formik1.touched.Age && formik1.errors.Age && (
                                            <FormHelperText error>
                                                {formik1.errors.Age}
                                            </FormHelperText>
                                        )}

                                        <label className="label-top">Date of Birth</label>
                                        <input
                                            className="input-control w-100"
                                            type="date"
                                            name="dob"
                                            onChange={formik1.handleChange}
                                            value={formik1.values.dob}
                                            onBlur={formik1.handleBlur}
                                            style={{ cursor: "pointer" }}
                                        />
                                        <p>Date</p>
                                        {formik1.touched.dob && formik1.errors.dob && (
                                            <FormHelperText error>
                                                {formik1.errors.dob}
                                            </FormHelperText>
                                        )}
                                        <div>
                                            <label className="label-top">Gender</label>
                                            <div className="p-1">
                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="gender"
                                                        onChange={(e) =>
                                                            handleRadioGender(e.target.value)
                                                        }
                                                        id="Male"
                                                        value="Male"
                                                    />
                                                    <label className="form-check-label ps-2" for="Male">
                                                        Male
                                                    </label>
                                                </div>
                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="gender"
                                                        onChange={(e) =>
                                                            handleRadioGender(e.target.value)
                                                        }
                                                        id="Female"
                                                        value="Female"
                                                    />
                                                    <label
                                                        className="form-check-label ps-2"
                                                        for="Female"
                                                    >
                                                        Female
                                                    </label>
                                                </div>
                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="gender"
                                                        onChange={(e) =>
                                                            handleRadioGender(e.target.value)
                                                        }
                                                        id="Other"
                                                        value="Other"
                                                    />
                                                    <label
                                                        className="form-check-label ps-2"
                                                        for="Other"
                                                    >
                                                        Other
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <label className="label-top">Phone Number</label>
                                        <input
                                            className="input-control w-100"
                                            name="Number"
                                            maxLength={14}
                                            placeholder="(000) 000-0000"
                                            onChange={formik1.handleChange}
                                            value={formik1.values.Number}
                                            onBlur={formik1.handleBlur}
                                        />
                                        {formik1.touched.Number && formik1.errors.Number && (
                                            <FormHelperText error>
                                                {formik1.errors.Number}
                                            </FormHelperText>
                                        )}

                                        <label className="label-top">Email</label>
                                        <input
                                            className="input-control w-100"
                                            name="email"
                                            onChange={formik1.handleChange}
                                            value={formik1.values.email}
                                            onBlur={formik1.handleBlur}
                                        />
                                        <p>example@example.com</p>
                                        {formik1.touched.email && formik1.errors.email && (
                                            <FormHelperText error>
                                                {formik1.errors.email}
                                            </FormHelperText>
                                        )}

                                        <label className="label-top">
                                            Type of ID you would like to present?
                                        </label>
                                        <input
                                            className="input-control w-100"
                                            name="present"
                                            // onChange={formik1.handleChange}
                                            value={formik1.values?.present?.toUpperCase()}
                                            onBlur={formik1.handleBlur}
                                        />
                                        <p>License, Passport, Student ID, or State ID</p>
                                        {formik1.touched.present && formik1.errors.present && (
                                            <FormHelperText error>
                                                {formik1.errors.present}
                                            </FormHelperText>
                                        )}
                                    </div>
                                    <hr />
                                </div>
                            </div> */}
                {/* Address */}
                {/* <div className="form-group">
                                <div className="row m-0">
                                    <label className="label-top">Address</label>
                                    <input
                                        className="input-control w-100"
                                        name="street1"
                                        onChange={formik1.handleChange}
                                        value={formik1.values.street1}
                                        onBlur={formik1.handleBlur}
                                    />
                                    <p>Street Address</p>
                                    {formik1.touched.street1 && formik1.errors.street1 && (
                                        <FormHelperText error>
                                            {formik1.errors.street1}
                                        </FormHelperText>
                                    )}

                                    <input
                                        className="input-control w-100"
                                        name="street2"
                                        onChange={formik1.handleChange}
                                        value={formik1.values.street2}
                                        onBlur={formik1.handleBlur}
                                    />
                                    <p>Street Address Line 2</p>
                                    {formik1.touched.street2 && formik1.errors.street2 && (
                                        <FormHelperText error>
                                            {formik1.errors.street2}
                                        </FormHelperText>
                                    )}
                                    <div className="col-12 col-md-6 col-lg-6 ps-0">
                                        <input
                                            className="input-control w-100"
                                            name="city"
                                            onChange={formik1.handleChange}
                                            value={formik1.values.city}
                                            onBlur={formik1.handleBlur}
                                        />
                                        <p>City</p>
                                        {formik1.touched.city && formik1.errors.city && (
                                            <FormHelperText error>
                                                {formik1.errors.city}
                                            </FormHelperText>
                                        )}
                                        <input
                                            className="input-control w-100"
                                            name="userState"
                                            maxLength={5}
                                            onChange={formik1.handleChange}
                                            value={formik1.values.userState}
                                            onBlur={formik1.handleBlur}
                                        />
                                        <p>State</p>
                                        {formik1.touched.userState &&
                                            formik1.errors.userState && (
                                                <FormHelperText error>
                                                    {formik1.errors.userState}
                                                </FormHelperText>
                                            )}
                                    </div>
                                    <div className="col-12 col-md-6 col-lg-6 pe-0">
                                        <input
                                            className="input-control w-100"
                                            name="province"
                                            onChange={formik1.handleChange}
                                            value={formik1.values.province}
                                            onBlur={formik1.handleBlur}
                                        />
                                        <p>province</p>
                                        {formik1.touched.province && formik1.errors.province && (
                                            <FormHelperText error>
                                                {formik1.errors.province}
                                            </FormHelperText>
                                        )}

                                        <input
                                            className="input-control w-100"
                                            name="zipcode"
                                            maxLength={5}
                                            onChange={formik1.handleChange}
                                            value={formik1.values.zipcode}
                                            onBlur={formik1.handleBlur}
                                        />
                                        <p>Postal / Zip Code</p>
                                        {formik1.touched.zipcode && formik1.errors.zipcode && (
                                            <FormHelperText error>
                                                {formik1.errors.zipcode}
                                            </FormHelperText>
                                        )}
                                    </div>

                                    <label className="label-top">Type of Residence</label>

                                    <div className="p-1">
                                        <div className="form-check">
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                name="residence"
                                                onChange={(e) => handleRadioResidence(e.target.value)}
                                                id="residence"
                                                value="Owned"
                                            />
                                            <label
                                                className="form-check-label ps-2"
                                                for="residence"
                                            >
                                                Owned
                                            </label>
                                        </div>
                                        <div className="form-check">
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                name="residence"
                                                onChange={(e) => handleRadioResidence(e.target.value)}
                                                id="exampleRadios6"
                                                value="Rented"
                                            />
                                            <label
                                                className="form-check-label ps-2"
                                                for="residence"
                                            >
                                                Rented
                                            </label>
                                        </div>
                                        <div className="form-check">
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                name="residence"
                                                onChange={(e) => handleRadioResidence(e.target.value)}
                                                id="exampleRadios7"
                                                value="Living with Family"
                                            />
                                            <label
                                                className="form-check-label ps-2"
                                                for="residence"
                                            >
                                                Living with Family
                                            </label>
                                        </div>
                                        <div className="form-check">
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                name="residence"
                                                onChange={(e) => handleRadioResidence(e.target.value)}
                                                id="exampleRadios8"
                                                value="others"
                                            />
                                            <label
                                                className="form-check-label ps-2"
                                                for="residence"
                                            >
                                                Other
                                            </label>
                                        </div>
                                    </div>

                                    <label className="label-top">
                                        How many years have you been staying in this residence?
                                    </label>
                                    <input
                                        className="input-control w-50"
                                        name="years"
                                        onChange={formik1.handleChange}
                                        value={formik1.values.years}
                                        onBlur={formik1.handleBlur}
                                    />
                                    {formik1.touched.years && formik1.errors.years && (
                                        <FormHelperText error>
                                            {formik1.errors.years}
                                        </FormHelperText>
                                    )}
                                </div>
                            </div>
                            <hr className="horizontal-line" />
                            <div className="form-group">
                                <div className="row m-0">
                                    <label className="label-top">Civil Status</label>
                                    <div className="p-1 row m-0">
                                        <div className="col-12 col-md-6 col-lg-6 ps-0">
                                            <div className="form-check">
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="civil"
                                                    onChange={(e) => handleRadioCivil(e.target.value)}
                                                    id="exampleRadios10"
                                                    value="Single"
                                                />
                                                <label className="form-check-label ps-2" for="civil">
                                                    Single
                                                </label>
                                            </div>
                                            <div className="form-check">
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="civil"
                                                    onChange={(e) => handleRadioCivil(e.target.value)}
                                                    id="exampleRadios11"
                                                    value="Divorced"
                                                />
                                                <label className="form-check-label ps-2" for="civil">
                                                    Divorced
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-6 col-lg-6 ps-0">
                                            <div className="form-check">
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="civil"
                                                    onChange={(e) => handleRadioCivil(e.target.value)}
                                                    id="exampleRadios12"
                                                    value="Married"
                                                />
                                                <label className="form-check-label ps-2" for="civil">
                                                    Married
                                                </label>
                                            </div>
                                            <div className="form-check">
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="civil"
                                                    onChange={(e) => handleRadioCivil(e.target.value)}
                                                    id="exampleRadios13"
                                                    value="Widowed"
                                                />
                                                <label className="form-check-label ps-2" for="civil">
                                                    Widowed
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row m-0">
                                    <label className="label-top">
                                        If married, please enter the name of the spouse
                                    </label>
                                    <div className="col-12 col-md-6 col-lg-6 ps-0">
                                        <input
                                            className="input-control w-100 eeeeeeeee"
                                            name="spouseFirstName"
                                            onChange={formik1.handleChange}
                                            value={formik1.values.spouseFirstName}
                                            onBlur={formik1.handleBlur}
                                        />
                                        <p>First Name</p>
                                        {formik1.touched.spouseFirstName &&
                                            formik1.errors.spouseFirstName && (
                                                <FormHelperText error>
                                                    {formik1.errors.spouseFirstName}
                                                </FormHelperText>
                                            )}
                                    </div>
                                    <div className="col-12 col-md-6 col-lg-6 pe-0">
                                        <input
                                            className="input-control w-100"
                                            name="spouseLastName"
                                            onChange={formik1.handleChange}
                                            value={formik1.values.spouseLastName}
                                            onBlur={formik1.handleBlur}
                                        />
                                        <p>Last Name</p>
                                        {formik1.touched.spouseLastName &&
                                            formik1.errors.spouseLastName && (
                                                <FormHelperText error>
                                                    {formik1.errors.spouseLastName}
                                                </FormHelperText>
                                            )}
                                    </div>
                                </div>
                                <label className="label-top">Spouse phone number</label>
                                <input
                                    className="input-control w-50"
                                    name="spouseNumber"
                                    maxLength={14}
                                    placeholder="(000) 000-0000"
                                    onChange={formik1.handleChange}
                                    value={formik1.values.spouseNumber}
                                    onBlur={formik1.handleBlur}
                                />
                                {formik1.touched.spouseNumber &&
                                    formik1.errors.spouseNumber && (
                                        <FormHelperText error>
                                            {formik1.errors.spouseNumber}
                                        </FormHelperText>
                                    )}
                                <label className="label-top">Number of dependents</label>
                                <input
                                    className="input-control w-50"
                                    name="dependents"
                                    placeholder="e.g, 23"
                                    onChange={formik1.handleChange}
                                    value={formik1.values.dependents}
                                    onBlur={formik1.handleBlur}
                                />
                                {formik1.touched.dependents && formik1.errors.dependents && (
                                    <FormHelperText error>
                                        {formik1.errors.dependents}
                                    </FormHelperText>
                                )}
                                <label className="label-top">
                                    Total amount of monthly payments (including rents/expenses
                                    etc.)
                                </label>
                                <input
                                    className="input-control w-50"
                                    name="payment"
                                    onChange={formik1.handleChange}
                                    value={formik1.values.payment}
                                    onBlur={formik1.handleBlur}
                                />
                                {formik1.touched.payment && formik1.errors.payment && (
                                    <FormHelperText error>
                                        {formik1.errors.payment}
                                    </FormHelperText>
                                )}
                            </div> */}
                {/* Employment Details */}
                {/* <div className="application-detail">
                                <h4>Employment Details</h4>
                            </div>
                            <div className="form-group">
                                <div className="row m-0">
                                    <label className="label-top">Source of Income</label>
                                    <div className="p-1">
                                        <div className="form-check">
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                name="source"
                                                onChange={(e) => handleRadioSource(e.target.value)}
                                                id="SourceofIncome2"
                                                value="Employed"
                                            />
                                            <label className="form-check-label ps-2" for="source">
                                                Employed
                                            </label>
                                        </div>
                                        <div className="form-check">
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                name="source"
                                                onChange={(e) => handleRadioSource(e.target.value)}
                                                id="SourceofIncome3"
                                                value="Self-Employed"
                                            />
                                            <label className="form-check-label ps-2" for="source">
                                                Self-Employed
                                            </label>
                                        </div>
                                        <div className="form-check">
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                name="source"
                                                onChange={(e) => handleRadioSource(e.target.value)}
                                                id="SourceofIncome4"
                                                value="Other"
                                            />
                                            <label className="form-check-label ps-2" for="source">
                                                Other
                                            </label>
                                        </div>
                                    </div>
                                    <label className="label-top">Occupation</label>
                                    <input
                                        className="input-control w-50"
                                        name="Occupation"
                                        onChange={formik1.handleChange}
                                        value={formik1.values.Occupation}
                                        onBlur={formik1.handleBlur}
                                    />
                                    {formik1.touched.Occupation &&
                                        formik1.errors.Occupation && (
                                            <FormHelperText error>
                                                {formik1.errors.Occupation}
                                            </FormHelperText>
                                        )}
                                    <label className="label-top">Job Position/Title</label>
                                    <input
                                        className="input-control w-50"
                                        name="job"
                                        onChange={formik1.handleChange}
                                        value={formik1.values.job}
                                        onBlur={formik1.handleBlur}
                                    />
                                    {formik1.touched.job && formik1.errors.job && (
                                        <FormHelperText error>
                                            {formik1.errors.job}
                                        </FormHelperText>
                                    )}
                                    <label className="label-top">
                                        How many years have you been working with this company?
                                    </label>
                                    <input
                                        className="input-control w-50"
                                        name="yearswork"
                                        placeholder="e.g, 23"
                                        onChange={formik1.handleChange}
                                        value={formik1.values.yearswork}
                                        onBlur={formik1.handleBlur}
                                    />
                                    {formik1.touched.yearswork && formik1.errors.yearswork && (
                                        <FormHelperText error>
                                            {formik1.errors.yearswork}
                                        </FormHelperText>
                                    )}
                                    <label className="label-top">Hired Date</label>
                                    <input
                                        className="input-control w-50"
                                        type="date"
                                        name="hiredate"
                                        onChange={formik1.handleChange}
                                        value={formik1.values.hiredate}
                                        onBlur={formik1.handleBlur}
                                        style={{ cursor: "pointer" }}
                                    />
                                    <p>Date</p>
                                    {formik1.touched.hiredate && formik1.errors.hiredate && (
                                        <FormHelperText error>
                                            {formik1.errors.hiredate}
                                        </FormHelperText>
                                    )}
                                    <label className="label-top">Company Name</label>
                                    <input
                                        className="input-control w-50"
                                        name="companyName"
                                        onChange={formik1.handleChange}
                                        value={formik1.values.companyName}
                                        onBlur={formik1.handleBlur}
                                    />
                                    {formik1.touched.companyName &&
                                        formik1.errors.companyName && (
                                            <FormHelperText error>
                                                {formik1.errors.companyName}
                                            </FormHelperText>
                                        )}
                                    <label className="label-top">Company Address</label>
                                    <input
                                        className="input-control w-100"
                                        name="companyAddress"
                                        onChange={formik1.handleChange}
                                        value={formik1.values.companyAddress}
                                        onBlur={formik1.handleBlur}
                                    />
                                    <p>Street Address</p>
                                    {formik1.touched.companyAddress &&
                                        formik1.errors.companyAddress && (
                                            <FormHelperText error>
                                                {formik1.errors.companyAddress}
                                            </FormHelperText>
                                        )}
                                    <input
                                        className="input-control w-100"
                                        name="companyAddress2"
                                        onChange={formik1.handleChange}
                                        value={formik1.values.companyAddress2}
                                        onBlur={formik1.handleBlur}
                                    />
                                    <p>Street Address Line 2</p>
                                    {formik1.touched.companyAddress2 &&
                                        formik1.errors.companyAddress2 && (
                                            <FormHelperText error>
                                                {formik1.errors.companyAddress2}
                                            </FormHelperText>
                                        )}
                                    <div className="col-12 col-md-6 col-lg-6 ps-0">
                                        <input
                                            className="input-control w-100"
                                            name="companyCity"
                                            onChange={formik1.handleChange}
                                            value={formik1.values.companyCity}
                                            onBlur={formik1.handleBlur}
                                        />
                                        <p>City</p>
                                        {formik1.touched.companyCity &&
                                            formik1.errors.companyCity && (
                                                <FormHelperText error>
                                                    {formik1.errors.companyCity}
                                                </FormHelperText>
                                            )}
                                    </div>
                                    <div className="col-12 col-md-6 col-lg-6 pe-0">
                                        <input
                                            className="input-control w-100"
                                            name="companyProvince"
                                            onChange={formik1.handleChange}
                                            value={formik1.values.companyProvince}
                                            onBlur={formik1.handleBlur}
                                        />
                                        <p>province</p>
                                        {formik1.touched.companyProvince &&
                                            formik1.errors.companyProvince && (
                                                <FormHelperText error>
                                                    {formik1.errors.companyProvince}
                                                </FormHelperText>
                                            )}
                                    </div>
                                    <input
                                        className="input-control w-100"
                                        name="companyZipCode"
                                        maxLength={5}
                                        onChange={formik1.handleChange}
                                        value={formik1.values.companyZipCode}
                                        onBlur={formik1.handleBlur}
                                    />
                                    <p>Postal / Zip Code</p>
                                    {formik1.touched.companyZipCode &&
                                        formik1.errors.companyZipCode && (
                                            <FormHelperText error>
                                                {formik1.errors.companyZipCode}
                                            </FormHelperText>
                                        )}

                                    <label className="label-top">
                                        Monthly Gross Income ($)
                                    </label>
                                    <input
                                        className="input-control w-50"
                                        name="grossIncome"
                                        onChange={formik1.handleChange}
                                        value={formik1.values.grossIncome}
                                        onBlur={formik1.handleBlur}
                                    />
                                    {formik1.touched.grossIncome &&
                                        formik1.errors.grossIncome && (
                                            <FormHelperText error>
                                                {formik1.errors.grossIncome}
                                            </FormHelperText>
                                        )}
                                    <label className="label-top">Annual Gross Income ($)</label>
                                    <input
                                        className="input-control w-50"
                                        name="annualIncome"
                                        onChange={formik1.handleChange}
                                        value={formik1.values.annualIncome}
                                        onBlur={formik1.handleBlur}
                                    />
                                    {formik1.touched.annualIncome &&
                                        formik1.errors.annualIncome && (
                                            <FormHelperText error>
                                                {formik1.errors.annualIncome}
                                            </FormHelperText>
                                        )}
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="row m-0">
                                    <label className="label-top">Employment History</label>
                                    <div className="col-lg-3 col-md-3 col-6 ps-0">
                                        <label
                                            className="label"
                                            style={{ textTransform: "capitalize" }}
                                        >
                                            Company Name
                                        </label>
                                        <input
                                            className="input-control w-100"
                                            name="HistoryComName"
                                            onChange={formik1.handleChange}
                                            value={formik1.values.HistoryComName}
                                            onBlur={formik1.handleBlur}
                                        />
                                        {formik1.touched.HistoryComName &&
                                            formik1.errors.HistoryComName && (
                                                <FormHelperText error>
                                                    {formik1.errors.HistoryComName}
                                                </FormHelperText>
                                            )}
                                    </div>
                                    <div className="col-lg-3 col-md-3 col-6">
                                        <label
                                            className="label"
                                            style={{ textTransform: "capitalize" }}
                                        >
                                            Position
                                        </label>
                                        <input
                                            className="input-control w-100"
                                            name="position"
                                            onChange={formik1.handleChange}
                                            value={formik1.values.position}
                                            onBlur={formik1.handleBlur}
                                        />
                                        {formik1.touched.position && formik1.errors.position && (
                                            <FormHelperText error>
                                                {formik1.errors.position}
                                            </FormHelperText>
                                        )}
                                    </div>
                                    <div className="col-lg-3 col-md-3 col-6">
                                        <label
                                            className="label"
                                            style={{ textTransform: "capitalize" }}
                                        >
                                            Phone #
                                        </label>
                                        <input
                                            className="input-control w-100"
                                            name="Phone"
                                            onChange={formik1.handleChange}
                                            value={formik1.values.Phone}
                                            onBlur={formik1.handleBlur}
                                        />
                                        {formik1.touched.Phone && formik1.errors.Phone && (
                                            <FormHelperText error>
                                                {formik1.errors.Phone}
                                            </FormHelperText>
                                        )}
                                    </div>
                                    <div className="col-lg-3 col-md-3 col-6 pe-0">
                                        <label
                                            className="label"
                                            style={{ textTransform: "capitalize" }}
                                        >
                                            # of Months
                                        </label>
                                        <input
                                            className="input-control w-100"
                                            name="Month"
                                            onChange={formik1.handleChange}
                                            value={formik1.values.Month}
                                            onBlur={formik1.handleBlur}
                                        />
                                        {formik1.touched.Month && formik1.errors.Month && (
                                            <FormHelperText error>
                                                {formik1.errors.Month}
                                            </FormHelperText>
                                        )}
                                    </div>
                                    <div className=" my-4">
                                        <button type="button" class="btn btn-secondary">
                                            +
                                        </button>
                                    </div>
                                    <label className="label-top">
                                        Please upload any required documents here
                                    </label>
                                    <input
                                        className="input-control w-100"
                                        accept="image/*"
                                        type="file"
                                        name="file"
                                        onChange={(e) => fileUpload(e.target.files[0])}
                                        // value={}
                                        onBlur={formik1.handleBlur}
                                    />
                                    {formik1.values.file && (
                                        <img width="100px" src={formik1.values.file} alt="" />
                                    )}

                                    {formik1.touched.file && formik1.errors.file && (
                                        <FormHelperText error>
                                            {formik1.errors.file}
                                        </FormHelperText>
                                    )}
                                </div>
                            </div> */}
                {/* Loan Details */}
                {/* <div className="application-detail">
                                <h4>Loan Details</h4>
                            </div> */}
                <div className="form-group">
                  <div className="row m-0">
                    {/* <label className="label-top">Loan Terms</label> */}
                    <div className="p-1 row m-0">
                      {/* <div className="col-6 ps-0"> */}
                      {/* <div className="form-check">
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="LoanTerms"
                                                    onChange={(e) => handleRadioLoan(e.target.value)}
                                                    id="exampleRadios41"
                                                    value="year1"
                                                />
                                                <label
                                                    className="form-check-label ps-2"
                                                    for="LoanTerms"
                                                >
                                                    1 year
                                                </label>
                                            </div> */}
                      {/* <div className="form-check">
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="LoanTerms"
                                                    onChange={(e) => handleRadioLoan(e.target.value)}
                                                    id="exampleRadios42"
                                                    value="year3"
                                                />
                                                <label
                                                    className="form-check-label ps-2"
                                                    for="LoanTerms"
                                                >
                                                    3 years
                                                </label>
                                            </div>
                                            <div className="form-check">
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="LoanTerms"
                                                    onChange={(e) => handleRadioLoan(e.target.value)}
                                                    id="exampleRadios43"
                                                    value="year5"
                                                />
                                                <label
                                                    className="form-check-label ps-2"
                                                    for="LoanTerms"
                                                >
                                                    5 years
                                                </label>
                                            </div>
                                            <div className="form-check">
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="LoanTerms"
                                                    onChange={(e) => handleRadioLoan(e.target.value)}
                                                    id="exampleRadios44"
                                                    value="year7"
                                                />
                                                <label
                                                    className="form-check-label ps-2"
                                                    for="LoanTerms"
                                                >
                                                    7 years
                                                </label>
                                            </div>
                                        </div> */}
                      {/* <div className="col-6 pe-0">
                                            <div className="form-check">
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="LoanTerms"
                                                    onChange={(e) => handleRadioLoan(e.target.value)}
                                                    id="exampleRadios51"
                                                    value="year2"
                                                />
                                                <label
                                                    className="form-check-label ps-2"
                                                    for="LoanTerms"
                                                >
                                                    2 year
                                                </label>
                                            </div>
                                            <div className="form-check">
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="LoanTerms"
                                                    onChange={(e) => handleRadioLoan(e.target.value)}
                                                    id="exampleRadios52"
                                                    value="year4"
                                                />
                                                <label
                                                    className="form-check-label ps-2"
                                                    for="LoanTerms"
                                                >
                                                    4 years
                                                </label>
                                            </div>
                                            <div className="form-check">
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="LoanTerms"
                                                    onChange={(e) => handleRadioLoan(e.target.value)}
                                                    id="exampleRadios53"
                                                    value="year6"
                                                />
                                                <label
                                                    className="form-check-label ps-2"
                                                    for="LoanTerms"
                                                >
                                                    6 years
                                                </label>
                                            </div>
                                            <div className="form-check">
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="LoanTerms"
                                                    onChange={(e) => handleRadioLoan(e.target.value)}
                                                    id="exampleRadios54"
                                                    value="other"
                                                />
                                                <label
                                                    className="form-check-label ps-2"
                                                    for="LoanTerms"
                                                >
                                                    Other
                                                </label>
                                            </div>
                                        </div> */}

                      <label className="label-top">Loan Amount</label>
                      <input
                        className="input-control w-50"
                        name="loanAmount"
                        onChange={formik1.handleChange}
                        value={formik1.values.loanAmount}
                        onBlur={formik1.handleBlur}
                      />
                      {formik1.touched.loanAmount &&
                        formik1.errors.loanAmount && (
                          <FormHelperText error>
                            {formik1.errors.loanAmount}
                          </FormHelperText>
                        )}

                      {/* <label className="label-top">Purpose of Loan</label>
                                        <input
                                            className="input-control w-50"
                                            name="purposeLoan"
                                            onChange={formik1.handleChange}
                                            value={formik1.values.purposeLoan}
                                            onBlur={formik1.handleBlur}
                                        />
                                        {formik1.touched.purposeLoan &&
                                            formik1.errors.purposeLoan && (
                                                <FormHelperText error>
                                                    {formik1.errors.purposeLoan}
                                                </FormHelperText>
                                            )}
                                        <label className="label-top">
                                            Reason why you are requesting for a loan
                                        </label>
                                        <textarea
                                            className="input-control w-100"
                                            type="textarea"
                                            rows="6"
                                            name="ReasonLoan"
                                            onChange={formik1.handleChange}
                                            value={formik1.values.ReasonLoan}
                                            onBlur={formik1.handleBlur}
                                        />
                                        {formik1.touched.ReasonLoan &&
                                            formik1.errors.ReasonLoan && (
                                                <FormHelperText error>
                                                    {formik1.errors.ReasonLoan}
                                                </FormHelperText>
                                            )}*/}
                    </div>
                  </div>
                </div>
                {/* <div className="application-detail">
                                <h4>References</h4>
                            </div> */}
                {/* References */}
                {/* <div className="form-group">
                                <div className="row m-0">
                                    <label className="label-top">References</label>
                                    <table class="table table-bordered">
                                        <thead>
                                            <tr>
                                                <th scope="col"></th>
                                                <th scope="col">Name</th>
                                                <th scope="col">Relationship</th>
                                                <th scope="col">Company Name</th>
                                                <th scope="col">Phone Number</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>1</td>
                                                <td>
                                                    <input
                                                        type="text"
                                                        className="input_table"
                                                        onChange={formik1.handleChange}
                                                        value={formik1.values.ref1name}
                                                        onBlur={formik1.handleBlur}
                                                        name="ref1name"
                                                    />
                                                    {formik1.touched.ref1name &&
                                                        formik1.errors.ref1name && (
                                                            <FormHelperText error>
                                                                {formik1.errors.ref1name}
                                                            </FormHelperText>
                                                        )}
                                                </td>
                                                <td>
                                                    <input
                                                        type="text"
                                                        className="input_table"
                                                        onChange={formik1.handleChange}
                                                        value={formik1.values.ref1relationship}
                                                        onBlur={formik1.handleBlur}
                                                        name="ref1relationship"
                                                    />
                                                    {formik1.touched.ref1relationship &&
                                                        formik1.errors.ref1relationship && (
                                                            <FormHelperText error>
                                                                {formik1.errors.ref1relationship}
                                                            </FormHelperText>
                                                        )}
                                                </td>
                                                <td>
                                                    <input
                                                        type="text"
                                                        className="input_table"
                                                        onChange={formik1.handleChange}
                                                        value={formik1.values.ref1companyname}
                                                        onBlur={formik1.handleBlur}
                                                        name="ref1companyname"
                                                    />
                                                    {formik1.touched.ref1companyname &&
                                                        formik1.errors.ref1companyname && (
                                                            <FormHelperText error>
                                                                {formik1.errors.ref1companyname}
                                                            </FormHelperText>
                                                        )}
                                                </td>
                                                <td>
                                                    <input
                                                        type="text"
                                                        className="input_table"
                                                        onChange={formik1.handleChange}
                                                        value={formik1.values.ref1namephone}
                                                        onBlur={formik1.handleBlur}
                                                        name="ref1namephone"
                                                    />
                                                    {formik1.touched.ref1namephone &&
                                                        formik1.errors.ref1namephone && (
                                                            <FormHelperText error>
                                                                {formik1.errors.ref1namephone}
                                                            </FormHelperText>
                                                        )}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>2</td>
                                                <td>
                                                    <input type="text" className="input_table" />
                                                </td>
                                                <td>
                                                    <input type="text" className="input_table" />
                                                </td>
                                                <td>
                                                    <input type="text" className="input_table" />
                                                </td>
                                                <td>
                                                    <input type="text" className="input_table" />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>3</td>
                                                <td>
                                                    <input type="text" className="input_table" />
                                                </td>
                                                <td>
                                                    <input type="text" className="input_table" />
                                                </td>
                                                <td>
                                                    <input type="text" className="input_table" />
                                                </td>
                                                <td>
                                                    <input type="text" className="input_table" />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="application-detail">
                                <h4>Terms and Conditions</h4>
                            </div> */}
                {/* Terms and Conditions */}
                <div className="form-group">
                  <div className="row m-0">
                    {/* <div className="paragraph-file ps-0">
                                        <p>
                                            I also confirm that all information in this form is
                                            accurate and true.
                                        </p>
                                        <p>
                                            l authorize Stickball Bank to do a background check on
                                            me if needed.
                                        </p>
                                        <p>
                                            I understand that the information I entered in this form
                                            will be considered strictly confidential and private.
                                        </p>
                                        <p>
                                            I understand that the loan I'll receive (if approved)
                                            will not be used on any illegal activities.
                                        </p>
                                        <p>
                                            I agree to indemnify Stickball Bank and all of its
                                            employees for any liabilities or harm.
                                        </p>
                                    </div>
                                    <div className="p-1">
                                        <div className="form-check my-5">
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                value=""
                                                id="flexCheckDefault"
                                            />
                                            <label
                                                className="form-check-label"
                                                for="flexCheckDefault"
                                            >
                                                I agree to <a href="#"> terms & conditions </a>
                                                of the Stickball Bank. <span> *</span>
                                                <span>required</span>
                                            </label>
                                        </div>
                                    </div> */}
                    <label className="label-top">Applicant Signature</label>
                    <input
                      className="input-control w-50"
                      name="signature"
                      onChange={formik1.handleChange}
                      value={formik1.values.signature}
                      onBlur={formik1.handleBlur}
                    />
                    {formik1.touched.signature && formik1.errors.signature && (
                      <FormHelperText error>
                        {formik1.errors.signature}
                      </FormHelperText>
                    )}
                    <label className="label-top">Date Signed</label>
                    <input
                      className="input-control w-50"
                      type="date"
                      name="datesigned"
                      onChange={formik1.handleChange}
                      value={formik1.values.datesigned}
                      onBlur={formik1.handleBlur}
                      style={{ cursor: "pointer" }}
                    />
                    <p>Date</p>
                    {formik1.touched.datesigned &&
                      formik1.errors.datesigned && (
                        <FormHelperText error>
                          {formik1.errors.datesigned}
                        </FormHelperText>
                      )}
                  </div>
                </div>
              </DialogContent>
              <hr />
              <div className="submit-button text-center">
                <button type="submit">Submit</button>
              </div>
            </form>
          </div>
        </Dialog>
        {/* <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => setOpen(false)}
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle className="dialog-title">Add Amount</DialogTitle>
          <form onSubmit={formik1.handleSubmit}>
            <DialogContent style={{ width: '400px' }}>
              <div className="form-group">
                <label className="label">Amount</label>
                <input className="input-control"
                  name="amount"
                  onChange={formik1.handleChange}
                  value={formik1.values.amount}
                  onBlur={formik1.handleBlur}
                />
                {(formik1.touched.amount && formik1.errors.amount) && (
                  <FormHelperText error>
                    {formik1.errors.amount}
                  </FormHelperText>
                )}
              </div>
            </DialogContent>
            <DialogActions>
              <Button className="btn-logout" onClick={() => setOpen(false)}><span style={{ color: '#242424' }}>Cancel</span></Button>
              <Button className="btn-logout" type="submit">Request</Button>
            </DialogActions>
          </form>
        </Dialog> */}
        <LearningStyleInfoModal
          openModal={openLearningStyleModal}
          handleClose={closeLearningStyleModal}
        />
      </>
    );
}
