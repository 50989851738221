import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import TurnPhoneV from '../other/TurnPhoneV'

import { useMutation } from 'react-query';
import { userLog } from '../_api/index'
import { useTranslation } from 'react-i18next'
import Parser from "html-react-parser";

import { QuillEditor } from '../lessons/details/quil-editor'

import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import { useSelector } from 'react-redux'

import Slide from '@mui/material/Slide';
import LessonSlider from '../lessons/details/LessonSlider'

import { slideInRight } from 'react-animations';
import Radium, { StyleRoot } from 'radium';
const styles = {
  slideInRight: {
    animation: 'x 2s',
    animationName: Radium.keyframes(slideInRight, 'slideInRight')
  },
}


export default function BusinessFoundation(props) {

  const { t } = useTranslation();
  const { mutate: log } = useMutation(userLog);
  const userData = useSelector((state) => state.user.userData?.data)
  const { id } = useParams();
  const [businessF, setBusinessF] = useState(true)
  const [isMobile, setIsMobile] = useState(false);
  const [isDesktop, setIsDesktop] = useState(false)
  const [boxHeight, setBoxHeight] = useState(250)
  function handleOrientation() {
    setIsMobile(window.innerHeight > window.innerWidth && window.innerWidth < 1000)
    setIsDesktop(window.innerWidth < 1000)
    if (window.innerWidth > 1300) {
      setBoxHeight(250)
    } else if (window.innerWidth > 1250) {
      setBoxHeight(250)
    } else if (window.innerWidth > 1200) {
      setBoxHeight(250)
    } else if (window.innerWidth > 1100) {
      setBoxHeight(200)
    }
  }
  window.onresize = handleOrientation;
  useEffect(() => {
    setIsMobile(window.innerHeight > window.innerWidth && window.innerWidth < 1000)
    setIsDesktop(window.innerWidth < 1000)
    if (window.innerWidth > 1300) {
      setBoxHeight(250)
    } else if (window.innerWidth > 1250) {
      setBoxHeight(250)
    } else if (window.innerWidth > 1200) {
      setBoxHeight(250)
    } else if (window.innerWidth > 1100) {
      setBoxHeight(200)
    }
  }, [window.innerWidth, window.innerHeight, window.orientation]);
  if (isMobile) {
    return (<TurnPhoneV />)
  } else
    return (
      <>
        <StyleRoot>
          <div className="head"
            style={{
              padding: '20px',
              display: 'flex',
              justifyContent: 'center',
              position: 'fixed',
              paddingTop: '37px',
              left: '0px',
              right: '0px',
              zIndex: 10000,
              backgroundColor: '#616ded',
              overflow: 'hidden'
            }}>
            <div className="btn-back-home" onClick={() => {
              log({
                pageName: 'Module Details',
                buttonName: 'Back'
              })
              setBusinessF(false);
              if (isDesktop) {
                setTimeout(() => {
                  window.history.go(-1)
                }, 1000)
              } else {
                window.history.go(-1)
              }

            }}>
              <ArrowBackIosRoundedIcon style={{ fontSize: '18px', cursor: 'pointer' }} />
            </div>
            <img width="130px" src="/assets/svg/logo-white.gif" alt="" />
          </div>
          <div className="body-landscape" style={{ overflow: 'hidden', }} >
            <div className="container-landscape main-home-container main-home-1-container" style={{ overflow: 'auto', }}>
              <div className="container-inner-landscape" style={{ overflow: 'hidden', }}>

                {
                  isDesktop ?
                    <>
                      <div className="main-content"
                        style={{ padding: '0px', paddingTop: '100px', width: '100%', overflow: 'hidden', alignItems: 'unset' }}
                      >
                        <Slide direction="left" in={businessF} timeout={1000} mountOnEnter unmountOnExit>
                          <div className="card details-card" style={styles.slideInRight}>
                            <div className="head">
                              <img src={
                                userData?.filter(module => { return (module._id === id) })[0]?.icon?.svg
                              } alt="" />
                              <h1>{userData?.filter(module => { return (module._id === id) })[0]?.name}</h1>
                            </div>
                            <div className="buttons">
                              <Link to="/altspace-vr">
                                <button className="get-started-btn" onClick={() => log({
                                  pageName: 'Module Details',
                                  buttonName: 'Get Started'
                                })}>{t("module.btnStarted")}</button>
                              </Link>
                            </div>

                          </div>
                        </Slide>
                        <Slide direction="left" in={businessF} timeout={1000} mountOnEnter unmountOnExit>
                          <div className="card card-slide" style={styles.slideInRight}>
                            <LessonSlider userData={userData} moduleID={id} />
                            {
                              userData.filter(module => { return (module._id === id) })[0]?.lessons?.length === 0 &&
                              <img width="50%" src="../assets/svg/lesson/no-data.svg" alt="" />
                            }
                          </div>
                        </Slide>
                      </div>
                    </>
                    :
                    <>
                      <div className="main-content container-fluid"
                        style={{
                          padding: '0px',
                          paddingTop: '26px',
                          width: '100%',
                          flexDirection: 'column',
                          alignItems: 'center',
                          justifyContent: 'center',
                          paddingBottom: '0px',
                          overflow: 'hidden',
                        }}
                      >
                        <div className="row d-flex justify-content-center align-items-center" style={{ overflowY: 'auto', width: '100%' }}>
                          <div className="col-4 d-flex justify-content-center align-items-center" style={{ height: '90vh' }}>
                            <div className="card details-card">
                              <div className="head">
                                <img src={
                                  userData?.filter(module => { return (module._id === id) })[0]?.icon?.svg
                                } alt="" />
                                <h1 style={{ fontWeight: 'bold' }}>{userData?.filter(module => { return (module._id === id) })[0]?.name}</h1>
                              </div>
                              <div className="buttons">
                                <Link to="/altspace-vr">
                                  <button className="get-started-btn" onClick={() => log({
                                    pageName: 'Module Details',
                                    buttonName: 'Get Started'
                                  })}>{t("module.btnStarted")}</button>
                                </Link>
                              </div>
                            </div>
                          </div>
                          <div className="col-8" style={{ height: '100%', padding: '20px', paddingTop: '40px', paddingBottom: 0 }}>
                            <div className="row d-flex justify-content-center align-items-center pt-5" style={{ height: '100%', width: '100%' }}>
                              {
                                userData?.filter(module => { return (module._id === id) })[0]?.lessons?.map((lesson, index) => {
                                  return (
                                    <>
                                      <div className="col-6 mb-4">
                                        <div className="card1">
                                          <div style={{
                                            display: 'flex',
                                            padding: '12px',
                                            borderRadius: '8px',
                                            backgroundColor: '#fff',
                                            minHeight: boxHeight,
                                            width: '100%'
                                          }}
                                          >
                                            <div
                                              style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                padding: '46px 30px',
                                                borderRadius: '5px',
                                                backgroundColor: '#fb785c',
                                              }}
                                            >
                                              <img width="46" src={lesson?.icon?.svg} alt="" />
                                            </div>
                                            <div
                                              style={{
                                                marginLeft: '12px',
                                                height: boxHeight,
                                                overflow: 'auto'
                                              }}
                                            >
                                              <h1 style={{ fontSize: '25px', fontWeight: 'bold' }}>{Parser(lesson.name || "")}</h1>
                                              {/* <Tooltip title={<QuillEditor value={lesson?.description} readOnly={true} />} arrow> */}
                                              <p>
                                                <QuillEditor value={lesson?.description} readOnly={true} />
                                              </p>
                                              {/* </Tooltip> */}

                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  )
                                })
                              }
                              {
                                userData.filter(module => { return (module._id === id) })[0]?.lessons?.length === 0 &&
                                <div className="col-6 d-flex justify-content-center align-items-center pb-5" style={{ width: '100%' }}>
                                  <img className="mb-3" width="50%" src="../assets/svg/lesson/no-data.svg" alt="" />
                                </div>
                              }
                            </div>

                          </div>
                        </div>

                      </div>
                    </>
                }

              </div>
            </div>
          </div>
        </StyleRoot>
      </>
    )
}
