import React from 'react'

export default function LazzyLoading() {
    return <div>
        <div className="lazzy-container d-flex flex-column">
            <h4 style={{ color: '#616ded', marginBottom: '20px' }}>Loading...</h4>
            <div class="lds-roller">
                <div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div>
            </div>
        </div>
    </div>
}
