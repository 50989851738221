import React, { useState, useEffect } from 'react'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import { useMutation, useQueryClient } from 'react-query';
import { login, userLog } from '../_api/index'
import isAuthenticated from '../auth/auth'
import { useTranslation } from 'react-i18next'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import uuid from 'react-uuid'
import CircularProgress from '@mui/material/CircularProgress';
import { Grid, Button, Typography, FormControl, TextField } from "@mui/material"
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Header from '../common/NewHeader';
import OutlinedInput from '@mui/material/OutlinedInput';
import { useSelector, useDispatch } from 'react-redux'
import { setLoading } from '../../redux-features/user/userSlice'
import { handleUser } from '../../redux-features/userDetails'
import ApiSauce from '../../services/networkRequest'
import { USER } from '../../config/WebServices';
import GoogleLoginButton from './GoogleLoginButton';

export default function Login() {

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    // Retrieve specific query parameters
    const link = queryParams.get('returnURL');
    const anchor = window.location.hash.substring(1);
    const NOUNCE = uuid();
    const { t } = useTranslation();
    const dispatch = useDispatch()
    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const { mutate: log } = useMutation(userLog);
    const navigate = useNavigate()
    const queryClient = useQueryClient();
    const [message, setMessage] = useState("")
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const register = useSelector(state => state.user.register)

    const { isLoading: isLoading, isSuccess: isSuccess, mutate: loginData } = useMutation(login, {
        onSuccess: (response) => loginResponseHandling(response),
        onError: (error) => loginResponseHandling(null, error)
    });
  
    const errorMessage = (error) => {
        console.log("🚀 ~ errorMessage ~ error:", error)
    }
    
    const loginResponseHandling = (response, error) => {
        if (error) {
            const { message } = error
            if (message === "Request failed with status code 404") return setMessage("Sorry, User does not exist")
            return setMessage(`Internal Error, Sorry for inconvenience, Please try again!`)
        }
        const { token, message } = response
        if (token) {
            setMessage(`User logged in successfully`)
            localStorage.setItem("token", token);
            getUserDetails(token)
            dispatch(setLoading(true))
            queryClient.invalidateQueries('fetching-all-data');

            if (register) return navigate("/video")
            if (link) return navigate(link + "#" + anchor)
            navigate('/module')
            return

        }
        if (message === "No such User") return setMessage(`Sorry, this email is not registered for login, Please Register and try again`)
        if (response.message === "Invalid Credentials") return setMessage(`Given password is not valid, Please try again or reset your password`)
        if (response.message === "Password not found") return setMessage(`Password not found. Please login using Google.`)
    }
    const getUserDetails = async (token) => {
        try {
            const { user } = await ApiSauce.getWithToken({ url: USER, token })
            user.token = token
            dispatch(handleUser(user))
        } catch (error) {
            console.log("🚀 ~ file: Login.js:98 ~ getUserDetails ~ error:", error)
        }
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        const data = {
            email: email,
            password: password,
        }
        loginData(data)
    }
    useEffect(() => {
        // storing input name
        localStorage.setItem("NOUNCE", NOUNCE);
    }, [NOUNCE]);

    return (
        <>
            {isAuthenticated(localStorage.getItem("token")) && navigate("/module")}
            <Header backLink='/' logo page='Login' />
            <Grid className="loginscreen" container sx={{ height: "100vh", padding: "8vw 14vw" }}>
                <Grid item xs={12} md={7} conatainer justifyContent="center" sx={{ flexDirection: "column" }}>
                    <h1 className="loginscreen__heading loginscreen__headingone">Login</h1>
                    <form onSubmit={(e) => { handleSubmit(e) }}>
                        <div className="loginscreen__email">
                            <label className="loginscreen__label">Email Address</label>
                            <div className="loginscreen__inputIcon">

                                <TextField
                                    variant="outlined"
                                    className="loginscreen__email"
                                    fullWidth
                                    id="email"
                                    placeholder={t('login.userName')}
                                    size="small"
                                    onChange={(e) => {
                                        setEmail(e.target.value)
                                    }}
                                    sx={{ borderRadius: "10px", width: "100%", '& legend': { display: 'none' }, '& fieldset': { top: 0 } }}
                                />

                            </div>
                        </div>
                        <div className="loginscreen__password">
                            <label className="loginscreen__label">Password</label>
                            <div className="loginscreen__inputIcon">
                                <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
                                    <OutlinedInput
                                        id="outlined-adornment-password"
                                        type={showPassword ? 'text' : 'password'}
                                        size="small"
                                        className="loginscreen__password"
                                        onChange={(e) => {
                                            setPassword(e.target.value)
                                            setMessage("")
                                        }}
                                        placeholder={t('login.password')}
                                        sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 } }}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                    edge="end"
                                                >
                                                    {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                    />
                                </FormControl>
                            </div>
                        </div>
                        <p className="text-danger error-text">{message}</p>
                        <Typography varinat="body1" className="loginscreen__forgotpassword">
                            <Link className="forgot-password" to="/reset-password">
                                {t('login.forgotPassword')}
                            </Link>
                        </Typography>
                        <div className="loginscreen__btnwidth">
                            <Button disabled={!password || !email} type="submit" style={{ width: "100%" }} variant="contained" className="loginscreen__button loginscreen__buttonone"
                                onClick={() => log(
                                    {
                                        pageName: 'Login',
                                        buttonName: 'Login'
                                    })}>

                                {
                                    isLoading ?
                                        <CircularProgress style={{ color: 'white' }} />
                                        : <>{t('login.login')}</>
                                }
                            </Button>
                        </div>
                    </form>
                    <div className="loginscreen__btnwidth">
                        <a href={`https://clever.com/oauth/authorize?response_type=token&redirect_uri=${process.env.REACT_APP_REDIRECT_URL}&client_id=${process.env.REACT_APP_CLEVER_CLIENT_ID}&state=${NOUNCE}`}>
                            <Button variant="outlined" style={{ width: "100%" }} className="loginscreen__button loginscreen__buttontwo">Login with Clever</Button>
                        </a>
                    </div>
                    <GoogleLoginButton />
                    {/* <div className="loginscreen__btnwidth">
                        <hr />
                    </div> */}
                    {/* <div className="loginscreen__btnwidth" style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
                            <GoogleLogin
                                width={300}
                                theme='filled_blue'
                                onSuccess={(response) => loginData({...response, loginType:"google"})} 
                                onError={errorMessage} 
                            />
                    </div> */}
                </Grid>
                <Grid item container justifyContent="center" alignItems="center" xs={5} sx={{ display: { xs: "none", md: "flex" } }}>
                    <img className="loginscreen__illustration" src="../assets/img/auth.svg" alt="" />
                </Grid>
            </Grid>
        </>
    )
}
