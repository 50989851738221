import React, { useState, useEffect } from 'react'
import TurnPhoneH from '../other/TurnPhoneH'
import { Link, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import isAuthenticated from '../auth/auth'
import { Grid, Button, Typography } from "@mui/material"


import { useMutation } from 'react-query';
import { userLog } from '../_api/index'
export default function Wellcome() {
    const navigate = useNavigate()
    const { t, i18n } = useTranslation();
    const { mutate: log } = useMutation(userLog);
    // const [lang, setLang] = useState("en")

    const [isMobile, setIsMobile] = useState(false);
    function handleOrientation() {
        setIsMobile(window.innerHeight < window.innerWidth && window.innerWidth < 1000)
    }
    window.onresize = handleOrientation;
    useEffect(() => {
        setIsMobile(window.innerHeight < window.innerWidth && window.innerWidth < 1000)
    }, [window.innerWidth, window.innerHeight, window.orientation]);

    // useEffect(() => {
    //     localStorage.setItem("lang", lang)
    //     i18n.changeLanguage(localStorage.getItem("lang"))
    // }, [lang])



    if (isMobile) {
        return (<TurnPhoneH content="Turn your phone to side to start" />)
    } else
        return (

            <>{isAuthenticated(localStorage.getItem("token")) && navigate("/module")}
                <div className="body" >
                    <Grid className="welcomescreen" container sx={{ height: "100vh" }}>
                        <Grid item sm={12} md={7}>
                            <img className="welcomescreen__image" src="/assets/svg/logo.gif" style={{ width: '25vh' }} alt="" />
                            <h1 className="welcomescreen__heading welcomescreen__headingone">{(t('welcome.beforeBr').split(" "))[0]} <span className="welcomescreen__headingspan">{t('welcome.beforeBr').split(" ").splice(1, 10).join(" ")}</span></h1>
                            <h1 className="welcomescreen__heading">{(t('welcome.afterBr').split(" "))[0]} <span className="welcomescreen__headingspan">{t('welcome.afterBr').split(" ").splice(1, 10).join(" ")}</span></h1>

                            <Grid item container justifyContent="center" alignItems="center" sx={{ display: { xs: 'flex', md: 'none' } }} sm={5}>
                                <img className="welcomescreen__illustration2" src="../assets/img/welcomeillustration.png" alt="" />
                            </Grid>

                            <Link className="welcomescreen__link" to="/learn-more"><Button onClick={() => log(
                                {
                                    pageName: 'Welcome Screen',
                                    buttonName: 'Learn More'
                                })} variant="contained" className="welcomescreen__button welcomescreen__buttonone">{t('welcome.btnLearnMore')}</Button></Link>
                            <Link className="welcomescreen__link" to="/get-started"><Button onClick={() => log(
                                {
                                    pageName: 'Welcome Screen',
                                    buttonName: 'Skip Tour'
                                })} variant="outlined" className="welcomescreen__button welcomescreen__buttontwo" >{t('welcome.btnSkip')}</Button></Link>
                        </Grid>
                        <Grid item container justifyContent="center" alignItems="center" sx={{ display: { xs: 'none', md: 'flex' } }} sm={5}>
                            <img className="welcomescreen__illustration" src="../assets/img/welcomeillustration.png" alt="" />
                        </Grid>
                        <Grid item xs={12} container alignItems="flex-end" justifyContent="flex-end">
                            <Grid container sx={{ justifyContent: { xs: "center", md: "flex-end" } }} alignItems="flex-end">
                                <Typography className="welcomescreen__lang welcomescreen__languagetext" variant="body1"
                                    sx={{ marginRight: "0.7rem", color: "#9B9FAC" }}> {t('gotCode.langChange')} :</Typography>
                                <Typography className="welcomescreen__lang language"
                                    onClick={() => {
                                        localStorage.setItem('lang', 'en')
                                        window.location.reload()
                                    }}
                                    variant="body1" sx={{ marginRight: "1rem" }}
                                    style={{ color: localStorage.getItem("lang") === 'en' ? '#616DED' : '#9B9FAC' }}
                                >
                                    {t('gotCode.langEng')}
                                </Typography>
                                <Typography className="welcomescreen__lang language"
                                    onClick={() => {
                                        localStorage.setItem('lang', 'sp')
                                        window.location.reload()
                                    }}
                                    variant="body1"
                                    style={{ color: localStorage.getItem("lang") === 'sp' ? '#616DED' : '#9B9FAC' }}
                                >
                                    {t('gotCode.langSp')}
                                </Typography>
                                <Typography className="welcomescreen__lang language"
                                    onClick={() => {
                                        localStorage.setItem('lang', 'ht')
                                        window.location.reload()
                                    }}
                                    variant="body1"
                                    style={{ color: localStorage.getItem("lang") === 'ht' ? '#616DED' : '#9B9FAC', marginLeft: 20 }}
                                >
                                    {t('gotCode.langHt')}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    {/* <div className="container splash-screen-container">
                        <div className="container-inner row d-flex justify-content-center">
                            <img src="/assets/svg/logo.gif" style={{ width: '180px' }} alt="" />
                            <div className="content col-sm-4">
                                <div className="main">
                                    <img src="../assets/img/welcomeillustration.png" alt="" />
                                    <h1>{t('welcome.beforeBr')}
                                        <br /> {t('welcome.afterBr')}</h1>
                                </div>
                                <div className="buttons">
                                    <Link to="/learn-more">
                                        <button className="get-started" onClick={() => log(
                                            {
                                                pageName: 'Welcome Screen',
                                                buttonName: 'Learn More'
                                            })}
                                        > {t('welcome.btnLearnMore')}</button>
                                    </Link>
                                    <Link to="/get-started">
                                        <button className="login"
                                            onClick={() => log(
                                                {
                                                    pageName: 'Welcome Screen',
                                                    buttonName: 'Skip Tour'
                                                })}
                                        >{t('welcome.btnSkip')}</button>
                                    </Link>
                                    <div className="field mt-3 btn-select" style={{ textAlign: 'left' }}>
                                        <div className="row d-flex justify-content-center text-light">
                                            {t('gotCode.langChange')} :
                                            <button
                                                onClick={() => setLang('en')}
                                                style={{
                                                    outline: 'none',
                                                    border: 'none',
                                                    width: 'fit-content',
                                                    backgroundColor: 'transparent',
                                                    color: localStorage.getItem("lang") === 'en' ? '#fb785c' : '#fff'
                                                }}
                                            >{t('gotCode.langEng')}</button>
                                            <button
                                                onClick={() => setLang('sp')}
                                                style={{
                                                    outline: 'none',
                                                    border: 'none',
                                                    width: 'fit-content',
                                                    backgroundColor: 'transparent',
                                                    color: localStorage.getItem("lang") === 'sp' ? '#fb785c' : '#fff'
                                                }}
                                            >{t('gotCode.langSp')}</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}

                </div>
            </>
        )
}
