import React, { useState, useEffect } from 'react'
import TurnPhoneH from '../other/TurnPhoneH'
import { useLocation, useNavigate } from 'react-router-dom'
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close'
import Header from '../common/NewHeader'
import { fullRegister, userLog, getOrganizations, googleSignup, login } from '../_api/index'
import { getAdmindzByOrg } from '../_api/journal'
import CircularProgress from '@mui/material/CircularProgress';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import NumbersOutlinedIcon from '@mui/icons-material/NumbersOutlined';

import ClassOutlinedIcon from '@mui/icons-material/ClassOutlined';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';

import { Upload } from "@aws-sdk/lib-storage";
import { S3Client } from "@aws-sdk/client-s3";
import { useTranslation } from 'react-i18next'
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import { useMutation, useQueryClient, useQuery } from 'react-query';
import { styled } from '@mui/material/styles';

import Badge from '@mui/material/Badge';
import Avatar from '@mui/material/Avatar';
import toast from 'react-hot-toast'
import { TextField, Grid, Typography, FormControl, Button, InputLabel, Container, Box, Modal } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from 'moment';
import { FormControlLabel } from '@mui/material';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import InputAdornment from '@mui/material/InputAdornment';
import AccountCircle from '@mui/icons-material/AccountCircle';
import { setLoading as setLoading4 } from '../../redux-features/user/userSlice';
import ApiSauce from '../../services/networkRequest'
import DriveFileRenameOutlineOutlinedIcon from '@mui/icons-material/DriveFileRenameOutlineOutlined';
import { useDispatch, useSelector } from 'react-redux';
import { handleUser } from '../../redux-features/userDetails';
import { USER } from '../../config/WebServices';
import { useGoogleLogin } from '@react-oauth/google';

const SmallAvatar = styled(Avatar)(({ theme }) => ({
    width: 22,
    height: 22,
    border: `2px solid ${theme.palette.background.paper}`,
}));

export default function FullRegister() {

    const { t } = useTranslation();
    const navigate = useNavigate()
    const { mutate: log } = useMutation(userLog);

    const dispatch = useDispatch()

    const register = useSelector(state => state.user.register)
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const link = queryParams.get('returnURL');
    const anchor = window.location.hash.substring(1);

    const queryClient = useQueryClient();
    const [serverError, setServerError] = useState(false)
    const [profileImage, setProfileImage] = useState("")
    const [email, setEmail] = useState("")
    const [dob, setDob] = useState()
    const [zipCode, setZipCode] = useState("")
    const [phone, setPhone] = useState("")
    const [password, setPassword] = useState("")
    const [message, setMessage] = useState("")
    const [error, setError] = useState(false)
    const [showPass1, setShowPass1] = useState(false)
    const [showPass2, setShowPass2] = useState(false)
    const [loading, setLoading] = useState(false)
    const [organization, setOrganization] = useState("")
    const [organizations, setOrganizations] = useState([])
    const [dobLabel, setDobLabel] = useState()
    const [dateValue, setDateValue] = useState(null)
    const navigateLink = "/email-verification/" + email;
    const [value, setValue] = React.useState(null);
    const [file, setFileName] = useState()
    const [error2, setError2] = useState()
    const [firstName, setFirstName] = useState()
    const [lastName, setLastName] = useState()
    const [confirmPass, setConfirmPass] = useState()
    const [teacher, setTeacher] = useState("")
    const [other, setOther] = useState("")
    const [modalOpen, setModalOpen] = useState(false)
    const [payload, setPayload] = useState()
    const [isMobile, setIsMobile] = useState(false);

    let isNorthwell = process.env.REACT_APP_API_LINK.includes("northwell")

    const handleClickShowPassword = () => setShowPass1((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };


    const handleClickShowPassword2 = () => setShowPass2((show) => !show);

    const handleMouseDownPassword2 = (event) => {
        event.preventDefault();
    };
    const { isLoading, data: orgs } = useQuery('get-organizations', getOrganizations)
    useEffect(() => {
        if (!isLoading) {
            setOrganizations(orgs?.data || [])
            setOrganization(orgs?.data[0]?._id || "")
        }
    }, [isLoading, orgs])


    const { isLoading: adminsLoading, data: adminsList } = useQuery(['admins-by-org', organization], () => getAdmindzByOrg(organization), {
        enabled: Boolean(organization)
    })
    const { isLoading: reqLoading, isSuccess: isSuccess3, mutate: fullReg } = useMutation(fullRegister, {
        onSuccess: (response) => {
            queryClient.invalidateQueries('request-code');
            setMessage("Registered Successfully !")
            setServerError(false)
            setTimeout(() => {
                navigate(navigateLink)
            }, 1000);
        },
        onError: (error) => {
            if (error.message === "Request failed with status code 409") {
                setMessage("User already registered, try with different Email")
                setServerError(true)
            } else {
                setMessage("Internal error, sorry for inconvenience, please try again!")
                setServerError(true)
            }
        }
    });

    const fileUpdate = async (file) => {
        setLoading(true)
        const target = { Bucket: process.env.REACT_APP_BUCKET_NAME, Key: file.name, Body: file };
        const creds = {
            accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
            secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
        }
        try {
            const parallelUploads3 = new Upload({
                client: new S3Client({ region: process.env.REACT_APP_REGION, credentials: creds }),
                leavePartsOnError: false, // optional manually handle dropped parts
                params: target,
            });

            parallelUploads3.on("httpUploadProgress", (progress) => {
            });

            await parallelUploads3.done().then((res) => {
                setProfileImage(`https://${process.env.REACT_APP_BUCKET_NAME}.s3.amazonaws.com/${file.name}`)
                setLoading(false)
            })

            setFileName(file.name)

        } catch (e) {
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        if (!password) {
            setMessage("Please Enter Password")
            setServerError(true)
            return
        }
        if (!email) {
            setMessage("Please Select Email")
            setServerError(true)
            return
        }
        if (!firstName) {
            setMessage("Please Enter Name")
            setServerError(true)
            return
        } else {

        }
        if (organization === "") {
            setMessage("Please select organization")
            setServerError(true)
            return
        }

        var regName = /[a-zA-Z ]*$/
        if (!regName.test(firstName)) {
            setMessage("Please Enter Correct Name")
            setServerError(true)
            return
        }
        if (firstName.length < 3) {
            console.log(firstName)
            setMessage("Name must be atealst five chracter long")
            setServerError(true)
            return
        }
        // if(lastName.length < 3){
        //     setMessage("Last Name should be at least 5 character long")
        //     setServerError(true)
        //     return
        // }
        if (!dob || dob == 0) {
            setMessage("Please Enter correct age")
            setServerError(true)
            return
        }
        if (!password) {
            setMessage("Please Enter password")
            setServerError(true)
            return
        }
        if (password.length < 6) {
            setMessage("Password must be at least 6 chracter long")
            setServerError(true)
            return
        }
        if (!teacher) {
            setMessage("Please Select a teacher")
            setServerError(true)
            return
        }
        // if (!zipCode) {
        //     setMessage("Enter Zipcode")
        //     setServerError(true)
        //     return
        // }
        // if (zipCode?.length < 3) {
        //     setMessage("Zipcode must be at least 3 character long")
        //     setServerError(true)
        //     return
        // }
        if (confirmPass != password) {
            setMessage("Passwords do not match")
            setServerError(true)
            return
        }
        if (!error) {
            const currentDate = moment();
            const modifiedDate = currentDate.subtract(dob, 'years');
            const data = {
                email: email,
                firstName: firstName,
                // lastName: lastName,
                password: password,
                zipCode: zipCode,
                dob:modifiedDate.format('MM/DD/YYYY'),
                phoneNumber: '',
                OrganizationId: organization,
                photo: profileImage,
                teacher: teacher,
            }
            console.log("data",data)
            fullReg(data);
        }
    }

    function handleOrientation() {
        setIsMobile(window.innerHeight < window.innerWidth && window.innerWidth < 1000)
    }
    window.onresize = handleOrientation;

    const { isLoading: reqLoading2, isSuccess: isSuccess2, mutate: GoogleSignup } = useMutation(googleSignup, {
        onSuccess: (response) => {
            console.log("🚀 ~ GetStarted ~ response:", response)
            queryClient.invalidateQueries('request-code');
            loginResponseHandling(response,null)
            setMessage("Registered Successfully !")
        },
        onError: (error) => {
            if (error.message === "Request failed with status code 409") {
                setMessage("User already registered, try with different Email")
            } else {
                setMessage("Internal error, sorry for inconvenience, please try again!")
            }
        }
    });
    
    const _g_login = useGoogleLogin({
        onSuccess: async tokenResponse => {
          loginData({tokenResponse:tokenResponse.access_token, loginType: "google" })
        },
        flow: 'implicit', // implicit is the default
      });
      const { isLoading: isLoading2, isSuccess: isSuccess, mutate: loginData } = useMutation(login, {
        onSuccess: (response) => {
            loginResponseHandling(response)
        },
        onError: (error) => {
            if(error?.response?.data?.payload){
                let _payload = error?.response?.data?.payload
                setPayload({
                    firstName:_payload.given_name,
                    lastName:_payload.family_name,
                    email:_payload.email,
                    picture: _payload.picture
                })
            }
            loginResponseHandling(null, error)
        }
    });
    const handleGoogleSignup = () => {
        const data = {
            ...payload,
            OrganizationId: organization,
            teacher: teacher,
        }
        GoogleSignup(data)

    }
    const loginResponseHandling = (response, error) => {
        if (error) {
            const { message } = error
            if (message === "Request failed with status code 404") {
                setModalOpen(true)
                return setMessage("Sorry, User does not exist")
            }
            return setMessage(`Internal Error, Sorry for inconvenience, Please try again!`)
        }
        const { token, message } = response
        if (token) {
            setMessage(`User logged in successfully`)
            localStorage.setItem("token", token);
            getUserDetails(token)
            dispatch(setLoading4(true))
            queryClient.invalidateQueries('fetching-all-data');

            if (register) return navigate("/video")
            if (link) return navigate(link + "#" + anchor)
            navigate('/module')
            return

        }
        if (message === "No such User !") {
            return setMessage(`Sorry, this email is not registered for login, Please Register and try again`)
        }
        if (response.message === "Invalid Credentials") return setMessage(`Given password is not valid, Please try again or reset your password`)
        if (response.message === "Password not found") return setMessage(`Password not found. Please login using Google.`)

    }

    const getUserDetails = async (token) => {
        try {
            const { user } = await ApiSauce.getWithToken({ url: USER, token })
            user.token = token
            dispatch(handleUser(user))
        } catch (error) {
            console.log("🚀 ~ file: Login.js:98 ~ getUserDetails ~ error:", error)
        }
    }
    useEffect(() => {
        setIsMobile(window.innerHeight < window.innerWidth && window.innerWidth < 1000)
    }, [window.innerWidth, window.innerHeight, window.orientation]);
    if (isMobile) {
        return (<TurnPhoneH content="Turn your phone to side for Register" />)
    } else
        return (
            <>
                {
                    isSuccess &&
                    <div className="text-white">
                        <Alert className="alert" severity="success"
                            action={
                                <IconButton
                                    aria-label="close"
                                    color="inherit"
                                    size="small"
                                    onClick={() => {
                                        navigate(navigateLink)
                                    }}
                                >
                                    <CloseIcon fontSize="inherit" />
                                </IconButton>
                            }
                        ><h5 className="text-success">{message}</h5></Alert>
                    </div>
                }

                <Header logo backLink="/customer-support" goBack={true} page="Code Register" />

                <Container maxWidth="lg">
                    <Grid container mt={8}>
                        <Grid container sm={6}>
                            <form onSubmit={(e) => handleSubmit(e)} style={{ width: "100%" }}>
                                <Grid item xs={12} my={1}>
                                    <Typography sx={{ fontWeight: '700', fontSize: '30px', color: '#616DED' }}>Register</Typography>
                                    <Typography sx={{ fontWeight: '600', fontSize: '12px', textTransform: 'uppercase', color: '#616DED' }} mt={3}>Personal Information</Typography>
                                </Grid>
                                <Grid item xs={12} my={1}>
                                    <FormControl sx={{ width: "70%" }}>
                                        <Typography pl={1} sx={{ fontWeight: "600", fontSize: "12px", color: "#242424", textTransform: "uppercase" }}>PROFILE PICTURE</Typography>
                                        <Grid container item xs={12} direction="column" sx={{ background: "#F0F1FF", borderRadius: "1rem", }}>
                                            <Grid container item xs={12} alignItems="center">
                                                <Grid container item xs={12} p={4} direction="column">
                                                    <Typography sx={{ fontWeight: "500", fontSize: "12px", color: "#616DED" }}>Upload your profile picture</Typography>
                                                    {
                                                        file ?
                                                            file :
                                                            <Grid container justifyContent="flex-start" alignItems="center">
                                                                <FileUploadOutlinedIcon sx={{ color: "#616DED" }} />
                                                                <Typography sx={{ fontWeight: "500", fontSize: "12px", color: "#616DED" }}>Upload</Typography>
                                                            </Grid>
                                                    }
                                                </Grid>
                                                <input accept="image/*"
                                                    onChange={(e) => fileUpdate(e.target.files[0])} type="file"
                                                    style={{ opacity: '0', position: 'absolute', width: '100%', height: '100%', maxHeight: '80%', zIndex: 1000, cursor: 'pointer' }} />
                                            </Grid>
                                        </Grid>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} my={1}>
                                    <FormControl sx={{ width: "70%" }}>
                                        <Typography pl={1}
                                            sx={{ fontWeight: "600", fontSize: "12px", color: "#242424", textTransform: "uppercase" }}>
                                            Nick Name <span style={{ color: 'red' }}>*</span>
                                        </Typography>
                                        <TextField size="small" placeholder="Nick Name" type="text" fullWidth id="outlined-basic"
                                            onChange={(e) => setFirstName(e.target.value)} value={firstName} variant="outlined"
                                            sx={{ fontFamily: "Poppins", '& legend': { display: 'none' }, '& fieldset': { top: 0 } }}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <DriveFileRenameOutlineOutlinedIcon />
                                                    </InputAdornment>
                                                ),
                                            }} />
                                    </FormControl>
                                </Grid>
                                {/* <Grid item xs={12} my={1}>
                                    <FormControl sx={{ width: "70%" }}>
                                        <Typography pl={1} sx={{ fontWeight: "600", fontSize: "12px", color: "#242424", textTransform: "uppercase" }}>Last Name</Typography>
                                        <TextField size="small"  placeholder="Please Enter Your Last name" type="text" fullWidth id="outlined-basic" onChange={(e) => setLastName(e.target.value)} value={lastName} variant="outlined" sx={{ fontFamily: "Poppins", '& legend': { display: 'none' }, '& fieldset': { top: 0 } }}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <PermIdentityIcon />
                                                    </InputAdornment>
                                                ),
                                            }} />
                                    </FormControl>
                                </Grid> */}
                                <Grid item xs={12} my={1}>
                                    <FormControl sx={{ width: "70%" }}>
                                        <Typography pl={1} sx={{ fontWeight: "600", fontSize: "12px", color: "#242424", textTransform: "uppercase" }}>
                                            EMAIL ADDRESS <span style={{ color: 'red' }}>*</span>
                                        </Typography>
                                        <TextField size="small" placeholder="Please Enter Your Email" type="email" fullWidth id="outlined-basic"
                                            onChange={(e) => setEmail(e.target.value)} value={email} variant="outlined"
                                            sx={{ fontFamily: "Poppins", '& legend': { display: 'none' }, '& fieldset': { top: 0 } }}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <EmailOutlinedIcon />
                                                    </InputAdornment>
                                                ),
                                            }} />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} my={1}>
                                    <FormControl sx={{ width: "70%" }}>
                                        <Typography pl={1} sx={{ fontWeight: "600", fontSize: "12px", color: "#242424", textTransform: "uppercase" }}>
                                            AGE <span style={{ color: 'red' }}>*</span>
                                        </Typography>
                                        <TextField size="small" placeholder="Please Enter Your Age" type="number" fullWidth id="outlined-basic"
                                            onChange={(e) => {
                                                setDob(e.target.value)
                                            }} value={dob} variant="outlined"
                                            sx={{ fontFamily: "Poppins", '& legend': { display: 'none' }, '& fieldset': { top: 0 } }}
                                        />
                                        {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DatePicker
                                                value={dob}
                                                onChange={(newValue) => {
                                                    setDob(moment(newValue?.$d).format("MM/DD/YYYY"));
                                                }}
                                                className="aaaaaaaaaaaaaaaaa"
                                                renderInput={
                                                    (params) => <TextField
                                                        className="texxxxxxxxxxxt"
                                                        style={inputStyle}
                                                        onFocus={() => setDobLabel("")} fullWidth variant="outlined" size="small"
                                                        sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 } }}  {...params} />
                                                }
                                                inputFormat="MM/DD/YYYY"
                                            />
                                        </LocalizationProvider> */}
                                    </FormControl>
                                </Grid>
                                {/* <Grid item xs={12} my={1}>
                                    <FormControl sx={{ width: "70%" }}>
                                        <Typography pl={1} sx={{ fontWeight: "600", fontSize: "12px", color: "#242424", textTransform: "uppercase" }}>CONTACT NUMBER</Typography>
                                        <TextField size="small" placeholder={t('fullRegister.phoneNumber')} onChange={(e) => {
                                            setPhone(e.target.value)
                                            var re = "^\([0-9]{3}\)[0-9]{3}-[0-9]{4}$";
                                            setError2(re.test(e.target.value))
                                            
                                        }} value={phone} fullWidth id="outlined-basic" sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 } }} variant="outlined" InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <LocalPhoneOutlinedIcon />
                                                </InputAdornment>
                                            ),
                                        }} />
                                        {
                                            error &&
                                            <p className="error">Enter phone number in US format</p>
                                        }
                                    </FormControl>
                                </Grid> */}


                                {/* <Grid item xs={12} my={1}>
                                    <FormControl sx={{ width: "70%" }}>
                                        <Typography pl={1} sx={{ fontWeight: "600", fontSize: "12px", color: "#242424", textTransform: "uppercase" }}>
                                            ZIP CODE
                                        </Typography>
                                        <TextField size="small" placeholder="Enter Zip Code"
                                            onChange={(e) => setZipCode(e.target.value)} value={zipCode} fullWidth id="outlined-basic"
                                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 } }} variant="outlined" InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <NumbersOutlinedIcon />
                                                    </InputAdornment>
                                                ),
                                            }} />
                                    </FormControl>
                                </Grid> */}

                                <Grid item xs={12} my={1}>
                                    <FormControl sx={{ width: "70%" }}>
                                        <Typography pl={1} sx={{ fontWeight: "600", fontSize: "12px", color: "#242424", textTransform: "uppercase" }}>
                                            Organization <span style={{ color: 'red' }}>*</span>
                                        </Typography>

                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={organization}
                                            label={"Text"}
                                            className="reqcode__role"
                                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 } }}
                                            size="small"
                                            onChange={(e) => setOrganization(e.target.value)}
                                            defaultValue="Student"

                                        >
                                            {
                                                isLoading ?
                                                    <MenuItem >Loading...</MenuItem>
                                                    :
                                                    organizations?.sort((a, b) => a?.name?.localeCompare(b?.name))?.map((org) => <MenuItem key={org?._id} value={org?._id}>{org?.name}</MenuItem>)
                                            }
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} my={1}>
                                    <FormControl sx={{ width: "70%" }}>
                                        <Typography pl={1} sx={{ fontWeight: "600", fontSize: "12px", color: "#242424", textTransform: "uppercase" }}>
                                            Teacher <span style={{ color: 'red' }}>*</span>
                                        </Typography>

                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={!!other ? other : teacher}
                                            label={"Teacher"}
                                            className="reqcode__role"
                                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 } }}
                                            size="small"
                                            onChange={(e) => {
                                                if (e.target.value !== 'Other') {
                                                    setTeacher(e.target.value)
                                                    setOther("")
                                                } else {
                                                    setOther(e.target.value)
                                                    setTeacher("")
                                                }
                                            }}
                                            defaultValue="Select Teacher"
                                            disabled={Boolean(!organization)}


                                        >
                                            <MenuItem disabled value="xyz" >Select Teacher</MenuItem>
                                            {
                                                adminsLoading ?
                                                    <MenuItem disabled value="xyz" >Loading...</MenuItem>
                                                    :
                                                    adminsList?.map((admin) => <MenuItem key={admin?.username} value={admin?.username}>{admin?.username}</MenuItem>)

                                            }

                                            <MenuItem value="Other">Other</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                {
                                    other === "Other" &&

                                    <Grid item xs={12} my={1}>
                                        <FormControl sx={{ width: "70%" }}>
                                            <TextField size="small" placeholder={`Enter ${isNorthwell?"Teacher / Mentor Name":"Teacher Name"}`} type="text" fullWidth id="outlined-basic"
                                                onChange={(e) => setTeacher(e.target.value)} value={teacher} variant="outlined"
                                                sx={{ fontFamily: "Poppins", '& legend': { display: 'none' }, '& fieldset': { top: 0 } }}
                                            />
                                        </FormControl>
                                    </Grid>
                                }

                                <Grid item xs={12} my={1}>
                                    <FormControl sx={{ width: "70%" }}>
                                        <Typography pl={1} sx={{ fontWeight: "600", fontSize: "12px", color: "#242424", textTransform: "uppercase" }}>
                                            Password <span style={{ color: 'red' }}>*</span>
                                        </Typography>
                                        <OutlinedInput
                                            id="outlined-adornment-password"
                                            type={showPass1 ? 'text' : 'password'}
                                            size="small"
                                            className="loginscreen__password"
                                            onChange={(e) => setPassword(e.target.value)}
                                            value={password}

                                            placeholder={t('login.password')}
                                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 } }}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowPassword}
                                                        onMouseDown={handleMouseDownPassword}
                                                        edge="end"
                                                    >
                                                        {showPass1 ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                        />
                                    </FormControl>
                                </Grid>



                                <Grid item xs={12} my={1}>
                                    <FormControl sx={{ width: "70%" }}>
                                        <Typography pl={1} sx={{ fontWeight: "600", fontSize: "12px", color: "#242424", textTransform: "uppercase" }}>
                                            Confirm Password <span style={{ color: 'red' }}>*</span>
                                        </Typography>
                                        <OutlinedInput
                                            id="outlined-adornment-password"
                                            type={showPass2 ? 'text' : 'password'}
                                            size="small"
                                            value={confirmPass}
                                            className="loginscreen__password"
                                            onChange={(e) => { setConfirmPass(e.target.value) }}
                                            placeholder="Confirm Password"
                                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 } }}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowPassword2}
                                                        onMouseDown={handleMouseDownPassword2}
                                                        edge="end"
                                                    >
                                                        {showPass2 ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                        />
                                    </FormControl>
                                </Grid>


                                {
                                    error && <p className="text-danger error-text">Password doesn't match !</p>
                                }
                                {
                                    serverError &&
                                    <p className="text-danger error-text">{message}</p>
                                }


                                <Grid item xs={12} my={1}>
                                    <FormControl sx={{ width: "70%" }}>
                                        <Button variant="contained" sx={{ width: "100%", background: "#616DED" }} type="submit">{
                                            reqLoading ?
                                                <CircularProgress style={{ color: 'white' }} />
                                                : <>{t('fullRegister.btnNext')}</>
                                        }</Button>
                                    </FormControl>
                                </Grid>
                            </form>
                            <Grid item xs={12} sm={8} mt={3} width={"70%"} style={{marginBottom:15,marginLeft:8}}>
                          <Button onClick={() => _g_login()} variant="outlined" className="getstarted__button google_button" 
                            style={styles.googleBtn}>
                                <img src='../assets/img/google-icon.png' style={{ width: 35 }} />Continue With Google</Button>
                        </Grid>
                        </Grid>

                        <Grid item container sm={6} justifyContent="center" alignItems="center">
                            <Box width="100%">
                                <img src="assets/img/reg.svg" alt="Registration Vector" />
                            </Box>
                        </Grid>
                    </Grid >
                    
                <Modal
                    open={modalOpen}
                    onClose={() => setModalOpen(prev => !prev)}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style} >
                        {/* <p onClick={() => setModalOpen(false)} style={styles.CloseIcon}>❌</p> */}
                        <Typography id="modal-modal-title" variant="h6" component="h3">
                            Please provide following information to proceed
                        </Typography>

                        <Grid container>
                            <Grid item xs={12} my={1}>
                                {/* <FormControl sx={{ width: "70%" }}> */}
                                <Typography pl={1} sx={{ fontWeight: "600", fontSize: "12px", color: "#242424", textTransform: "uppercase" }}>
                                    Organization <span style={{ color: 'red' }}>*</span>
                                </Typography>

                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={organization}
                                    label={"Text"}
                                    className="reqcode__role"
                                    sx={{ '& legend': { display: 'none' }, width: '100%', '& fieldset': { top: 0 } }}
                                    size="small"
                                    onChange={(e) => setOrganization(e.target.value)}
                                    defaultValue="Student"

                                >
                                    {
                                        isLoading ?
                                            <MenuItem >Loading...</MenuItem>
                                            :
                                            organizations?.sort((a, b) => a?.name?.localeCompare(b?.name))?.map((org) => <MenuItem key={org?._id} value={org?._id}>{org?.name}</MenuItem>)
                                    }
                                </Select>
                                {/* </FormControl> */}
                            </Grid>
                            <Grid item xs={12} my={1}>
                                <Typography pl={1} sx={{ fontWeight: "600", fontSize: "12px", color: "#242424", textTransform: "uppercase" }}>
                                    Teacher <span style={{ color: 'red' }}>*</span>
                                </Typography>

                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={!!other ? other : teacher}
                                    label={"Teacher"}
                                    className="reqcode__role"
                                    sx={{ '& legend': { display: 'none' }, width: '100%', '& fieldset': { top: 0 } }}
                                    size="small"
                                    onChange={(e) => {
                                        if (e.target.value !== 'Other') {
                                            setTeacher(e.target.value)
                                            setOther("")
                                        } else {
                                            setOther(e.target.value)
                                            setTeacher("")
                                        }
                                    }}
                                    defaultValue="Select Teacher"
                                    disabled={Boolean(!organization)}
                                >
                                    <MenuItem disabled value="xyz" >Select Teacher</MenuItem>
                                    {
                                        adminsLoading ?
                                            <MenuItem disabled value="xyz" >Loading...</MenuItem>
                                            :
                                            adminsList?.map((admin) => <MenuItem key={admin?.username} value={admin?.username}>{admin?.username}</MenuItem>)

                                    }
 
                                    <MenuItem value="Other">Other</MenuItem>
                                </Select>
                            </Grid>
                            {
                                other === "Other" &&
                                <Grid item xs={12} my={1}>
                                    <TextField size="small" placeholder={`Enter ${isNorthwell ? "Teacher / Mentor Name" : "Teacher Name"}`} type="text" style={{ width: "100%" }} id="outlined-basic"
                                        onChange={(e) => setTeacher(e.target.value)} value={teacher} variant="outlined"
                                        sx={{ fontFamily: "Poppins", '& legend': { display: 'none' }, '& fieldset': { top: 0 } }}
                                    />
                                </Grid>
                            }
                            <Grid item xs={12} my={1}>
                                <Button variant="contained" disabled={!organization || !teacher} sx={{ width: "100%", background: "#616DED" }} 
                                    onClick={handleGoogleSignup}>{
                                    reqLoading ?
                                        <CircularProgress style={{ color: 'white' }} />
                                        : "Register"
                                }</Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Modal>
                </Container >
            </>
        )
}





const inputStyle = {
    lineHeight: '40px',
    border: 'none',
    background: 'transparent'
}

// const styles = {
//     root: {
//         "flex-direction": "row-reverse"
//     }
// };
const style = {
    position: "absolute",
    top: "40%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    bgcolor: "background.paper",
    // boxShadow: 24,
    p: 4,
    borderRadius: 3,
};
const styles = {
    googleBtn:{
        textTransform:"none",
        borderRadius: "14px", 
        color: "rgb(113 114 115)", 
        fontWeight: "normal", 
        border: "1px solid lightgrey",
        letterSpacing:"normal",
        gap:10,
        display:"flex",
        width:"100%",
        alingSelf:"center"
        },
    CloseIcon:{
        textAlign:"flex-end",
        position:"absolute",
        right:5,
        top:5,
         padding:3,
        background:"white",
        borderRadius:"50%",
        border:"1px solid black",
        cursor:"pointer"
    }
}