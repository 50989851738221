import React, { useState, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import {
    Grid,
    Slide,
    Card,
    CardHeader,
    CardContent,
    Divider,
} from '@mui/material'
import { useQuery, } from 'react-query'
import { getTimeTrackers } from '../../../_api/time-management'

import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import TablePagination from '@mui/material/TablePagination';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import moment from 'moment'
import { LazzyLoading } from './LazzyLoading'


export default function TrackList({ page: listPage }) {
    const [timeData, setTimeData] = useState([])
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const { isLoading, data } = useQuery('get-time-list', getTimeTrackers)

    useEffect(() => {
        if (!isLoading) {
            setTimeData(data?.payload || [])
        }
    }, [isLoading, data])



    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    return (
        <Slide direction="left" in={listPage === "trackList"} mountOnEnter unmountOnExit>
            <Grid sx={mainPageStyle}>
                <Card sx={cardStyle}>
                    <CardHeader sx={{ textAlign: 'center' }} title="Time Tracker List" />
                    <Divider />
                    <CardContent>
                        <TableContainer>
                            <div style={{ width: '100%', height: '455px', overflow: 'auto' }}>
                                <Table aria-label="collapsible table" stickyHeader>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell />
                                            <TableCell>Project Name</TableCell>
                                            <TableCell align="left">Job Name</TableCell>
                                            <TableCell align="left">Assignee</TableCell>
                                            <TableCell align="right">Tracking Date</TableCell>
                                            <TableCell align="right">Punch In</TableCell>
                                            <TableCell align="right">Punch Out</TableCell>
                                            <TableCell align="left">Note</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            isLoading ? <>
                                                <TableRow sx={{ width: '100%' }}>
                                                    <TableCell colspan={7}>
                                                        <LazzyLoading />
                                                    </TableCell>

                                                </TableRow>
                                            </>
                                                :
                                                timeData
                                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.map((row) => (
                                                        <Row key={row._id} row={row} />
                                                    ))}
                                    </TableBody>
                                </Table>
                            </div>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25, 100]}
                                component="div"
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'flex-end',
                                    '& p': {
                                        margin: 0
                                    }
                                }}
                                count={timeData?.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </TableContainer>
                    </CardContent>
                </Card>
            </Grid>
        </Slide>
    );
}


const mainPageStyle = {
    background: '#ecedf3',
}
const cardStyle = {
    m: '20px 70px'
}




function Row(props) {
    const { row } = props;
    const [open, setOpen] = useState(false);

    return (
        <>
            <TableRow sx={{ '& td': { borderBottom: 'unset' } }}>
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell> {row?.projectName}</TableCell>
                <TableCell align="left">{row?.jobName}</TableCell>
                <TableCell align="left">{row?.label}</TableCell>
                <TableCell align="right">{moment(row?.trackingDate).format('LL')}</TableCell>
                <TableCell align="right">{moment(row?.punchIn).format('LT')}</TableCell>
                <TableCell align="right">{moment(row?.punchOut).format('LT')}</TableCell>
                <TableCell align="left">{row?.note}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box>
                            <Typography variant="h6">
                                Breaks
                            </Typography>
                            <Table size="small" aria-label="purchases">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center">Start</TableCell>
                                        <TableCell align="center">End</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        !row?.breaks?.length ?
                                            <TableRow>
                                                <TableCell align="center" colspan="2">No Breaks !</TableCell>
                                            </TableRow>
                                            :
                                            row?.breaks?.map((br) => (
                                                <TableRow key={br?._id}>
                                                    <TableCell align="center">{moment(br?.start).format('LT')}</TableCell>
                                                    <TableCell align="center">
                                                        {moment(br?.end).format('LT')}
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
}





