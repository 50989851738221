import React, { useState, useEffect } from 'react'
import TurnPhoneH from '../other/TurnPhoneH'
import { useTranslation } from 'react-i18next'

export default function SuccessSkillSearch() {
    const { t } = useTranslation();
    const [isMobile, setIsMobile] = useState(false);
    function handleOrientation() {
        setIsMobile(window.innerHeight < window.innerWidth && window.innerWidth < 1000)
    }
    window.onresize = handleOrientation;
    useEffect(() => {
        setIsMobile(window.innerHeight < window.innerWidth && window.innerWidth < 1000)
    }, [window.innerWidth, window.innerHeight, window.orientation]);
    if (isMobile) {
        return (<TurnPhoneH content={t("successSkillsIntro.turn")} />)
    } else
        return (
            <>
                <div className="body-bg" style={{ position: 'relative', height: '100vh' }}>
                    <div className="container turn-screen-container " style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <div className="container-inner m-0 p-0">
                            <img className="mb-5" style={{ width: '245px' }} src="/assets/svg/logo-white.gif" alt="" />
                            <h1 style={{ marginTop: '50px' }}><i>{t("successSkillsForm.search")}...</i></h1>
                        </div>
                    </div>
                </div>
            </>
        )
}
