import React, { useState, useEffect } from 'react'
import TurnPhoneV from '../../other/TurnPhoneV'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { emptyPoints, setLoading } from '../../../redux-features/user/userSlice'

import { userLog } from '../../_api/index'
import { useMutation } from 'react-query';
import isAuthenticated from '../../auth/auth'
import SkillsBar from '../skills/SkillsBar';
import { useTranslation } from 'react-i18next'
import RewardsModal from '../details/RewardsModal'
import Loading from '../../common/Loading'
import { Grid, Typography } from "@mui/material"
import { Link } from 'react-router-dom'



export default function QuizResult() {
    const dispatch = useDispatch()
    const { t } = useTranslation();
    const navigate = useNavigate()
    const [totalPoints, setTotalPoints] = useState(0)
    const lessonID = useSelector(state => state.user.lessonID);
    const quizPoints = useSelector(state => state.user?.quizPoints)
    const quiz = useSelector(state => state.user?.quiz)
    const moduleID = useSelector(state => state.user.moduleID);
    const skillID = useSelector(state => state.user.skillID);
    const moduleName = useSelector(state => state.user.moduleName);
    const skillSections = useSelector(state => state?.user?.skillSections)
    const modules = useSelector((state) => state.user.userData?.data)
    let loading = useSelector(state => state?.user?.loading)
    let navLink = `/skills?moduleID=${moduleID}&lessonID=${lessonID}&skillID=${skillID}`
    const { mutate: log } = useMutation(userLog);
    const [isMobile, setIsMobile] = useState(false);
    const [qtp, setQtp] = useState(0);
    const [open, setOpen] = useState(false)
    const [flag, setFlag] = useState(false)
    let currentModule = useSelector(state => state?.user?.module)
    let currentLesson = useSelector(state => state?.user?.lesson)
    let currentSkill = useSelector(state => state?.user?.skill)

    function handleOrientation() {
        setIsMobile(window.innerHeight > window.innerWidth && window.innerWidth < 1000)
    }
    window.onresize = handleOrientation;
    useEffect(() => {
        setIsMobile(window.innerHeight > window.innerWidth && window.innerWidth < 1000)
    }, [window.innerWidth, window.innerHeight, window.orientation]);
    useEffect(() => {
        let totalQuizPoints = 0
        if (!!quiz?.length) {
            quiz?.map(que => {
                totalQuizPoints += que?.points;
            })
        }
        setQtp(totalQuizPoints)
    }, [quiz])

    useEffect(() => {
        if (skillSections?.length > 0) {
            let temp = 0;
            skillSections?.map(ss => {
                temp = temp + ss?.allContent?.points
            })
            setTotalPoints(temp)
        }
    }, [skillSections])

    // useEffect(() => {
    //     let subSkills = []
    //     modules?.map(module => {
    //         if (module?._id === moduleID) {
    //             module?.lessons?.map(lesson => {
    //                 if (lesson?._id === lessonID) {
    //                     lesson?.skills?.map(skil => {
    //                         if (skil?._id === skillID) {
    //                             subSkills = skil?.subSkills
    //                         }
    //                     })
    //                 }
    //             })
    //         }
    //     })
    //     if (subSkills.filter(ss => { return (ss.isCompleted) })?.length === subSkills?.length) {
           
    //     }
    // }, [modules])

    // useEffect(()=>{
    //     loading = true;
    //     dispatch(setLoading(true))
    // },[])

    


    if(quiz.length == 0){
        if(!loading){
            if(currentModule.isCompleted){
                navLink = `/module`
                navigate(navLink)
            }else{
                if(currentSkill.isCompleted){
                    navLink = `/lesson?id=${moduleID}`
                    navigate(navLink)
                }else{
                    navigate(navLink)
                }
            }
        }
    }
   
   


    const getPoints = () => {
        let temp = (quizPoints / qtp * 100).toFixed(0)
        if (temp < 100) {
            return (temp)
        } else {
            return (100)
        }
    }
    const handleNavigation = () => {
        
        if(currentSkill.isCompleted){
            navLink = `/lesson?id=${moduleID}`
        }
        if(currentModule.isCompleted){
            navLink = `/module`   
        }
        navigate(navLink)
    }

 

        if (isMobile) {
            return (<TurnPhoneV />)
        } else{
            if(quiz.length == 0 || loading){
                return <Loading />
            }else{
                return (
                    <> {!isAuthenticated(localStorage.getItem("token")) && navigate("/login")}
                        <RewardsModal open={open} setOpen={setOpen} />
                        <SkillsBar page="Quiz Result" />
                        <div className="skills-details-container quiz-result" style={{ maxHeight: "100vh", overflow: "hidden" }}>
                            <div className="row text-center m-0 p-0" style={{ height: '100%' }}>
                                {/* <h6>Module 1</h6> */}
                                <div className="container-feedback" style={{ height: "90vh" }}>
                                    <div className="row m-0 p-0">
                                        <h2 className="text-center mt-3">Results</h2>
                                    </div>
                                    <div className="row feedback-content d-flex justify-content-center m-0 mt-2">
                                        <div className="col-4">
                                            <div className="correct-ans">
                                                <h1>{skillSections?.length}/{skillSections?.length}</h1>
                                                <h4>{t("quizResult.completed")}</h4>
                                            </div>
                                        </div>
                                        <div className="col-4">
                                            <img src="../assets/svg/lesson/quiz-result.svg" alt="" />
                                            {
                                                !!quiz?.length && <h3 className="mt-2">Quiz Score 100%</h3>
                                            }
    
                                        </div>
                                        <div className="col-4">
                                            <div className="correct-ans">
                                                <h1>{totalPoints}</h1>
                                                <h4>{t("quizResult.pointsEarned")}</h4>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row d-flex justify-content-center">
                                        <h5>{t("quizResult.congratulationsOnCompletion")}</h5>
                                    </div>
                                    <div className="row d-flex justify-content-center mt-1">
                                        <button
                                            onClick={() => {
                                                log({
                                                    pageName: 'Quiz Result',
                                                    buttonName: 'Next'
                                                })
                                                dispatch(emptyPoints())
                                                handleNavigation()
                                            }}
                                        >{t("quizResult.btnNext")}</button>
                                    </div>
    
    
                                    {/* <Link to="/wordscape" className="words-game" style={{ left: '105px' }}
                                        onClick={() => log({
                                            pageName: 'Quiz Results',
                                            buttonName: 'Word Game'
                                        })}>
                                        <img src="../assets/svg/main-home/word-game.svg" alt="" />
                                    </Link>
                                    <div className="completed-lessons"
    
                                        onClick={() => {
                                            setOpen(true)
                                            log({
                                                pageName: 'Quiz Result',
                                                buttonName: 'Rewards'
                                            })
                                        }}
                                    >
                                        <span>{user.earnedPoints} / {user.totalPoints}</span>  <LocalAtmRoundedIcon />
                                    </div>
                                    <div className="chat-btn" style={{ right: "30px" }}
                                        onClick={() => log({
                                            pageName: 'Quiz Results',
                                            buttonName: 'Chat'
                                        })}>
                                        <img src="../assets/svg/main-home/chat-icon.svg" alt="" />
                                    </div> */}
                                </div>
    
    
                            </div>
                        </div>
    
                    </>
                )
            }
          
        }
 
}
