import React, { useState, useEffect } from 'react'
import TurnPhoneH from '../other/TurnPhoneH'
import Header from '../common/NewHeader'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { userLog } from '../_api/index'
import { useMutation } from 'react-query';
import isAuthenticated from '../auth/auth'
import { useTranslation } from 'react-i18next'
import SuccessSkillList from './SuccessSkillList'
import SuccessSkillSearch from './SuccessSkillSearch'
import KeyboardBackspaceRoundedIcon from '@mui/icons-material/KeyboardBackspaceRounded';
import { Grid, Box } from "@mui/material";
import { Button } from "@mui/material"
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import { FormControl } from "@mui/material"


export default function SuccessSkillForm() {
    const states = [
        'Alabama', 'Alaska', 'American Samoa', 'Arizona', 'Arkansas', 'California', 'Colorado', 'Connecticut', 'Delaware', 'District of Columbia', 'Federated States of Micronesia', 'Florida', 'Georgia', 'Guam', 'Hawaii', 'Idaho', 'Illinois', 'Indiana', 'Iowa', 'Kansas', 'Kentucky', 'Louisiana', 'Maine', 'Marshall Islands', 'Maryland', 'Massachusetts', 'Michigan', 'Minnesota', 'Mississippi', 'Missouri', 'Montana', 'Nebraska', 'Nevada', 'New Hampshire', 'New Jersey', 'New Mexico', 'New York', 'North Carolina', 'North Dakota', 'Northern Mariana Islands', 'Ohio', 'Oklahoma', 'Oregon', 'Palau', 'Pennsylvania', 'Puerto Rico', 'Rhode Island', 'South Carolina', 'South Dakota', 'Tennessee', 'Texas', 'Utah', 'Vermont', 'Virgin Island', 'Virginia', 'Washington', 'West Virginia', 'Wisconsin', 'Wyoming'
    ]
    const { t } = useTranslation();
    const navigate = useNavigate()
    const { mutate: log } = useMutation(userLog);
    const jobs = useSelector((state) => state.job.jobData)
    const [skillsArray, setSkillsArray] = useState([])
    const [selectedSkills, setSelectedSkills] = useState([])
    const [otherSkill, setOtherSkill] = useState("")
    const [desiredJob, setDesiredJob] = useState("")
    const [currentJob, setCurrentJob] = useState("")
    const [isList, setIsList] = useState(false)
    const [isSearch, setIsSearch] = useState(false)
    const [error, setError] = useState(false)
    const [userState, setUserState] = useState("")
    const [age, setAge] = useState()
    const [flag, setFlag] = useState(false)

    const [isMobile, setIsMobile] = useState(false);
    function handleOrientation() {
        setIsMobile(window.innerHeight < window.innerWidth && window.innerWidth < 1000)
    }
    window.onresize = handleOrientation;
    useEffect(() => {
        setIsMobile(window.innerHeight < window.innerWidth && window.innerWidth < 1000)
    }, [window.innerWidth, window.innerHeight, window.orientation]);
    useEffect(() => {
        if (isSearch) {
            setTimeout(() => {
                setIsSearch(false)
                setIsList(true)
            }, 3000)
        }
    })

    const handleChange = () => {
    }

    useEffect(() => {
        if (jobs) {
            let selectedJob = jobs?.filter(job => { return (job._id === currentJob) })
            setSkillsArray(selectedJob[0]?.skills)
        }
    }, [currentJob, jobs])

    const handleSubmit = (e) => {
        e.preventDefault();
        // const element = {
        //     _id: Math.rando + otherSkill,
        //     name: otherSkill,
        // }
        setSkillsArray([...skillsArray, otherSkill])
        setOtherSkill("")
    }
    if (isMobile) {
        return (<TurnPhoneH content={t("successSkillsIntro.turn")} />)
    } else if (isList) {
        return (
            <SuccessSkillList
                selectedSkills={selectedSkills}
                desiredJob={desiredJob}
                jobs={jobs}
                currentJob={currentJob}
            />
        )
    } else if (isSearch) {
        return (<SuccessSkillSearch />)
    } else
        return (
            <>
                {!isAuthenticated(localStorage.getItem("token")) && navigate("/login")}
                <Header logo page="Success Skill Form" />

                <Grid container sx={{ height: "100vh" }}>
                    <Header logo page="Success Skill Intro" />
                    <Grid container className='_grid-container-mob-scr'>
                        <Grid item xs={6} container justifyContent="center" alignItems="center">
                            <Box className='w-100'>

                                <div className="success-form-container _success-form">
                                    {
                                        error &&
                                        <p className="text-danger">Please fill all the fields</p>
                                    }
                                    <h3>{t("successSkillsForm.oldJob")}:</h3>

                                    <FormControl fullWidth className='_form-control'>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={currentJob}
                                            onChange={(e) => setCurrentJob(e.target.value)}
                                            className="reqcode__role"
                                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 } }}
                                            size="small"
                                        >
                                            <MenuItem value="none" disabled>Choose job title here</MenuItem>
                                            {
                                                jobs?.map((job, index) => {
                                                    return (
                                                        <MenuItem key={index} value={job._id}>{job?.name}</MenuItem>
                                                    )
                                                })
                                            }
                                        </Select>
                                    </FormControl>
                                    {/* <select className="select" onChange={(e) => setCurrentJob(e.target.value)}>
                                        <option value="" disabled selected>{t("successSkillsForm.jobPlaceholder")}</option>
                                        {
                                            jobs?.map((job, index) => {
                                                return (
                                                    <option key={index} value={job._id}>{job?.name}</option>
                                                )
                                            })
                                        }

                                        </select> */}
                                    {
                                        currentJob !== '' &&
                                        <>
                                            <h3 className="addSkill__successkillform" style={{ marinTop: '22px' }}>{t("successSkillsForm.addSkill")}</h3>
                                            <div className="row">
                                                {
                                                    skillsArray?.map((skill, index) => {
                                                        return (
                                                            <div id={skill} key={index} style={{ width: "29%", flex: "0 0 auto" }} className="btn-skills" onClick={() => {
                                                                log({
                                                                    pageName: 'Success Skill Form',
                                                                    buttonName: skill + "+"
                                                                })
                                                                document.getElementById(skill).style.backgroundColor = '#4c6ed7'
                                                                document.getElementById(skill).style.color = '#fff'
                                                                document.getElementById(skill).style.borderRadius = '15px'
                                                                selectedSkills?.filter((skillS => { return (skillS === skill) })).length === 0 &&
                                                                    setSelectedSkills(old => [...old, skill])
                                                            }}>{skill}</div>
                                                        )
                                                    })
                                                }
                                                <div className="col-4 _btn-plus">
                                                    <button className="jobskills__addbutton" onClick={() => setFlag(!flag)}>
                                                        {
                                                            flag ?
                                                                <CloseIcon style={{ color: "white" }} /> : <AddIcon style={{ color: "white" }} />
                                                        }

                                                    </button>
                                                </div>
                                            </div>

                                            {
                                                flag &&
                                                (
                                                    <div className="row">
                                                        <form action="" style={{ gap: '15px', padding: '0' }} className="d-flex align-items-center justify-content-space-between" onSubmit={(e) => handleSubmit(e)}>
                                                            <input type="text" placeholder={t("successSkillsForm.addMoreSkills")} value={otherSkill} onChange={(e) => setOtherSkill(e.target.value)} required />
                                                            {otherSkill !== "" && <button type="submit" className="mt-2 successskill__formafteraddbutton"
                                                                onClick={() => log({
                                                                    pageName: 'Success Skill Form',
                                                                    buttonName: 'Add'
                                                                })}
                                                            >{t("successSkillsForm.add")}</button>}
                                                        </form>
                                                    </div>
                                                )
                                            }

                                        </>
                                    }
                                    <h3>{t("successSkillsForm.newJob")}:</h3>
                                    <FormControl fullWidth className='_form-control'>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={desiredJob}
                                            className="reqcode__role"
                                            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 } }}
                                            size="small"
                                            onChange={(e) => setDesiredJob(e.target.value)}
                                        >
                                            <MenuItem value='none' disabled>Choose job title here</MenuItem>
                                            {
                                                currentJob !== '' &&
                                                jobs?.map((job, index) => {
                                                    return (
                                                        currentJob !== job._id &&
                                                        <MenuItem key={index} value={job._id}>{job?.name}</MenuItem>
                                                    )
                                                })
                                            }
                                        </Select>
                                    </FormControl>
                                    <div className="">
                                        <h3>
                                            {/* {t("successSkillsForm.city")} */}
                                            Select State
                                            :</h3>
                                        <div className="city-state-select" onChange={(e) => setUserState(e.target.value)}>
                                            <FormControl fullWidth className='_form-control'>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={userState}
                                                    className="reqcode__role"
                                                    sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 } }}
                                                    size="small"
                                                    onChange={(e) => setUserState(e.target.value)}
                                                >
                                                    <MenuItem value="none" disabled>Select State</MenuItem>
                                                    {
                                                        states.map((stat, index) => {
                                                            return (
                                                                <MenuItem key={index} value={stat}>{stat}</MenuItem>
                                                            )
                                                        })
                                                    }
                                                </Select>
                                            </FormControl>
                                        </div>
                                    </div>
                                    <Button variant="outlined" sx={{ width: "100%" }} className="loginscreen__button loginscreen__buttontwo _gobtn-success" onClick={() => {
                                        if (!desiredJob || !userState || !currentJob) {
                                            setError(true)
                                        } else {
                                            setIsSearch(true)
                                        }
                                        log({
                                            pageName: 'Success Skill Fom',
                                            buttonName: 'Go'
                                        })
                                    }
                                    }> {t("successSkillsForm.go")}</Button>


                                    {/* <button
                                            // to={"/success-skill-search/" + desiredJob + "/" + skillsArray}
                                            className="btn submit-btn bg-primary1"
                                            style={{ borderRadius: '10.4px' }}
                                            onClick={() => {
                                                if (desiredJob === "" || userState === "") {
                                                    setError(true)
                                                } else {
                                                    setIsSearch(true)
                                                }
                                                log({
                                                    pageName: 'Success Skill Fom',
                                                    buttonName: 'Go'
                                                })
                                            }
                                            }
                                        >{t("successSkillsForm.go")}</button> */}

                                </div>
                            </Box>
                        </Grid>
                        <Grid item xs={6} container flexDirection="column" justifyContent="center" alignItems="center">
                            <img src="../assets/img/job.png" alt="" />
                        </Grid>
                    </Grid>
                </Grid>
            </>
        )
}
