import React from 'react'
import { useTranslation } from 'react-i18next';
import './loader_styles/loader.css';
import Logo from './Logo';


export default function Loading() {

    const { t } = useTranslation();
    return (
        <>
            <div className="full-container">
                <Logo  />
                <div className="contain">
                    <img src="/assets/img/loader.png"  className="load" />
                    <div class="bubbles">
                    <div class="firstbubble"></div>
                    <div class="secondbubble"></div>
                    <div class="thirdbubble"></div>
                    <div class="fourthbubble"></div>
                    <div class="fifthbubble"></div>
                </div>
                </div>  
                <br />
                <h3 className="text-center text loadingtext">{t("loading.text")}</h3>
            </div>
        </>
    )
}