import * as Axios from 'axios';
const API_URL = process.env.REACT_APP_API_LINK;

const axios = Axios.create({
    'headers': {
        'Authorization': `Stickball ${localStorage.getItem('token')}`,
        'x-api-key': process.env.REACT_APP_X_API_KEY
    }
})

export const addJournal = async (data) => {

    const res = await axios.post(`${API_URL}/journal`, data, {
        'headers': {
            'Authorization': `Stickball ${localStorage.getItem('token')}`
        }
    });
    return res.data;
}
export const shareJournal = async (data) => {

    const res = await axios.post(`${API_URL}/journal/share`, data, {
        'headers': {
            'Authorization': `Stickball ${localStorage.getItem('token')}`
        }
    });
    return res.data;
}

export const getJournals = async () => {
    const res = await axios.get(`${API_URL}/journal`, {
        'headers': {
            'Authorization': `Stickball ${localStorage.getItem('token')}`
        }
    })
    return (res.data)
}
// export const getQuestions = async () => {
//     const res = await axios.get(`${API_URL}/journal?type=question`)
//     return (res.data)
// }
export const updateJournal = async (data) => {
    let temp = {}
    if (data.type === 'question') {
        temp = {
            question: data.question,
            title: data.title,
            // isAnswer: isAnwered,
            teacher: data.teacher,
            answer: data.answer,
        }
    } else {
        temp = {
            title: data.title,
            content: data.content,
            attachment: data.attachment,
        }
    }

    const res = await axios.put(`${API_URL}/journal/${data.id}`, temp, {
        'headers': {
            'Authorization': `Stickball ${localStorage.getItem('token')}`
        }
    })
    return (res.data)
}

export const deleteJournal = async (data) => {
    const res = await axios.delete(`${API_URL}/journal/${data.id}`, {
        'headers': {
            'Authorization': `Stickball ${localStorage.getItem('token')}`
        }
    })
    return (res.data)
}

export const getAdminList = async () => {
    const res = await axios.get(`${API_URL}/journal/admin-list`, {
        'headers': {
            'Authorization': `Stickball ${localStorage.getItem('token')}`
        }
    })
    return (res.data)
}
export const getAdmindzByOrg = async (id) => {
    const res = await axios.get(`${API_URL.replace("/client", "")}/admin?organizationId=${id}`)
    return (res.data)
}

