import React from 'react'
import { CarouselProvider, Slider, Slide } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import Parser from "html-react-parser";
//checking
export default function LessonSlider(props) {
    const { moduleID, userData } = props;
    return (
        <>
            <CarouselProvider
                naturalSlideWidth={100}
                naturalSlideHeight={120}
                totalSlides={userData?.filter(module => { return (module._id === moduleID) })[0]?.lessons?.length + 1}
                visibleSlides={1.5}
            >
                <Slider>
                    {
                        userData.filter(module => { return (module._id === moduleID) })[0]?.lessons?.map((lesson, index) => {
                            return (
                                <Slide style={{ marginLeft: '10px' }} index={index}>
                                    <div className="slid-item">
                                        <div className="left-section">
                                            <img src={lesson?.icon?.svg} alt="" />
                                        </div>
                                        <div className="right-section">
                                            <h3>{Parser(lesson.name)}</h3>
                                            <p>{Parser(lesson?.description || "").slice(0, 150)}...</p>
                                        </div>
                                    </div>
                                </Slide>
                            )
                        })
                    }
                </Slider>
            </CarouselProvider>
        </>
    )
}
