import * as Axios from 'axios';

import isAuthenticated from '../auth/auth';


const API_URL = process.env.REACT_APP_API_LINK;
// const API_URL = "http://localhost:4000/client"
const CLOUD_API = "https://api.cloudinary.com/v1_1/ddujllgru/image/upload";



const axios = Axios.create({
    'headers': { 'x-api-key': process.env.REACT_APP_X_API_KEY }
})
window.name = "Success"
//posting code requet to api
export const initialRegister = async (data) => {
    const res = await axios.post(`${API_URL}/user/initial-register`, data);
    return res.data;
}

//registering new code
export const fullRegister = async (data) => {
    const res = await axios.post(`${API_URL}/user/full-register`, data);
    return res.data;
}

//google signup
export const googleSignup = async (data) => {
    const res = await axios.post(`${API_URL}/user/google-signup`, data);
    return res.data;
}

//customer support new code
export const customerSupport = async (data) => {
    const res = await axios.post(`${API_URL.replace('/client', "")}/customer-support`, data);
    return res.data;
}


//updating the profile/user
export const userUpdate = async (data) => {
    const res = await axios.put(`${API_URL}/user`, data,
        {
            headers: {
                'Authorization': `Stickball ${localStorage.getItem('token')}`
            }
        }
    );
    return res.data;
}
export const getUsers = async (clas) => {
    const res = await axios.get(`${API_URL}/journal/user`,
        {
            headers: {
                'Authorization': `Stickball ${localStorage.getItem('token')}`
            }
        }
    );
    return res.data;
}

export const getOrganizations = async (clas) => {
    const res = await axios.get(`${API_URL}/organizations`,
        {
            headers: {
                'Authorization': `Stickball ${localStorage.getItem('token')}`
            }
        }
    );
    return res.data;
}


//verifying email address
export const emailVerification = async (data) => {
    const res = await axios.post(`${API_URL}/user/verify-email`, data)
    return (res.data)
}

//posting register request to api
export const register = async (data) => {
    const res = await axios.post(`${API_URL}/user/code-register`, data);
    return res.data;
}

//resending code
export const resendCode = async (data) => {
    const res = await axios.post(`${API_URL}/user/resend-code`, data);
    return res.data;
}

//code reset posting to api
export const resetCode = async (data) => {
    const res = await axios.post(`${API_URL}/user/reset-code`, data);
    return res.data;
}

//reset Password
export const resetPassword = async (data) => {
    const res = await axios.post(`${API_URL}/user/reset-password`, data);
    return res.data;
}

//change Password
export const changePassword = async (data) => {
    const res = await axios.post(`${API_URL}/user/change-password`, data, {
        headers: {
            'Authorization': `Stickball ${localStorage.getItem('token')}`
        }
    })
    return res.data;
}


//verify Password
export const verifyOTP = async (data) => {
    const res = await axios.post(`${API_URL}/user/verify-otp`, data);
    return res.data;
}

//posting login request to the api
export const login = async (data) => {
    const res = await axios.post(`${API_URL}/user/login`, data,
        {
            method: 'Post',
            withCredentials: true
        }
    );
    return res.data;
}

//posting clever login request to the api
export const cleverLogin = async (data) => {
    const res = await axios.post(`${API_URL}/user/clever-login`, data,
        {
            method: 'Post',
            withCredentials: true
        }
    );
    return res.data;
}

//posting clever register request to the api
export const cleverRegister = async (data) => {
    const res = await axios.post(`${API_URL}/user/clever-register`, data,
        {
            method: 'Post',
            withCredentials: true
        }
    );
    return res.data;
}

//uploading image to the cloudnary
export const uploadImage = async (data) => {
    const res = await axios.post(`${CLOUD_API}`, data);
    return res.data;
}


export const getJobs = async () => {
    if (isAuthenticated(localStorage.getItem("token")) === true) {
        const res = await axios.get(`${API_URL}/jobs/all-data`,
            {
                headers: {
                    'Authorization': `Stickball ${localStorage.getItem('token')}`
                }
            }
        )
        return (res.data)
    }
    else {
        return [];
    }
}


export const getAllData = async () => {
    if (isAuthenticated(localStorage.getItem("token")) === true) {
        window.name = "Success"
        try {
            const res = await axios.get(`${API_URL}/user/all-data`,
                {
                    headers: {
                        'Authorization': `Stickball ${localStorage.getItem('token')}`
                    }
                })
            console.log("🚀 ~ getAllData ~ res:", res)
            return (res.data)
        } catch {
            window.name = "Error"
        }
    }
    else {
        return [];
    }
}

export const newEnrollment = async (data) => {
    try {
        const res = await axios.post(`${API_URL}/user/new-enrollment`, data,
            {
                headers: {
                    'Authorization': `Stickball ${localStorage.getItem('token')}`
                }
            }
        );
        return res.data;
    } catch {
        window.name = true
    }
}

export const adminEnrolledData = async (data) => {
    try {
        const res = await axios.post(`${API_URL}/user/admin-all-data`, data,
            {
                headers: {
                    'Authorization': `Stickball ${sessionStorage.getItem('token')}`
                }
            }
        );
        return res.data;
    } catch {
        window.name = true
    }
}


export const continueEnrollment = async (data) => {
    try {
        const res = await axios.post(`${API_URL}/user/continue-enrollment`, data,
            {
                headers: {
                    'Authorization': `Stickball ${localStorage.getItem('token')}`
                }
            }
        );
        return res.data;
    } catch {
        window.name = true
    }
}

export const userLog = async (data) => {
    const res = await axios.post(`${API_URL}/user/log`, data,
        {
            headers: {
                'Authorization': `Stickball ${localStorage.getItem('token')}`
            }
        }
    );
    return res.data;
}

export const getSections = async (skillID) => axios.get(`${API_URL.replace("/client", "")}/skill-section/populate/${skillID}`);

export const getForms = () => {
    return axios
        .get(API_URL.replace("/client", "") + "/form", {
            headers: {
                'Authorization': `Stickball ${localStorage.getItem('adminToken')}`
            }
        })
        .then(response => {
            return response.data;
        })
}


export const getAllChatbots = () => {
    return axios
        .get(API_URL.replace("/client", "") + "/chat-bot", {
            params: { limit: 1000, offset: 0 },
            headers: {
                'Authorization': `Stickball ${localStorage.getItem('adminToken')}`
            }
        })
        .then(response => {
            return response.data;

        })
}

export const getJotForms = () => {
    return axios
        .get(API_URL.replace("/client", "") + "/jotform/forms", {
            params: { limit: 1000, offset: 0 },
            headers: {
                'Authorization': `Stickball ${localStorage.getItem('adminToken')}`
            }
        })
        .then(response => {
            return response.data;

        })
}

export const getAllIcons = async (iconToAdd) => {
    const res = await axios.get(`${API_URL.replace("/client", "")}/icon`,
        {
            headers: {
                'Authorization': `Stickball ${localStorage.getItem('adminToken')}`
            }
        });
    return res.data;
};