import React, { forwardRef, useState } from 'react'

import {
    IconButton,
    Dialog,
    DialogContent,
    DialogTitle,
    Slide,
    Avatar
} from '@mui/material'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';


const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function GoalsModal() {
    const [openModal, setOpenModal] = useState(false)
    return (
        <>
            <Avatar onClick={() => setOpenModal(true)} style={goalButtonStyle} alt="Remy Sharp" src="/assets/img/goal-icon.svg" />
            <Dialog
                open={openModal}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => setOpenModal(false)}
                fullWidth
                maxWidth='lg'
            >
                <DialogTitle sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <IconButton style={{ height: 'fit-content' }} onClick={() => {
                        setOpenModal(false)
                    }}><CloseOutlinedIcon /></IconButton>
                </DialogTitle>
                <DialogContent>
                    {/* <iframe width="100%" height="550px" src={`http://localhost:3001/financial-goals?view=goals&token=${localStorage.getItem('token')}&isIframe=true`} frameborder="0"></iframe> */}
                    <iframe width="100%" height="550px" src={`${process.env.REACT_APP_BUDG_LINK}financial-goals?view=goals&token=${localStorage.getItem('token')}&isIframe=true`} frameborder="0"></iframe>
                </DialogContent>
            </Dialog>
        </>
    )
}


const goalButtonStyle = {
    position: 'fixed',
    width: 50,
    height: 50,
    padding: 7,
    right: 30,
    bottom: 270,
    background: '#fff',
    boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.2)',
    cursor: 'pointer',
}