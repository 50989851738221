import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import App from './App';
import './i18n'
import 'aos/dist/aos.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux'
import { store, persistor } from './redux/store'
import { GoogleOAuthProvider } from '@react-oauth/google';

import { ThemeProvider } from "@mui/material/styles";
import { theme } from './theme/index';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity,
    }
  }
});

ReactDOM.render(
  <BrowserRouter>
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_AUTH_CLIENT_API}>
      <QueryClientProvider client={queryClient} >
        {/* <ReactQueryDevtools initialIsOpen={false} /> */}
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <ThemeProvider theme={theme} >
              <App />
            </ThemeProvider>
          </PersistGate>
        </Provider>
      </QueryClientProvider>
    </GoogleOAuthProvider>
  </BrowserRouter>,
  document.getElementById('root')
);
serviceWorkerRegistration.unregister();
reportWebVitals();
