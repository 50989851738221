import React, { useEffect } from 'react';
import {
    Grid,
    Button,
    Slide,
} from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import TaskIcon from '@mui/icons-material/Task';
import GridOnIcon from '@mui/icons-material/GridOn';
import PlaylistAddOutlinedIcon from '@mui/icons-material/PlaylistAddOutlined';

export default function MainPage({ setPage, setPageTitle, page }) {


    return (
        <Slide direction="right" in={page === "main"} mountOnEnter unmountOnExit>
            <Grid style={mainPageStyle}>
                <div className="row" style={bannerStyle}>
                    <img style={{ width: '120px', marginTop: '70px' }} src="assets/svg/logo.gif" alt="" />
                    <h1 style={headingStyle}>Time Management</h1>
                </div>
                <div className="row">
                    <div className="col-12" style={{ ...gridStyle, marginTop: '-50px' }}>
                        <Button
                            startIcon={<AccessTimeIcon sx={{ height: 68, width: 68 }} />}
                            sx={btnStyle}
                            onClick={() => {
                                setPageTitle("Track Time")
                                setPage("trackTime")
                            }}
                        >Track Time</Button>
                        <Button
                            startIcon={<TaskIcon sx={{ height: 68, width: 68 }} />}
                            sx={btnStyle}
                            onClick={() => {
                                setPageTitle("Log Task")
                                setPage("taskLog")
                            }}
                        >Log Task</Button>
                    </div>
                    <div className="col-12" style={gridStyle}>
                        <Button
                            onClick={() => {
                                setPageTitle("Time List")
                                setPage("trackList")
                            }}
                            startIcon={<GridOnIcon sx={{ height: 68, width: 68 }} />} sx={btnStyle}>Time List</Button>
                        <Button
                            onClick={() => {
                                setPageTitle("Task List")
                                setPage("logList")
                            }}
                            startIcon={<GridOnIcon sx={{ height: 68, width: 68 }} />} sx={btnStyle}>Task List</Button>
                    </div>

                    <div className="col-12" style={gridStyle}>

                        <Button
                            onClick={() => {
                                setPageTitle("To do List")
                                setPage("todoList")
                            }}
                            startIcon={<PlaylistAddOutlinedIcon sx={{ height: 68, width: 68 }} />} sx={btnStyle}>To do List</Button>
                    </div>
                </div>
            </Grid>
        </Slide>
    )
}


const mainPageStyle = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    // height: '100%',
    backgroundImage: "url('assets/img/bg-timer.jpg')",
    backgroundSize: 'cover',
    paddingBottom: 100,
}
const btnStyle = {
    display: 'flex',
    justifyContent: 'space-around',
    width: 308,
    color: '#616ded',
    backgroundColor: '#fff',
    borderRadius: '10px',
    p: '40px',
    fontSize: 18,
    fontWeight: 700,
    height: 'fit-content',
    '&: hover': {
        backgroundColor: '#616ded',
        color: '#fff',
    }
}
const gridStyle = {
    display: 'flex',
    justifyContent: 'center',
    gap: '40px',
    padding: 10,
    margin: 10,
    height: 'fit-content'
}
const bannerStyle = {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    backgroundColor: 'rgba(113, 149, 180, 0.69)',
    justifyContent: 'center',
    padding: '35px 0px 140px 0px',
    marginTop: -40,

}

const headingStyle = {
    fontSize: 28,
    fontWeight: 700,
    textAlign: 'center',
    marginTop: 40,
    paddingTop: 40,
    color: '#616ded',
}