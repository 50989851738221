import React, { useState, useEffect } from 'react'
import TurnPhoneV from '../../other/TurnPhoneV'
import { useNavigate } from 'react-router-dom'
import isAuthenticated from '../../auth/auth'
import { useSelector } from 'react-redux'

export default function Rewards() {
    const navigate = useNavigate()
    const [isMobile, setIsMobile] = useState(false);

    const user = useSelector((state) => state?.user?.userData?.user)
    function handleOrientation() {
        setIsMobile(window.innerHeight > window.innerWidth && window.innerWidth < 1000)
    }
    window.onresize = handleOrientation;
    useEffect(() => {
        setIsMobile(window.innerHeight > window.innerWidth && window.innerWidth < 1000)
    }, [window.innerWidth, window.innerHeight, window.orientation]);
    if (isMobile) {
        return (<TurnPhoneV />)
    } else
        return (
            <>
                {!isAuthenticated(localStorage.getItem("token")) && navigate("/login")}
                <div className="skills-details-container quiz-result m-0 position-absolute">
                    <div className="row d-flex justify-content-center align-items-center text-center m-0 p-0" style={{ height: '100%' }}>
                        {/* <h6>Module 1</h6>
                        <h4>Personal Finance</h4> */}
                        <div className="container-feedback" style={{ width: '80%', boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.2)', borderRadius: '16px', padding: '5rem 0' }}>
                            <div className="row m-0 p-0">
                                <h2 className="text-center mt-3">Rewards</h2>
                            </div>
                            <div className="row feedback-content d-flex justify-content-center m-0 p-0">
                                <div className="col-4">
                                    <div className="correct-ans">
                                        <h1>{user?.earnedPoints}</h1>
                                        <h4>Points Earned</h4>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <img src="../assets/svg/lesson/quiz-result.svg" alt="" />
                                    {/* <h3>100%</h3> */}
                                </div>
                                <div className="col-4">
                                    <div className="correct-ans">
                                        <h1>{user?.totalPoints}</h1>
                                        <h4>Total Points</h4>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="row d-flex justify-content-center">
                                <h5>Congratulations on completion</h5>
                            </div> */}
                            <div className="row d-flex justify-content-center mt-1">
                                <button
                                    onClick={() => window.history.go(-1)}
                                >Back</button>
                            </div>

                            {/* 
                            <Link to="/wordscape" className="words-game" style={{ left: '105px' }}>
                                <img src="../assets/svg/main-home/word-game.svg" alt="" />
                            </Link>
                            <div className="completed-lessons">
                                <span>0/100</span> <LocalAtmRoundedIcon />
                            </div>
                            <div className="chat-btn" style={{ right: "30px" }}>
                                <img src="../assets/svg/main-home/chat-icon.svg" alt="" />
                            </div>
                            <Link to={"skills-detail/" + window.location.href + "/token"} className="redirect-budg">
                                <PlayArrowRoundedIcon style={{ fontSize: '15px' }} />
                            </Link> */}
                        </div>


                    </div>
                </div>
            </>
        )
}
