import React, { useRef } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Slide from "@mui/material/Slide";
import { Typography } from "@mui/material";
import { useDispatch } from 'react-redux'

import { setAsReadNotification, setAsUnReadNotification } from '../../redux-features/user/userSlice'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} timeout={1000} />;
});

export const AttachementDisplayModal = ({
  selectedNotification,
  open,
  onClose
}) => {
  const dispatch = useDispatch()
  const videoRef = useRef(null);
  const dialogActions = (
    <>
      {selectedNotification?.status === "unread" ? (
        <Button
          style={{ color: "#616ded" }}
          onClick={() => {
            dispatch(setAsReadNotification(selectedNotification._id));
          }}
        >
          Mark As Read
        </Button>
      ) : (
        <Button
          style={{ color: "#616ded" }}
          onClick={() => {
            dispatch(setAsUnReadNotification(selectedNotification._id));
          }}
        >
          Mark as unread
        </Button>
      )}
    </>
  );
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="sm"
      fullWidth
      TransitionComponent={Transition}
      keepMounted
    >
      <IconButton
        aria-label="close"
        onClick={() => {
          if (selectedNotification?.fileType === "video") {
            videoRef.current.pause()
          }
          onClose();
        }}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent
        sx={{ display: "flex", justifyContent: "center", flexDirection: "column" }}
      >
        <Typography sx={{ py: 4 }}>{selectedNotification?.message}</Typography>
        {selectedNotification?.fileType === "image" && (
          <div
            style={{
              width: "100%",
              position: "relative",
            }}
          >
            <img
              src={selectedNotification?.fileUrl}
              style={{
                objectFit: "cover",
                width: "100%",
                height: "100%",
              }}
              alt="Notification Image"
            />
          </div>
        )}
        {selectedNotification?.fileType === "video" && (
          <div
            style={{
              width: "100%",
              height: "250px",
              position: "relative",
            }}
          >
            <video
              style={{
                objectFit: "cover",
                width: "100%",
                height: "100%",
                position: "absolute",
                top: 0,
                left: 0,
              }}
              ref={videoRef}
              controls
            >
              <source src={selectedNotification?.fileUrl} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        )}
      </DialogContent>

      <DialogActions>{dialogActions}</DialogActions>
    </Dialog>
  );
};
